import React, { useContext, useState, useEffect } from 'react';


// firbase
import { database } from '../FirebaseConfig';

// icons
import Copy from '../../assets/img/copy.png';

// context
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';

//assets

// import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
// import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { KeyboardArrowDown, CloseRounded, SearchRounded } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import AvatarChatImage from '../Avatar/AvatarChatImage';
import { set } from 'firebase/database';
import CustomButton from '../../common/CustomButton.jsx';

const InviteMember = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  // console.log(modalCtx.details.activeNetworkId);

  const [email, setEmail] = useState();
  const [emailList, setEmailList] = useState([]);
  const [shareUrl, setShareUrl] = useState('');
  // const [isLoading, setIsLoading] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inviteSent, setInviteSent] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTab, setSelectedTab] = useState(2);

  useEffect(() => {
    if (modalCtx.details.tab == 'invite') {
      changeTabTo3()
    }
  }, []);

  useEffect(() => {
    fetchResults();
    console.log(modalCtx.details)
  }, []);

  useEffect(() => {
    if (searchQuery && selectedTab === 2) {
      searchMembers();
    }
  }, [searchQuery, selectedTab]);



  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + modalCtx.details.activeNetworkId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authCtx.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          setShareUrl(data.data.shareUrl);
          // console.log(data);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const searchMembers = () => {
    if (!searchQuery) {
      setMembers([]);
    }
    setMembersLoading(true);

    fetch(
      process.env.REACT_APP_API_URI +
      `/v2/user/${authCtx.userName}/hub/${modalCtx.details.activeNetworkId}/search/hubInvite/${searchQuery}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          // console.log(data);
          const limitedMemberData = data?.data.slice(0, 100); // Limit data to the first 100 results
          setMembers(limitedMemberData);
          setMembersLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const inviteMember = (user) => {
    // console.log(user, modalCtx.details.activeNetworkId);

    let payload = {
      userId: user,
      hubId: modalCtx.details.activeNetworkId,
      role: 'invited',
      notification: 'invite-sent',
    };

    fetch(
      process.env.REACT_APP_API_URI + `/v2/hubs/${modalCtx.details.activeNetworkId}/membership`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          setOpenSnackbar(true);
          setInviteSent(true);
          // console.log(data);
        } else {
          // setOpenSnackbar(true);
        }
      });
    setInviteSent(false);

    // setTimeout(() => {
    //   setMembers([])
    //   setSearchQuery('')
    // }, 3000);
  };

  const sendEmailInvite = (user) => {
    // console.log(modalCtx.details.activeNetworkId);
    console.log(emailList);
    setSendingEmail(true)
    
    let payload = {
      emails: emailList
    };
    
    fetch(process.env.REACT_APP_API_URI + `/v2/hubs/${modalCtx.details.activeNetworkId}/inviteByEmail`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then((response) => { return response.json(); })
    .then((data) => {
      if (data.status === 'success') {
        setOpenSnackbar(true);
        setInviteSent(true);
        // console.log(data);
        setEmailList([])
        setSendingEmail(false)
        } else {
          // setOpenSnackbar(true);
        }
      });
    // setInviteSent(false);

    // setTimeout(() => {
    //   setMembers([])
    //   setSearchQuery('')
    // }, 3000);
  };
  const sendUserInviteByUserName = (user) => {

    const userIdList = selectedMembers.map((item) => item.id);
    let payload = {
      userIds: userIdList,
      hubId: modalCtx.details.activeNetworkId,
      role: 'invited',
    };

    fetch(process.env.REACT_APP_API_URI + `/v2/hubs/${modalCtx.details.activeNetworkId}/Bulkmembership`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
      .then((response) => { return response.json(); })
      .then((data) => {
        if (data.status === 'success') {
          setOpenSnackbar(true);
          setInviteSent(true);
          setSelectedMembers([]);
          // console.log(data);
        } else {
        }
      });

  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const closeHandle = () => {
    modalCtx.closeModal();
  };

  const addEmail = (e) => {
    let emailStr = e.target.value;
    setEmail(emailStr);
    // console.log(emailStr);
  };

  const addToEmailArray = (e) => {
    // console.log(e);
    if (e.charCode === 13 && /(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(email)) {
      // getEmail.push(email)
      setEmailList((prev) => [...prev, email]);
      setEmail('');
    }
    // console.log(emailList);
  };

  const removeFromEmailArray = (index) => {
    // console.log('clicked');
    setEmailList((current) => current.filter((_, id) => id !== index));
    // console.log(emailList);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setOpenSnackbar(true);
  };

  const changeTabTo3 = () => {
    setSelectedTab(2)
  }

  const checkChecked = (id) => {
    return selectedMembers.some((item) => item.id === id);
  }

  const handleMemberSelect = (e, member) => {
    // console.log(e.target)
    if (e.target.checked) {
      // console.log('checked')
      setSelectedMembers([...selectedMembers, member])
      console.log(selectedMembers)
    } else {
      setSelectedMembers(selectedMembers.filter((item) => item.id !== member.id))
    }
  }
  // console.log(shareUrl)

  const getTabData = (tab) => {
    switch (tab) {
      case 0:
        return <>
          <div className='tab-panel'>
            <div className="invite-team-container">
              <div className="invite-by-link">
                <div className="link">
                  <div className="link-box">{shareUrl}</div>
                  <div className="link-copy" onClick={() => copyToClipboard()}>
                    <img src={Copy} alt="copy icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      case 1:
        return <>
          <div className='tab-panel'>
            <div className="invite-team-container">
              <div className="invite-by-email">
                <div className="add-email">
                  {emailList.map((item, index) => (
                    <div className="email" key={index}>
                      {item}
                      <div
                        className="remove-email"
                        onClick={() => removeFromEmailArray(index)}
                      >
                        <CloseRounded className="rm-email" />
                      </div>
                    </div>
                  ))}
                  <input
                    type="text"
                    placeholder="Press enter to add new email"
                    value={email}
                    onChange={addEmail}
                    onKeyPress={addToEmailArray}
                  />
                </div>
              </div>
              <div className="send-btn-container">
                <CustomButton disabled={!emailList.length} className={emailList.length ? 'f-btn active' : 'f-btn'} onClick={() => sendEmailInvite()} >
                  {
                    sendingEmail
                    ? <CircularProgress style={{color: "#fff"}} size={20} />
                    : "Send"
                  }
                </CustomButton>
              </div>
            </div>
          </div>
        </>
      case 2:
        return <>
          <div className='tab-panel'>
            <div className="invite-team-container">
              <div className="invite-members">
                <div className="search-member">
                  <input
                    type="text"
                    placeholder="Search frequency members"
                    onChange={({ target }) => setSearchQuery(target.value)}
                    value={searchQuery}
                  />
                  <SearchRounded className="search-icon" />
                </div>
              </div>

              {members.length == 0 ? null : <p className="followers-heading">Users</p>}
              <div className="frequency-members">
                {/* Showing Selected Users */}
                {!membersLoading && selectedMembers.length > 0 ? selectedMembers.map((member) => <div className="member" key={member.id}>
                  <div className="member-profile">
                    <div className="member-img">
                      <AvatarChatImage
                        avatarRef={member.avatar}
                        title={member.name}
                      />
                    </div>
                    <div className="member-deets">
                      <span className="user">{member.name}</span>
                      <span className="username">@{member.username}</span>
                    </div>
                  </div>

                  <Checkbox
                    checked={checkChecked(member.id)}
                    onClick={(event) => handleMemberSelect(event, member)}
                    sx={{
                      '&.Mui-checked': {
                        color: '#CD4A4A',
                      },
                    }}
                  />
                </div>) : null}
                {
                  !membersLoading && searchQuery.trim() !== ''
                    ? members?.map((member) => (!checkChecked(member.id) ?
                      <div className="member" key={member.id}>
                        <div className="member-profile">
                          <div className="member-img">
                            <AvatarChatImage
                              avatarRef={member.avatar}
                              title={member.name}
                            />

                          </div>
                          <div className="member-deets">
                            <span className="user">{member.name}</span>
                            <span className="username">@{member.username}</span>
                          </div>
                        </div>
                        {/* <Checkbox
											sx={{
												'&.Mui-checked': {
													color: '#CD4A4A',
												},
											}}
										/> */}
                        <Checkbox
                          // icon={<CircleUnchecked />}
                          // checkedIcon={<CircleCheckedFilled />}
                          // value={checkChecked(member.id)}
                          checked={checkChecked(member.id)}
                          onClick={(event) => handleMemberSelect(event, member)}
                          sx={{
                            '&.Mui-checked': {
                              color: '#CD4A4A',
                            },
                          }}
                        />
                      </div>
                      : null))
                    : null
                }
                {/* {members.length > 3 ? (
								<div className="more-btn">
									<KeyboardArrowDown />
								</div>
							) : null} */}
                {membersLoading ? (
                  <div className="loading">
                    <CircularProgress style={{ color: '#007C89' }} size={60} />
                  </div>
                ) : null}
                {searchQuery.trim() === '' ? <p>Please enter a username.</p> : null}
              </div>
            </div>
            {selectedTab === 2 ? (
              <div className="invite-to-team-button-container">
                <CustomButton className="f-btn-bordered" onClick={closeHandle}>
                  Cancel
                </CustomButton>
                <CustomButton disabled={!selectedMembers.length > 0} className={searchMembers.length ? 'f-btn active' : 'f-btn'} onClick={sendUserInviteByUserName}>Invite</CustomButton>
              </div>
            ) : null}
          </div>
        </>

      default:
        break;
    }
  }


  return (
    <>
      <div className="invite-to-team">
        <div className="invite-team-heading">
          <h3>Invite your team</h3>
          <CloseRounded onClick={closeHandle} className="close-btn" />
        </div>
        <div >
          <div className='invite-member-tabs' >
            <div className={selectedTab == 2 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(2)}>Invite Members</div>
            <div className={selectedTab == 0 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(0)}>Copy link</div>
            <div className={selectedTab == 1 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(1)}>Share Via Email</div>
          </div>

          {getTabData(selectedTab)}

        </div>

        <div className="invite-team-container">
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: '100%' }}
            >
              {inviteSent ? 'Invite sent.' : 'Link copied to clipboard.'}
            </Alert>
          </Snackbar>
        </div>
      </div>

    </>
  );
};

export default InviteMember;
