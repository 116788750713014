import { useContext, useEffect, useState } from 'react'
import React from 'react'
//helper
import AddTrailingDots from '../../../helper/addTrailingDots'
import GetRandomColorForString from '../../../helper/getRandomColorForString'

import GetFirebaseUrl from './GetFirebaseUrl'
import GetImageFirebase from './GetImageFirebase'
import GetVideoFirebase from './GetVideoFirebase'
import GetPdfFirebase from './GetPdfFirebase'

import NoData from '../../../assets/img/networks/404.svg'
import View from '../../../assets/img/networks/view.svg'
import Download from '../../../assets/img/networks/download.svg'
import Edit from '../../../assets/img/networks/edit.svg'

import AuthContext from '../../../store/AuthContext'
import ModalContext from '../../../store/ModalContext'

import { DeleteOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { CircularProgress, Tooltip } from '@mui/material'
import CustomButton from '../../../common/CustomButton'

const Folder = ({ NetworkId, role }) => {

  const authCtx = useContext(AuthContext) 
  const modalCtx = useContext(ModalContext)

  const [attachments, setAttachments] = useState([])
  const [togglingAttachmentVisibility, setTogglingAttachmentVisibility] = useState(false)
  const [togglingAttachmentId, setTogglingAttachmentId] = useState(null)

  useEffect(() => {
    getAttachments()
  }, [NetworkId, modalCtx])

  const getAttachments = async () => {

    let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, } }
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${NetworkId}/attachments`, options)
      const data = await res.json()
      if (data.status === 'success') {
        setAttachments(data.data)
        console.log(data)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
    }

  }

  const toggleAttachmentVisibility = async (id) => {

    setTogglingAttachmentVisibility(true)

    var payload = {
      "attachmentId": id,
    };
    let options = {
      method: "PUT",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
      body: JSON.stringify(payload)
    }
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${authCtx.userId}/attachments/download`, options)
      const data = await res.json()
      if (data.status === 'success') {
        getAttachments()
        setTogglingAttachmentVisibility(false)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
      setTogglingAttachmentVisibility(false)
    }

  }

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  const toggleVisibilityIcon = (item) => {

    if (togglingAttachmentVisibility && togglingAttachmentId == item.id) {
      return <CircularProgress size={18} className='attachment-action' />
    } else if (item?.isDownloadable == 'yes') {
      return <VisibilityOutlined onClick={() => {toggleAttachmentVisibility(item.id); setTogglingAttachmentId(item.id)}} className='attachment-action' />
    } else {
      return <VisibilityOffOutlined onClick={() => {toggleAttachmentVisibility(item.id); setTogglingAttachmentId(item.id)}} className='attachment-action' />
    }

  }

  const displayThumbnail=(extension,fileRef)=>{
    if(extension.toLowerCase()==="png"||extension.toLowerCase()==="jpg"||extension.toLowerCase()==="jpeg"||extension.toLowerCase()==="tif"||extension.toLowerCase()==="webp"||extension.toLowerCase()==="svg")
    {
      return(
        <GetImageFirebase imageRef={fileRef} />
      )
    }
    else if(extension.toLowerCase()==="mp4"||extension.toLowerCase()==="avi"||extension.toLowerCase()==="wmy"||extension.toLowerCase()==="mov")
    {
      return(
        <GetVideoFirebase imageRef={fileRef} height={100} width={100} />
      )
    }
    else if(extension.toLowerCase()==="pdf")
    {
      return(
        <GetPdfFirebase imageRef={fileRef}/>
      )
    }
  }
  
  return (
    <div className='folder-container' >
      <div className="folder-wrapper">
        <div className="folder">
          <div className="folder-head">
            <h3>Attachments</h3>
            {(role == 'owner' || role == 'moderator') ? <CustomButton className="f-btn-big" onClick={() => handleModal('create-attachment', NetworkId)}>Add Attachment</CustomButton> : null}
          </div>
          <div className="folder-flex">
            {
              attachments?.length > 0
                ? attachments?.map((item, index) => (
                  
                  <div className="folder-item" key={index} >
                    {GetRandomColorForString(item.extension, 'folder-type')}
                    <div className="folder-image">
                      {displayThumbnail(item.extension,item.fileRef)}
                    </div>
                    <div className="folder-details">
                      <Tooltip title={item.titleName ? item.titleName : item.fileName} >
                        <h4 className="folder-title">{item.titleName ? item.titleName : item.fileName}</h4>
                      </Tooltip>
                      <div className="attachment-details">
                        <div className="attachment-size">{item.size ? item.size : '0 MB'}</div>
                        {(role == 'owner' || role == 'moderator') ? toggleVisibilityIcon(item) : null}
                        <GetFirebaseUrl className='attachment-action' fileRef={item.fileRef} file={item} />
                        {(role == 'owner' || role == 'moderator') ? <img onClick={() => handleModal('rename-attachment', item)} className='attachment-action' src={Edit} alt="edit attachment" /> : null}
                        {(role == 'owner' || role == 'moderator') ? <DeleteOutlined onClick={() => handleModal('delete-attachment', item)} className='attachment-action' /> : null}
                      </div>
                    </div>
                  </div>
                  
                ))
                : <div className="no-folder-info">
                  <img src={NoData} alt="no data" />
                  <h3>Sorry, Attachments</h3>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Folder