import React, { useEffect, useState, useContext, useRef } from 'react';

//firebase
import { update, ref, set } from "firebase/database";
import { database } from "../../../Components/FirebaseConfig";

//assets
import AddImg from '../../../assets/img/attachment/add-img.png'
import AddVid from '../../../assets/img/attachment/add-vid.png'
import AddGif from '../../../assets/img/attachment/add-gif.png'
import { Modal, Box } from '@mui/material';
import { CloseRounded, SendRounded, AttachFileRounded, MoodRounded } from '@mui/icons-material';

//context
import AuthContext from '../../../store/AuthContext.js';
import ModalContext from '../../../store/ModalContext.js';
import MessageContext from '../../../store/MessageContext.js';

//components
import Picker from 'emoji-picker-react';
import { Mention, MentionsInput } from 'react-mentions';

const DmMessageBox = ({ dmId, membersArray, messageBoxRef }) => {

  // console.log(selectedNetwork)

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const msgCtx = useContext(MessageContext);

  const db = database;
  const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;

  const [messageText, setMessageText] = useState('');

  const [showPicker, setShowPicker] = useState(false);
  const [openAttach, setOpenAttach] = useState(false);
  const [members, setMembers] = useState([])

  const focusRef = useRef(null)

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: "50vw",
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 3,
    maxHeight: "85vh"
  };


  // puts the msgCtx value in the text box
  useEffect(() => {
    //focusRef.current.focus()
    if (msgCtx.messageType === "edit") {
      setMessageText(msgCtx.details.length === 0 ? "" : msgCtx.details.message)
    } else if (msgCtx.messageType === "reply") {
      setMessageText("")
    } else {
      setMessageText("")
    }
  }, [msgCtx.details])

  // // reset msgCtx if there is change in network hubs
  // useEffect(() => {

  //   if (msgCtx.messageType === "edit" && selectedNetwork !== msgCtx.details.huhId) {
  //     msgCtx.setDetails("", "")
  //   } else if (msgCtx.messageType === "reply" && selectedNetwork !== msgCtx.details.huhId) {
  //     msgCtx.setDetails("", "")
  //   } else {
  //     return;
  //   }

  // }, [selectedNetwork])

  useEffect(() => {

    let users = []
    membersArray.map((item, i) => {
      users[i] = {
        id: i + 1,
        display: item.username
      };
    });
    setMembers(users)

  }, [membersArray])


  // // reset msgCtx if there is change in network hubs
  // useEffect(() => {
  //   //console.log(selectedNetwork)

  //   // fetching members
  //   fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + selectedNetwork + '/members', {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authCtx.token
  //     }
  //   }).then(response => {
  //     return response.json();
  //   }).then(data => {
  //     if (data.status === "success") {
  //       //console.log(data?.data)
  //       makeUserObject(data?.data)
  //     } else {
  //     }
  //   });

  // }, [selectedNetwork])


  // desides where to send the msg
  const decideMsg = () => {
    switch (msgCtx.messageType) {
      case "edit": updateMessage()
        break;

      case "reply":
        replyMessage()
        break;

      case "":
        pushNewMessage()
        break;

      default:
        break;
    }
  }

  // pushes completely new msg to firebase
  const pushNewMessage = () => {

    let msgTxt = messageText;
    setMessageText("");
    let array = msgTxt.split(' ');

    const map1 = array.map(x => {
      if (x.includes("@[")) {
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return `@${returnArr}`
        }
      } else {
        return x
      }
    });


    let finalMsg = map1.join(" ");
    console.log(finalMsg);

    let timeStamp = Date.now();

    var messagePayload = {
      "avatar": "",
      "userId": authCtx.userId,
      "hubId": 0,
      "userName": authCtx.userName,
      "message": finalMsg,
      "attachment": "",
      "timestamp": timeStamp
    };

    const dmMessageRef = ref(db, '/directMessages/' + dmId + '/' + timeStamp);

    set(dmMessageRef, messagePayload);

    var payload = {
      userId: authCtx.userId,
      sendingUserIds: members,
      hubId: dmId,
      messageType: "directMessages",
      userName: authCtx.userName,
      message: finalMsg,
      avatar: authCtx.avatarRef,
      replyMessage: {},
      tags: "",
      messageId: timeStamp
    };

    fetch(process.env.REACT_APP_API_URI + '/v2/chat', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
    });
  }

  // pushes reply msg to firebase
  const replyMessage = () => {

    setMessageText("");

    let msgTxt = messageText;
    let rAttachment = msgCtx.details.attachment;
    let rContent = msgCtx.details.message;
    let rUserId = msgCtx.details.userId
    let rUsername = msgCtx.details.userName
    let timeStamp = Date.now();
    let array = msgTxt.split(' ');

    const map1 = array.map(x => {
      if (x.includes("@[")) {
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]")
        )
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")")
        );
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return `@${returnArr}`
        }
      } else {
        return x
      }
    });


    let finalMsg = map1.join(" ")
    console.log(finalMsg)

    var messagePayload = {
      "avatar": "",
      "userId": authCtx.userId,
      "hubId": 0,
      "userName": authCtx.userName,
      "replyMessage": {
        "attachment": rAttachment,
        "content": rContent,
        "id": "0",
        "userId": rUserId,
        "username": rUsername
      },
      "message": finalMsg,
      "attachment": "",
      "timestamp": timeStamp
    };

    const dmMessageRef = ref(db, '/directMessages/' + dmId + '/' + timeStamp)

    set(dmMessageRef, messagePayload);

    var payload = {
      userId: authCtx.userId,
      sendingUserIds: members,
      hubId: 0,
      messageType: "directMessages",
      userName: authCtx.userName,
      message: finalMsg,
      avatar: authCtx.avatarRef,
      replyMessage: {
        attachment: rAttachment,
        content: rContent,
        id: "0",
        userId: rUserId,
        username: rUsername
      },
      tags: "",
      messageId: timeStamp
    };
    console.log(payload);

    fetch(process.env.REACT_APP_API_URI + '/v2/chat', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
    });

  }

  // pushes updated msg to firebase
  const updateMessage = () => {
    setMessageText("");

    let timeStamp = modalCtx.details.timestamp
    let hubId = modalCtx.details.hubId
    let msgTxt = messageText;
    let array = msgTxt.split(' ');

    const map1 = array.map(x => {
      if (x.includes("@[")) {
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return `@${returnArr}`
        }
      } else {
        return x
      }
    });


    let finalMsg = map1.join(" ")
    console.log(finalMsg)

    const dmMessageRef = ref(db, '/directMessages/' + dmId + '/' + timeStamp)

    update(dmMessageRef, { "message": finalMsg })
    msgCtx.setDetails("", "")
    modalCtx.closeModal();
  }

  // open attachment menu
  const toggleAttachBtn = () => {
    if (!openAttach) {
      setOpenAttach(true)
    } else {
      setOpenAttach(false)
    }
  }

  //getting meesage from input
  const getMessageText = (e) => {
    if (e.target.value == "\n") return;
    console.log(e)
    var megStr = e.target.value
    setMessageText(megStr)
  }

  //  opens a modal for message actions
  const showModal = (modal, video) => {
    setOpenAttach(false)
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }

  //emoji picker
  const onEmojiClick = (event, emojiObject) => {
    setMessageText(prevInput => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && messageText) {
      decideMsg(msgCtx.messageType);
      setMessageText("")
    }
  }

  // what type of msg is to be edited
  const msgType = () => {
    switch (msgCtx.messageType) {
      case "":
        return null;

      case "reply":
        return (
          <div className="msg-edit">
            <div className="content-container">
              <div className="reply-content">
                <div className="content">
                  <div className="name"><span>{msgCtx.details.userName}</span> <CloseRounded onClick={clearMsg} /></div>
                  <div className="content">{msgCtx.details.message !== "" ? msgCtx.details.message : <span style={{ background: "transparent", fontWeight: "bold", color: "#007C8990" }}>Media</span>}</div>
                </div>
                <div className="close-btn">
                  <CloseRounded onClick={clearMsg} />
                </div>
              </div>
            </div>
          </div>
        );

      case "edit":
        return (
          <div className="msg-edit">
            <div className="content-container">
              <div className="reply-content">
                <div className="content">
                  <div className="name"><span>{authCtx.userName}</span> </div>
                  <div className="content">{msgCtx.details.message !== "" ? msgCtx.details.message : "Media"}</div>
                </div>
                <div className="close-btn">
                  <CloseRounded onClick={clearMsg} />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  }

  // to clear a reply msg or edit msg
  const clearMsg = () => {
    msgCtx.setDetails("", "")
    setMessageText("")
  }


  const defaultStyle = {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
    },

    '&multiLine': {
      control: {
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        border: '1px solid silver',
      },
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },

    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }

  const checkForClipboard = () => {
    window.addEventListener('paste', function (evt) {
      const clipboardItems = evt.clipboardData.items;
      const items = [].slice.call(clipboardItems).filter(function (item) {
        // Filter the image items only
        console.log(item)
        return /^image\//.test(item.type);
      });
      if (items.length === 0) {
        console.log("no image")
        return;
      }

      // const item = items[0];
      // const blob = item.getAsFile();

      // const imageEle = document.getElementById('preview');
      // imageEle.src = URL.createObjectURL(blob);
      // let file = new File([blob], "file name",{type:"image/jpeg", lastModified:new Date().getTime()}, 'utf-8');
      // let container = new DataTransfer(); 
      // container.items.add(file);
      // document.querySelector('#file_input').files = container.files;
    });
  }

  //console.log(messageText)

  return (
    <>
      <div className="message-box-container">

        <div className="message-box" ref={messageBoxRef}>

          {msgType()}
          <div className="message-text-box">
            <div className="dm-textbox-option" onClick={() => setShowPicker(val => !val)} ><span className="icon"><MoodRounded /></span></div>
            <div className="dm-textbox-option attach-parent" onClick={toggleAttachBtn}>
              <span className="icon"><AttachFileRounded /></span>
            </div>
            <div className="attach-media" style={openAttach ? null : { display: "none" }}>
              <div className="attach-btns">
                <div className="arrow">
                  <div></div>
                </div>
                <div className="icon-img" onClick={() => showModal('AddImage', { dmId })}>
                  <img src={AddImg} alt="add" />
                  <span>Add Image</span>
                </div>
                <div className="icon-img" onClick={() => showModal('AddVideo', { dmId })}>
                  <img src={AddVid} alt="add" />
                  <span>Upload Video</span>
                </div>
                <div className="icon-img" onClick={() => showModal('AddGiphy', { dmId })}>
                  <img src={AddGif} alt="add" />
                  <span>Import Giphy</span>
                </div>
              </div>
            </div>
            {/* <input
                placeholder="Type a message"
                onKeyDown={handleKeyDown}
                className="input-style"
                value={messageText}
                //defaultValue={msgCtx.details.length === undefined ? '' : msgCtx.details.message}
                onChange={e => setMessageText(e.target.value)} 
                ref={focusRef}
              /> */}
            <MentionsInput
              // ref={focusRef}
              onPaste={() => checkForClipboard()}
              className="input-style"
              onKeyDown={handleKeyDown}
              markup="@[__display__](__id__)"
              value={messageText}
              style={defaultStyle}
              onChange={e => getMessageText(e)}
              placeholder={"Type a message"}
              a11ySuggestionsListLabel={"Suggested mentions"}
            >
              <Mention trigger={emailRegex} data={(email) => [{ id: null, display: email }]} style={{ backgroundColor: "#3483FA70" }} />
              <Mention data={members} style={{ backgroundColor: "#3483FA70" }} trigger="@" />
              {/*  // TODO: change the member array with the networks array for the # trigger  */}
              <Mention data={members} style={{ backgroundColor: "#3483FA70" }} trigger="#" />
            </MentionsInput>

            {/* <input type="text" className="text-input" value={messageText} placeholder="Type a message" onKeyDown={handleKeyDown} onChange={(e) => setMessageText(e.target.value)} /> */}
            {/* <button className="dm-textbox-option"><span className="icon"><MicRoundedIcon /></span></button> */}
            <div className="dm-textbox-option" disabled={messageText == '' ? true : false} onClick={() => decideMsg(msgCtx.messageType)}><span className="icon" ><SendRounded /></span></div>
          </div>
        </div>
      </div>
      <Modal open={showPicker} className="add-goal-lightbox emoji-lightbox">
        <Box sx={boxStyles} className="emoji-box" >
          <div className="close-emoji">
            <CloseRounded onClick={() => setShowPicker(false)} />
          </div>
          {showPicker && <Picker pickerStyle={{ width: '100%' }} onEmojiClick={onEmojiClick} />}
        </Box>
      </Modal>
    </>
  )
}

export default DmMessageBox