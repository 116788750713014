import React, { useState, useContext } from 'react'

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';

//componets
import AvatarChatImage from '../Components/Avatar/AvatarChatImage.jsx';
import WaveFollowing from '../Components/Wave/WaveFollowing.jsx';
import WaveDiscover from '../Components/Wave/WaveDiscover.jsx';

//assets
import FollowingBlack from '../assets/img/wave/following-black.svg'
import FollowingRed from '../assets/img/wave/following-red.svg'
import DiscoverBlack from '../assets/img/wave/discover-black.svg'
import DiscoverRed from '../assets/img/wave/discover-red.svg'
import CreateBlack from '../assets/img/wave/create-black.svg'

import '../Styles/Wave.scss'

const Wave = () => {

	const authCtx = useContext(AuthContext);
	const modalCtx = useContext(ModalContext);
	const [noFriends, setNoFriends] = useState(false)
	const [activeTab, setActiveTab] = useState('following')

    const handleModal = (modal) => {
        modalCtx.openModal();
        modalCtx.setDetails(modal, null);
    }


  return (
    <div className='wave-container' >
        <div className="wave-nav-container">
            <div className="nav-item" onClick={() => setActiveTab('following')}>
                <img src={activeTab =='following' ? FollowingRed : FollowingBlack} alt="" />
                <span style={activeTab == 'following' ? {color: '#CD4A4A'} : null} >Following</span>
            </div>
            <div className="nav-item" onClick={() => setActiveTab('discover')}>
                <img src={activeTab =='discover' ? DiscoverRed : DiscoverBlack} alt="" />
                <span style={activeTab == 'discover' ? {color: '#CD4A4A'} : null}>Discover</span>
            </div>
            <div className="nav-item" onClick={() => handleModal('createPost')}>
                <img src={CreateBlack} alt="" />
                <span>Create</span>
            </div>
        </div>
        
        {activeTab == 'following' ? <WaveFollowing noFriends={noFriends} /> : null}

        {activeTab == 'discover' ? <WaveDiscover /> : null}
        
    </div>
  )
}    

export default Wave