import React, { useRef, useEffect, useState, useContext } from 'react'

//context
import ModalContext from '../../../../store/ModalContext';
import AuthContext from '../../../../store/AuthContext';

//MUI
import { Alert, Button, CircularProgress, FormControlLabel, FormGroup, InputBase, Snackbar, Switch, TextField, Tooltip } from '@mui/material';
import { Close, InfoRounded } from '@mui/icons-material';

//components
import Dropzone from '../../../ReactDrop/Dropzone';

//firebase
import { ref, set } from 'firebase/database';
import { ref as sRef, uploadBytes } from '../../../FirebaseConfig';
import storage, { database } from '../../../FirebaseConfig';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import CustomButton from '../../../../common/CustomButton';

const CreateEvent = () => {

  const dateNow = new Date()
  console.log(dateNow)
  //firebase db
  const db = database;

  //refs
  const FileRef = useRef(null)

  //handle file input
  const handleFileInput = () => {
    FileRef.current.click()
  }

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [file, setFile] = useState(null)
  const [previewFile, setPreviewFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('')
  const [fileExtension, setFileExtension] = useState('')
  const [eventType, setEventType] = useState('public')
  const [eventTitle, setEventTitle] = useState('')
  const [eventDescription, setEventDescription] = useState('')
  const [eventLink, setEventLink] = useState('')

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [loading, setLoading] = useState(false)


  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  // console.log(fileSize)
  // console.log(fileExtension)

  //adding a post
  const addPost = () => {

    handleEventPost()
    if (!file) return
    // setLoading(true)

    // let fileRef = `users/${authCtx.userId}/events/${file.name}`;
    // const storageRef = sRef(storage, fileRef)

    // uploadBytes(storageRef, file).then((snapshot) => {

    //   var postPayload = {
    //     hubId: modalCtx.details,
    //     title: eventTitle,
    //     image: fileRef,
    //     eventType: eventType,
    //     description: eventDescription,
    //     link: eventLink,
    //     startDate: moment(startDate).format('YYYY-MM-DD h:mm:ss'),
    //     endDate: moment(endDate).format('YYYY-MM-DD h:mm:ss'),
    //     startTimestamp: moment(startDate).format('x'),
    //     endTimestamp: moment(endDate).format('x')
    //   };

    //   postAttachment(postPayload)

    // }, (error) => console.log(error));
  }

  const postAttachment = async (payload) => {
    console.log(payload)

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/event`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        },
        body: JSON.stringify(payload)
      })
      const data = await res.json()
      if (data.status === 'success') {
        // console.log(data)
        modalCtx.closeModal()
        setLoading(false)
      } else {
        setLoading(false)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
      setLoading(false)
    }

  }


  // get dropped files
  // const onDrop = (files) => {

  //   let file = files[0]
  //   if (!file) return

  //   setFileSize(fileSizeCalc(file.size))
  //   getExtension(file.name)

  //   let imageFileTooBig = false

  //   if (calculateFileSize(file) >= 25) {
  //     imageFileTooBig = true
  //   }

  //   if (imageFileTooBig) {
  //     setSnackbarMessage('Image size should below 25 MB')
  //     setOpenSnackBar(true)
  //   } else {
  //     setFile(file)
  //     returnFilePreviewObject(file)
  //   }
  // };

  const handleFile = (e) => {

    let file = e.target.files[0]
    if (!file) return

    setFileSize(fileSizeCalc(file.size))
    getExtension(file.name)

    let imageFileTooBig = false

    if (calculateFileSize(file) >= 25) {
      imageFileTooBig = true
    }

    if (imageFileTooBig) {
      setSnackbarMessage('Image size should below 25 MB')
      setOpenSnackBar(true)
      FileRef.current.value = null;
    } else {
      setFile(file)
      returnFilePreviewObject(file)
    }
  }

  const handleEventPost = () => {
    if (eventTitle == '') {
      setSnackbarMessage('Enter Title Of Event.')
      setOpenSnackBar(true)
    } else if (file == null) {
      setSnackbarMessage('Add Image For Event.')
      setOpenSnackBar(true)
    } else if (moment(startDate).format('x') === moment(endDate).format('x')) {
      setSnackbarMessage('Start Date and End Date Cannot Be The Same.')
      setOpenSnackBar(true)
    } else if (moment(startDate).format('x') - moment(endDate).format('x') > 0) {
      setSnackbarMessage('Start Date and End Date Cannot Be The Same.')
      setOpenSnackBar(true)
    } else {
      setFile(file)
      returnFilePreviewObject(file)
    }
  }


  //calculates files size
  const calculateFileSize = (file) => {
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;

    return fileMb
  }

  const getExtension = (name) => {
    let fileName = name
    setFileExtension(fileName?.split('.').pop())
    const file = fileName?.split('.')?.slice(0, -1)
    setFileName(file.join('-'))
  }

  const fileSizeCalc = (size) => {

    var fSExt = ['Bytes', 'KB', 'MB', 'GB']
    let i = 0;
    //check if file is in GB,MB,KB or Bytes
    while (size > 900) {
      size /= 1024; //divide file size 
      i++;
    }
    //get exact size
    var exactSize = (Math.round(size * 100) / 100) + fSExt[i];
    return exactSize
  }

  const returnFilePreviewObject = (incomingFile) => {
    console.log(incomingFile)
    if (incomingFile) {
      var reader = new FileReader();
      reader.onload = (file) => {
        setPreviewFile({ name: incomingFile.name, type: incomingFile.type, file: file.target.result })
      }
      reader.readAsDataURL(incomingFile)
    }
  }


  //remove the file from files array and preview array
  const removeFile = () => {
    if (file && previewFile) {
      setFile(null)
      setPreviewFile(null)
      setFileName('')
    }
  }

  //handle snackbar close
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false)
  }


  // console.log(moment(startDate).format('YYYY-MM-DD h:mm:ss'))
  console.log(dayjs(startDate).millisecond())
  console.log(dayjs(endDate).format('DD/MM/YYYY'))
  // console.log(moment(startDate).format('x') - moment(endDate).format('x'))

  console.log(Date.now())

  return (
    <>
      <div className='create-event'>
        <input ref={FileRef} onChange={(e) => handleFile(e)} type="file" accept='image/*' className='file-input' />
        <div className="event-details">
          <div className="event-image-title">
            <div className="event-image" placeholder='add image' onClick={() => handleFileInput()}>
              {
                previewFile
                  ? <img src={previewFile.file} alt="preview" />
                  : null
              }
            </div>
            <div className="title-details">
              <div className="event-title-input">
                <label>Event Title:</label>
                <input onChange={(e) => setEventTitle(e.target.value)} value={eventTitle} className='input-box' />
              </div>
              <div className="net-public-private">
                <div className={eventType === "public" ? "net-public active" : "net-public"} onClick={() => setEventType("public")}>public</div>
                <div className={eventType === "private" ? "net-private active" : "net-private"} onClick={() => setEventType("private")} >private</div>
              </div>
            </div>
          </div>
          <div className="event-details-input">

            <div className="event-link">
              <label>Event link:</label>
              <input onChange={(e) => setEventLink(e.target.value)} value={eventLink} className='input-box' />
            </div>

            <div className="event-description">
              <label>Event description:</label>
              <input onChange={(e) => setEventDescription(e.target.value)} value={eventDescription} className='input-box' />
            </div>

            <div className="event-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disablePast
                  label="Start Date and Time"
                  value={dayjs(startDate)}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disablePast
                  label="End Date and Time"
                  value={dayjs(endDate)}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </div>

          </div>
          <div className="confirm-delete-btns">
            <CustomButton className="f-btn-bordered" onClick={closeHandle}>
              Cancel
            </CustomButton>
            <CustomButton className="f-btn" onClick={() => !loading && addPost()} >
              {
                loading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : "Create"
              }
            </CustomButton>
          </div>
        </div>

        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default CreateEvent