import React, { useContext, useState, useEffect } from 'react'

//context
// import AuthContext from '../../store/AuthContext.js';
// import ModalContext from '../../store/ModalContext.js';

//assets
import NoData from '../../../assets/img/networks/404.svg'

//comps
import GetImageFirebase from './GetImageFirebase.jsx';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LiveViewer from './Live/LiveViewer.jsx';
import CompletedLiveViewer from './Live/CompletedLiveViewer.jsx';

//mui
import { NotificationsNoneRounded, VisibilityOutlined } from '@mui/icons-material';

// firebase
import { ref, onValue, orderByChild, limitToLast } from 'firebase/database'
import { database } from '../../../Components/FirebaseConfig.jsx'

//scss
import { Button } from '@mui/material';
import moment from 'moment';

const LiveStream = ({ NetworkId }) => {

  const db = database;

  // const authCtx = useContext(AuthContext);
  // const modalCtx = useContext(ModalContext);


  const [liveStreams, setLiveStreams] = useState([]);
  const [liveStreamsLive, setLiveStreamsLive] = useState([]);
  const [liveStreamsCompleted, setLiveStreamsCompleted] = useState([]);
  const [selectedLive, setSelectedLive] = useState([]);

  // const [streamViewOn, setStreamViewOn] = useState(false)
  const [liveStreamViewOn, setLiveStreamViewOn] = useState(false)
  const [completedStreamViewOn, setCompletedStreamViewOn] = useState(false)


  useEffect(() => {
    addLiveStreamListener()
  }, [NetworkId])

  useEffect(() => {

    const tempLiveArray = liveStreams?.filter((item) => item.status == 'live' || item.status == 'scheduled')
    const tempCompletedArray = liveStreams?.filter((item) => item.status == 'completed')

    setLiveStreamsLive(tempLiveArray)
    setLiveStreamsCompleted(tempCompletedArray)

  }, [liveStreams])


  const addLiveStreamListener = () => {
    const dbRef = ref(
      db,
      '/hubStreams/' + NetworkId,
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setLiveStreams(snapshotToArray(snapshot))
    })
    // console.log(dbRef)
  }

  const snapshotToArray = (snapshot) => {
    // console.log(snapshot)
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  const handleCompletedVideoView = (live) => {
    setCompletedStreamViewOn(true)
    setSelectedLive(live)
  }
  const handleLiveVideoView = (live) => {
    setLiveStreamViewOn(true)
    setSelectedLive(live)
  }



  console.log(liveStreams)
  // console.log(liveStreamsLive)

  return (
    <>
      <div className="live-container">
        <div className="live-stream-catalog" style={liveStreamViewOn || completedStreamViewOn ? { display: "none" } : null} >
          <div className="stream-container" style={liveStreamsLive?.length <= 0 ? { display: 'none' } : null}>
            <div className="streams-carousel">
              {
                liveStreamsLive?.length > 0 ?
                  (<Carousel autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false} showThumbs={false} interval={5000}>
                    {
                      liveStreamsLive?.map((item, index) => (
                        <div key={index} className="stream" onClick={() => item.status !== 'scheduled' ? handleLiveVideoView(item) : null} >

                          {
                            item.thumbnail
                              ? <GetImageFirebase imageRef={item.thumbnail} />
                              : null
                          }

                          <div className="profile">
                            <GetImageFirebase imageRef={item.avatar} />
                            <div className="name">{item.creator}</div>
                          </div>

                          {
                            item.status !== 'scheduled'
                              ? <div className="view-count">
                                <VisibilityOutlined />
                                <div className="count">400</div>
                              </div>
                              : <div className="scheduled-for">
                                LIVE SCHEDULED FOR {moment(item?.startTimestamp).format("DD MMM, LT")}
                              </div>
                          }

                          {
                            item.status !== 'scheduled'
                              ? <div className="we-are-live">
                                <span>we are live</span>
                              </div>
                              : <div className="notify-me">
                                <Button className='notify-me-btn' ><NotificationsNoneRounded /> Notify Me</Button>
                              </div>
                          }

                        </div>
                      ))
                    }
                  </Carousel>
                  ) : null
              }
            </div>
          </div>

          <div className="explore-streams">
            <h3>Explore Streams</h3>
            <div className="streams">
              {
                liveStreamsCompleted?.length > 0
                  ? liveStreamsCompleted?.map((item, index) => (
                    <div key={index} className="stream-box" onClick={() => handleCompletedVideoView(item)} >
                      <div className="stream-img">
                        <GetImageFirebase imageRef={item.thumbnail} />
                      </div>
                      <div className="stream-deets">
                        <div className="stream-name">{item.creator}</div>
                        <div className="stream-date">{moment(item?.timestamp).format('LLL')}</div>
                        <div className="stream-status">
                          <span>Status : </span>
                          <div className={`status is-${item.status}`}>{item.status}</div>
                        </div>
                      </div>
                    </div>
                  ))
                  : <div className="no-events-info">
                  <img src={NoData} alt="no data" />
                  <h3>Sorry, No Live Stream yet</h3>
                </div>
              }
            </div>
          </div>
        </div>

        {
          liveStreamViewOn
            ? <LiveViewer setSelectedLive={setSelectedLive} setLiveStreamViewOn={setLiveStreamViewOn} liveData={selectedLive} NetworkId={NetworkId} />
            : null
        }
        {
          completedStreamViewOn
            ? <CompletedLiveViewer setSelectedLive={setSelectedLive} setCompletedStreamViewOn={setCompletedStreamViewOn} liveData={selectedLive} NetworkId={NetworkId} />
            : null
        }

      </div>
    </>
  )
}

export default LiveStream
