import React, { useContext, useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';


import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import FollowerContext from '../../store/FollowerContext.js';

import { CloseRounded } from '@mui/icons-material';
import AvatarChatImage from '../Avatar/AvatarChatImage.jsx';
import NetworkImage from '../Networks/NetworkImage.jsx';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../common/CustomButton.jsx';

const UserProfile = () => {

  const navigate = useNavigate();
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const followerCtx = useContext(FollowerContext);

  const [userData, setUserData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("network");
  const [isLoading, setIsLoading] = useState(false)

  const closeHandle = () => {
    modalCtx.closeModal();
  }

  useEffect(() => {
    getUserProfileById()
  }, [modalCtx])

  const getHubTopic = (networkId) => {
    switch (networkId) {
      case 1:
        return 'Arts & Entertainment'
      case 2:
        return 'health & Wellness'
      case 3:
        return 'Live Sport'
      case 4:
        return 'US News'
      case 5:
        return 'Science'
      case 6:
        return 'Sports'
      case 7:
        return 'Support Groups'
      case 8:
        return 'Education'
      case 9:
        return 'Other'
      case 10:
        return 'Politics'
      case 11:
        return 'Social Justice'

      default:
        break;
    }
  }


  const getUserProfileById = async () => {
    try {

      setIsLoading(true)
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + modalCtx.details + "/profile", {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        }
      })
      const data = await res.json()
      if (data.status === "success") {
        console.log(data.data)
        setUserData(data.data)
        setIsLoading(false)
      } else {
        // setOpenSnackbar(true);
      };
    } catch (error) {
      console.log(error)
    }
  }

  const handleNavigation = (id) => {
    navigate("/networks/" + id, { replace: true })
    window.location.reload()
    closeHandle()
  }

  const handleFollowUser = (id) => {
    if (id == authCtx.userId) return null;

    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-follow' style={{ width: '50%' }} onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-follow' style={{ width: '50%' }} onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }

  const unFollowUser = async (id) => {
    console.log(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({ userId: id })
        })

      const data = await res.json()

      if (data.status === 'success') {
        console.log(data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const followUser = async (id) => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({ userId: id })
        })

      const data = await res.json()

      if (data.status === 'success') {
        console.log(data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const startNewDm = async (userData) => {

    const payload = {
      userIds: [userData.id],
      title: userData.name
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${authCtx.userId}/directMessages`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
          body: JSON.stringify(payload)
        }
      )
      const data = await res.json()

      if (data.status === 'success') {
        navigate("/messages/" + data.dmId, { replace: false });
        closeHandle()

      } else if (data.status === 'exists') {
        navigate("/messages/" + data.dmId, { replace: false });
        // console.log(data)
        closeHandle()
      }
    } catch (error) {
      console.log('error while getting members :', error)
    }

  }

  if (isLoading) {
    return <div className="user-profile-container" style={{ height: '627px', display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ 'color': '#007C89' }} size={60} /></div>
  }


  return (
    <>
      <div className="user-profile-container" >
        {/* <div className="profile-container">
          <div className="user-name">
          <div className="name">{userData && userData?.name}</div>
          <div className="username">@{userData && userData?.username}</div>
          <div className="follow-btns">
          <button>FOLLOW</button>
              <button disabled>SEND DM</button>
            </div>
          </div>
          <div className="user-img">
            {userData && <AvatarChatImage avatarRef={userData?.avatarRef} />}
          </div>
         </div> */}
        <div className="profile-container">
          <div className="user-img">
            {userData && <AvatarChatImage avatarRef={userData?.avatarRef} />}
          </div>
          <div className="user-name">
            <div className="name">{userData && userData?.name}</div>
            <div className="username">@{userData && userData?.username}</div>
          </div>
        </div>
        {/* <nav>
						<div className="nav-container">
							{authCtx.userId === modalCtx.details ? <span className={selectedTab === "invite" ? 'active' : null}  onClick={() => setSelectedTab("invite")}>Invites</span> : null}
							<span className={selectedTab === "network" ? 'active' : null}  onClick={() => setSelectedTab("network")}>Networks</span>
							<span className={selectedTab === "follower" ? 'active' : null}  onClick={() => setSelectedTab("follower")}>Followers</span>
							<span className={selectedTab === "following" ? 'active' : null}  onClick={() => setSelectedTab("following")}>Following</span>
						</div>
					</nav> */}
        {/* <div className="tab-container">

            <div className="invite-container" style={selectedTab === "invite" ? null : {display: "none"} }>
              {userData && userData?.networks?.map((data, i) => (
                <div key={i} className="networks">
                  <div className="hub-img">
                    <NetworkImage coverRef={ data.coverRef } title={data.title} />
                  </div>
                  <div className="network-text">
                    <h2>{data.title}</h2>
                  </div>
                </div>
              ))}
              {
                userData?.networks?.length === 0 ? <div className="no-networks">no invite yet.</div> : null
              }
            </div>

            <div className="networks-container" style={selectedTab === "network" ? null : {display: "none"}}>
              {userData && userData?.networks?.map((data, i) => (
                <div key={i} className="networks">
                  <div className="hub-img">
                    <NetworkImage coverRef={ data.coverRef } title={data.title} />
                  </div>
                  <div className="network-text">
                    <h2>{data.title}</h2>
                  </div>
                </div>
              ))}
              {
                userData?.networks?.length === 0 ? <div className="no-networks">User has no networks yet.</div> : null
              }
            </div>

            <div  className="follower-container" style={selectedTab === "follower" ? null : {display: "none"}}>
              {userData && userData?.networks?.map((data, i) => (
                <div key={i} className="follower">
                  <div className="follower-img">

                    <NetworkImage coverRef={ data.coverRef } title={data.title} />

                  </div>
                  <div className="follower-name">
                    <h2>{data.title}</h2>
                  </div>
                </div>
              ))}
              {
                userData?.networks?.length === 0 ? <div className="no-follower">User has no followers yet.</div> : null
              }
            </div>

            <div className="following-container" style={selectedTab === "following" ? null : {display: "none"}}>
              {userData && userData?.networks?.map((data, i) => (
                <div key={i} className="following">
                  <div className="following-img">
                    <NetworkImage coverRef={ data.coverRef } title={data.title} />
                  </div>
                  <div className="following-name">
                    <h2>{data.title}</h2>
                  </div>
                </div>
              ))}
              {
                userData?.networks?.length === 0 ? <div className="no-following">No following yet.</div> : null
              }
            </div>

          </div> */}

        <div className="networks-container">
          <h2>Networks</h2>
          <div className="networks">
            {userData && userData?.networks?.map((data, i) => (
              <div className="network">

                <div className="network-img" onClick={() => handleNavigation(data.id)}>
                  <NetworkImage coverRef={data.coverRef} title={data.title} />
                </div>
                <div className="network-side-a">
                  <div className="network-info">
                    <div className="network-name" onClick={() => handleNavigation(data.id)}>{data.title}</div>
                  </div>
                  <div className="network-topic">{getHubTopic(data.topicId)}</div>
                </div>
                <CustomButton>Join</CustomButton>
              </div>
            ))}
          </div>
        </div>

        <div className="action-btns">
          <CustomButton className='f-btn-bordered' style={{ width: '50%' }} onClick={() => startNewDm(userData)} >Message</CustomButton>
          {handleFollowUser(userData.id)}
          {/* <CustomButton className={userData.isFollower ?'f-btn-bordered' : 'f-btn'} style={{ width: '50%' }} disabled={userData.isFollower} >{userData.isFollower ? "following" : "follow"}</CustomButton> */}
        </div>

        <CloseRounded className='close' onClick={() => closeHandle()} />
      </div>
    </>
  )
}

export default UserProfile;
