import React, { useContext, useState, useEffect } from 'react';

import { ref, update, remove } from "firebase/database";
import { uploadBytes, ref as sRef, uploadString } from "../../../../Components/FirebaseConfig";
import { database } from "../../../../Components/FirebaseConfig";
import storage from "../../../../Components/FirebaseConfig";


import { Button, Tooltip, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl, CircularProgress, Snackbar, Alert, FormLabel, Slider, Checkbox, IconButton, ClickAwayListener, Switch, Modal, Box, Typography, Slide, FormGroup } from '@mui/material';
import { styled } from "@mui/material/styles";
import Avatar1 from '../../../../assets/img/avatar/avatar1.jpg'

import ModalContext from '../../../../store/ModalContext.js';
import AuthContext from '../../../../store/AuthContext.js';
import FollowerContext from '../../../../store/FollowerContext.js';
import { AddRounded, CloseRounded, GppMaybeOutlined, Public, SearchRounded, Wallpaper, Close, InfoOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AvatarChatImage from '../../../Avatar/AvatarChatImage.jsx';
import NetworkImage from '../../../Networks/NetworkImage.jsx';
import CustomButton from '../../../../common/CustomButton.jsx';


const CreateNetworkNew = (props) => {


  //misc
  const db = database;
  const navigate = useNavigate();
  const marks = [
    {
      value: 30,
      label: '30',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  //context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const followerCtx = useContext(FollowerContext)
  //states
  const [nameIp, setNameIp] = useState("");
  const [NetworkRestriction, setNetworkRestriction] = useState('open')
  const [activeStep, setActiveStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState(2);
  const [searchQuery, setSearchQuery] = useState();
  const [searchFollower, setSearchFollower] = useState("");
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState();
  const [hubImage, setHubImage] = useState();
  const [topic, setTopic] = useState('');
  const [hubImagePreview, setHubImagePreview] = useState();
  const [hubImageRef, setHubImageRef] = useState("");
  const [hubTitle, setHubTitle] = useState("");
  const [hubType, setHubType] = useState("frequency");
  const [accessType, setAccessType] = useState("private")
  const [topicId, setTopicId] = useState(1);
  const [universeId, setUniverseId] = useState(0);
  const [headline, setHeadline] = useState("");
  const [parentId, setParentId] = useState();
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState('recurring')
  const [recurringType, setRecurringType] = useState('weekly')
  const [permissions, setPermissions] = useState("open");
  const [imageURL, setImageURL] = useState('')
  const [featureItem, setFeatureItem] = useState("")
  const [features, setFeatures] = useState([])
  const [oneTimeFeatures, setOneTimeFeatures] = useState([])
  const [snackBarMessageText, setSnackBarMessageText] = useState("Please enter a title for the Network.")
  const [newNetworkId, setNetworkId] = useState(null);
  const [flaggedValue, setFlaggedValue] = useState(50);
  const [removedValue, setRemovedValue] = useState(80);
  const [inviteList, setInviteList] = useState([]);
  const [fees, setFees] = useState('');

  //boolean state
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isPaid, setIspaid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteopen, setDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1)
  const [buttonDisabled, setButtonDisabled] = useState(false)




  // useEffect
  useEffect(() => {

    setImageURL(modalCtx?.details?.data?.imageURL);
    setHubType(modalCtx?.details?.data?.hubType || 'frequency');
    setNameIp(modalCtx?.details?.data?.nameIp || '');
    setUniverseId(modalCtx?.details?.data?.universeId || 0);
    setTopicId(modalCtx?.details?.data?.topicId || 1);
    setHeadline(modalCtx?.details?.data?.headline || '');
    setDescription(modalCtx?.details?.data?.description || '');

  }, [])

  
  useEffect(() => {
    
    if (searchQuery) {
      searchMembers();
    }
    
  }, [searchQuery]);

  useEffect(() => {

    if (activeStep === 5) {
      navigate(`/network-created/${newNetworkId}`);
      modalCtx.closeModal();
    }

  }, [activeStep])

  useEffect(() => {
    if(flaggedValue > removedValue){
      setRemovedValue(flaggedValue)
    }
  }, [flaggedValue])
  
  // console.log(modalCtx);
  
  
  
  //functions
  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const handleChange = (event) => {
    setNameIp(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleChangePermission = (event) => {
    setPermissions(event.target.value);
  };

  const onAddFeature = () => {
    if (paymentType == "recurring") {
      if (featureItem.length === 0) {
        return
      }
      const newId = features.length === 0 ? 1 : Math.max(...features.map(item => item.id)) + 1;
      var obj = { id: newId, feature: featureItem };
      setFeatureItem("")
      setFeatures(features.concat(obj))
      handleAddClose()
    }
    else {
      if (featureItem.length === 0) {
        return
      }
      const newId = oneTimeFeatures.length === 0 ? 1 : Math.max(...oneTimeFeatures.map(item => item.id)) + 1;
      var obj = { id: newId, feature: featureItem };
      setFeatureItem("")
      setOneTimeFeatures(oneTimeFeatures.concat(obj))
      handleAddClose()
    }

  }

  const onDeleteFeature = (idToDelete) => {
    if (paymentType == "recurring") {
      const newFeatures = features.filter(item => item.id !== idToDelete);
      setFeatures(newFeatures)
      handleDeleteClose()
    }
    else {
      const newFeatures = oneTimeFeatures.filter(item => item.id !== idToDelete);
      setOneTimeFeatures(newFeatures)
      handleDeleteClose()
    }
  }

  const handleNetworkRestriction = (event) => {
    setNetworkRestriction(event.target.value);
  }

  const handleChangeTopic = (event) => {
    setTopicId(event.target.value);
  };

  const handleChangeUniverse = (event) => {
    setUniverseId(event.target.value);
  };

  const handleTitle = (e) => {
    let newText = e.target.value
    setHubTitle(newText)
  }

  const handleHeadline = (e) => {
    let newText = e.target.value
    setHeadline(newText)
  }

  const handleDesc = (e) => {
    let newText = e.target.value
    setDescription(newText)
  }


  const handleFile = (e) => {
    let img = e.target.files[0];
    setHubImage(img)
    const reader = new FileReader();

    reader.onload = function (e) {
      setHubImagePreview(e.target.result)
    };
    reader.readAsDataURL(img);

  }

  const removeImg = () => {
    setHubImagePreview('')
    setHubImage('')
  }

  const closeHandle = () => {
    modalCtx.setDetails('', {});
    modalCtx.closeModal();
  }

  const uploadImageModal = () => {
    modalCtx.setDetails("AddNewImage", { "from": 'create-network', hubType, nameIp, universeId, topicId, headline, description, selectedNetwork: modalCtx.details.selectedNetwork });
    modalCtx.openModal()
  }



  function valuetext(value) {
    return `${value}`;
  }

  // select payment recurring or one time
  function handlePaymentTypeChange(e) {
    setPaymentType(e.target.value);
  }
  function handleRecurringType(e) {
    setRecurringType(e.target.value);
  }

  const searchedFollower = followerCtx.followers?.filter(follower => {
    return follower.name.toLowerCase().includes(searchFollower.toLowerCase()) || follower.username.toLowerCase().includes(searchFollower.toLowerCase())
  })

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const feature_modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const feature_modal_attributes_styles = [
    {
      marginTop: "1rem",
      width: "100%",
      height: "40px",
      border: "none",
      borderBottom: "2px solid #ccc",
      padding: "0 0.5rem",
      transition: "250ms ease",
      outline: "none"
    },
    {
      marginLeft: "auto",
      marginTop: "2rem",
      fontWeight: 600,
      textTransform: "capitalize",
      width: "100%",
      height: "40px",
      background: "#cd4a4a",
      color: "#fff"
    },
    {
      fontWeight: 600,
      marginTop: "1rem",
      textTransform: "capitalize",
      width: "100%",
      height: "40px",
      color: "#cd4a4a",
      border: "2px solid #cd4a4a",
    }
  ];

  const getToxicityText = () => {
    if (flaggedValue > 90) {
      return ("You're such a f***ing moron");
    } else if (flaggedValue > 80) {
      return ("What the f*** are you doing in this chat");
    } else if (flaggedValue > 70) {
      return ("Ah sh**! What on earth are we going to do about this crazy mess");
    } else if (flaggedValue > 60) {
      return ("Oh sh**! That's great news");
    } else if (flaggedValue > 50) {
      return ("You son of a gun");
    } else if (flaggedValue > 40) {
      return ("I think it's funny to bully people online and make them feel bad.");
    } else {
      return ("You should not be in this chat");
    }
  }
  const getToxicityText2 = () => {
    if (removedValue > 90) {
      return ("You're such a f***ing moron");
    } else if (removedValue > 80) {
      return ("What the f*** are you doing in this chat");
    } else if (removedValue > 70) {
      return ("Ah sh**! What on earth are we going to do about this crazy mess");
    } else if (removedValue > 60) {
      return ("Oh sh**! That's great news");
    } else if (removedValue > 50) {
      return ("You son of a gun");
    } else if (removedValue > 40) {
      return ("I think it's funny to bully people online and make them feel bad.");
    } else {
      return ("You should not be in this chat");
    }
  }
  console.log(modalCtx)
  
  console.log(inviteList)


  const getTabData = (tab) => {
    switch (tab) {

      case 1:
        return <>
          <div className='invite-member-tab'>
            <div className="invite-member-container">
              <div className="invite-members">
                <div className="search-member">
                  <input type="text" placeholder="Search frequency members" onChange={({ target }) => setSearchFollower(target.value)} />
                  <SearchRounded className="search-icon" />
                </div>
              </div>

              {searchedFollower?.length == 0 ? <p className="followers-heading">You have no followers</p> : <p className="followers-heading">Followers</p>}

              <div className="frequency-members" style={searchedFollower?.length == 0 ? { display: 'none' } : null}>
                {
                  searchedFollower?.map((member, index) => (
                    <div className="member" key={member.id}>
                      <div className="member-profile" key={index}>
                        <div className="member-img">
                          <AvatarChatImage
                            avatarRef={member.avatar}
                            title={member.name}
                          />
                        </div>
                        <div className="member-deets">
                          <span className="user">{member.name}</span>
                          <span className="username">@{member.username}</span>
                        </div>
                      </div>
                      <Checkbox sx={{ '&.Mui-checked': { color: '#CD4A4A', }, }}  onChange={() => handleFollowerInviteList(member)} checked={inviteList?.includes(member.user_id)} />
                    </div>
                  )
                  )
                }
                
                {
                  membersLoading
                    ?
                    <div className="loading">
                      <CircularProgress style={{ color: '#007C89' }} size={60} />
                    </div>
                    : null
                }

              </div>
            </div>
          </div>
        </>
      case 2:
        return <>
          <div className='invite-member-tab'>
            <div className="invite-member-container">
              <div className="invite-members">
                <div className="search-member">
                  <input type="text" placeholder="Search frequency networks" onChange={({ target }) => setSearchQuery(target.value)} />
                  <SearchRounded className="search-icon" />
                </div>
              </div>

              {members?.length == 0 ? null : <p className="followers-heading">Followers</p>}

              <div className="frequency-members" style={members?.length == 0 ? { display: 'none' } : null}>
                {
                  !membersLoading
                    ? members?.map((member) => (
                      <div className="member" key={member.id}>
                        <div className="member-profile">
                          <div className="member-img">
                            <AvatarChatImage
                              avatarRef={member.avatar}
                              title={member.name}
                            />
                          </div>
                          <div className="member-deets">
                            <span className="user">{member.name}</span>
                            <span className="username">@{member.username}</span>
                          </div>
                        </div>
                        <Checkbox sx={{ '&.Mui-checked': { color: '#CD4A4A', }, }} onChange={() => handleInviteList(member)} checked={inviteList.includes(member.email)} />
                      </div>
                    ))
                    : null
                }
                {membersLoading ? (
                  <div className="loading">
                    <CircularProgress style={{ color: '#007C89' }} size={60} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>

      default:
        break;
    }
  }


  const handleNextStep = () => {
    if (activeStep === 1) {
      createNetworkFirstStep()
    }
    else if (activeStep === 2) {
      updateAccess()
    }
    else if (activeStep === 3) {
      updateModeration()
    }
    else if (activeStep === 4) {
      inviteMembers()
    } else {
      setActiveStep(prev => prev + 1)
    }
  }

  const checkIfNextBtnDisabled = () => {
    if (activeStep === 1 && (nameIp.trim() == "" || headline.trim() == "" || description.trim() == "")) {
      return true
    } else {
      return false
    }
  }

  // pushing image to firebase
  const pushImage = (id) => {

    if (hubImage == null) return;

    setLoading(true)
    setUploading(true)
    let coverRef = `/users/${authCtx.userId}/hubImages/${hubImage.name}`;

    const storageRef = sRef(storage, coverRef)

    uploadBytes(storageRef, hubImage).then((snapshot) => {
      let imagePayload = {
        coverRef: coverRef
      }
      setHubImageRef(coverRef)

      fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + id + '/uploadImage', {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(imagePayload)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data?.status === "success") {
          setUploading(false)
        } else {
        }
      });
    }, (error) => console.log(error));

  }

  const createNetwork = () => {

    if (!hubTitle) {
      setOpenSnackbar(true)
      return;
    }

    const payload = {
      "userId": authCtx.userId,
      "parentId": 0,
      "topicId": topicId,
      "cover": hubImageRef,
      "hubType": hubType,
      "title": hubTitle,
      "headline": headline,
      "description": description,
      "permissions": permissions,
      "paid": "no",
      "paymentFee": 0.00,
      "paymentType": "none",
      "paymentRecurring": "none"
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data?.status === "success") {
        pushImage(data.id)
      } else {
      }
    });

  }

  const searchMembers = () => {
    if (!searchQuery) {
      setMembers([]);
    }
    setMembersLoading(true);

    fetch(
      process.env.REACT_APP_API_URI +
      `/v2/user/${authCtx.userName}/hub/${modalCtx.details.selectedNetwork}/search/hubInvite/${searchQuery}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          const first100Members = data?.data.slice(0, 100);
          setMembers(first100Members);
          setMembersLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const createNetworkFirstStep = async () => {

    setButtonDisabled(true)

    const payload = {
      userId: authCtx.userId,
      parentId: 0,
      universeId: 0,
      topicId: topicId,
      cover: imageURL?.slice(1),
      title: nameIp,
      headline: headline,
      description: description,
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/networks`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()

      if (data.status === 'success') {
        console.log(data.id)
        setActiveStep(prev => prev + 1)
        setNetworkId(data.id);
        setButtonDisabled(false)
      } else if (data.status === 'failure') {
        setButtonDisabled(false)
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      console.log('error while creating network : ', error)
    }
  }
  const updateAccess = async () => {

    setButtonDisabled(true)

    const payload = {
      hubType: accessType,
      permissions: NetworkRestriction,
      paid: isPaid ? "yes" : "no",
    }
    if (payload.paid === "yes") {
      payload.paymentFee = Number(fees);
      payload.paymentType = paymentType;
      payload.paymentRecurring = paymentType === "recurring" ? recurringType : '';
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/networks/${newNetworkId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()
      console.log(data);

      if (data.status === 'success') {
        let featuresArr = [];
        if (paymentType === "recurring") {
          features.forEach(e => featuresArr.push(e.feature));
        }
        else {
          oneTimeFeatures.forEach(e => featuresArr.push(e.feature));
        }
        const payload = {
          features: featuresArr
        }
        console.log(payload);

        try {
          const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/networks/${newNetworkId}/feature`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
            body: JSON.stringify(payload)
          })

          const data = await res.json()
          console.log(data);

          if (data.status === 'success') {
            setActiveStep(prev => prev + 1)
            setButtonDisabled(false)
          } else if (data.status === 'failure') {
            setButtonDisabled(false)
            setOpenSnackbar(true)
            setSnackBarMessageText(data.message)
          }

        } catch (error) {
          console.log('error while creating network : ', error)
        }
      } else if (data.status === 'failure') {
        setButtonDisabled(false)
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      console.log('error while creating network : ', error)
    }
  }
  const updateModeration = async () => {

    setButtonDisabled(true)

    const payload = {
      moderation_low: flaggedValue,
      moderation_high: removedValue
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${newNetworkId}/moderation`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()
      console.log(data);

      if (data.status === 'success') {
        setActiveStep(prev => prev + 1)
        setButtonDisabled(false)
      } else if (data.status === 'failure') {
        setButtonDisabled(false)
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      console.log('error while creating network : ', error)
    }
  }
  const inviteMembers = async () => {

    // setButtonDisabled(true)
    console.log(inviteList);

    const payload = {
      emails: inviteList
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${newNetworkId}/inviteByEmail`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()
      console.log(data);

      if (data.status === 'success') {
        setActiveStep(prev => prev + 1)
        setButtonDisabled(false)
      } else if (data.status === 'failure') {
        setButtonDisabled(false)
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      console.log('error while creating network : ', error)
    }
  }

  const handleInviteList = (member) => {
    let email = member.email;
    if (inviteList.includes(email)) {
      let list = inviteList.filter(e => e !== email);
      setInviteList(l => list);
    }
    else {
      let list = [...inviteList, email];
      setInviteList(l => list);
    }
  }

    const handleFollowerInviteList = (member) => {
      console.log(member)
    let id = member.user_id;
    if (inviteList.includes(id)) {
      let list = inviteList.filter(e => e !== id);
      setInviteList(l => list);
    }
    else {
      let list = [...inviteList, id];
      setInviteList(l => list);
    }
  }


  return (
    <>
      <div className="create-network-modal-container">
        <div className="create-network">
          <div className="header-steps-container">
            <div className='modal-header'>
              <h2>Create Network</h2>
              <Close onClick={closeHandle} style={{ cursor: "pointer" }} className='close' />
            </div>
            <div className="steps">
              <div className={activeStep >= 1 ? "step completed active" : "step"}>Details</div>
              <div className={activeStep >= 2 ? "step completed active" : "step"}>Access</div>
              <div className={activeStep >= 3 ? "step completed active" : "step"}>AI Moderation</div>
              <div className={activeStep >= 4 ? "step completed active" : "step"}>Invite</div>
            </div>
          </div>

          <div className="create-network-all-steps-content-container">
            <div className="network-details-container" style={activeStep == 1 ? null : { display: 'none' }}>

              <div className="network-image-container">
                <div className="image-container" onClick={() => { uploadImageModal() }}>
                  {
                    imageURL
                      ? <NetworkImage coverRef={imageURL} className='image' />
                      : <Wallpaper sx={{ width: '50px', height: '50px' }} color={'#CACACA'} />
                  }
                </div>
                {imageURL && <Close onClick={() => setImageURL('')} style={{ cursor: "pointer" }} className='close' />}
              </div>

              <div className="network-name-status-container">
                <div className="network-status">
                  <div className={hubType === "frequency" ? "status-public active" : "status-public"} onClick={() => setHubType("frequency")}>Frequency Only</div>
                  <div className={hubType === "shared" ? "status-private active" : "status-private"} onClick={() => setHubType("shared")}>Shared Network</div>
                  <div className='tooltip-info' style={{ zIndex: '1000000' }} >
                    <Tooltip open={showTooltip} onOpen={() => setShowTooltip(true)} onClose={() => setShowTooltip(false)} title="Shared networks can be shared among universe that you own." placement="bottom-end" style={{ zIndex: '1000000' }} >
                      <InfoOutlined onClick={() => setShowTooltip(!showTooltip)} />
                    </Tooltip>
                  </div>
                </div>
                {hubType === "shared" && <FormControl sx={{ m: 1, minWidth: 120, width: '100%', background: "#FFDFDF", borderRadius: "5px" }} >
                  <Select
                    sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: "#FFDFDF !important" }, '.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: "#FFDFDF !important" }, }}
                    labelId="select-universe-label"
                    id="select-universe"
                    displayEmpty
                    renderValue={universeId !== 0 ? undefined : () => "Select Your Partner Universe"}
                    value={universeId}
                    onChange={handleChangeUniverse}
                    label="Age"
                    MenuProps={{ style: { zIndex: 1000000 } }}
                  >
                    <MenuItem value={1}>Universe 1</MenuItem>
                    <MenuItem value={2}>Universe 2</MenuItem>
                    <MenuItem value={3}>Universe 3</MenuItem>
                    <MenuItem value={4}>Universe 4</MenuItem>
                    <MenuItem value={5}>Universe 5</MenuItem>
                    <MenuItem value={6}>Universe 6</MenuItem>
                    <MenuItem value={7}>Universe 7</MenuItem>
                  </Select>
                </FormControl>}
                <input type="text" className='network-name-input' placeholder='Name' value={nameIp} onChange={(e) => { handleChange(e) }} />
              </div>

              <div className="select-topic-container">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                  <InputLabel id="select-topic-label">Topic</InputLabel>
                  <Select
                    labelId="select-topic-label"
                    id="select-topic"
                    value={topicId}
                    onChange={handleChangeTopic}
                    label="Topic"
                    MenuProps={{ style: { zIndex: 1000000 } }}
                  >
                    <MenuItem value={1}>Arts & Entertainment</MenuItem>
                    <MenuItem value={2}>health & Wellness</MenuItem>
                    <MenuItem value={3}>Live Sport</MenuItem>
                    <MenuItem value={4}>US News</MenuItem>
                    <MenuItem value={5}>Science</MenuItem>
                    <MenuItem value={6}>Sports</MenuItem>
                    <MenuItem value={7}>Support Groups</MenuItem>
                    <MenuItem value={8}>Education</MenuItem>
                    <MenuItem value={9}>Other</MenuItem>
                    <MenuItem value={10}>Politics</MenuItem>
                    <MenuItem value={11}>Social Justice</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="headline-container">
                <input type="text" className='network-headline-input' placeholder='Headline' value={headline} onChange={handleHeadline} />
              </div>
              <div className="description-container">
                <textarea rows={7} type="text" className='network-description-input' placeholder='Description' value={description} onChange={handleDesc} />
              </div>

              {/*<div className="network-type-container">
                <FormControl >
                  <label style={{ fontWeight: "600" }} id="network-type-group-label">How may users join this network ?</label>
                  <RadioGroup
                    aria-labelledby="network-type-group-label"
                    defaultValue="open"
                    name="radio-buttons-group"
                    onChange={handleNetworkRestriction}
                  >
                    <FormControlLabel value="open" control={<Radio style={{ color: "#CD4A4A" }} />} label="Open network, anyone may join without restriction." />
                    <FormControlLabel value="invite" control={<Radio style={{ color: "#CD4A4A" }} />} label="By request only but any member may invite." />
                  </RadioGroup>
                </FormControl>
              </div>*/}

            </div>

            <div className="network-access-container" style={activeStep == 2 ? null : { display: 'none' }} >
              <div className="network-access-inner-container">
                <div className="network-access">
                  <div className={accessType === "public" ? "access-public active" : "access-public"} onClick={() => setAccessType("public")}><Public /> Public</div>
                  <div className={accessType === "private" ? "access-private active" : "access-private"} onClick={() => setAccessType("private")}><GppMaybeOutlined /> Private</div>
                </div>
              </div>
              {accessType === "public" && <div className="network-type-container">
                <FormControl >
                  <label style={{ fontWeight: "600", textAlign: "center" }} id="network-type-group-label">How may users join this network ?</label>
                  <RadioGroup
                    aria-labelledby="network-type-group-label"
                    defaultValue="open"
                    name="radio-buttons-group"
                    onChange={handleNetworkRestriction}
                  >
                    <FormControlLabel value="open" control={<Radio style={{ color: "#CD4A4A" }} />} label="Open network, anyone may join without restriction." />
                    <FormControlLabel value="invite" control={<Radio style={{ color: "#CD4A4A" }} />} label="By request only but any member may invite." />
                  </RadioGroup>
                </FormControl>
              </div>}
              <div className="paid-network-toggle-container">
                <h1 className="paid-network-heading">
                  Is this a paid network ?
                </h1>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.25rem"
                }} className='paid-switch-container'>
                  <IOSSwitch checked={isPaid} onChange={(e) => setIspaid(e.target.checked)} />
                  {isPaid && <span style={{ fontSize: "20px" }}>Paid</span>}
                </div>
                <div className="paid-network-content-container">
                  <Modal
                    sx={{ zIndex: 1000000 }}
                    open={addOpen}
                    onClose={handleAddClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={feature_modal_style}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Add Feature
                        </Typography>
                        <Close onClick={handleAddClose} />
                      </div>
                      <input style={feature_modal_attributes_styles[0]} type="text" className="feature-input" placeholder="Feature" onChange={(e) => setFeatureItem(e.target.value)} />
                      <Button onClick={onAddFeature} style={feature_modal_attributes_styles[1]} className="add-btn">
                        Add Feature
                      </Button>
                      <Button onClick={handleAddClose} style={feature_modal_attributes_styles[2]} className="cancel-btn">
                        Cancel
                      </Button>
                    </Box>
                  </Modal>
                  <Modal sx={{ zIndex: 1000000 }} open={deleteopen} onClose={handleDeleteClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box sx={feature_modal_style}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Are you sure you want to delete this feature ?
                        </Typography>
                        <Close onClick={handleDeleteClose} />
                      </div>
                      <Button onClick={() => onDeleteFeature(idToDelete)} style={feature_modal_attributes_styles[1]} className="add-btn">
                        Delete
                      </Button>
                      <Button onClick={handleDeleteClose} style={feature_modal_attributes_styles[2]} className="cancel-btn">
                        Cancel
                      </Button>
                    </Box>
                  </Modal>
                  {
                    isPaid ? <>
                      <input type="number" name="" placeholder='Fee' id="" value={fees} onChange={(e) => setFees(e.target.value)} />
                      <div className="payment-type-container">
                        <div className="recurring-one-time-option-container">
                          <RadioGroup className='payment-type-radiogroup-container' defaultValue={'recurring'} value={paymentType} onChange={handlePaymentTypeChange}>
                            <FormControlLabel value="recurring" control={<Radio style={{ color: "#CD4A4A" }} />} label="Recurring" />
                            <FormControlLabel value="once_off" control={<Radio style={{ color: "#CD4A4A" }} />} label="One time" />
                          </RadioGroup>
                        </div>
                        {
                          paymentType === "recurring" ?
                            <div className="recurring-payment-container">
                              <RadioGroup className="recurring-payment-type-radiogroup-container" onChange={handleRecurringType} value={recurringType}>
                                <div className='recurring-payment-type-flexbox'>
                                  <h1 className="recurring-payment-type-heading">
                                    Weekly
                                  </h1>
                                  <FormControlLabel value="weekly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                                </div>
                                <div className='recurring-payment-type-flexbox'>
                                  <h1 className="recurring-payment-type-heading">
                                    Fortnightly
                                  </h1>
                                  <FormControlLabel value="Fortnightly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                                </div>
                                <div className='recurring-payment-type-flexbox'>
                                  <h1 className="recurring-payment-type-heading">
                                    Yearly
                                  </h1>
                                  <FormControlLabel value="Yearly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                                </div>
                                <div className='recurring-payment-type-flexbox'>
                                  <h1 className="recurring-payment-type-heading">
                                    Monthly
                                  </h1>
                                  <FormControlLabel value="Monthly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                                </div>
                                <div className="features-container">
                                  <h3 className="features-heading">Features</h3>
                                  <div className="add-feature-button-container">
                                    <CustomButton onClick={handleAddOpen}> <span>+</span> Add Feature</CustomButton>
                                  </div>
                                </div>
                                <div className="added-features-container">
                                  {features.map((feature, i) => {
                                    return (
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='feature-item' key={i}>
                                        <p className="features-text">{Object.values(feature)[1]}</p>
                                        <div className="delete-feature-button-container">
                                          <DeleteOutlineOutlined style={{ cursor: "pointer" }} onClick={() => { handleDeleteOpen(); setIdToDelete(Object.values(feature)[0]) }} />
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </RadioGroup>
                            </div>
                            :
                            <div className="one-time-payment-container">
                              <div className="features-container">
                                <h3 className="features-heading">Features</h3>
                                <div className="add-feature-button-container">
                                  <CustomButton onClick={handleAddOpen}> <span>+</span> Add Feature</CustomButton>

                                </div>

                              </div>
                              <div className="added-features-container">
                                {
                                  oneTimeFeatures.map((feature, i) => {
                                    return (
                                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='feature-item' key={i}>
                                        <p className="features-text">{Object.values(feature)[1]}</p>
                                        <div className="delete-feature-button-container">
                                          <DeleteOutlineOutlined style={{ cursor: "pointer" }} onClick={() => { handleDeleteOpen(); setIdToDelete(Object.values(feature)[0]) }} />
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                        }

                      </div>
                    </> : <></>
                  }
                </div>
              </div>

            </div>


            <div className="network-moderation-container" style={activeStep == 3 ? null : { display: 'none' }}>

              <div className="moderation-flagged">
                <div className="title">
                  At what point does it get flagged ?
                </div>
                <div className="range-slider">
                  <Slider
                    style={{ color: "#CD4A4A" }}
                    aria-label="Always visible"
                    defaultValue={80}
                    getAriaValueText={valuetext}
                    step={1}
                    min={30}
                    max={100}
                    marks={marks}
                    valueLabelDisplay="on"
                    value={flaggedValue}
                    onChange={(e) => setFlaggedValue(l => e.target.value)}
                  />
                </div>
                <div className="comment-container">
                  <div className="profile">
                    <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                    <div className="profile-name">Philip</div>
                  </div>
                  <div className="message-text">{getToxicityText()}</div>
                </div>
              </div>
              <div className="moderation-removed">
                <div className="title">
                  At what point does the message get's removed ?
                </div>
                <div className="range-slider">
                  <Slider
                    style={{ color: "#CD4A4A" }}
                    aria-label="Always visible"
                    defaultValue={100}
                    getAriaValueText={valuetext}
                    step={1}
                    min={30}
                    max={100}
                    marks={marks}
                    valueLabelDisplay="on"
                    value={removedValue}
                    onChange={(e) => e.target.value < flaggedValue ? null : setRemovedValue(h => e.target.value)}
                  />
                </div>
                <div className="comment-container">
                  <div className="profile">
                    <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                    <div className="profile-name">Philip</div>
                  </div>
                  <div className="message-text">{getToxicityText2()}</div>
                </div>
              </div>
            </div>

            <div className="network-invite-members-container" style={activeStep == 4 ? null : { display: 'none' }} >
              <div >
                <div className='invite-member-tabs' >
                  <div className={selectedTab == 1 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(1)}>Profiles</div>
                  <div className={selectedTab == 2 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(2)}>Networks</div>
                </div>

                {getTabData(selectedTab)}

              </div>
            </div>

          </div>

          <div className="navigate-btn-container">
            {activeStep > 1 ? <CustomButton className="f-btn-bordered" onClick={() => setActiveStep(prev => prev - 1)} >Prev</CustomButton> : null}
            <CustomButton className="f-btn" onClick={() => handleNextStep()} disabled={checkIfNextBtnDisabled()} >
              {
                buttonDisabled
                  ? <CircularProgress size={20} style={{ color: '#FFF' }} />
                  : "Next"
              }
            </CustomButton>
          </div>

        </div>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
            {snackBarMessageText}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default CreateNetworkNew;
