import React, { createContext, useContext, useEffect, useState } from 'react';

//components
import Dashboard from '../Components/Featured/Dashboard';
import Networks from '../Components/Featured/Networks';
import LoadingFallback from '../Components/LoadingFallback';
import WaveFollowing from '../Components/Wave/WaveFollowing.jsx';
import WaveDiscover from '../Components/Wave/WaveDiscover.jsx';
import Explore from './Explore';

//context
import AuthContext from '../store/AuthContext';
import ModalContext from '../store/ModalContext';

//scss
import '../Styles/Featured.scss'
import '../Styles/Wave.scss'
import { Add } from '@mui/icons-material';

export let FeaturesActiveTabSwitch;


const Featured = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('discover');
  const [popularNetworks, setPopularNetworks] = useState([]);
  const [mostEngaged, setMostEngaged] = useState([]);

  FeaturesActiveTabSwitch = setActiveTab;

  useEffect(() => {

    let options = {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    Promise.all([
      fetch(process.env.REACT_APP_API_URI + '/v2/homev4/' + authCtx.userId, options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v2/hubs/popular/networks/' + authCtx.userId, options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + '/mostEngaged', options).then(response => response.json())
    ]).then((data) => {
      console.log(data);
      if (data[0].status === "success" && data[1].status == "success" && data[2]) {
        setPopularNetworks(data[1].data);
        setMostEngaged(data[2].data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
      }
    })
      .catch((err) => {
        console.log(err);
      });

    // fetch(process.env.REACT_APP_API_URI + '/v2/homev4/' + authCtx.userId, {
    // 	method: 'GET',
    // 	mode: 'cors',
    // 	headers: { 
    // 		'Content-Type': 'application/json',
    // 		'Authorization': 'Bearer ' + authCtx.token
    // 	}
    // })
    // .then(response => response.json())
    // .then(data => {
    // 	if (data.status === "success") {
    // 		setTimeout(() => {
    // 			setIsLoading(false);
    // 		}, 1000);
    // 	} else {
    // 	}
    // });

  }, []);

  const handleModal = (modal) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, null);
  }

  if (isLoading) {
    return (
      <LoadingFallback />
    )
  }

  return (
    <div className="featured-container">
      <Dashboard networks={popularNetworks} mostEngaged={mostEngaged} />
      <div className='featured-tabs-container' >
        <div className="featured-nav-container">
          <div className="nav-item" onClick={() => setActiveTab('discover')}>
            <span style={activeTab === 'discover' ? { color: '#CD4A4A', borderBottom: '3px solid #CD4A4A' } : null}>Discover</span>
          </div>
          <div className="nav-item" onClick={() => setActiveTab('networks')}>
            <span style={activeTab === 'networks' ? { color: '#CD4A4A', borderBottom: '3px solid #CD4A4A' } : null} >Networks</span>
          </div>
          <div className="nav-item" onClick={() => setActiveTab('following')}>
            <span style={activeTab === 'following' ? { color: '#CD4A4A', borderBottom: '3px solid #CD4A4A' } : null} >profiles</span>
          </div>
          <div className="nav-item" onClick={() => setActiveTab('news')}>
            <span style={activeTab === 'news' ? { color: '#CD4A4A', borderBottom: '3px solid #CD4A4A' } : null}>News</span>
          </div>
          <div className="nav-item create-post" onClick={() => handleModal('createPost')}>
            <Add className='create-post-icon' />
            <span>Create</span>
          </div>
        </div>

        {activeTab === 'networks' ? <Networks setIsLoading={setIsLoading} /> : null}

        {activeTab === 'following' ? <WaveFollowing /> : null}

        {activeTab === 'discover' ? <WaveDiscover /> : null}

        {activeTab === 'news' ? <Explore /> : null}

      </div>

    </div>
  );
}

export default Featured;