import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage  from "../FirebaseConfig";
import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';

// mui icons
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';

const noMenuRoutes = ["/login"];

function AvatarAttachmentMedia({ attachment, previewPath }) {

	const authCtx 							= useContext(AuthContext);
	const modalCtx = useContext(ModalContext);

	const { pathname } 						= useLocation();
	const [attachmentMedia, setAttachmentMedia] 	= useState("");
	const [previewImage, setPreviewImage] 	= useState("");

	const showVid = (modal, video) => {
		modalCtx.openModal();
		modalCtx.setDetails(modal, video);
	  }

	useEffect(() => {
		if (previewPath) {
			
			getPreviewImg(previewPath);
			getImg(attachment);
			messageDecide(attachmentMedia)
			
		}

		if (attachment !== "" && attachment !== null && attachment.includes("/hubImages/") ) {
			getImg(attachment);
			messageDecide(attachmentMedia, previewImage)
		} else if (attachment !== "" && attachment !== null && attachment.includes("giphy.com") ) {
			setAttachmentMedia(attachment)
		} else if (attachment !== "" && attachment !== null && attachment.includes("base64") ) {
			setAttachmentMedia(attachment)
		} else {
			return;
		}
		 
  }, [attachment, previewPath]);
	

  // get the attachment either image or video
	const getImg = (coverRef) => {
		getDownloadURL(ref(storage, coverRef))
			.then((url) => {
				setAttachmentMedia(url);
			})
			.catch((error) => {
				console.log(error)
			});
  }

  // get preview image if only there isa video
	const getPreviewImg = (coverRef) => {
		getDownloadURL(ref(storage, coverRef))
			.then((url) => {
				setPreviewImage(url);
			})
			.catch((error) => {
				console.log(error)
			});
  }


  const messageDecide = (attachmentMedia, previewImage) => {

	 if (!previewImage && attachmentMedia) {

		return (
			<>
			<img className='chat-attachment' src={attachmentMedia} alt="attachment" />
			</>
		)
	}
	 if (previewImage) {
		return (
		<>
			<img className='chat-attachment' src={previewImage} alt="attachment" />
			<div className='play-btn' onClick={() => showVid('PlayVideo', attachmentMedia)} ><PlayCircleFilledRoundedIcon/></div> 
		</>
		)

	} else {
	}

  }

	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<>
		{attachmentMedia === "" ? <p style={{color: "#770000", fontWeight: "bold", fontStyle: "italic", width: "190px"}}>Failed to load meadia</p> : messageDecide(attachmentMedia, previewImage)}
		</>
	);
}
export default AvatarAttachmentMedia;