import React, { useContext } from 'react'
import { useState } from 'react'
import CustomButton from '../../../common/CustomButton'
//mui
import { Close } from '@mui/icons-material'
import { Alert, Snackbar, Slider, CircularProgress } from '@mui/material'

import Avatar1 from '../../../assets/img/avatar/avatar1.jpg'

//context
import AuthContext from '../../../store/AuthContext'
import ModalContext from '../../../store/ModalContext'


const EditNetworkModeration = () => {

  const marks = [{ value: 30, label: '30', }, { value: 100, label: '100', },];


  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)

  console.log(modalCtx)

  //state
  const [flaggedValue, setFlaggedValue] = useState(50);
  const [removedValue, setRemovedValue] = useState(80);

  const [updating, setUpdating] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessageText, setSnackBarMessageText] = useState("Please enter a title for the Network.")


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const closeHandle = () => {
    modalCtx.setDetails('', {});
    modalCtx.closeModal();
  }

  function valuetext(value) {
    return `${value}`;
  }


  const getToxicityText = () => {
    if (flaggedValue > 90) {
      return ("You're such a f***ing moron");
    } else if (flaggedValue > 80) {
      return ("What the f*** are you doing in this chat");
    } else if (flaggedValue > 70) {
      return ("Ah sh**! What on earth are we going to do about this crazy mess");
    } else if (flaggedValue > 60) {
      return ("Oh sh**! That's great news");
    } else if (flaggedValue > 50) {
      return ("You son of a gun");
    } else if (flaggedValue > 40) {
      return ("I think it's funny to bully people online and make them feel bad.");
    } else {
      return ("You should not be in this chat");
    }
  }
  const getToxicityText2 = () => {
    if (removedValue > 90) {
      return ("You're such a f***ing moron");
    } else if (removedValue > 80) {
      return ("What the f*** are you doing in this chat");
    } else if (removedValue > 70) {
      return ("Ah sh**! What on earth are we going to do about this crazy mess");
    } else if (removedValue > 60) {
      return ("Oh sh**! That's great news");
    } else if (removedValue > 50) {
      return ("You son of a gun");
    } else if (removedValue > 40) {
      return ("I think it's funny to bully people online and make them feel bad.");
    } else {
      return ("You should not be in this chat");
    }
  }

  const handleFlaggedValue = (e) => {
    setFlaggedValue(l => e.target.value)
    if (removedValue < flaggedValue) {
      setRemovedValue(h => e.target.value)
    }
  }


  const updateModeration = async () => {

    setUpdating(true)

    const payload = {
      moderation_low: flaggedValue,
      moderation_high: removedValue
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${modalCtx.details.id}/moderation`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()
      console.log(data);

      if (data.status === 'success') {
        setUpdating(false)
        closeHandle()

      } else if (data.status === 'failure') {
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      setUpdating(false)
      console.log('error while creating network : ', error)
    }
  }

  return (
    <div className="create-network-modal-container">
      <div className="create-network">
        <div className="header-steps-container">
          <div className='modal-header'>
            <h2>Edit Network Moderation</h2>
            <Close onClick={closeHandle} style={{ cursor: "pointer" }} className='close' />
          </div>
        </div>

        <div className="network-moderation-container">

          <div className="moderation-flagged">
            <div className="title">
              At what point does it get flagged ?
            </div>
            <div className="range-slider">
              <Slider
                style={{ color: "#CD4A4A" }}
                aria-label="Always visible"
                defaultValue={80}
                getAriaValueText={valuetext}
                step={1}
                min={30}
                max={100}
                marks={marks}
                valueLabelDisplay="on"
                value={flaggedValue}
                onChange={(e) => handleFlaggedValue(e)}
              />
            </div>
            <div className="comment-container">
              <div className="profile">
                <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                <div className="profile-name">Philip</div>
              </div>
              <div className="message-text">{getToxicityText()}</div>
            </div>
          </div>
          <div className="moderation-removed">
            <div className="title">
              At what point does the message get's removed ?
            </div>
            <div className="range-slider">
              <Slider
                style={{ color: "#CD4A4A" }}
                aria-label="Always visible"
                defaultValue={100}
                getAriaValueText={valuetext}
                step={1}
                min={30}
                max={100}
                marks={marks}
                valueLabelDisplay="on"
                value={removedValue}
                onChange={(e) => e.target.value <= flaggedValue - 1 ? null : setRemovedValue(h => e.target.value)}
              />
            </div>
            <div className="comment-container">
              <div className="profile">
                <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                <div className="profile-name">Philip</div>
              </div>
              <div className="message-text">{getToxicityText2()}</div>
            </div>
          </div>
        </div>

        <div className="navigate-btn-container" style={{ justifyContent: "center" }}>
          <CustomButton className="f-btn" onClick={() => !updating && updateModeration()} >
            {
              updating
                ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                : "Save Changes"
            }
          </CustomButton>
        </div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
          {snackBarMessageText}
        </Alert>
      </Snackbar>

    </div>
  )
}

export default EditNetworkModeration