import { useContext, useEffect, useReducer, useRef, useState } from "react";

import { Button } from "@mui/material";
import AuthContext from "../../store/AuthContext";
import styles from "./style.module.scss";


import CircularProgress from "@mui/material/CircularProgress";

import { Checkbox } from '@mui/material';

import Arts from "../../assets/img/network-topics/Arts & Entertainment.svg";
import Health from "../../assets/img/network-topics/Health & Wellness.svg";
import LSports from "../../assets/img/network-topics/Live Sports.svg";
import News from "../../assets/img/network-topics/US News.svg"
import Science from "../../assets/img/network-topics/Science.svg"
import Sports from "../../assets/img/network-topics/Sports.svg"
import CustomButton from "../../common/CustomButton";

const topicsIcons = {
  "Arts & Entertainment": Arts,
  'Health & Wellness': Health,
  'Live Sports': LSports,
  'US News': News,
  'Science': Science,
  "Sports": Sports
}

const pushData = async (data, authCtx) => {
  const response = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/topics',
    {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(data.filter(el => el.isSelected).map(topic => topic.id)),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authCtx.token,
      },
    })
    .then((data) => data.json())
    .then(i => console.log(i));
}

const fetchTopics = async (dispatch, authCtx) => {
  const { topics: topicsFromServer } = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/topics',
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authCtx.token,
      },
    })
    .then((data) => data.json());

  console.log(topicsFromServer)
  dispatch({ type: 'set', topics: topicsFromServer })
}

const TopicBadge = ({ data, dispatch }) => {
  const icon = topicsIcons[data.topic];
  return <div className={styles.topic}>
    <div className={styles.topic__data}>
      {icon ? <img className={styles.topic__data__icon} src={icon} alt={data.topic} /> : <div className={styles.topic__data__icon} />}
      <div className={styles.topic__data__title}>{data.topic}</div>
    </div>

    <Checkbox
      // icon={<CircleUnchecked />}
      // checkedIcon={<CircleCheckedFilled />}
      checked={data.isSelected}
      onClick={() => dispatch({ type: "update", topicId: data.id })}
    />
  </div>
}

export const ChooseNetworkTopics = () => {
  const authCtx = useContext(AuthContext);
  const topicsReduce = (state, action) => {
    switch (action.type) {
      case "set":
        return [...action.topics];
      case "update":
        return state.map(topic => topic.id === action.topicId
          ? { ...topic, isSelected: !topic.isSelected }
          : topic);
      default: return state;
    }
  };


  const [topics, dispatch] = useReducer(topicsReduce, []);

  useEffect(() => {
    if (!topics.length) {
      fetchTopics(dispatch, authCtx);
    }
  }, [dispatch])

  if (!topics.length) {
    return (
      <div className="container ">
        <div className="network-wrapper loading">
          <CircularProgress style={{ color: "#F7D54D" }} size={60} />
        </div>
      </div>
    );
  }

  return <div className={styles.wrapper}>
    <div className={styles.container}>
      <h1> Choose Preferred Network Topics</h1>
      <div className={styles.topics}>
        {topics.map(topic => <TopicBadge key={topic.id} data={topic} dispatch={dispatch} />)}
      </div>
      <CustomButton className='f-btn-full-width' style={{width: '80%', marginTop: '1.25rem'}} variant="contained"
        onClick={() => pushData(topics, authCtx)}>
        Save
      </CustomButton>
    </div>
  </div>
}