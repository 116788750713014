import React, { useContext, useState } from 'react';
import CustomButton from '../../../common/CustomButton.jsx';

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';

import '../../../Styles/Modal.scss'
import TextInput from '../../TextInput.jsx';

const AddLinks = () => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);


  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('')
  const [showLinks, setShowLinks] = useState([])
  const [loading, setLoading] = useState(false)

  
  const closeLinks = () => {
		modalCtx.closeModal();
	  }

    // console.log(modalCtx.details);

    const addLinks = async (id) => {
      try {
        setLoading(true)
        const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/:userId/links',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: JSON.stringify({
              userId: authCtx?.userId,
              title: title,
              url: url
            })
          })
  
        const data = await res.json()
  
        if (data.status === 'success') 
        console.log("success")
          setShowLinks(data)
          closeLinks()
          setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

  return (
    <>
    <div className="add-links-container">

      <div className="links-modal">
        <div className="link-header">
          <h2>Add Link</h2>
        </div>  
        <div className="add-links">
          <div className="title">
            <TextInput type="text" placeholder='Title' onChange={(e) => setTitle(e.target.value)} value={title}  />
          </div>    
          <div className="link-url">
            <TextInput type="text" placeholder='Link' onChange={(e) => setUrl(e.target.value)} value={url} />
          </div>
        </div>
      
        <div className="buttons">
          <CustomButton className='f-btn-bordered' onClick={() => closeLinks()} >Close</CustomButton>
          <CustomButton onClick={() => addLinks()}>
          {
                  loading
                    ? <CircularProgress style={{ color: '#fff' }} size={24} />
                    : "SAVE"
                }
            </CustomButton>
        </div>
      </div>
    </div>
    </>
  )
}

export default AddLinks;
