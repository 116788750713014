import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ref, getDownloadURL, listAll } from "firebase/storage";

import AuthContext from '../store/AuthContext.js';
import CheckoutFormCreator from "../Components/Stripe/CheckoutFormCreator";
import storage  from "../Components/FirebaseConfig";

import Network1 from '../assets/img/networks/network1.png';


import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

// const stripePromise = loadStripe("pk_test_51L8kJIByqzP5Ykgqjb3owFykoKDKTdegzQZfrR87rcad5BbofFacQMp5C3SiIAAXqk0Krvlb80tgYMuAmygbZVQ700dmhrX5dD");
const stripePromise = loadStripe("pk_live_51L8kJIByqzP5YkgqTBWAOEftejoIMs29tf9IlXFZWb0YvcD82gSjuteTL6W1IgW6hkscGvvC3zm8EsKpmwqw1uPd00uTlamd1C");

const ProfileCreatorPayment = () => {

	const { userId }  = useParams();  
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [transactionId, setTransactionId] = useState("");
	const [clientSecret, setClientSecret] = useState("");
  const [showPayment, setShowPayment] = useState(false);

	useEffect(() => {
    // createSubscription();
  }, []);

  const createSubscription = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/stripe/creator/subscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId }),
    })
		.then((res) => res.json())
		.then(data => {
      setClientSecret(data.clientSecret);
      setUserName(data.userName);
      setUserEmail(data.userEmail);
      setSubscriptionId(data.subscriptionId);
      setTransactionId(data.transaction);
      setShowPayment(true);
      setIsLoading(false);
    });
  }

	const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  // if (isLoading) {
	// 	return <div className="container paywall loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	// }

	return (
		<div className="container paywall">
			<header>
				<div data-aos="fade-up">
					<h1>Become a Paid Creator</h1>
				</div>

        <div className="network-desc">
          <h4>An annual subscription of $99 is required to create an unlimited number of Paid Networks and Sub Networks.</h4>
        </div>
				
			</header>

      <div className="network-info">
        {
          !showPayment ? (<button onClick={() => createSubscription()}>{ isLoading ? <div style={{ padding: "0" }}><CircularProgress  style={{'color': '#fff'}} size={20} /></div> : <span>Pay $99</span> }</button>) : null
        }
      </div>

			{
        showPayment ? (
          <div className="payment-box">
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutFormCreator stripeSecret={clientSecret} userId={userId} userName={userName} userEmail={userEmail} subscriptionId={subscriptionId} transactionId={transactionId}/>
              </Elements>
            )}
          </div>
        ) : null
      }
			
		</div>
	);

}
export default ProfileCreatorPayment;