import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ref, getDownloadURL, listAll } from "firebase/storage";

import { CircularProgress } from '@mui/material';

import AuthContext from '../store/AuthContext.js';
import CheckoutForm from "../Components/Stripe/CheckoutForm";
import CheckoutFormCard from "../Components/Stripe/CheckoutFormCard";
import storage  from "../Components/FirebaseConfig";

import Network1 from '../assets/img/networks/network1.png';

import NetworkImage from '../Components/Networks/NetworkImage';


import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { CheckCircle } from "@mui/icons-material";
AOS.init();

const stripePromise = loadStripe("pk_live_51L8kJIByqzP5YkgqTBWAOEftejoIMs29tf9IlXFZWb0YvcD82gSjuteTL6W1IgW6hkscGvvC3zm8EsKpmwqw1uPd00uTlamd1C");
// const stripePromise = loadStripe("pk_test_51L8kJIByqzP5Ykgqjb3owFykoKDKTdegzQZfrR87rcad5BbofFacQMp5C3SiIAAXqk0Krvlb80tgYMuAmygbZVQ700dmhrX5dD");

const PayWall = () => {

	const { id, userId }  = useParams();
  const authCtx 				= useContext(AuthContext);
  
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [networkTitle, setNetworkTitle] = useState("");
  const [networkPaymentFee, setNetworkPaymentFee] = useState(0);
  const [networkPaymentType, setNetworkPaymentType] = useState(0);
  const [networkPaymentRecurring, setNetworkPaymentRecurring] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
	const [clientSecret, setClientSecret] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [networkCover, setNetworkCover] = useState(Network1);

	useEffect(() => {
    getHubDetails();
    // Create PaymentIntent as soon as the page loads
    
  }, []);

  const getHubDetails = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/stripe/networks/' + id + '/users/' + userId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        setNetworkTitle(data.network[0].title);
        setNetworkPaymentFee(data.network[0].payment_fee);
        
        if(data.network[0].payment_recurring != "none") {
          setNetworkPaymentRecurring(data.network[0].payment_recurring);
          setNetworkPaymentType("recurring");
          getSubscriptionIntent();
        } else {
          setNetworkPaymentRecurring("once off");
          setNetworkPaymentType("once_off");
          getIntent();
        }
        setUserEmail(data.user[0].email);
        setUserName(data.user[0].name);
        setNetworkCover(data.network[0].cover);
      } else if(data.status == "member") {
        setNetworkTitle(data.network[0].title);
        setIsMember(true);
        setNetworkCover(data.network[0].cover);        
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const getSubscriptionIntent = () => {

    let payload = {
      userId: userId,
      hubId: id
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/stripe/paymentSubscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
		.then((res) => res.json())
		.then(data => {
      setClientSecret(data.clientSecret);
      setShowPayment(true);
      setIsLoading(false);
    });

  }  

  const getIntent = () => {
    let payload = {
      userId: userId,
      hubId: id
    }
    fetch(process.env.REACT_APP_API_URI + '/v2/stripe/paymentIntent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({ userId, id }),
    })
		.then((res) => res.json())
		.then(data => {
      setClientSecret(data.clientSecret);
      setShowPayment(true);
      setIsLoading(false);
    });
  }

	const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const showPaymentInfo = () => {
    let paymentPage = "loading";
    console.log(paymentPage);
    if(showPayment && clientSecret && networkPaymentRecurring == "once off") {
      paymentPage = "once";
    } else if(showPayment && clientSecret && networkPaymentType == "recurring") {
      paymentPage = "recurring";
    }
    console.log(paymentPage);
    
    switch(paymentPage) {
      
      case "loading": return <div className="network-info"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>

      case "once": return <Elements options={options} stripe={stripePromise}>
        <CheckoutForm stripeSecret={clientSecret} userId={userId} hubId={id}/>
      </Elements>

      case "recurring": return <Elements options={options} stripe={stripePromise}>
        <CheckoutFormCard stripeSecret={clientSecret} userId={userId} hubId={id} userName={userName} userEmail={userEmail} paymentFee={networkPaymentFee}/>
      </Elements>

      default: return null;
    }
  }

  if (isLoading) {
		return <div className="container paywall loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  if (isMember) {
    return (
      <div className="container paywall connect-setup">
        <header>
  
          <div className="network-img"><NetworkImage coverRef={ networkCover } title={ networkTitle } /></div>
          <div className="network-desc">
            <h2>{ networkTitle }</h2>
            <CheckCircle fontSize={"large"} />
            <h4>You're already a member of this network</h4>
          </div>
        </header>
      </div>
    );
    
	}

	return (
		<div className="container paywall">
			<header>

				<div data-aos="fade-up">
					<h1>Join this Network</h1>
				</div>
        <div className="network-img"><NetworkImage coverRef={ networkCover } title={ networkTitle }  /></div>
        <div className="network-desc">
          <h2>{ networkTitle }</h2>
          <h4>This Network requires a<br/>{ networkPaymentRecurring } fee of ${ networkPaymentFee } to join</h4>
        </div>
				
			</header>

      

      <div className="network-info">
        { showPaymentInfo() }
      </div>

      
			
		</div>
	);

}
export default PayWall;