import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import ModalContext from '../../../../store/ModalContext';
import AuthContext from '../../../../store/AuthContext';
import { CircularProgress } from '@mui/material';
import CustomButton from '../../../../common/CustomButton';

const RenameAttachment = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTitle(modalCtx.details?.titleName)
  }, [modalCtx])

  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  const handleRename = async () => {

    setLoading(true)

    let payload = {
      "attachmentId": modalCtx.details?.id,
      "title": title
    }

    let options = { method: "PUT", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, body: JSON.stringify(payload) } }

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${modalCtx.details?.hubId}/attachments`, options)
      const data = await res.json()
      if (data.status === 'success') {
        console.log(data)
        modalCtx.closeModal()
        setLoading(false)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
      setLoading(false)
    }

  }

  console.log(title)

  return (
    <>
      <div className='confirm-delete'>
        <div className="text">
          Rename File:
        </div>
        <input value={title} onChange={({ target }) => setTitle(target.value)} />
        <div className="confirm-delete-btns">
          <CustomButton className="f-btn-bordered" style={{ width: '50%' }} onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton className="f-btn-half-width" onClick={() => handleRename()} >
            {
              loading
                ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                : "Rename"
            }
          </CustomButton>
        </div>
      </div>
    </>
  )
}

export default RenameAttachment