import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

//assets
import closeIcon from '../../assets/img/settings/close.svg';
import CustomButton from '../../common/CustomButton.jsx';

const Notification = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
	}

  const closeHandle = () => {
		modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  return (
    <>
      <div className='notification-settings'>
        <div className="notification-header">
            <h2>Notification Settings</h2>
            <img src={closeIcon} alt="close" className='close' onClick={() => closeHandle()} />
        </div>
        <div className="notification-preference">
            <div className="item">
                <div className="item-details">
                  <div className="item-main-text">No Notifications</div>
                  {/* <div className="item-secondary-text">Receive notifications for every message in hub</div> */}
                </div>
                <div className="item-control">
                    {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                    <span class="switch">
                      <input type="checkbox" class="switch" id="switch-1" />
                      <label for="switch-1"></label>
                    </span>
                </div>
            </div>
            <div className="item">
                <div className="item-details">
                  <div className="item-main-text">For Mentions Only</div>
                  {/* <div className="item-secondary-text">Receive notifications on only mentions</div> */}
                </div>
                <div className="item-control">
                    {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                    <span class="switch">
                      <input type="checkbox" class="switch" id="switch-2" />
                      <label for="switch-2"></label>
                    </span>
                </div>
            </div>
            <div className="item">
                <div className="item-details">
                  <div className="item-main-text">All Messages</div>
                  {/* <div className="item-secondary-text">Do not send Notification</div> */}
                </div>
                <div className="item-control">
                    {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} /> */}
                    <span class="switch">
                      <input type="checkbox" class="switch" id="switch-3" />
                      <label for="switch-3"></label>
                    </span>
                </div>
            </div>
        </div>
        <div className="invite-to-team-button-container">
          <CustomButton className="f-btn-bordered" onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton className="f-btn">Save</CustomButton>
        </div>
    </div>
    </>
  )
}

export default Notification;
