import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthContext from './AuthContext';

const FollowerContext = createContext({
  followers: [],
  following: [],
  updateFollowContext: (updateFollowContext) => {},
});

export function FollowerContextProvider(props) {

  const authCtx = useContext(AuthContext)
  const [followers, setFollowers] = useState();
  const [following, setFollowing] = useState();

  // console.log(follower)

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      getFollowers()
      getFollowing()
    }
  }, [])

  const getFollowers = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/followers',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        })

      const data = await res.json()
      if (data.status === 'success') {
        setFollowers(data.data)
      }
    } catch (error) {
      console.log(error)
    }

  }

  const getFollowing = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/following',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        })

      const data = await res.json()
      if (data.status === 'success') {
        setFollowing(data.data)
      }
    } catch (error) {
      console.log(error)
    }

  }


  function updateFollowHandler(data) {
    getFollowers()
    getFollowing()
  }

  const context = {
    followers: followers,
    following: following,
    updateFollowContext: updateFollowHandler,
  };
  console.log("followers :", followers)
  console.log("following :", following)
  return <FollowerContext.Provider value={context}>
    {props.children}
  </FollowerContext.Provider>
}

export default FollowerContext;