import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

//assets
import closeIcon from '../../assets/img/settings/close.svg';
import CustomButton from '../../common/CustomButton.jsx';

const ConfirmDelete = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  return (
    <>
      <div className='confirm-delete'>
        <div className="text">
          Are you sure you want to delete this network ?
        </div>
        <div className="confirm-delete-btns">
          <CustomButton className="f-btn-bordered" style={{width: '50%'}} onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton className="f-btn" style={{ width: '50%' }}>Delete</CustomButton>
        </div>
      </div>
    </>
  )
}

export default ConfirmDelete;
