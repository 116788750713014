import { useContext, useEffect, useState } from 'react'
import React from 'react'

import AddTrailingDots from '../../../helper/addTrailingDots'

import NoData from '../../../assets/img/networks/404.svg'
import Chatting from '../../../assets/img/networks/chatting.png'
import Users from '../../../assets/img/networks/user-group.png'

import AuthContext from '../../../store/AuthContext'
import { AlarmRounded } from '@mui/icons-material'
import moment from 'moment'
import GetImage from './GetImage'
import GetImageFirebase from '../../../Components/Networks/GetImageFirebase'
import ModalContext from '../../../store/ModalContext'
import { Button } from '@mui/material'


import avatarimg from '../../../assets/img/avatar/avatar3.jpg'
import CustomButton from '../../../common/CustomButton'

const Events = ({ NetworkId, changeNetwork, role }) => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)
  const [allEvents, setAllEvents] = useState(true);
  const [singleEventData, setSingleEventData] = useState({});


  const [events, setEvents] = useState([])

  useEffect(() => {
    getSubHubs()
  }, [NetworkId])

  const getSubHubs = async () => {

    let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, } }
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/event/hub/${NetworkId}/${new Date()?.toUTCString()}`, options)
      const data = await res.json()
      if (data.status === 'success') {
        setEvents(data.data)
        console.log(data)
      }

    } catch (error) {
      console.log('error getting events', error)
    }

  }

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  const handleClick = (data) => {
    setSingleEventData(data)
    setAllEvents(false)

  }

  const createEventHandler = (event, i) => {

    if (!event) return null

    return <>
      <div key={i + 'qwert'} className="event" onClick={() => handleClick(event)} >
        <div className="event-image">
          <GetImageFirebase imageRef={event?.image} />
          <div className="event-time-overlay">
            <span className="event-time-overlay-day" >{moment(event?.startTimestamp).format("DD")}</span>
            <span className="event-time-overlay-month" >{moment(event?.startTimestamp).format("MMM")}</span>
          </div>
        </div>
        <div className="event-details">
          <h3>{event?.title}</h3>
          <a className='event-link' target='_blank' rel="noreferrer" href={event?.link}>{event?.link}</a>
          <div className="event-time">
            <AlarmRounded className='event-time-icon' />
            <span className='event-time-string' >{moment(event?.startTimestamp).format("LT")}</span>
          </div>
        </div>
      </div>
    </>
  }



  // console.log(Object.entries(events))
  return (
    <div className='events-container' >
      {allEvents ? <div className="events-wrapper">
        <div className="events">
          <div className="events-head">
            <h3>Events</h3>
            {(role == 'owner' || role == 'moderator') ? <CustomButton className="f-btn-big" onClick={() => handleModal('create-event', NetworkId)}>Create Event</CustomButton> : null}
          </div>
          <div className="events-flex">
            {
              Object.entries(events)?.length > 0
                ? Object.entries(events)?.map((item, index) => (
                  <div key={index} className="event-group-container">
                    <div className="event-date">{item[0]}</div>
                    <div className="event-group">
                      {
                        item[1]?.map((event, i) => (
                          createEventHandler(event, i)
                        ))
                      }
                    </div>
                  </div>
                ))
                : <div className="no-events-info">
                  <img src={NoData} alt="no data" />
                  <h3>Sorry, No event yet</h3>
                </div>
            }
          </div>
        </div>
      </div> :

        <div className='single-event'>
          <div className='event-image'>
            <GetImageFirebase imageRef={singleEventData?.image} />
          </div>
          <div className='event-header'>
            <h2>{singleEventData?.title}</h2>
            <div className='header-item'>
              <div>
                <h3>Date</h3>
                <p>{moment(singleEventData?.startTimestamp).format("DD MMM, dddd")}</p>
              </div>
              <div>
                <h3>Time</h3>
                <div className='timeZone'>
                  <p>{moment(singleEventData?.startTimestamp).format("LT")}</p> -
                  <p>{moment(singleEventData?.endTimestamp).format("LT")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='event-description'>
            <h3>Description</h3>
            <p>{singleEventData?.description}</p>
          </div>
          <div className='event-link'>
            <h3>Link</h3>
            <a href={singleEventData?.link}>{singleEventData?.link}</a>
          </div>
          <div className='event-footer'>
            <h3>People Attending Event</h3>
            <div>
              <div id="imageContainer">
                <img src={avatarimg} alt='img' class="avatar-image" />
                <img src={avatarimg} alt='img' class="avatar-image overlap" />
                <img src={avatarimg} alt='img' class="avatar-image overlap" />
                <img src={avatarimg} alt='img' class="avatar-image overlap" />
                <div class="avatar-image count">+3</div>
              </div>

              <Button className='join-btn' >Join</Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Events