import React, { createContext, useState } from 'react';

const UserProfileContext = createContext({
  userName: '',
  details: {},
  setDetails: (setDetails) => {},
});

export function UserProfileContextProvider(props) {

  const [details, setDetails] = useState({});
  const [userName, setUserName] = useState('');

  function setDetailsHandler(username, data) {
    setUserName(username)
    setDetails(data);
  }



  const context = {
    userName: userName,
    details: details,
    setDetails: setDetailsHandler,
  };

  return <UserProfileContext.Provider value={context}>
    {props.children}
  </UserProfileContext.Provider>
}

export default UserProfileContext;