import { useContext, useEffect, useState } from 'react'
import React from 'react'

import AddTrailingDots from '../../../helper/addTrailingDots'

import NoData from '../../../assets/img/networks/404.svg'
import Chatting from '../../../assets/img/networks/chatting.png'
import Placeholder from '../../../assets/img/networks/placeholder.png'
import Users from '../../../assets/img/networks/user-group.png'

import AuthContext from '../../../store/AuthContext'
import GetImageFirebase from './GetImageFirebase'

import CustomButton from '../../../common/CustomButton'
import ModalContext from '../../../store/ModalContext'

const SubNetworks = ({ NetworkId, changeNetwork }) => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)

  const [subNetworks, setSubNetworks] = useState([])

  useEffect(() => {
    getSubHubs()
  }, [NetworkId])

  const getSubHubs = async () => {

    let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, } }
    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${NetworkId}/subhubs`, options)
      const data = await res.json()
      if (data.status === 'success') {
        setSubNetworks(data.data)
        console.log(data)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
    }

  }
  const handleModal = (modal, data) => {
    modalCtx.openModal()
    modalCtx.setDetails(modal, data)
  }

  return (
    <div className='sub-network-container' >
      <div className="sub-network-wrapper">
        <div className="sub-network">
          <div className="sub-network-head">
            <h3>Sub Networks</h3>
            <CustomButton className="f-btn-big" onClick={() => handleModal('create-sub-network',{selectedNetwork:NetworkId})}>Add Sub Network</CustomButton>
          </div>
          <div className="sub-network-flex">
            {
              subNetworks?.length > 0
                ? subNetworks?.map((item, index) => (
                  <div key={index} className="sub-network-item" onClick={() => changeNetwork(item.id, 'sub-network', item.parentHubId)}>
                    <div className="sub-network-image">
                      {
                        item.coverRef != ''
                          ? <GetImageFirebase className="network-img" imageRef={item.coverRef} />
                          : <img src={Placeholder} alt="placeholder" />
                      }
                    </div>
                    <div className="sub-network-details">
                      <h4 className="sub-network-title">{AddTrailingDots(item.title, 75)}</h4>
                      <div className="network-activity-details">
                        <div className="activity">
                          <img src={Users} alt="members" />
                          <span>{item.subscriberCount}</span>
                        </div>
                        <div className="activity">
                          <img src={Chatting} alt="messages" />
                          <span>{item.messageCount}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : <div className="no-sub-network-info">
                  <img src={NoData} alt="no data" />
                  <h3>Sorry, No sub network yet</h3>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubNetworks