import React, { useState, useEffect, useCallback, useContext } from 'react';
import { signInWithEmailAndPassword, signOut, onIdTokenChanged, createUserWithEmailAndPassword } from 'firebase/auth';

//firebase
import { auth } from "./Firebase"
import { ref, set, update } from "firebase/database";
import { database } from "../Components/FirebaseConfig";

//context
import FollowerContext from './FollowerContext';

let logoutTimer;
const tokenExpirationTime = new Date(new Date().getTime() + 3605000);

const AuthContext = React.createContext({
  token: '',
  userId: '',
  userName: '',
  displayName: '',
  email: '',
  avatarRef: '',
  isLoggedIn: false,
  login: (token, userId, userName, email, scope, tokenExpiration) => { },
  logout: () => { }
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime               = new Date().getTime();
  const adjExpirationTime         = new Date(expirationTime).getTime();

  const remainingDuration         = adjExpirationTime - currentTime;

  return remainingDuration;
}

export const AuthContextProvider = (props) => {


  //context
  const followerCtx = useContext(FollowerContext)

  //firebase db
  const db = database;
  
  const retrieveStoredToken = () => {
    const storedToken               = localStorage.getItem('token');
    const storedTokenExpiration     = localStorage.getItem('tokenExpiration');
    const remainingTime             = calculateRemainingTime(storedTokenExpiration);
    
    if(remainingTime <= 5000) {
      auth.onAuthStateChanged((user) => {
        user.getIdToken().then(idToken => {
          setToken(idToken);
          localStorage.setItem('token', idToken);
          localStorage.setItem('tokenExpiration', tokenExpirationTime);
        })
        return localStorage.getItem('token');
      })
    }
    return storedToken;
  }
  
  const initialToken                  = retrieveStoredToken();
  // const initialToken                  = localStorage.getItem('token');
  const initialUserId                 = localStorage.getItem('userId');
  const initialUserName               = localStorage.getItem('userName');
  const initialDisplayName            = localStorage.getItem('displayName');
  const initialEmail                  = localStorage.getItem('email');
  const initialAvatarRef              = localStorage.getItem('avatarRef');

  const [token, setToken] = useState(initialToken);
  const [userId, setUserId] = useState(initialUserId);
  const [userName, setUserName] = useState(initialUserName);
  const [displayName, setDisplayName] = useState(initialDisplayName);
  const [email, setEmail] = useState(initialEmail);
  const [avatarRef, setAvatarRef] = useState(initialAvatarRef);

  const userIsLoggedIn = !!token;

  // useEffect(() => {
  //   onIdTokenChanged(auth, data => {
  //     console.log("Called from here");
  //     if(data) {
  //       setToken(data.accessToken);
  //       setUserId(data.uid);
  //       localStorage.setItem('token', data.accessToken);
  //       localStorage.setItem('userId', data.uid);
  //     }
  //   })
  //   followerCtx.updateFollower()
  // }, [onIdTokenChanged, setToken, setUserId]);

  const loginHandler = (email, password, userName, displayName, avatarRef, joinNetwork = false, hash) => {

    signInWithEmailAndPassword(auth, email, password)
      .then((data) => {

        setToken(data.user.accessToken);
        setUserId(data.user.uid);
        setUserName(userName);
        setDisplayName(displayName);
        setEmail(email);
        setAvatarRef(avatarRef);

      localStorage.setItem('token', data.user.accessToken);
      localStorage.setItem('tokenExpiration', tokenExpirationTime);
      localStorage.setItem('userId', data.user.uid);
      localStorage.setItem('userName', userName);
      localStorage.setItem('displayName', displayName);
      localStorage.setItem('email', email);
      localStorage.setItem('avatarRef', avatarRef);

        if (joinNetwork) {
          joinNetworkPost(hash, data.user.uid, data.user.accessToken)
        }
        const mainContainer = document.querySelector('.main-container')
        mainContainer.style = 'height: calc(100% - 56px)'

      })
      .catch((error) => {
        console.log(error);
      });

  }

  const registerHandler = (email, password, displayName, joinNetwork = false, hash) => {

    createUserWithEmailAndPassword(auth, email, password)
      .then((data) => {

        const uid = data.user.uid
        const tempToken = data.user.accessToken

        var userPayload = {
          "userId": uid,
          "name": displayName,
          "username": displayName,
          "email": email,
        };
        console.log(userPayload)

        fetch(process.env.REACT_APP_API_URI + `/v2/user`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
          body: JSON.stringify(userPayload)
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {

            set(ref(db, `users/${uid}`), { "email": email })
              .then(() => {
                console.log('user added')
                getUserData(uid, tempToken)
              })
              .catch((error) => {
                console.log(error)
              });

            if (joinNetwork) {
              joinNetworkPost(hash, uid, tempToken)
            }

          } else {
            console.log('something went wrong while adding user')
          }
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.code);
        if (e.code === 'weak-password') {
          console.log("The password provided is too weak.")
        } else if (e.code === 'email-already-in-use') {
          console.log("The account already exists for that email.")
        } else if (e.code === 'too-many-requests') {
          console.log("Sorry, failed too many signup attempts. Please try again later")
        }
      });

  }

  const getUserData = (uid, tempToken) => {

    fetch(process.env.REACT_APP_API_URI + `/v2/user/${uid}`, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        setToken(tempToken);
        setUserId(data.data.id);
        setUserName(data.data.name);
        setDisplayName(data.data.username);
        setEmail(data.data.email);
        setAvatarRef(data.data.avatarRef);

        localStorage.setItem('token', tempToken);
        localStorage.setItem('tokenExpiration', tokenExpirationTime);
        localStorage.setItem('userId', data.data.id);
        localStorage.setItem('userName', data.data.username);
        localStorage.setItem('displayName', data.data.name);
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('avatarRef', data.data.avatarRef);

      } else {
        console.log('something went wrong while getting user')
      }
    });

  }

  const logoutHandler = () => {

    signOut(auth)
      .then(() => {
        console.log("Signed out");
        setToken(null);
        setUserId(null);
        setUserName(null);
        setDisplayName(null);
        setEmail(null);
        setAvatarRef(null);

      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('userId');
      localStorage.removeItem('userName');
      localStorage.removeItem('displayName');
      localStorage.removeItem('email');
      localStorage.removeItem('avatarRef');
    })
    .catch((error) => {
      console.log(error);
    });
    
  };

  const joinNetworkPost = async (hash, userId, token) => {

    const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${hash}/networkMembership`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({
        userId: userId
      })
    })
    const resData = await res.json()

    if (resData.status == "success") {
      console.log("joined network", resData);
      // navigate({ to: '/networks' })
      window.location.assign(window.location.origin + "/networks");
      // window.location = "https://exampleURL.com/";

    } else {
      console.log("Failed to join network");
    }

  }

  const contextValue = {
    token: token,
    userId: userId,
    userName: userName,
    displayName: displayName,
    email: email,
    avatarRef: avatarRef,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    register: registerHandler,
    logout: logoutHandler
  }
  console.log(contextValue);
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;