import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../FirebaseConfig";
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';
import Placeholder from '../../assets/img/networks/placeholder.png'

const noMenuRoutes = ["/login"];

function GetImageFirebase({ imageRef, ratio, className }) {

  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const [avatarImage, setAvatarImage] = useState("");

  useEffect(() => {
    if (imageRef !== "" && imageRef !== null && imageRef !== undefined) {
      getImg(imageRef);
      console.log(imageRef)
    }
  }, [imageRef]);

  const getImg = (coverRef) => {

    const isPresent = coverRef.includes('https://');

    isPresent ?
      setAvatarImage(coverRef)
      :
      getDownloadURL(ref(storage, coverRef))
        .then((url) => {
          setAvatarImage(url);
        })
        .catch((error) => {
          console.log(error)
        });
  }




  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {avatarImage ? <img src={avatarImage} alt="post" className={className} /> : <img src={Placeholder} alt="post" className={className} />}
    </>
  );
}
export default GetImageFirebase;