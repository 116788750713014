import React, { useState, useContext } from 'react'
import Logo from '../assets/img/home/logo.png'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { CloseRounded } from '@mui/icons-material'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import AuthContext from '../store/AuthContext.js';
import AvatarImage from '../Components/Avatar/AvatarImage';
import PhoneSlider from "../Components/PhoneSlider/PhoneSlider";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import EditProfileIcon from '../assets/img/navbar/edit-profile.svg';
import ManagePaidNetworksIcon from '../assets/img/navbar/manage-paid-networks.svg';
import TransactionsIcon from '../assets/img/navbar/transactions.svg';
import DeleteAccountIcon from '../assets/img/navbar/delete-account.svg';
import NetworkTopicsIcon from '../assets/img/navbar/network-topics.svg';
import LogoutIcon from '../assets/img/navbar/logout.svg';
import Social1 from '../assets/img/banner/social1.svg';
import Social2 from '../assets/img/banner/social2.svg';

import SpiralIcon from '../assets/img/banner/spiral.png';
import CircleIcon from '../assets/img/banner/circle1.png';
import PolarIcon from '../assets/img/banner/polar.png';
import PentagonIcon from '../assets/img/banner/pentagon.png';
import TriangleIcon from '../assets/img/banner/triangle1.png';

import CommunityImage from '../assets/img/community/community.png';
import CommunityBg from '../assets/img/community/community-bg.png';
import FeatureIcon1 from '../assets/img/featured/feature1.png';
import FeatureIcon2 from '../assets/img/featured/feature2.png';
import FeatureIcon3 from '../assets/img/featured/feature3.png';

import FooterImage from '../assets/img/footer/footer-img.png';



//scss
import '../Styles/Home.scss'
import CustomButton from '../common/CustomButton.jsx';

const Home = () => {

  const authCtx = useContext(AuthContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const DropdownMenuElements = [
    {
      label: 'Edit Profile',
      icon: EditProfileIcon,
      onClick: () => {
        setDropdownOpen(false);
        navigate('/profile/edit', { replace: true });
      }
    },
    {
      label: 'Manage Paid Networks',
      icon: ManagePaidNetworksIcon,
      onClick: () => {

      }
    },
    {
      label: 'Transactions',
      icon: TransactionsIcon,
      onClick: () => {

      }
    },
    {
      label: 'Delete Account',
      icon: DeleteAccountIcon,
      onClick: () => {

      }
    },
    {
      label: 'Network Topics',
      icon: NetworkTopicsIcon,
      onClick: () => {
        setDropdownOpen(false);
        navigate('/profile/topics', { replace: true });
      }
    },
    {
      label: 'Logout',
      icon: LogoutIcon,
      onClick: () => authCtx.logout()
    },
  ];

  const navigate = useNavigate();


  return (
    <div className='home-container' style={openMenu ? { overflow: "hidden" } : null} >
      <section className="nav-container">
        <nav>
          <div className="logo"><img src={Logo} alt="logo" /></div>

          <div className="links">
            {
              authCtx.isLoggedIn ? (
                <div className="nav">
                  <div className='nav-links'>
                    <NavLink to="/networks"><span>Networks</span></NavLink>
                    <NavLink to="/explore"><span>Explore</span></NavLink>
                    <NavLink to="/messages"><span>Messages</span></NavLink>
                  </div>
                  <div className="profile">
                    <span>{`Hi ${authCtx.userName}`}</span>
                    <div className="nav-icon">
                      {/* <KeyboardArrowDownRoundedIcon onClick={() => authCtx.logout()} /> */}
                      <KeyboardArrowDownRoundedIcon style={{ color: 'black' }} onClick={() => setDropdownOpen(!dropdownOpen)} />
                    </div>

                    <div className="drowdown-menu" style={dropdownOpen ? null : { display: "none" }}>
                      {
                        DropdownMenuElements.map((item, index) => (
                          <div key={index} className="drowdown-menu-element" onClick={item.onClick}>
                            <img src={item.icon} alt='' />
                            <h1> {item.label} </h1>
                          </div>
                        ))
                      }
                    </div>
                    <AvatarImage />
                  </div>
                </div>
              ) : (
                <div className='link-container'>
                  <NavLink to="/login"><button className='login-button'>Login</button></NavLink>
                  <NavLink to="/register"><CustomButton className='f-btn-big'>Sign Up</CustomButton></NavLink>
                </div>
              )
            }
          </div>
          <MenuRoundedIcon className='menu-icon' style={openMenu ? { display: "none" } : null} onClick={() => setOpenMenu(true)} />
          <div className={openMenu ? "open mobile-links" : "mobile-links close"}>
            <CloseRounded onClick={() => setOpenMenu(false)} />
            {
              authCtx.isLoggedIn ?

                <div className="nav">
                  <div className='nav-links'>
                    <NavLink to="/home"><span>Home</span></NavLink>
                    <NavLink to="/networks"><span>Networks</span></NavLink>
                    <NavLink to="/explore"><span>Explore</span></NavLink>
                    <NavLink to="/messages"><span>Messages</span></NavLink>
                  </div>
                  <div className="profile">
                    <span>{`Hi ${authCtx.userName}`}</span>
                    <AvatarImage />
                  </div>
                </div>
                :
                <div className='link-container'>
                  <NavLink to="/login"><button>Login</button></NavLink>
                  <NavLink to="/register"><button>Sign Up</button></NavLink>
                </div>
            }
          </div>
        </nav>
      </section>

      <main className={"main-content"}>
        <section className={"banner-section overflow-hidden"}>
          <div className={"banner-bg"} >
            <svg width={"101%"} height={"100%"} viewBox="1 0 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient gradientUnits="userSpaceOnUse" x1="250.199" y1="-0.089" x2="250.199" y2="273.474" id="gradient-1" gradientTransform="matrix(-0.000795, -0.999999, 1.833572, -0.001457, -0.237588, 380.700831)">
                  <stop offset="0" style={{ stopColor: 'rgb(28, 157, 164)' }} />
                  <stop offset="0.245" style={{ stopColor: 'rgb(64, 110, 170)' }} />
                  <stop offset="0.496" style={{ stopColor: "rgb(145, 76, 132)" }} />
                  <stop offset="0.768" style={{ stopColor: 'rgb(199, 74, 90)' }} />
                  <stop offset="1" style={{ stopColor: "rgb(243, 132, 40)" }} />
                </linearGradient>
              </defs>
              <path
                style={{
                  paintOrder: 'fill',
                  fill: 'url(#gradient-1)',
                  fillRule: 'nonzero'
                }}
                d="M 500 271.166 L 500.399 0 L 0.399 -0.089 L 0 273.474 C 0 273.474 23.563 238.729 40.735 240.726 C 57.907 242.723 62.301 256.302 77.476 259.497 C 92.651 262.692 102.236 222.756 116.613 217.164 C 130.99 211.572 149.761 216.765 166.134 260.295 C 182.507 303.825 192.492 201.588 214.457 189.607 C 236.422 177.626 245.608 280.263 264.777 273.074 C 283.946 265.885 285.944 192.403 300.72 187.212 C 315.496 182.021 334.265 208.378 338.658 212.771 C 343.051 217.164 347.844 224.353 361.023 240.727 C 374.202 257.101 388.978 278.666 414.936 268.681 C 440.894 258.696 431.592 261.124 458.067 246.317 C 484.542 231.51 500 271.166 500 271.166 Z" />
            </svg>
            <img className={"position-absolute"} style={{ left: '-75px', bottom: '55%' }} src={SpiralIcon} alt="" />
            <img className={"position-absolute"} style={{ right: '-95px', top: '0' }} src={SpiralIcon} alt="" />

            <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '8%', top: '100px' }} src={TriangleIcon} alt="" />
            <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '10%', top: '100px' }} src={TriangleIcon} alt="" />

            <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '64px', left: '35%', top: '80px' }} src={PolarIcon} alt="" />
            <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '64px', right: '20%', top: '280px' }} src={PolarIcon} alt="" />
            <img className={"position-absolute"} style={{ transform: 'rotate(-20deg)', width: '64px', left: '25%', top: '35%' }} src={PolarIcon} alt="" />

            <img className={"position-absolute"} style={{ width: '60px', left: '42%', top: '470px' }} src={CircleIcon} alt="" />
            <img className={"position-absolute"} style={{ width: '60px', right: '9%', top: '490px' }} src={CircleIcon} alt="" />

            <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '12%', top: '460px' }} src={PentagonIcon} alt="" />
            <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '25%', top: '650px' }} src={PentagonIcon} alt="" />
          </div>

          <div className={"container"} style={{ position: 'relative' }}>
            <div style={{ marginBottom: '70px' }}>
              <h1 className={"banner-title text-white"}>Frequency <br /> The Network Generator</h1>
              <p style={{ fontSize: '20px' }}>Our app empowers people to build communities</p>
            </div>
            <div className={"banner-social d-flex justify-content-center"}>
              <a className={"banner-social-item"} href="https://play.google.com/store/apps/details?id=co.frequencyworld.app&hl=en_US&gl=US&pli=1" target="_blank" rel="noreferrer">
                <img src={Social1} alt="" />
              </a>
              <a className={"banner-social-item"} href="https://apps.apple.com/us/app/frequency-people/id1608443524" target="_blank" rel="noreferrer">
                <img src={Social2} alt="" />
              </a>
            </div>
            <PhoneSlider />
          </div>
        </section>

        <section className="community-section section-padding">
          <div className="custom-container d-flex justify-content-center align-items-center w-100">
            <div className={"community-group d-flex flex-wrap align-items-center w-90"}>
              <div className={"col"}>
                <div className={"community-item community-item-one position-relative"}>
                  <img className={"community-image d-block w-80"} src={CommunityImage} alt="" />
                  <img className={"community-bg-image position-absolute"} src={CommunityBg} alt="" />
                </div>
              </div>
              <div className={"col"}>
                <div className="community-item community-item-two">
                  <div className={"custom-title"}>Connect With Your Communities On Frequency</div>
                  <p className={"community-text"}>The Frequency app empowers users, brands, influencers, music artists, and podcasters to own hubs that monetize their followers through tickets sales, ads, subscription to exclusive live and AR content, as well as NFTs.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section section-padding">
          <div className="custom-container">
            <div className={"feature-group d-flex flex-wrap text-center"}>
              <div className={"col"}>
                <div className={"feature-item"}>
                  <img className={"feature-img"} src={FeatureIcon1} alt="" />
                  <div className={"feature-title"}>Make new friends and keep the old</div>
                  <p>Search for an existing hub to join the conversation or create your own hub where a group of like minded individuals connect. Customize your hub with options like public or private visibility.</p>
                </div>
              </div>
              <div className={"col"}>
                <div className="feature-item">
                  <img className={"feature-img"} src={FeatureIcon2} alt="" />
                  <div className={"feature-title"}>Make money 24/7</div>
                  <p>Hub owners like brands and influencers monetize their community all the time. through the release of creative non-exclusive and exclusive content.</p>
                </div>
              </div>
              <div className={"col"}>
                <div className="feature-item">
                  <img className={"feature-img"} src={FeatureIcon3} alt="" />
                  <div className={"feature-title"}>Chat, Live, AR features and more</div>
                  <p>Using Frequency gives you an opportunity to explore the latest social features for a more intense and engaging experience.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-section section-padding">
          <div className="custom-container">
            <div className={"contact-block d-flex align-items-center justify-content-between"}>
              <div>
                <div className={"custom-title"}>Get Started with Frequency</div>
                <span className={"contact-text"}>Frequency is always LIVE</span>
              </div>
              <NavLink 
                to="/contact"
              > <CustomButton className='f-btn-extra-big'> Contact Us</CustomButton></NavLink>
            </div>
          </div>
        </section>
      </main>

      <footer className={"footer-section"}>
        <div className="custom-container">
          <div className="footer-block">
            <img className={"footer-image"} src={FooterImage} alt="" />
            <div className={"footer-link"}>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
              <Link to={"/terms-of-use"}>Terms of use</Link>
            </div>
          </div>
          <div className={"footer-copyright"}>Powered by SpokeHub, Inc. 2022 <sup>&copy;</sup></div>
        </div>
      </footer>
    </div>
  )
}

export default Home;