import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm(props) {
  
  const stripe                    = useStripe();
  const elements                  = useElements();

  const [message, setMessage]     = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );
    // const clientSecret = props.stripeSecret;

    // if (!clientSecret) {
    //   return;
    // }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   console.log(paymentIntent);
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     // case "requires_payment_method":
    //     //   setMessage("Your payment was not successful, please try again.");
    //     //   break;
    //     default:
    //       setMessage("");
    //       break;
    //   }
    // });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) { return; }
    setIsLoading(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://app.frequencypeople.com/networks/" + props.hubId + "/paywall/" + props.userId + "/completed",
      }
    });
    
    if(error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" style={{ height: "auto" }} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Join Network"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}