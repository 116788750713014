import React, { useState, useCallback, useContext, useEffect, useRef } from 'react'
import closeIcon from '../../assets/img/settings/close.svg';
import boldIcon from '../../assets/img/networks/nav/bold-icon.svg'
import italicIcon from '../../assets/img/networks/nav/italic-icon.svg'
import underlineIcon from '../../assets/img/networks/nav/underline-icon.svg'
import Icon from '@mui/material/Icon';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { CircularProgress } from '@mui/material';

import { ChromePicker } from 'react-color';
import { Select, FormControl, MenuItem } from '@mui/material';

import ModalContext from '../../store/ModalContext';
import AuthContext from '../../store/AuthContext';
import MessageContext from '../../store/MessageContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '../../common/CustomButton';

var Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = [...Font.whitelist, 'Lato', 'Mulish', 'WorkSans', 'Nunito'];
ReactQuill.Quill.register(Font, true);

var Type = ReactQuill.Quill.import('formats/size');
Type.whitelist = [...Type.whitelist, 'Normal', 'Heading', 'SubHeading', 'Small'];
ReactQuill.Quill.register(Type, true);

export default function CreateCourse() {

  const modalCtx                                 = useContext(ModalContext);
  const authCtx                                  = useContext(AuthContext);
  const msgCtx                                   = useContext(MessageContext);

  const quillRef                                 = useRef();
  const [textBold, setTextBold]                  = useState(false);
  const [textItalic, setTextItalic]              = useState(false);
  const [textUnderline, setTextUnderline]        = useState(false);
  const [showColorPicker, setShowColorPicker]    = useState(false);
  const [currColor, setCurrColor]                = useState("#000");
  const [selectedFont, setSelectedFont]          = useState('Lato');
  const [selectedTextType, setSelectedTextType]  = useState('Normal');
  const [editorValue, setEditorValue]            = useState('');
  const [courseTitle, setCourseTitle]            = useState('');
  const [createCourseFalg, setCreateCourseFlag]  = useState(false);
  
  const fontOptions = ['Lato', 'Mulish', 'WorkSans', 'Nunito'];
  const textTypeOptions = ['Normal', 'Heading', 'SubHeading', 'Small'];

  const handleClose = () => {
    modalCtx.closeModal();
  }

  const modules = {
    toolbar: {
      container: '#custom-toolbar',
    },
  }

  const toolbarHandler = {

    handleFont: (font) => {
      const quill = quillRef.current.getEditor();
      setSelectedFont(font)
      quill.format('font', font)
    },

    handleTextType: (type) => {
      const quill = quillRef.current.getEditor();
      setSelectedTextType(type)
      quill.format('size', type)
    },

    handleBold: (val) => {
      const quill = quillRef.current.getEditor();
      setTextBold(!textBold);
      quill.format('bold', val)
    },
    
    handleItalic: (val) => {
      const quill = quillRef.current.getEditor();
      setTextItalic(!textItalic);
      quill.format('italic', val)
    },

    handleUnderline: (val) => {
      const quill = quillRef.current.getEditor()
      setTextUnderline(!textUnderline)
      quill.format('underline', val)
    },
    
    handleColor: (color) => {
      const quill = quillRef.current.getEditor();
      setCurrColor(color)
      quill.format('color', color)
    }
  }


  const postData = {
      "hubId": "1",
      "title": courseTitle,
      "description": editorValue,
      "imageRef": msgCtx.details.url,
      "createdBy": authCtx.userId
  }
  

  const handleCreateCourse = async () => {
    setCreateCourseFlag(true);
    console.log("Posted Data", postData)
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v2/course', {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(postData)
      })
      
      if (!response.ok) {
        console.log(response)
        throw new Error("Network response was not ok")
      }
      const data = await response.json();
      console.log('Post request successful:', data);
      setCreateCourseFlag(false)
    } catch (error) {
      console.error('Error during POST request:', error.message);
    }

  }

  const uploadImage = () => {
    modalCtx.setDetails('AddNewImage', {"from": "create-course"});
    modalCtx.openModal()
  }

  const handleEditorChange = (value) => {
    setEditorValue(value);
  }

  const handleCourseTitle = (value) => {
    setCourseTitle(value.target.value);
  }

  return (
    <>
      <div className='create-course-container'>
        <div className='create-course'>

          <div className='header'>
            <h3>Create Course</h3>
            <img src={closeIcon} alt="" onClick={handleClose}/>
          </div>

          <div className='content'>

            <p>Add Course Thumbnail</p>
            <div className='thumbnail-btn'>
              {'url' in msgCtx.details ? <img src={ msgCtx.details.url } /> :  <Icon className='camera-image' type='file' component={AddAPhotoIcon} style={{ fontSize: 40 }} onClick={() => uploadImage()}/>}
            </div>

            <div className='course-title-input'>
              <input type="text" placeholder='Course title' onChange={handleCourseTitle}/>
            </div>

            <div className='course-description-text-editor'>
              <div id="custom-toolbar" className='custom-toolbar'>

                <div>
                  <FormControl variant="standard" sx={{ m: 1, maxWidth: 120, fontSize: 16 }}>
                    <Select
                      disableUnderline={true}
                      id="fontDropdown"
                      value={selectedFont}
                      onChange={(e) => {
                      const font = e.target.value;
                        setSelectedFont(font);
                        toolbarHandler.handleFont(font)
                      }}
                      style={{fontSize: '14px'}}
                    >
                      {fontOptions.map((font, index) => (
                      <MenuItem  key={index} value={font} style={{ fontFamily: `${font}`, fontSize: '14px'}}>
                        {font}
                      </MenuItem >
                    ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <Select
                      id="textTypeDropdown"
                      disableUnderline={true}
                      value={selectedTextType}
                      onChange={(e) => {
                      const textType = e.target.value;
                      setSelectedTextType(textType);
                      toolbarHandler.handleTextType(textType)
                      }}
                      style={{fontSize:'14px'}}
                    >
                      {textTypeOptions.map((text, index) => (
                      <MenuItem  key={index} value={text} style={{fontSize:"14px"}}>
                        {text}
                      </MenuItem >
                    ))}
                    </Select>
                  </FormControl>
                </div>

                <img className='bold' src={ boldIcon } alt="Bold" onClick={() => toolbarHandler.handleBold(!textBold)}/>
                <img className='italic' src={ italicIcon } alt="Italic" onClick={() => toolbarHandler.handleItalic(!textItalic)} />
                <img className='underline' src={underlineIcon} alt="Underline" onClick={() => toolbarHandler.handleUnderline(!textUnderline)} />
                <div className='color-picker'>
                  <div onClick={(e) => setShowColorPicker(!showColorPicker)} style={{ width: "25px", height: '25px', borderRadius: '4px', backgroundColor: `${currColor}` }}></div>
                  {showColorPicker && <ChromePicker 
                      className='colorSketchPicker' id="colorPicker" color={currColor} onChangeComplete={(color) => toolbarHandler.handleColor(color.hex)}
                      styles={{default: {picker: { borderRadius: '4px', padding: '12px 12px 0 12px' },},}}/>
                  }
                </div>

              </div>
              <ReactQuill placeholder="Course Descripation" className='editor' modules={modules} ref={quillRef} value={editorValue} onChange={handleEditorChange}/>
            </div>

          </div>

          <div className='course-create-btn'>
            <CustomButton className='f-btn-full-width' onClick={() => handleCreateCourse()}>
              {createCourseFalg ? <CircularProgress size={18} style={{ color: '#FFF' }} /> : <span>Create Course</span>}
            </CustomButton>
          </div>

        </div>
      </div>
    </>
  )

}
