import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import AvatarChatImage from '../Avatar/AvatarChatImage.jsx';
import { Button } from '@mui/material';

const Invitation = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [userData, setUserData] = useState([]);

  const closeHandle = () => {
		modalCtx.closeModal();
  }

  console.log(modalCtx.details);

  useEffect(() => {

    fetch(process.env.REACT_APP_API_URI + '/v2/user/' + modalCtx.details.userId + "/profile" , {
      method: 'GET',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setUserData(data.data)
      } else {
        // setOpenSnackbar(true);
      }
    });

  }, []);

  const joinNetwork = () => {

    let payload = {
      userId: modalCtx.details.userId,
      hubId: modalCtx.details.networkId,
      role: modalCtx.details.role
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + modalCtx.details.networkId + "/membership" , {
      method: 'PUT',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        modalCtx.closeModal()
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  return (
    <>
      <div className="invitation-container">
        <div className="invite-header">
          <h2>Invitation</h2>
          <CloseRounded className='close' onClick={() => closeHandle()} />
        </div>
        {/* <div className="profile-container">
          <div className="user-img">
            {userData && <AvatarChatImage avatarRef={userData?.avatarRef} />}
          </div>
          <div className="user-name">
              <div className="name">{userData && userData?.name}dfgdfgdgf</div>
              <div className="username">@{userData && userData?.username}dfdfgdgd</div>
          </div>
        </div> */}
        {/* <p>Alex has invited you to this network. Would you <br /> like to join?</p> */}
        <p>You have been invited to this network. Would you <br /> like to join?</p>
        <div className="action-btns">
          <Button className='red-btn white-bg-btn' onClick={() => closeHandle()}  >Dismiss</Button>
          <Button className='red-btn' onClick={() => joinNetwork()}>Accept</Button>
        </div>
      </div>
    </>
  )
}

export default Invitation;
