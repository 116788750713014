import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

//firebase
import { ref, set, onValue, orderByChild, limitToLast, update, query, limitToFirst } from "firebase/database";
import { database } from "../../../Components/FirebaseConfig.jsx";

//assets
import clap from '../../../assets/img/reactions/chat_reaction_clap.svg'
import heart from '../../../assets/img/reactions/chat_reaction_heart.svg'
import laugh from '../../../assets/img/reactions/chat_reaction_laugh.svg'
import thumb from '../../../assets/img/reactions/chat_reaction_thumbs_up.svg'
import { CircularProgress } from '@mui/material';
import { CloseRounded, MoreVertRounded, SentimentSatisfiedRounded, CommentsDisabledRounded, MeetingRoom } from '@mui/icons-material';

//context
import AuthContext from '../../../store/AuthContext.js';
import ModalContext from '../../../store/ModalContext.js';
import MessageContext from '../../../store/MessageContext.js';
import UserProfileContext from '../../../store/UserProfileContext.js';

//components
import AvatarChatImage from '../../../Components/Avatar/AvatarChatImage';
import moment from 'moment';
import DmMessageBox from './DmMessageBox.jsx';
import ChatAttachmentHandler from './ChatAttachmentHandler.jsx';



const DmChat = ({ currentDirectMessageId, updateDms, loadMoreMessages, setLoadMoreMessages }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const msgCtx = useContext(MessageContext);
  let navigate = useNavigate();

  const db = database;

  const [isOpenReaction, setIsOpenReaction] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const [chats, setChats] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState();
  const [messageBoxHeight, setMessageBoxHeight] = useState(65);

  const [loadMessagesAmount, setLoadMessagesAmount] = useState(10)

  useEffect(() => {

    if (currentDirectMessageId !== null) {
      if (loadMoreMessages) {
        setIsLoading(true);
        getDirectMessages()
      }
      getMembers()
    }

  }, [currentDirectMessageId]);

  useEffect(() => {
    if (loadMoreMessages) {
      getDirectMessages()
    }
  }, [loadMessagesAmount])

  const messageBoxRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setMessageBoxHeight(node.getBoundingClientRect().height)
    });
    resizeObserver.observe(node);
  }, []);

  const getDirectMessages = () => {

    if (currentDirectMessageId !== "" && currentDirectMessageId !== null) {
      const dbRef = ref(db, '/directMessages/' + currentDirectMessageId)
      const chatsQuery = query(dbRef, orderByChild('timestamp'), limitToLast(loadMessagesAmount))
      onValue(chatsQuery, (snapshot) => {
        setChats(snapshotToArray(snapshot));
        setIsLoading(false);
        // updateDms()
        getFirstMessage()
        // setTimeout(() => {
        //   // scrollDownToNewMessages()
        // }, 500);
      });
    }
  }

  const getFirstMessage = () => {

    if (currentDirectMessageId !== "" && currentDirectMessageId !== null) {
      const dbRef = ref(db, '/directMessages/' + currentDirectMessageId)
      const chatsQuery = query(dbRef, orderByChild('timestamp'), limitToFirst(1))
      onValue(chatsQuery, (snapshot) => {
        const returnArr = [];
        snapshot.forEach((childSnapshot) => {
          const item = childSnapshot.val();
          returnArr.push(item);
        });
        handleLoadMoreMessages(returnArr[0])
      });
    }
  }

  const handleLoadMoreMessages = (firstMessage) => {
    if (firstMessage) {
      if (chats.find(i => firstMessage.timestamp == i.timestamp)) {
        setLoadMoreMessages(false)
      }
    }
  }

  const scrollDownToNewMessages = () => {
    const messagesDiv = document.querySelector(".messages")
    messagesDiv.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  const snapshotToArray = (snapshot) => {

    // // console.log(snapshot.val())
    // const data = snapshot.val()
    // let arrayOfChats = []

    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      returnArr.push(item);
    });
    console.log(returnArr)
    return returnArr.reverse();
    // if (data !== null || undefined) {
    //   arrayOfChats = Object.keys(data).map(key => {
    //     return data[key];
    //   })
    // }
    // return arrayOfChats.reverse()

  }


  //  opens a modal for message actions
  const showModal = (modal, video) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }


  const handleReaction = (data) => {
    // console.log("reaction open", data)
    setUserData(data)
    if (!isOpenReaction) {
      setIsOpenReaction(true)
    } else {
      setIsOpenReaction(false)
    }
  }


  const addReaction = (reaction) => {

    let hubId = userData?.hubId;
    let timeStamp = userData.timestamp;
    var clapCount = userData?.clapReactionCount ? userData?.clapReactionCount : 0;
    var heartCount = userData?.heartReactionCount ? userData?.heartReactionCount : 0;
    var laughCount = userData?.laughReactionCount ? userData?.laughReactionCount : 0;
    var thumbsUpCount = userData?.thumbsUpReactionCount ? userData?.thumbsUpReactionCount : 0;

    let payload = {}

    switch (reaction) {
      case "clap":
        clapCount++;
        payload["clapReactionCount"] = clapCount;
        break;

      case "heart":
        heartCount++;
        payload["heartReactionCount"] = heartCount;
        break;

      case "laugh":
        laughCount++;
        payload["laughReactionCount"] = laughCount;
        break;

      case "thumbs":
        thumbsUpCount++;
        payload["thumbsUpReactionCount"] = thumbsUpCount;
        break;

      default: return null;
    }

    const messageRef = ref(db, '/directMessages/' + currentDirectMessageId + '/' + timeStamp)
    update(messageRef, payload)
    setIsOpenReaction(false)
  }



  const messageFunction = (data) => {
    if (data.replyMessage) {
      return <div className="reply-msg">
        <div className="reply-content">
          <div className="name">{data?.replyMessage.username}</div>
          <div className="content">{data?.replyMessage.content !== "" ? data?.replyMessage.content : <span style={{ background: "transparent", fontWeight: "bold", color: "#007C8990" }}>Media</span>}</div>
        </div>
      </div>
    } else if (data.previewPath) {
      return <ChatAttachmentHandler attachment={data.attachment} previewPath={data.previewPath} />
    } else if (data.attachment) {
      return <ChatAttachmentHandler attachment={data.attachment} />
    } else {
      return null;
    }
  }


  const getMembers = () => {

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/0/members', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // console.log(data?.data)
        setMembers(data?.data)
      } else {
      }
    });

  }

  const prepareMentionMsg = (msg) => {
    let finalMsg = ''
    let array = msg.split(' ');


    const map1 = array.map(x => {
      if (x.includes("@[")) {
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return ` @${returnArr}`
        }
      } else if (/[@]/g.test(x)) {
        return ` ${x}`
      } else {
        return x
      }
    });


    //finalMsg = map1.join(" ")
    return map1?.map((word, index) => {
      if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(word)) {
        return <strong key={index} className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[@]/g.test(word)) {
        return <strong key={index} className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else {
        return " " + word + " "
      }
    })
  }

  const passUserName = (modal, userName) => {
    // console.log(userName)
    let newUserName = userName.substring(1)
    for (let i = 0; i < members.length; i++) {
      // console.log(newUserName)
      if (members[i].username === newUserName) {
        // userProfileCtx.setDetails(newUserName, members[i].id) 
        // let path = `/user/${members[i].username}`; 
        // navigate(path);
        modalCtx.openModal();
        modalCtx.setDetails(modal, members[i]?.id);
      }
    }
  }

  const checkForAvatar = (username, avatar) => {
    if (username === authCtx.userName) {
      return authCtx.avatarRef
    } else if (avatar) {
      return avatar
      // for (let i = 0; i < avatar.length; i++) {
      // 	if ( username == avatar[i].username ) {
      // 		return avatar[i].avatar
      // 	} else {
      // 	}
      // }
    }
  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }


  const handleMessagesScroll = (e) => {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = Math.trunc(Math.abs(e.target.scrollTop))
    const offsetHeight = e.target.offsetHeight
    if (scrollHeight - offsetHeight - scrollTop < 5 && loadMoreMessages) {
      setLoadMessagesAmount(prev => prev + 10)
    }

  }


  if (isLoading) {
    return <div className="container loading"><CircularProgress style={{ 'color': '#007C89' }} size={60} /></div>
  }

  // console.log(chats)
  // console.log(loadMoreMessages)
  // console.log(firstMessage)
  // console.log("messageBoxHeight", messageBoxHeight)

  return (
    <div className="direct-messages-chat-container">
      <div className="chat" style={{ maxHeight: `calc(100% - ${messageBoxHeight}px)` }}>
        <div className="messages" onScroll={handleMessagesScroll}>
          {chats?.length > 0 &&
            chats?.map((data, index) => (
              <>
                <div style={index == chats?.length - 1 ? { marginTop: '1rem' } : null} key={data.timestamp} className={data.userId === authCtx.userId ? "message message-mine" : "message"}>
                  <span className='message-text'>

                    {messageFunction(data)}

                    {data.message !== "" ? prepareMentionMsg(data.message) : null}

                    <div className='message-emote-time' >
                      <div className="time">{timestampToDate(data.timestamp)}</div>
                      {data.clapReactionCount ? <div className='reaction' ><img src={clap} alt="clap" /><span className='reaction-count'>{data.clapReactionCount}</span> </div> : null}
                      {data.thumbsUpReactionCount ? <div className='reaction' ><img src={thumb} alt="thumb" /><span className='reaction-count'>{data.thumbsUpReactionCount}</span> </div> : null}
                      {data.heartReactionCount ? <div className='reaction' ><img src={heart} alt="heart" /><span className='reaction-count'>{data.heartReactionCount}</span> </div> : null}
                      {data.laughReactionCount ? <div className='reaction' ><img src={laugh} alt="laugh" /><span className='reaction-count'>{data.laughReactionCount}</span> </div> : null}
                    </div>
                  </span>
                  <div className="message-emote-action">
                    <SentimentSatisfiedRounded onClick={() => handleReaction(data)} />
                    <MoreVertRounded onClick={() => showModal('ShowAction', data)} />
                  </div>
                  <div className='chat-avatar' style={{ minWidth: "35px" }} onClick={() => showModal("userProfile", data.userId)} >
                    <AvatarChatImage avatarRef={checkForAvatar(data.userId, data.avatar)} title={data.userName} />
                    <div className="name">
                      {data.userName}
                    </div>
                  </div>
                </div>
                <div className="add-reaction" style={isOpenReaction ? null : { display: "none" }} >
                  <div className="reactions">
                    <img src={clap} alt="clap" onClick={() => addReaction("clap", data)} />
                    <img src={heart} alt="heart" onClick={() => addReaction("heart", data)} />
                    <img src={laugh} alt="laugh" onClick={() => addReaction("laugh", data)} />
                    <img src={thumb} alt="thumb" onClick={() => addReaction("thumbs", data)} />
                    <CloseRounded className='close-reaction' onClick={handleReaction} />
                  </div>
                </div>
              </>
            )
            )
          }
          {
            chats.length === 0 || chats == null ? <div className="no-messages"><span className='top-span'><CommentsDisabledRounded /> No messages yet</span> <span>Start the conversation</span> </div> : null
          }
        </div>
      </div>

      <DmMessageBox messageBoxRef={messageBoxRef} membersArray={members} dmId={currentDirectMessageId} />
    </div>
  )
}

export default DmChat