
import React, { useState, useContext, useEffect, Component  } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material'
import { classes } from 'istanbul-lib-coverage';

import UserDrawer from './Drawers/UsersDrawer';
import MobileDrawer from './Drawers/MobileDrawer';
import CreateNetworkDrawer from './Drawers/CreateNetworkDrawer';

import DrawerContext from '../store/DrawerContext.js';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

	const drawerCtx = useContext(DrawerContext);
	const { pathname } = useLocation();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedTab, setSelectedTab] = React.useState(0);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	function closeDrawer() {
		drawerCtx.closeDrawer();
	}

	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	const currentDrawer = () => {
		switch(drawerCtx.drawer) {
			
			// Users
			case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UserDrawer userDetails={drawerCtx.details} /></Drawer>;
			case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>Create New User</h1></Drawer>;
			case "create-network": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNetworkDrawer /></Drawer>;
			case "mobile-navbar": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><MobileDrawer /></Drawer>;

			default:  return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
		}
	}

	// if(drawer) {
		return (
			<div>{ currentDrawer() }</div>
		);
	// } else {
	// 	return null;
	// }

	// return (
	// 	<>
	// 	<Drawer 
	// 	anchor='right'
	// 	classes={{ paper: classes.drawerContainer}}
	// 	onClose={() => setOpenDrawer(false)} 
	// 	open={openDrawer}
	// 	onOpen={() => setOpenDrawer(true)}>
				
	// 			<List>
	// 			{/* <h1>{this.props.name}</h1> */}
	// 				<Tabs value={selectedTab} onChange={handleChange}>
						
	// 					<Tab label="Details" />
	// 					<Tab label="Translation" />
	// 					<Tab label="Statistics" />

	// 				</Tabs>
	// 			</List>
	// 	</Drawer>
	// 		{/* <Button
	// 		className={classes.MoreVertIcon}
	// 		onClick={() => setOpenDrawer(!openDrawer)}
	// 		disableRipple>
	// 		{/* <MenuIcon className={classes.MoreVertIcon} />
	// 	</Button> */}
	// 	</>
		
	// );

}
export default DetailsDrawer;
