import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import AuthContext from '../store/AuthContext.js';


import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { CheckCircle } from "@mui/icons-material";
AOS.init();

// const stripePromise = loadStripe("pk_test_51L8kJIByqzP5Ykgqjb3owFykoKDKTdegzQZfrR87rcad5BbofFacQMp5C3SiIAAXqk0Krvlb80tgYMuAmygbZVQ700dmhrX5dD");

const PaymentComplete = () => {

	const authCtx 				= useContext(AuthContext);
  const [searchParams, setSearchParams]   = useSearchParams();
	const [isLoading, setIsLoading] = useState(true);
	const [setupComplete, setSetupComplete] = useState(false);
	const [currentHubUrl, setCurrentHubUrl] = useState("");

  const pi              = searchParams.get("payment_intent");

  useEffect(() => {
    getPaymentProgress();
  }, []);	

	const getPaymentProgress = () => {
		
		fetch(process.env.REACT_APP_API_URI + '/v2/stripe/checkPaymentIntent/' + pi, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }).then(response => {
      return response.json();
    }).then(data => {
			if(data.status == "success") {
        if(data.payment.status == "succeeded") {
          createMembership(data.payment.metadata.user_id, data.payment.metadata.hub_id);
        } else {
          setTimeout(() => {getPaymentProgress();}, 4000);
        }
        // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

	const createMembership = (userId, hubId) => {

    let jsonBody = JSON.stringify({ userId: userId, hubId: hubId });

		fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + hubId + '/membershipFromPayment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        setCurrentHubUrl(data.hubUrl);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

  const showCurrentStatus = () => {

    if(isLoading) {
      return <>
        <CircularProgress size={60} />
        <br/><br/>
        Checking payment status
      </>
    }  else {
      return <>
        <CheckCircle fontSize={"large"} />
        <br/><br/>
        <strong>Payment made.</strong>
        You are now a member of this network<br/><br/>
        <button><a href={currentHubUrl}>Open network</a></button><br/><br/><br/>
      </>
    }

  }

	return (
		<div className="container loading connect-setup">
      { showCurrentStatus() }   
		</div>
	);

}
export default PaymentComplete;