import React, { useContext, useState, useEffect } from 'react';

import { ref, update, remove } from "firebase/database";
import { uploadBytes, ref as sRef, uploadString } from "../Components/FirebaseConfig";
import { database } from "../Components/FirebaseConfig";
import storage from "../Components/FirebaseConfig";


import { Button, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl, CircularProgress, Snackbar, Alert, FormLabel, Slider, Checkbox } from '@mui/material';
import Avatar1 from '../assets/img/avatar/avatar1.jpg'
import Avatar2 from '../assets/img/avatar/avatar2.jpg'

import ModalContext from '../store/ModalContext.js';
import AuthContext from '../store/AuthContext.js';
import { AddRounded, CloseRounded, GppMaybeOutlined, Public, SearchRounded, Wallpaper } from '@mui/icons-material';

import '../Styles/CreateNetwork.scss'
import AvatarChatImage from '../Components/Avatar/AvatarChatImage';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../common/CustomButton.jsx';

const CreateNetworkNew = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [nameip, setNameip] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);
  const [NetworkRestriction, setNetworkRestriction] = useState('open')
  const [activeStep, setActiveStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState(2);
  const [searchQuery, setSearchQuery] = useState();
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState();
  const [hubImage, setHubImage] = useState();
  const [topic, setTopic] = useState('');
  const [hubImagePreview, setHubImagePreview] = useState();
  const [hubImageRef, setHubImageRef] = useState("");
  const [hubTitle, setHubTitle] = useState("");
  const [hubType, setHubType] = useState("public");
  const [topicId, setTopicId] = useState(1);
  const [headline, setHeadline] = useState("");
  const [parentId, setParentId] = useState();
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState("open");
  const [isPaid,setIspaid] = useState(false);
  const [paymentType, setpaymentType] = useState('recurring')
  const navigate = useNavigate();

  // useEffect
  useEffect(() => {
    if (searchQuery) {
      searchMembers();
    }
  }, [searchQuery]);

  // stopping incrementing activestep
  useEffect(()=>{
    activeStep==5 ? navigate('/network-created') : console.log(activeStep) ;
  },[activeStep])

  //functions
  const handleChange = (event) => {
    setNameip(event.target.value);
  };


  useEffect(()=>{
    console.log(nameip)
  },[nameip])

  useEffect(()=>{
    console.log('members',members)
  },[members])

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
    setOpenSnackbarWarning(false)
  }

  const handleChangePermission = (event) => {
    setPermissions(event.target.value);
  };

  const handleNetworkRestriction=(event) => {
    setNetworkRestriction(event.target.value);
  }

  useEffect(()=>{
    console.log(NetworkRestriction)
  },[NetworkRestriction])

  const handleChangeTopic = (event) => {
    setTopicId(event.target.value);
  };

  const handleTitle = (e) => {
    let newText = e.target.value
    setHubTitle(newText)
    console.log(hubTitle)
  }

  const handleHeadline = (e) => {
    let newText = e.target.value
    setHeadline(newText)
    console.log(headline)
  }

  const handleDesc = (e) => {
    let newText = e.target.value
    setDescription(newText)
    console.log(description)
  }
  

  const handleFile = (e) => {
    let img = e.target.files[0];
    console.log(img)
    setHubImage(img)
    console.log(hubImage)
    const reader = new FileReader();

    reader.onload = function (e) {
      console.log(e)
      setHubImagePreview(e.target.result)
    };
    reader.readAsDataURL(img);

  }

  const removeImg = () => {
    setHubImagePreview('')
    setHubImage('')
  }

  const closeHandle = () => {
    modalCtx.closeModal();
  }


  // pushing image to firebase
  const pushImage = (id) => {

    if (hubImage == null) return;

    setLoading(true)
    setUploading(true)
    let coverRef = `/users/${authCtx.userId}/hubImages/${hubImage.name}`;

    const storageRef = sRef(storage, coverRef)

    uploadBytes(storageRef, hubImage).then((snapshot) => {
      let imagePayload = {
        coverRef: coverRef
      }
      setHubImageRef(coverRef)

      fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + id + '/uploadImage', {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(imagePayload)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data?.status === "success") {
          setUploading(false)
        } else {
        }
      });
    }, (error) => console.log(error));

  }

  const createNetwork = () => {

    if (!hubTitle) {
      setOpenSnackbarWarning(true)
      return;
    }

    const payload = {
      "userId": authCtx.userId,
      "parentId": 0,
      "topicId": topicId,
      "cover": hubImageRef,
      "hubType": hubType,
      "title": hubTitle,
      "headline": headline,
      "description": description,
      "permissions": permissions,
      "paid": "no",
      "paymentFee": 0.00,
      "paymentType": "none",
      "paymentRecurring": "none"
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data?.status === "success") {
        pushImage(data.id)
        console.log("new network ceated!!")
        setOpenSnackbar(true)
      } else {
      }
    });

  }

  const searchMembers = () => {
    if (!searchQuery) {
      setMembers([]);
    }
    setMembersLoading(true);

    fetch(
      process.env.REACT_APP_API_URI +
      `/v2/user/${authCtx.userName}/hub/${modalCtx.details.selectedNetwork}/search/hubInvite/${searchQuery}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          console.log(data);
          const first100Members = data?.data.slice(0, 100);
          setMembers(first100Members);
          setMembersLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const marks = [
    {
      value: 30,
      label: '30',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  // select payment recurring or one time

  function handlePaymentTypeChange(e){
   setpaymentType(e.target.value);
  }

  const getTabData = (tab) => {
    switch (tab) {

      case 1:
        return <>
          <div className='invite-member-tab'>
            <div className="invite-member-container">
              <div className="invite-members">
                <div className="search-member">
                  <input
                    type="text"
                    placeholder="Search frequency members"
                    onChange={({ target }) => setSearchQuery(target.value)}
                  />
                  <SearchRounded className="search-icon" />
                </div>
              </div>

              {members?.length == 0 ? null : <p className="followers-heading">Followers</p>}
              <div className="frequency-members" style={members.length == 0 ? { display: 'none' } : null}>
                {
                  !membersLoading
                    ? members?.map((member,index) => (
                      <div className="member" key={member.id}>
                        <div className="member-profile">
                          <div className="member-img">
                            <AvatarChatImage
                              avatarRef={member.avatar}
                              title={member.name}
                            />
                          </div>
                          <div className="member-deets">
                            <span className="user">{member.name}</span>
                            <span className="username">@{member.username}</span>
                          </div>
                        </div>
                        {/* <Checkbox
											sx={{
												'&.Mui-checked': {
													color: '#CD4A4A',
												},
											}}
										/> */}
                        <Checkbox
                          // icon={<CircleUnchecked />}
                          // checkedIcon={<CircleCheckedFilled />}
                          sx={{
                            '&.Mui-checked': {
                              color: '#CD4A4A',
                            },
                          }}
                        />
                      </div>
                    ))
                    : null
                }
                {/* {members.length > 3 ? (
								<div className="more-btn">
									<KeyboardArrowDown />
								</div>
							) : null} */}
                {membersLoading ? (
                  <div className="loading">
                    <CircularProgress style={{ color: '#007C89' }} size={60} />
                  </div>
                ) : null}
              </div>
            </div>
            {selectedTab === 1 ? (
              <div className="invite-to-team-button">
                <button className="btn">Invite</button>
              </div>
            ) : null}
          </div>
        </>
      case 2:
        return <>
          <div className='invite-member-tab'>
            <div className="invite-member-container">
              <div className="invite-members">
                <div className="search-member">
                  <input
                    type="text"
                    placeholder="Search frequency networks"
                    onChange={({ target }) => setSearchQuery(target.value)}
                  />
                  <SearchRounded className="search-icon" />
                </div>
              </div>

              {members?.length == 0 ? null : <p className="followers-heading">Followers</p>}
              <div className="frequency-members" style={members.length == 0 ? { display: 'none' } : null}>
                {
                  !membersLoading
                    ? members?.map((member) => (
                      <div className="member" key={member.id}>
                        <div className="member-profile">
                          <div className="member-img">
                            <AvatarChatImage
                              avatarRef={member.avatar}
                              title={member.name}
                            />
                          </div>
                          <div className="member-deets">
                            <span className="user">{member.name}</span>
                            <span className="username">@{member.username}</span>
                          </div>
                        </div>
                        {/* <Checkbox
											sx={{
												'&.Mui-checked': {
													color: '#CD4A4A',
												},
											}}
										/> */}
                        <Checkbox
                          // icon={<CircleUnchecked />}
                          // checkedIcon={<CircleCheckedFilled />}
                          sx={{
                            '&.Mui-checked': {
                              color: '#CD4A4A',
                            },
                          }}
                        />
                      </div>
                    ))
                    : null
                }
                {membersLoading ? (
                  <div className="loading">
                    <CircularProgress style={{ color: '#007C89' }} size={60} />
                  </div>
                ) : null}
              </div>
            </div>
            {selectedTab === 1 ? (
              <div className="invite-to-team-button">
                <button className="btn">Invite</button>
              </div>
            ) : null}
          </div>
        </>

      default:
        break;
    }
  }

  return (
    <>
      <div className="create-network-page-container">
        <div className="create-network">
          <div className="header-steps-container">
            <h2>Create Network</h2>
            <div className="steps">
              <div className={activeStep >= 1 ? "step completed active" : "step"}>Network Details</div>
              <div className={activeStep >= 2 ? "step completed active" : "step"}>Moderation</div>
              <div className={activeStep >= 3 ? "step completed active" : "step"}>Invite Members</div>
              <div className={activeStep >= 4 ? "step completed active" : "step"}>Paid</div>
            </div>
          </div>

          <div className="create-network-all-steps-content-container">
            <div className="network-details-container" style={activeStep == 1 ? null : { display: 'none' }}>

              <div className="network-image-container">
                <div className="image-container">
                  <Wallpaper sx={{ width: '50px', height: '50px' }} color={'#CACACA'} />
                </div>
              </div>

              <div className="network-name-status-container">
                <input type="text" className='network-name-input' placeholder='Name' onChange={(e)=>{handleChange(e)}} />
                <div className="network-status">
                  <div className={hubType === "public" ? "status-public active" : "status-public"} onClick={() => setHubType("public")}><Public /> Public</div>
                  <div className={hubType === "private" ? "status-private active" : "status-private"} onClick={() => setHubType("private")}><GppMaybeOutlined /> Private</div>
                </div>
              </div>

              <div className="select-topic-container">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                  <InputLabel id="select-topic-label">Topic</InputLabel>
                  <Select
                    labelId="select-topic-label"
                    id="select-topic"
                    value={topicId}
                    onChange={handleChangeTopic}
                    label="Topic"
                  >
                    <MenuItem value={1}>Arts & Entertainment</MenuItem>
                    <MenuItem value={2}>health & Wellness</MenuItem>
                    <MenuItem value={3}>Live Sport</MenuItem>
                    <MenuItem value={4}>US News</MenuItem>
                    <MenuItem value={5}>Science</MenuItem>
                    <MenuItem value={6}>Sports</MenuItem>
                    <MenuItem value={7}>Support Groups</MenuItem>
                    <MenuItem value={8}>Education</MenuItem>
                    <MenuItem value={9}>Other</MenuItem>
                    <MenuItem value={10}>Politics</MenuItem>
                    <MenuItem value={11}>Social Justice</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="headline-description-container">
                <input type="text" className='network-headline-input' placeholder='Headline' onChange={handleHeadline} />
                <input type="text" className='network-description-input' placeholder='Description' onChange={handleDesc} />
              </div>

              <div className="network-type-container">
                <FormControl >
                  <label style={{ fontWeight: "600" }} id="network-type-group-label">How may users join this network ?</label>
                  <RadioGroup
                    aria-labelledby="network-type-group-label"
                    defaultValue="open"
                    name="radio-buttons-group"
                    onChange={handleNetworkRestriction}
                  >
                    <FormControlLabel value="open" control={<Radio style={{ color: "#CD4A4A" }} />} label="Open network, anyone may join without restriction." />
                    <FormControlLabel value="invite" control={<Radio style={{ color: "#CD4A4A" }} />} label="By request only but any member may invite." />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>

            <div className="network-moderation-container" style={activeStep == 2 ? null : { display: 'none' }} >
            <div className="moderation-flagged">
              <div className="title">
                At what point does it get flagged ?
              </div>
              <div className="range-slider">
                <Slider
                  style={{ color: "#CD4A4A" }}
                  aria-label="Always visible"
                  defaultValue={80}
                  getAriaValueText={valuetext}
                  step={10}
                  min={30}
                  max={100}
                  marks={marks}
                  valueLabelDisplay="on"
                />
              </div>
              <div className="comment-container">
                <div className="profile">
                  <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                  <div className="profile-name">Philip</div>
                </div>
                <div className="message-text">Lorem ipsum dolor sit amet, b****h</div>
              </div>
            </div>
            <div className="moderation-removed">
              <div className="title">
                At what point does the message get's removed ?
              </div>
              <div className="range-slider">
                <Slider
                  style={{ color: "#CD4A4A" }}
                  aria-label="Always visible"
                  defaultValue={100}
                  getAriaValueText={valuetext}
                  step={10}
                  min={30}
                  max={100}
                  marks={marks}
                  valueLabelDisplay="on"
                />
              </div>
              <div className="comment-container">
                <div className="profile">
                  <div className="profile-image"><img src={Avatar1} alt="avatar" /></div>
                  <div className="profile-name">Philip</div>
                </div>
                <div className="message-text">Lorem ipsum dolor sit amet, F**k</div>
              </div>
            </div>
            </div>


            <div className="network-invite-members-container" style={activeStep == 3 ? null : { display: 'none' }} >
            <div >
              <div className='invite-member-tabs' >
                <div className={selectedTab == 1 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(1)}>Profiles</div>
                <div className={selectedTab == 2 ? 'tab-item active' : 'tab-item'} onClick={() => setSelectedTab(2)}>Networks</div>
              </div>

              {getTabData(selectedTab)}

            </div>
            </div>

            <div className="network-paid-container" style={activeStep == 4 ? null : { display: 'none' }} >
            <div className="paid-network-toggle-container">
              <h1 className="paid-network-heading">
                Is this a paid network ?
              </h1>
              {/* <Switch on={true} off={false} value={isPaid} onChange={()=>{
                isPaid ? setIspaid(false) : setIspaid(true);
              }} 
                styles={{
                  track: {
                    width: '60px',
                    height: '28px',
                    borderRadius:"100px"
                  },
                  trackChecked: {
                    width: '60px',
                    height: '28px',
                    backgroundColor: '#1ab53f', 
                  },
                  button: {
                    width: '24px',
                    height: '24px',
                    borderRadius:"100px",
                  },
                  buttonChecked: {
                    width: '24px',
                    height: '24px',
                    left:"33px"
                  },
                }}
              /> */}
            </div>
            <div className="paid-network-content-container">
              {
                isPaid? <>
                  <input type="number" name="" placeholder='Fee' id="" />
                  <div className="payment-type-container">
                    <div className="recurring-one-time-option-container">
                      <RadioGroup  className='payment-type-radiogroup-container' defaultValue={'recurring'} value={paymentType} onChange={handlePaymentTypeChange}>
                        <FormControlLabel value="recurring" control={<Radio style={{ color: "#CD4A4A" }} />} label="Recurring" />
                        <FormControlLabel value="oneTime" control={<Radio style={{ color: "#CD4A4A" }} />} label="One time" />
                      </RadioGroup>
                    </div>
                    {
                      paymentType === "recurring"  ? 
                      <div className="recurring-payment-container">
                      <RadioGroup className="recurring-payment-type-radiogroup-container">
                        <div className='recurring-payment-type-flexbox'>
                            <h1 className="recurring-payment-type-heading">
                              Weekly
                            </h1>
                            <FormControlLabel value="weekly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />}/>
                        </div>
                        <div className='recurring-payment-type-flexbox'>
                            <h1 className="recurring-payment-type-heading">
                              Fortnightly
                            </h1>
                            <FormControlLabel value="Fortnightly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />}/>
                        </div>
                        <div className='recurring-payment-type-flexbox'>
                            <h1 className="recurring-payment-type-heading">
                              Yearly
                            </h1>
                            <FormControlLabel value="Yearly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />}/>
                        </div>
                        <div className='recurring-payment-type-flexbox'>
                            <h1 className="recurring-payment-type-heading">
                            Monthly
                            </h1>
                            <FormControlLabel value="Monthly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />}/>
                        </div>
                        <div className="features-container">
                          <h3 className="features-heading">Features</h3>
                          <div className="add-feature-button-container">
                            <CustomButton> <span>+</span> Add Feature</CustomButton>
                          </div>
                        </div>
                      </RadioGroup>
                      </div>
                      :
                      <div className="one-time-payment-container">
                        <div className="features-container">
                          <h3 className="features-heading">Features</h3>
                          <div className="add-feature-button-container">
                            <CustomButton> <span>+</span> Add Feature</CustomButton>
                          </div>
                        </div>
                      </div>
                    }
                    
                  </div>
                </> : <></>
              }
            </div>
            </div>

          </div>
          
          <div className="navigate-btn-container">
            {activeStep > 1 ? <Button className="prev-btn" onClick={() => setActiveStep(prev => prev - 1)} >Prev</Button> : null}
            <Button className="next-btn" onClick={() => setActiveStep(prev => prev + 1)} disabled={ nameip=="" || headline=="" || description=="" ? true : false} >Next</Button>
          </div>
          
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Network was created.
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackbarWarning} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
            Please enter a title for the Network.
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default CreateNetworkNew;
