import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth"

const app = initializeApp({
  apiKey: "AIzaSyCzWiJRsOZz3jM5E4USs1OZxGxonM68w0k",
  authDomain: "frequency-world.firebaseapp.com",
  databaseURL: "https://frequency-world-default-rtdb.firebaseio.com",
  projectId: "frequency-world",
  storageBucket: "frequency-world.appspot.com",
  messagingSenderId: "603210809948",
  appId: "1:603210809948:web:87e34c0ac0658116391c99",
  measurementId: "G-97BQE3BYGE"
});

export const auth = getAuth();
export default app
