import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../../../Components/FirebaseConfig";
import AuthContext from '../../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';

import Placeholder from '../../../assets/img/networks/placeholder.png'
import ModalContext from '../../../store/ModalContext.js';

const noMenuRoutes = ["/login"];

const GetImageFirebase = ({ imageRef, ratio, className, height, width }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const { pathname } = useLocation();
  const [avatarImage, setAvatarImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (imageRef !== "" && imageRef !== null && imageRef !== undefined) {
      getImg(imageRef);
    } else {
      setLoading(false)
    }
  }, [imageRef]);

  const getImg = (coverRef) => {

    setLoading(true)

    const isPresent = coverRef.includes('https://');

    isPresent
      ? setAvatarImage(coverRef)
      : getDownloadURL(ref(storage, coverRef))
        .then((url) => {
          setAvatarImage(url);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        });
  }

  const showImg = (modal, image) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, image)
  }




  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  // if (loading) return <CircularProgress size={20} style={{ color: '#eb4d4b' }} />

  return (
    <>
      {avatarImage ? <img style={height !== null && width !== null ? { height: `${height}px`, width: `${width}px`} : null} src={avatarImage} alt="post" className={className} onClick={() => showImg('ViewImage', avatarImage)} /> : <img style={height !== null && width !== null ? { height: `${height}px`, width: `${width}px`} : null} src={Placeholder} alt="post" className={className} />}
    </>
  );
}
export default GetImageFirebase;