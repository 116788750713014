import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import {useContext, useEffect, useState} from "react";
import Arts from '../assets/img/network-topics/Arts & Entertainment.svg'
import Health from '../assets/img/network-topics/Health & Wellness.svg'
import LiveSports from '../assets/img/network-topics/Live Sports.svg'
import Sports from '../assets/img/network-topics/Sports.svg'
import Science from '../assets/img/network-topics/Science.svg'
import USNews from '../assets/img/network-topics/US News.svg'
import ModalContext from '../store/ModalContext';
import CustomButton from '../common/CustomButton';

const ProfileEdit = () => {

    const modalCtx = useContext(ModalContext);

    const PAGES = 2;
    const [page, setPage] = useState(1);
    const [imageURL, setImageURL] = useState('')


    useEffect(()=>{
        console.log(modalCtx.details)
        setImageURL(modalCtx?.details?.data?.imageURL)
    })

    const uploadImageModal = () => {
        modalCtx.setDetails("AddNewImage", {"from":'profile-edit'})
        modalCtx.openModal()
        console.log(modalCtx.setDetails)
    }

    return (
        <div className='profile-wrapper'>
            <div className='card-container'>
                {
                    page === 1 &&
                    <div className='card-body'>
                        <div className='gradient-header'> 
                            <h1> Profile details </h1>
                            <div className='change-avatar-container' onClick={ () => {uploadImageModal()}}>
                                {
                                    imageURL ? 
                                        <div>
                                            <img className='image' src={imageURL} />
                                        </div> : 
                                        <AddAPhotoIcon className='change-avatar-icon' />
                                }
                            </div>
                        </div>
                        <div className='card-info'>
                            <TextField id="standard-basic" label="Display name" variant="standard" className='name-input'/>
                        </div>
                    </div>
                }
                {
                    page === 2 && 
                    <div className='card-body preferred-network'>
                      <div className="heading">
                        <h1> Choose Preferred Network Topics </h1>
                      </div>
                      <div className='list'>
                        <div className="row">
                          <img src={Arts} alt="" /> 
                            <h2>Arts & Entertainment</h2>
                              <input type="checkbox" name="" id="" />
                        </div>
                            <div className="row">
                                <img src={Health} alt="" /> 
                                <h2>Health & Wellness</h2>
                                <input type="checkbox" name="" id="" />
                            </div>
                            <div className="row">
                                <img src={LiveSports} alt="" /> 
                                <h2>Live Sports</h2>
                                <input type="checkbox" name="" id="" />
                            </div>
                            <div className="row">
                                <img src={USNews} alt="" /> 
                                <h2>US News</h2>
                                <input type="checkbox" name="" id="" />
                            </div>
                            <div className="row">
                                <img src={Science} alt="" /> 
                                <h2>Science</h2>
                                <input type="checkbox" name="" id="" />
                            </div>
                            <div className="row">
                                <img src={Sports} alt="" /> 
                                <h2>Sports</h2>
                                <input type="checkbox" name="" id="" />
                            </div>
                        </div>
                    </div>
                }
                <div className="actions">
                    <CustomButton className='f-btn-full-width' style={{width: '80%', marginTop: '1.25rem'}} variant="contained">Save</CustomButton>
                    <MobileStepper
                        variant="dots"
                        steps={PAGES}
                        className="selector-dots"
                        activeStep={page-1}
                        nextButton={
                            <>{page < PAGES ? <CustomButton size="small" className='f-btn'
                            onClick={() => setPage(prev => prev < PAGES ? prev + 1 : prev)}>
                            Next
                            </CustomButton> : <div className="filler" />}</>
                        }
                        backButton={
                            <>{page > 1 ? <CustomButton size="small"
                            className='f-btn-bordered'
                            onClick={() => setPage(prev => prev > 0 ? prev - 1 : prev)}>
                            Prev
                            </CustomButton> : <div className="filler" />}</>
                        }
                        />
                </div>
            </div>
        </div>
    )
}

export default ProfileEdit;