
//context
import AuthContext from "../../../store/AuthContext";
import ModalContext from "../../../store/ModalContext";

//components
import LastMessage from "../../../Components/Chat/LastMessage";
import GetImageFirebase from "../../../Components/Networks/GetImageFirebase";
import CustomButton from "../../../common/CustomButton";

//libs

//assets

//images
import NetworksLite from '../../../assets/img/networks/networks-lite-gray.svg'
import Placeholder from '../../../assets/img/networks/placeholder.png'
import SidebarToggle from '../../../assets/img/networks/sidebar-toggle.svg'
import cross from '../../../assets/img/networks/wrong.svg'

//css
import { AddRounded, Close, TuneRounded } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";

const Sidebar = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const [ownedNetwork, setOwnedNetwork] = useState([])
  const [deletedNetwork, setDeletedNetwork] = useState([])
  const [activeMember, setActiveMember] = useState([])
  const [deletedMember, setDeletedMember] = useState([])
  const [invited, setInvited] = useState([])
  const [requested, setRequested] = useState([])

  const { userNetworks, setSidebarOpen, sidebarOpen, activeParentNetworkId, isSubNetwork, changeNetwork, activeSubNetworkId, setActiveTab } = props;

  const modalCtx = useContext(ModalContext)


  const handleModal = (modal, data) => {
    modalCtx.openModal()
    modalCtx.setDetails(modal, data)
  }

  console.log('userNetworks', userNetworks)

  const getSubNetworks = (network, hubId) => {
    if (network?.parentHubId === hubId && network.role !== "non-member" || network.role !== "invited") {
      return (
        <div key={network.id} className="sub-network-slot" onClick={() => changeNetwork(network.id, "sub-network", network.parentHubId)} >
          {
            activeSubNetworkId == network.id && isSubNetwork
              ? <div className="active-network-border"></div>
              : null
          }
          <div className="network-image" style={sidebarOpen ? null : { margin: '0 auto' }}>
            {
              network.coverRef != ''
                ? <GetImageFirebase className="network-img" imageRef={network.coverRef} />
                : <img src={Placeholder} alt="placeholder" />
            }
          </div>
          <div className="network-details" style={sidebarOpen ? null : { display: 'none' }}>
            <div className="text-details">
              <div className="network-title">{network.title}</div>
              <LastMessage selectedNetwork={network.id} />
            </div>
          </div>
        </div>
      )
    }
  }

  const getSubNetworksThumbnails = (network, hubId) => {
    if (network?.parentHubId === hubId && network.role == "non-member" || network.role == "invited") {
      return (
        <div key={network.id} className="sub-network-thumbnail" >
          <div className="network-image" >
            {
              network.coverRef != ''
                ? <GetImageFirebase className="network-img" imageRef={network.coverRef} />
                : <img src={Placeholder} alt="placeholder" />
            }
          </div>
        </div>
      )
    }
  }

  const handleSidebarToggle = () => {
    if (sidebarOpen) {
      setSidebarOpen(false)
    } else {
      setSidebarOpen(true)
    }
  }

  const toggleFilterBox = () => {
    setShowFilters(showFilters => !showFilters);
  }

  const checkFilterData = (userNetworks) => {
    if (userNetworks === null || undefined) return
    console.log(userNetworks)
    for (let i = 0; i < userNetworks.length; i++) {
      if (userNetworks[i].role === 'owner' && userNetworks[i].status === 'active') {
        setOwnedNetwork(prev => [...prev, userNetworks[i]])
      } else if (userNetworks[i].role === 'owner' && userNetworks[i].status === 'deleted') {
        setDeletedNetwork(prev => [...prev, userNetworks[i]])
      } else if (userNetworks[i].role === 'member' && userNetworks[i].status === 'active') {
        setActiveMember(prev => [...prev, userNetworks[i]])
      } else if (userNetworks[i].role === 'member' && userNetworks[i].status === 'deleted') {
        setDeletedMember(prev => [...prev, userNetworks[i]])
      } else if (userNetworks[i].role === 'invited') {
        setInvited(prev => [...prev, userNetworks[i]])
      } else if (userNetworks[i].role === 'requested') {
        setRequested(prev => [...prev, userNetworks[i]])
      }
    }
  }


  return (
    <>
      <aside className="network-sidebar-container" style={sidebarOpen ? null : { width: '121px' }}>
        <div className="sidebar-toggle-btn">
          <img onClick={() => handleSidebarToggle()} src={SidebarToggle} alt="sidebar toggle" style={sidebarOpen ? null : { transform: 'rotate(-180deg)' }} />
        </div>
        <div className="sidebar-actions">
          {
            sidebarOpen
              ? <>
                <TuneRounded className="sidebar-filters"  onClick={toggleFilterBox} />
                {showFilters && <div className="chat-filter-setting">
                  <div className="filter-header">
                    <p>Show Networks Where: </p>
                    <Close className="close" onClick={toggleFilterBox} />
                  </div>
                  <div className="single-filter">
                    <div className="filter-name">
                      <p>I'm the owner</p>
                      <input type="checkbox" />
                    </div>
                  </div>
                  <div className="single-filter">
                    <div className="filter-name">
                      <p>I'm a member of</p>
                      <input type="checkbox" />
                    </div>
                  </div>
                  <div className="single-filter">
                    <div className="filter-name">
                      <p>which I've requested</p>
                      <input type="checkbox" />
                    </div>
                  </div>
                  <div className="single-filter">
                    <div className="filter-name">
                      <p>Invited to Join</p>
                      <input type="checkbox" />
                    </div>
                  </div>
                  <div className="single-filter">
                    <div className="filter-name">
                      <p>Which I've Deleted</p>
                      <input type="checkbox" />
                    </div>
                  </div>
                  <div className="filter-footer">
                    <CustomButton className='f-btn-full-width' onClick={checkFilterData}>Apply Filters</CustomButton>
                  </div>
                </div>
                }
                <div className="create-network-btn" onClick={() => handleModal('create-network', { selectedNetwork: activeSubNetworkId })} >
                  CREATE NETWORK
                  <img src={NetworksLite} alt="networks" />
                </div>
              </>
              : <div className="create-network-btn-compact" onClick={() => handleModal('create-network', { selectedNetwork: activeSubNetworkId })} >
                <AddRounded style={{ color: '#1BA0A2' }} />
              </div>
          }

        </div>
        <div className="sidebar-networks">
          {
            userNetworks?.length > 0
              ? userNetworks?.map((network, index) => (
                <div key={network.id} className={`network-slot-container ${activeParentNetworkId == network.id ? "active-network" : ""}`}>
                  <div className="network-slot" onClick={() => changeNetwork(network.id, "parent", network.parentHubId)}>
                    {
                      activeParentNetworkId == network.id && !isSubNetwork
                        ? <div className="active-network-border"></div>
                        : null
                    }
                    <div className="network-image" style={sidebarOpen ? null : { margin: '0 auto' }}>
                      {
                        network.coverRef != ''
                          ? <GetImageFirebase className="network-img" imageRef={network.coverRef} />
                          : <img src={Placeholder} alt="placeholder" />
                      }
                    </div>
                    <div className="network-details" style={sidebarOpen ? null : { display: 'none' }}>
                      <div className="text-details">
                        <div className="network-title">{network.title}</div>
                        <LastMessage selectedNetwork={network.id} />
                      </div>
                      <div className="network-icons"></div>
                    </div>
                  </div>
                  <div className="sub-network-container" style={activeParentNetworkId == network.id ? null : { display: 'none' }}>
                    {
                      network?.subNetworks && network?.subNetworks?.length >= 1
                        ? <div className="sub-network-slots-container">
                          {
                            network?.subNetworks?.map((sub, index) => getSubNetworks(sub, network.id))
                          }
                        </div>
                        : null
                    }
                    {
                      network?.subNetworks && network?.subNetworks?.length > 2 && sidebarOpen
                        ? <div className="sub-network-show-all-container">
                          <div className="sub-network-show-all-slots">
                            {
                              network?.subNetworks?.slice(0, 4)?.map((sub, index) => getSubNetworksThumbnails(sub, network.id))
                            }
                          </div>
                          {network?.subNetworks?.length > 4 && <div className="sub-network-show-all-btn" onClick={() => setActiveTab("sub-networks")}>View All</div>}
                        </div>
                        : null
                    }
                  </div>
                </div>
              ))
              : null
          }
        </div>
      </aside>
    </>
  )
}

export default Sidebar