import React, { useContext, useRef, useState } from 'react'

//context
// import ModalContext from '../../store/ModalContext.js';
// import AuthContext from '../../store/AuthContext.js';

//carousel
import { Carousel } from 'react-responsive-carousel'

//dropzone
import Dropzone from '../ReactDrop/Dropzone';

//assets
import { Close, InfoRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import PhotoVideoIconLarge from '../../assets/img/wave/create-post/photo-video-icon-large.svg'

//styles
import '../../Styles/Modal.scss'
import ImageCropper from './ImageCropper';
import CustomButton from '../../common/CustomButton';

const ImportMedia = ({ setFiles, setPreviewArray, files, previewArray, removeFile, setSnackbarMessage, setOpenSnackBar }) => {
  
  //refs
  const FileRef = useRef(null)

  //context
  // const modalCtx = useContext(ModalContext);
  // const authCtx = useContext(AuthContext);

  //handle file input
  const handleFileInput = () => {
    FileRef.current.click()
  }



  // get dropped files
  const onDrop = (files) => {
    let imageFileTooBig = false
    let videoFileTooBig = false

    for(let i = 0; i < files.length; i++){
      if(calculateFileSize(files[i]) > 3 && files[i].type.includes('image')){
        imageFileTooBig = true
        break;
      } else if(calculateFileSize(files[i]) > 30 && files[i].type.includes('video')){
        videoFileTooBig = true
        break;
      }
    }

    if(videoFileTooBig){
      setSnackbarMessage('Video size should below 30 MB')
      setOpenSnackBar(true)
    } else if(imageFileTooBig){
      setSnackbarMessage('Image size should below 3 MB')
      setOpenSnackBar(true)
    } else {
      setFiles(files)
      returnFilePreviewObject(files)
    }
  }; 

  const handleFile = (e) => {
    let files = convertFileListToArray(e.target.files)
    console.log('file import ', files)

    let imageFileTooBig = false
    let videoFileTooBig = false
    
    for(let i = 0; i < files.length; i++){
      if(calculateFileSize(files[i]) >= 5 && files[i].type.includes('image')){
        imageFileTooBig = true
        break;
      } else if(calculateFileSize(files[i]) > 30 && files[i].type.includes('video')){
        videoFileTooBig = true
        break;
      }
    }

    if(videoFileTooBig){
      setSnackbarMessage('Video size should be below 30 MB')
      setOpenSnackBar(true)
      FileRef.current.value = null;
    } else if(imageFileTooBig){
      setSnackbarMessage('Image size should be below 5 MB')
      setOpenSnackBar(true)
      FileRef.current.value = null;
    } else {
      setFiles(files)
      returnFilePreviewObject(files)
    }
  }

  //convert from FileList to array
  const convertFileListToArray = (list) => {
    let arr = []
    for(let i = 0; i < list.length; i++){
      arr.push(list[i])
    }
    return arr
  }

  //calculates files size
  const calculateFileSize = (file) => {
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    return fileMb
  }

  const returnFilePreviewObject = (files) => {
    for (let i=0; i<files.length ; i++){
      var reader = new FileReader();
      reader.onload = (file) => {
        setPreviewArray(prev => [...prev, {index: i, name: files[i].name, type: files[i].type, file: file.target.result}])
      }
      reader.readAsDataURL(files[i])
    }
  }

  const filePreviewHandler = (array) => {
    return array?.map((item, i) => checkFileType(item, i)) 
  }

  const checkFileType = (item, i) => {
    let file 
    if(item.type.includes('image')){
      return file = <>
                      <Close className='import-close' onClick={() => removeFile(item.name)} />
                      {/* <ImageCropper image={item.file} aspectRatio1={9} aspectRatio2={16} /> */}
                      <img className='file-preview-image' src={item.file} alt='post'/>
                    </>
    } else if(item.type.includes('video')){
      return file = <>
                      <Close className='import-close' onClick={() => removeFile(item.name)} />
                      <video width='100%' height='100%' controls className='file-preview-video' src={item.file}></video>
                    </> 
    }
    return file
  }

  return (
    <>
      { 
        previewArray.length != 0 
        ? <div className="carousel-container">
            <Carousel infiniteLoop={true} showThumbs={false} showStatus={previewArray.length > 1 ? true : false} showIndicators={previewArray.length > 1 ? true : false} >
              {filePreviewHandler(previewArray)}
            </Carousel>
          </div>
        : <>
          <Dropzone onDrop={onDrop} accept={"image/*, video/*"} />
          <img src={PhotoVideoIconLarge} alt="import" />
          <h3>Drag photos and videos here</h3>
          <CustomButton className='f-btn' onClick={() => handleFileInput()} >Select from computer</CustomButton>
          <Tooltip
            title={
              <>
                <ol className='tooltip-for-posts' >
                  <li>Image file should be under <strong>3</strong> MB </li>
                  <li>Video file should be under <strong>30</strong> MB </li>
                </ol>
              </>
            }
          >
          <InfoRounded className='info-btn' />
          </Tooltip>
          <input ref={FileRef} onChange={(e) => handleFile(e)} type="file" className='file-input' />
          </>
      }
    </>
  )
}

export default ImportMedia
