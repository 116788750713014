import { useContext, useState, useCallback, createRef, useEffect } from "react";

//context
import AuthContext from "../../../store/AuthContext";
import ModalContext from "../../../store/ModalContext";

//firebase
import { uploadBytes, ref as sRef } from '../../FirebaseConfig'
import storage from "../../FirebaseConfig";

//react crop
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Base64 } from "js-base64";

//mui
import { CircularProgress } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

//dropzone
import Dropzone from './Dropzone';

import { getDownloadURL } from "firebase/storage";
import MessageContext from "../../../store/MessageContext";
import CustomButton from "../../../common/CustomButton";
// import { upload } from '@testing-library/user-event/dist/upload';

const UploadImageModal = (props) => {
  const calledFrom = props?.['details']?.['from']
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  const cropperRef = createRef();

  const [backupImage, setBackupImage] = useState(null);
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageURL, setImageURL] = useState("");

  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setBackupImage(e.target.result);
      }
      reader.readAsDataURL(file);
      return file;
    })
  }, []);

  const getCropData = () => {
    if (cropperRef.current && typeof cropperRef.current.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setFinalImage(baseToFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL()))
    }
  };

  const resetCropImage = () => {
    setImage(backupImage)
    setCropData(null)
    setFinalImage(undefined)
  }

  // this return a file object from a base file
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]

      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }

      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, { type: mime })
      return file
    } else {
      return null;
    }
  }

  // close
  const onClose = () => {
    if (calledFrom) {
      if (calledFrom === "create-network") {
        let data = modalCtx.details;
        data.imageURL = imageURL;
        modalCtx.setDetails(calledFrom, { "data": data });
      }
      else if (calledFrom === "editNetwork") {
        let data = modalCtx.details
        data.coverRef = imageURL;
        modalCtx.setDetails(calledFrom, data);
      }
      else {
        modalCtx.setDetails(calledFrom, { "data": { 'imageURL': imageURL } });
      }
      modalCtx.openModal();
    } else {
      modalCtx.closeModal()
    }

  }

  console.log(modalCtx.details)

  useEffect(() => {
    if (imageURL) {
      onClose()
    }
  }, [imageURL])

  // pushing image to firebase
  const pushImage = () => {
    if (finalImage == null) return;
    setIsUploading(true);
    let imgPath = ''
    let timeStamp = Date.now();
    let coverRef = `/users/${authCtx.userId}/hubImages/${finalImage.name}`
    const storageRef = sRef(storage, coverRef)

    imgPath = storageRef.fullPath

    uploadBytes(storageRef, finalImage).then((snapshot) => {

      setImageURL(coverRef)
      // getDownloadURL(storageRef).then((value) => {
      //   setImageURL(value)
      //   msgCtx.setDetails("imageURL", { "url": value })
      // })
      setIsUploading(false)
    })
  }

  return (
    <div className="upload-image-modal-container">
      <div className="title">
        <h2>Upload Image</h2>
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className="crop">
        {
          image ? finalImage == undefined ?
            <Cropper
              ref={cropperRef}
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.3}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              guides={true}
              // ? if no aspect ration is provided, the cropper will be in free crop mode
              aspectRatio={modalCtx.details.aspectRatio ? modalCtx.details.aspectRatio : undefined}
            /> : <img src={cropData} style={{ height: "100%", width: "100%", objectFit: 'contain' }} alt="preview" />
            : <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      <div className="crop-btns">
        {
          // ? the crop button will only show once the image is present in the setImage state
          !finalImage && image
            ? <CustomButton className='f-btn' onClick={() => getCropData()} disabled={!image}>Crop</CustomButton>
            : null
        }

        {
          // ? this reset button will reset the image and cropper 
          finalImage
            ? <CustomButton className='f-btn-bordered' onClick={() => resetCropImage()} disabled={!image}>Reset</CustomButton>
            : null
        }
        {
          finalImage
            ? <CustomButton className='f-btn' onClick={() => pushImage()} disabled={!image}>
              {
                isUploading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : 'Upload'
              }
            </CustomButton>
            : null
        }

        <div className="tips">
          <span>! Use mouse scroll wheel to zoom in and out of the image</span>
        </div>
      </div>
    </div>
  )
}

export default UploadImageModal;