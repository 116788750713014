import React from 'react'
import { useDropzone } from 'react-dropzone'
import CustomButton from '../../../common/CustomButton';

function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ accept, onDrop });

  const files = acceptedFiles.map((file) => (<li key={file.path}>{file.path - file.size} bytes</li>));

  return (
    <div className='d-n-d'>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }} {...getRootProps({ className: "dropzone" })}>
        <input className='input-zone' {...getInputProps()} />
        <div className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          {isDragActive ? (
            <p className='dropzone-content'>
              Release to drop the files here
            </p>
          ) : (<p className='dropzone-centent'>Drag’ n’ drop Image here, or click to select Image</p>)}
          <CustomButton type='button' onClick={open} className='f-btn'>Click to select Image</CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Dropzone;