import { Button } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import CustomButton from "../../common/CustomButton";

function Dropzone({ maxFiles, onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
      maxFiles
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="d-n-d" >
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{display: 'flex', flexDirection: 'column'}}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <p className="dropzone-content">
              Drag’ n’ drop Image here, or click to select Image
            </p>
          )}
          <CustomButton type="button" onClick={open} className="f-btn">Click to select Image </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Dropzone;