// react
import React, { useContext, useEffect, useState } from "react";

//mui
import { Button, TextField, CircularProgress } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// Assets
import profileImage from '../../src/assets/img/profile.png'
import aboutIcon from "../assets/img/about-icon.png";
import aboutIconActive from "../assets/img/about-icon-active.png";
import editButton from "../assets/img/edit-btn.svg";
import netWorkImage from "../assets/img/networkImage.png";

import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database } from "../Components/FirebaseConfig";
import Posts from "../Components/Wave/Posts";
import ModalContext from "../store/ModalContext";
import AuthContext from "../store/AuthContext";
import FollowerContext from "../store/FollowerContext";
import CustomButton from "../common/CustomButton";
import AvatarImage from "../Components/Avatar/AvatarImage";
import GetImage from "./Networks/components/GetImage";
import GetFirebaseUrl from "./Networks/components/GetFirebaseUrl";
import GetImageFirebase from "../Components/Networks/GetImageFirebase";


export const ProfileDetails = ({ownProfile, id}) => {
  const authCtx = useContext(AuthContext);
  const followerCtx = useContext(FollowerContext);


  const db = database;

  const modalCtx = useContext(ModalContext);


  const [activeTab, setActiveTab] = useState(1);
  const [userData, setUserData] = useState(null);
  const [postArray, setPostArray] = useState([]);
  const [membership, setMembership] = useState([])
  const [networkMembership, setNetworkMembership] = useState([])
  const [banners, setBanners] = useState([])
  const [links, setLinks] = useState([])
  const [followers, setFollowers] = useState([])
  const [following, setFollowing] = useState([])
  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)
  const [ownedNetwork, setOwnedNetwork] = useState([])
  const [deletedNetwork, setDeletedNetwork] = useState([])
  const [loading, setLoading] = useState(true);

  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  useEffect(() => {
    addPostListener();
  }, []);

  useEffect(() => {
    getUserData()
  }, [modalCtx])

  //get posts from firebase
  const addPostListener = () => {
    const dbRef = ref(db, "waves", orderByChild("timestamp"), limitToLast(50));
    onValue(dbRef, (snapshot) => {
      setPostArray(snapshotToArray(snapshot));
    });
  };

  const snapshotToArray = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      returnArr.push(item);
    });
    return returnArr.reverse();
  };

  const editAbout = () => {
    modalCtx.openModal();
    modalCtx.setDetails('edit-about');
  }

  const uploadImageModal = () => {
    modalCtx.setDetails("AddNewImage", { "from": 'profile' })
    modalCtx.openModal()
  }

  const addLinksModal = () => {
    modalCtx.setDetails("addLinks")
    modalCtx.openModal()

  }

  useEffect(() => {
    setLoading(true);
    getUserData()
  }, [id])

  const getUserData = () => {

    let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, }, };
    Promise.all([
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id, options)
        .then((response) => response.json()),
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id + "/memberships", options)
        .then((response) => response.json()),
      //   fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId + "/networkMembership", options)
      // 	.then((response) => response.json()),
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id + "/banners", options)
        .then((response) => response.json()),
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id + "/links", options)
        .then((response) => response.json()),
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id + "/followers", options)
        .then((response) => response.json()),
      fetch(process.env.REACT_APP_API_URI + "/v2/user/" + id + "/following", options)
        .then((response) => response.json()),
    ])
      .then((results) => {
        setOwnedNetwork([])
        setDeletedNetwork([])
        setUserData(results[0].data);
        checkMembership(results[1].data)

        setMembership(results[1].data)
        // setNetworkMembership(results[1].data)
        setBanners(results[2].banners)
        setLinks(results[3].links)
        setFollowers(results[4].data)
        setFollowing(results[5].data)

        setLoading(false);


      }).catch((err) => {
        console.log(err);
      });
  }

  console.log()

  const handleFollowUser = (id) => {

    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} />

    if (id === authCtx.userId) return null;
    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }

  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  // console.log(membership) 
  // console.log(banners)
  // console.log(links)
  // console.log(followers)
  // console.log(following)

  const checkMembership = (data) => {

    if (data === null || undefined) return
    console.log(data)
    for (let i = 0; i < data.length; i++) {
      if (data[i].role === 'owner' && data[i].status === 'active') {
        setOwnedNetwork(prev => [...prev, data[i]])
      } else if (data[i].role === 'owner' && data[i].status === 'deleted') {
        setDeletedNetwork(prev => [...prev, data[i]])
      }
    }
  }
  // console.log(ownedNetwork)
  return (
    <div className="container">
      {
        !loading  ? 
        <div className="profile-details">
          <div className="card-container">
            <div className="card-body">
              <div className="gradient-header">
                {banners?.map((item, i) => (
                  <div className="banner-image" key={i}>
                  <GetImageFirebase imageRef={item.banner_ref} />
                  </div>
                ))}
                <div className="edit-image-btn">
                {
                      ownProfile &&
                      <div class="overlay-top">
                        <div class="text">
                          <EditRoundedIcon sx={{ color: "white", opacity: "100%", height: "16px" }} />
                         Edit 
                        </div>
                      </div>
                    }
                </div>
                <div onClick={() => { ownProfile && uploadImageModal() }} className="avatar-container">
                    <AvatarImage avatarRef={userData?.avatarRef} name={userData?.name} />
                    {
                      ownProfile &&
                      <div class="overlay-top">
                        <div class="text">
                          <EditRoundedIcon sx={{ color: "white", opacity: "100%" }} />
                        </div>
                      </div>
                    }
                </div>
              </div>
            </div>
            <div className="card-info">
              <h2>{userData?.name}</h2>
              <h3>@{userData?.username}</h3>
              <div className="links-container">

                <div className="links">
                  {links?.map((item, i) => (
                    <h3>{item.title}</h3>
                    ))}
                </div>
                <div className="add-links">
                  <h4 onClick={() => { ownProfile && addLinksModal() }}>add links</h4>
                </div>
              </div>
              <div className="tabs">
                <div onClick={() => setActiveTab((tab) => 1)} className={`${activeTab === 1 ? "active-tab" : "/membership"} tab about-tab`}>
                  <img src={activeTab === 1 ? aboutIconActive : aboutIcon} alt="" /> About
                </div>
                <div onClick={() => setActiveTab((tab) => 2)} className={`${activeTab === 2 ? "active-tab" : "/networkMembership"} tab`}>
                  Posts <span>5</span>
                </div>
                <div onClick={() => setActiveTab((tab) => 3)} className={`${activeTab === 3 ? "active-tab" : "/banners"} tab`}>
                  Owned Networks <span>{ownedNetwork?.length}</span>
                </div>
                
                { ownProfile &&
                  <div onClick={() => setActiveTab((tab) => 4)} className={`${activeTab === 4 ? "active-tab" : "/links"} tab`}>
                    Deleted Networks <span>{deletedNetwork?.length}</span>
                  </div>
                } 
                <div onClick={() => setActiveTab((tab) => 5)} className={`${activeTab === 5 ? "active-tab" : "/followers"} tab`}>
                  Followers <span>{followers?.length}</span>
                </div>
                <div onClick={() => setActiveTab((tab) => 6)} className={`${activeTab === 6 ? "active-tab" : "/following"} tab`}>
                  Following <span>{following?.length}</span>
                </div>
                { ownProfile &&
                  <div onClick={() => setActiveTab((tab) => 7)} className={`${activeTab === 7 ? "active-tab" : "/invites"} tab`}>
                    Invites <span>5</span>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="tab-description">
            {activeTab === 1 && (
              <div className="about">
                {
                  ownProfile && 
                  <div className="edit-btn">
                    <img src={editButton} alt="" />
                    <div onClick={() => editAbout()}>Edit</div>
                  </div>
                }
                <div>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                  amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className="posts">
                <div className="posts-container">
                  <Posts array={postArray?.slice(0, 10)} />
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className="columns-display">
                {
                  ownedNetwork?.map((item, i) => (
                    <div className="tile" key={i}>
                      <div className="content">
                        <GetImageFirebase imageRef={item.coverRef} className={"network-img"} />
                        <div className="network-details" >
                          <div className="title">{item.title}</div>
                          <div className="network-desc" >{item.description}</div>
                        </div>
                      </div>
                      {
                        ownProfile &&
                        <CustomButton className="f-btn" onClick={() => showModal("confirm-delete-network", modalCtx?.details?.selectedNetwork)} >Delete</CustomButton>
                      }
                    </div>
                  ))
                }
              </div>
            )}
            {activeTab === 4 && (
              <div className="columns-display">
                {
                  deletedNetwork.map((item, i) => (

                    <div className="tile" key={i}>
                      <div className="content">
                        <GetImageFirebase imageRef={item.coverRef} className={"network-img"} />
                        <div>
                          {item.title}
                        </div>
                      </div>
                      <CustomButton disabled={true}>Deleted</CustomButton>
                    </div>
                  ))
                }
              </div>
            )}
            {activeTab === 5 && (
              <div className="columns-display">
                {
                  followers?.map((item, i) => (
                    <div className="tile" key={item.user_id + i}>
                      <div className="content" >
                        <GetImageFirebase imageRef={item.avatar} className={"following-img"} />
                        <div>
                          {item.name} <br /> <span>@{item.username}</span>
                        </div>
                      </div>
                      {handleFollowUser(item.user_id)}
                    </div>
                  ))
                }
              </div>
            )}
            {activeTab === 6 && (
              <div className="columns-display">
                {
                  following?.map((item, i) => (
                    <div className="tile" key={item.follow_user_id + i}>
                      <div className="content">
                        <GetImageFirebase imageRef={item.avatar} className={"following-img"} />
                        <div>
                          {item.name} <br /> <span>@{item.username}</span>
                        </div>
                      </div>
                      {handleFollowUser(item.follow_user_id)}
                    </div>
                  ))
                }
              </div>
            )}
            {activeTab === 7 && (
              <div className="columns-display">
                {
                  following?.map((item, i) => (
                    <div className="tile" key={item.follow_user_id + i}>
                      <div className="content">
                        <GetImageFirebase imageRef={item.avatar} className={"following-img"} />
                        <div>
                          {item.name} <br /> <span>@{item.username}</span>
                        </div>
                      </div>
                      {handleFollowUser(item.follow_user_id)}
                    </div>
                  ))
                }
              </div>
            )}
          </div>
        </div> :
        <div className="profile-details">
          <div className="card-container">
            <div className="profile-details-loading-container">
              <CircularProgress style={{ color: "#1BA0A2" }} size={60} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};
