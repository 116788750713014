
import { createRef, useContext, useEffect, useState } from "react";

//components
import TextInput from "../Components/TextInput";

//context
import AuthContext from "../store/AuthContext";

//assets
import LogoFrequencyPeople from "../assets/img/home/logo.png";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useParams } from "react-router-dom";

const Login = () => {

  const overlay = createRef();
  const login = createRef();
  const signup = createRef();
  const authCtx = useContext(AuthContext)

  const params = useParams()

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  const [loading, setLoading] = useState(false)

  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [userExist, setUserExist] = useState(false)
  const [activeTab, setActiveTab] = useState('register')
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    nameError: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
  });

  // useEffect(() => {
  //   //getOverlayClass()
  //   //getLoginClass()
  // }, [activeTab])

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      // joinNetwork(params.inviteId)
    } else {
      getUserData(params.inviteId)
    }
  }, [params])


  const validateForm = () => {

    let valid = true;

    if (data.nameError.length > 0 || data.name.length <= 0) {
      valid = false
    } else if (data.emailError.length > 0 || data.email.length <= 0) {
      valid = false
    } else if (data.passwordError.length > 0 || data.password.length <= 0) {
      valid = false
    } else if (data.confirmPasswordError.length > 0 || data.confirmPassword.length <= 0) {
      valid = false
    }
    return valid;

  };

  const validateFormLogin = () => {

    let valid = true;

    if (data.emailError.length > 0 || data.email.length <= 0) {
      valid = false
    } else if (data.passwordError.length > 0 || data.password.length <= 0) {
      valid = false
    }
    return valid;

  };

  //* after entering this function we  will first validate the register data and after successful validation, we will proceed to the next step
  const handleSignUpSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true)
      submitSignUp(params.inviteId)
    } else {
      console.error('Invalid Form')
      setSnackbarMessage('Invalid Form. Please fill all the fields.');
      setOpenSnackbar(true);
    }
  }

  //* after entering this function we  will first validate the data login and after successful validation, we will proceed to the next step
  const handleLoginSubmit = (event) => {
    event.preventDefault();
    if (validateFormLogin()) {
      setLoading(true)
      submitLogin()
    } else {
      console.error('Invalid Form')
      setSnackbarMessage('Invalid Form. Please fill all the fields.');
      setOpenSnackbar(true);
    }
  }

  const submitLogin = async (hash) => {

    const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/checkLogin', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: data.email,
      })
    })
    const resData = await res.json()

    if (resData.status == "success") {
      console.log(resData)
      authCtx.login(data.email, data.password, resData.data.userName, resData.data.displayName, resData.data.avatarRef, true, hash);
    } else {
      setSnackbarMessage(resData.message);
      setOpenSnackbar(true);
      setLoading(false)
    }
  }


  const submitSignUp = async (hash) => {

    const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/checkLogin', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: data.email
      })
    })
    const resData = await res.json()

    if (resData.status == "success") {
      setSnackbarMessage("User already exist, Please Log In.");
      // setOpenSnackbar(true);
    } else {
      console.log("WE ARE HERE!");
      authCtx.register(data.email, data.password, data.name, true, hash)
      setOpenSnackbar(true);
      setLoading(false)
    }

  }

  const getUserData = async (hash) => {

    const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/invite/${hash}`, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    const resData = await res.json()

    if (resData.status == "success") {
      console.log(resData)
      checkIfUserExist(resData.data[0]?.email)
    }

  }


  const checkIfUserExist = async (email) => {

    const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/checkLogin', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email
      })
    })
    const resData = await res.json()

    if (resData.status == "success") {
      console.log("User already exist, Please Log In.", resData);
      setData((prev) => ({ ...prev, email: email }));
      setUserExist(true)
    } else {
      console.log("User does not already exist, Please Sign In.");
      setData((prev) => ({ ...prev, email: email }));
      setUserExist(false)
    }

  }

  const joinNetwork = async (hash) => {

    const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${hash}/networkMembership`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({
        userId: authCtx.userId
      })
    })
    const resData = await res.json()

    if (resData.status == "success") {
      console.log("joined network", resData);
    } else {
      console.log("Failed to join network");
    }

  }

  //* this function will handle every data change from login to register
  const handleChange = (event) => {

    event.preventDefault();
    const { name, value } = event.target;

    switch (name) {
      case 'name': setData((prev) => ({ ...prev, nameError: value.length < 5 ? 'Name must be at least 5 characters long!' : '' }))
        break;
      case 'email': setData((prev) => ({ ...prev, emailError: validEmailRegex.test(value) ? '' : 'Email is not valid!' }))
        break;
      case 'password': setData((prev) => ({ ...prev, passwordError: value.length < 6 ? 'Password must be at least 6 characters long!' : '' }))
        break;
      case 'confirmPassword': setData((prev) => ({ ...prev, confirmPasswordError: value !== data.password ? 'Password did not match!' : '' }))
        break;
      default:
        break;
    }

    setData((prev) => ({ ...prev, [name]: value }));

  }

  //* this function will handle "ENTER" key press in a text field 
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (activeTab == 'login') {
        handleLoginSubmit();
      } else {
        handleSignUpSubmit()
      }
    }
  };

  //* this function will handle the overlay 
  /*const getOverlayClass = () => {
    if (activeTab == 'login') {
      overlay.current.classList.add("overlay-wrapper-login");
      overlay.current.classList.remove("overlay-wrapper-register");
    } else {
      overlay.current.classList.add("overlay-wrapper-register");
      overlay.current.classList.remove("overlay-wrapper-login");
    }
  }*/
  //* this function will handle the overlay 
  /*const getLoginClass = () => {
    if (activeTab == 'login') {
      login.current.style = 'transform: translateX(0px); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); opacity: 1;'
      signup.current.style = 'transform: translateX(300px); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); opacity: 0;'
      setTimeout(() => {
        signup.current.style = 'transform: translateX(300px); opacity: 0; visibility: hidden;'
      }, 500);
    } else {
      login.current.style = 'transform: translateX(-300px); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); opacity: 0;'
      setTimeout(() => {
        login.current.style = 'transform: translateX(-300px); opacity: 0; visibility: hidden;'
      }, 500);
      signup.current.style = 'transform: translateX(0px); transition: 500ms cubic-bezier(0.91, 0.07, 0.48, 1); opacity: 1;'
    }
  }*/

  const handleTabChange = () => {
    if (activeTab == 'login') {
      setActiveTab('register')
    } else {
      setActiveTab('login')
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  console.log(data)

  return (
    <>
      <div className="invite-page-desktop" >
        <div className="login-register-container">
          <div className="login">
            <div ref={login} className={`login-form ${activeTab == 'login' ? 'login-active' : 'login-not-active'}`}>
              <div className="logo">
                <img src={LogoFrequencyPeople} className="logo-img" height="64px" alt="Frequency People Logo" />
              </div>
              <h1>Login to your account</h1>
              <div className="login-inputs">
                <TextInput type='email' validate error={data.emailError.length > 0 ? data.emailError : false} name='email' placeholder='Email Address' value={data.email} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                <TextInput type='password' name='password' placeholder='Password' error={data.passwordError.length > 0 ? data.passwordError : false} value={data.password} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
              </div>
              <button className="login-btn" onClick={(e) => handleLoginSubmit(e)}>
                {
                  loading
                    ? <CircularProgress style={{ color: '#fff' }} size={24} />
                    : "Login"
                }
              </button>
            </div>
          </div>
          <div className="register">
            <div ref={signup} className={`register-form ${activeTab == 'register' ? 'register-active' : 'register-not-active'}`}>
              <div className="logo">
                <img src={LogoFrequencyPeople} className="logo-img" height="64px" alt="Frequency People Logo" />
              </div>
              <h1>Register for free</h1>
              <div className="register-inputs">
                {!userExist ? <TextInput type='text' validate error={data.nameError.length > 0 ? data.nameError : false} name='name' placeholder='Name' value={data.name} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} /> : null}
                <TextInput type='email' validate error={data.emailError.length > 0 ? data.emailError : false} name='email' placeholder='Email Address' value={data.email} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                <TextInput type='password' name='password' placeholder='Password' error={data.passwordError.length > 0 ? data.passwordError : false} value={data.password} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                {!userExist ? <TextInput type='password' name='confirmPassword' placeholder='Confirm Password' error={data.confirmPasswordError.length > 0 ? data.confirmPasswordError : false} value={data.confirmPassword} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} /> : null}
              </div>
              <button className="register-btn" onClick={(e) => handleSignUpSubmit(e)}>
                {
                  loading
                    ? <CircularProgress style={{ color: '#fff' }} size={24} />
                    : "Join"
                }
              </button>
            </div>
          </div>
          <div ref={overlay} className="overlay-wrapper overlay-wrapper-register" >
            <h1>
              {
                activeTab == 'login'
                  ? 'New Here ?'
                  : 'You have been invited by ?'
              }
            </h1>
            <p>
              {
                activeTab == 'login'
                  ? 'Enter your personal details and start journey with us'
                  : 'To keep connected with us please login with your personal information'
              }
            </p>
            {/*<button className="change-tab-btn" onClick={() => handleTabChange()} >
              {
                activeTab == 'login'
                  ? 'Join'
                  : 'Login'
              }
            </button>*/}
          </div>
        </div>
        <div className="orange-sphere"></div>
        <div className="blue-sphere"></div>
      </div>


      {/* Mobile version */}
      <div className="invite-page-mobile" >
        <div className="login-register-container">
          <div className="overlay-wrapper" >
            <h1>
              {
                activeTab == 'login'
                  ? 'New Here ?'
                  : 'You have been invited by ?'
              }
            </h1>
            <p>
              {
                activeTab == 'login'
                  ? 'Enter your personal details and start journey with us'
                  : 'To keep connected with us please login with your personal information'
              }
            </p>
            {/*<button className="change-tab-btn" onClick={() => handleTabChange()} >
              {
                activeTab == 'login'
                  ? 'Join'
                  : 'Login'
              }
            </button>*/}
          </div>
          <div className="mobile-forms">

            <div className={`register ${activeTab == 'register' ? 'register-active' : 'register-not-active'}`}>
              <div className={`register-form`}>
                <div className="logo">
                  <img src={LogoFrequencyPeople} className="logo-img" height="64px" alt="Frequency People Logo" />
                </div>
                <h1>Register for free</h1>
                <div className="register-inputs">
                  <TextInput type='text' validate error={data.nameError.length > 0 ? data.nameError : false} name='name' placeholder='Name' value={data.name} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                  <TextInput type='email' validate error={data.emailError.length > 0 ? data.emailError : false} name='email' placeholder='Email Address' value={data.email} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                  <TextInput type='password' name='password' placeholder='Password' error={data.passwordError.length > 0 ? data.passwordError : false} value={data.password} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                  <TextInput type='password' name='confirmPassword' placeholder='Confirm Password' error={data.confirmPasswordError.length > 0 ? data.confirmPasswordError : false} value={data.confirmPassword} onChange={(e) => handleChange(e)} onKeyDown={handleKeyPress} />
                </div>
                <button className="register-btn" onClick={(e) => handleSignUpSubmit(e)}>
                  {
                    loading
                      ? <CircularProgress style={{ color: '#fff' }} size={24} />
                      : "Join"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="orange-sphere"></div>
        <div className="blue-sphere"></div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Login