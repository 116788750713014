import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@mui/material';

import ModalContext from '../store/ModalContext.js';
// import axios from 'axios';
import PlayVideo from './Modals/PlayVideo.jsx';
import ViewImage from './Modals/ViewImage.jsx';
import MessageActions from './Modals/MessageActions.jsx';
import AddImage from './Modals/AddImage/AddImage.jsx';
import AddVideo from './Modals/AddVideo.jsx';
import AddGiphy from './Modals/AddGiphy.jsx';
import CreateNetwork from './Modals/Networks/CreateNetwork/CreateNetwork.jsx';
import UserProfile from './Modals/UserProfile.jsx';
import Settings from './Modals/Settings.jsx';
import MemberAction from './Modals/MemberAction.jsx';
import EditNetwork from './Modals/Networks/EditNetworkDetails.jsx';
import InviteToNetwork from './Modals/InviteToNetwork.jsx';
import InviteMember from './Modals/InviteMember.jsx';
import CreatePost from './Modals/Wave/CreatePost.jsx';
import Invitation from './Modals/Invitation.jsx';
import Notification from './Modals/Notification.jsx';
import ConfirmDelete from './Modals/ConfirmDelete.jsx';
import PostComments from './Modals/Wave/PostComments.jsx';
import TaggedPeopleModal from './Modals/Wave/TaggedPeopleModal.jsx';
import CreateCourse from './Modals/CreateCourse.jsx';
import UploadImageModal from './Modals/ImageCropper/UploadImageModal.jsx'

//networks
import CreateAttachment from './Modals/Networks/Attachments/CreateAttachment.jsx';
import RenameAttachment from './Modals/Networks/Attachments/RenameAttachment.jsx';
import DeleteAttachment from './Modals/Networks/Attachments/DeleteAttachment.jsx';
import CreateEvent from './Modals/Networks/Events/CreateEvent.jsx';
import EditAbout from './Modals/EditAbout.jsx';
import ConfirmDeleteNetwork from './Modals/Networks/ConfirmDeleteNetwork.jsx';
import CreateNetworkNew from './Modals/Networks/CreateNetwork/CreateSubNetwork.jsx';
import AddLinks from './Modals/AddLinks/AddLinks.jsx';
import EditAccess from './Modals/Networks/EditNetworkAccess.jsx';
import EditNetworkModeration from './Modals/Networks/EditNetworkModeration.jsx';
import EditNetworkAccess from './Modals/Networks/EditNetworkAccess.jsx';
import EditNetworkDetails from './Modals/Networks/EditNetworkDetails.jsx';

// const DetailsModal = ({ lightboxModalProps }) => {
const DetailsModal = () => {
  // const { setToUpdate, goals, currentGoalID, toEdit, openModal, handleCloseModal, closeGoalMenu, goalToDelete, goalAction } = lightboxModalProps;

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80vw',
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 3,
  };

  const currentModal = () => {
    switch (modalCtx.modal) {
      case 'PlayVideo': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <PlayVideo />
        </div>
      </div>;
      case 'ViewImage': return <div style={modalCtx.open ? { display: 'flex' } : null} className='modal-root-div'>
        <div className="modal-container">
          <ViewImage />
        </div>
      </div>
      case 'ShowAction': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <MessageActions />
        </div>
      </div>;
      case 'AddImage': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <AddImage />
        </div>
      </div>;
      case 'AddNewImage': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <UploadImageModal details={modalCtx.details} />
        </div>
      </div>;
      case "AddVideo": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <AddVideo />
        </div>
      </div>;
      case "AddGiphy": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <AddGiphy />
        </div>
      </div>;
      case 'create-network': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreateNetwork />
        </div>
      </div>;
      case 'create-sub-network': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreateNetworkNew />
        </div>
      </div>;
      case 'userProfile': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <UserProfile />
        </div>
      </div>;
      case 'settings': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <Settings />
        </div>
      </div>;
      case 'memberAction': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <MemberAction />
        </div>
      </div>;
      case 'editNetworkDetails': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <EditNetworkDetails />
        </div>
      </div>;
      case 'editNetworkAccess': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <EditNetworkAccess />
        </div>
      </div>;
      case 'editNetworkModeration': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <EditNetworkModeration />
        </div>
      </div>;
      case 'subNetwork': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <EditNetwork />
        </div>
      </div>;
      case 'invite': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <InviteToNetwork />
        </div>
      </div>;
      case 'inviteMember': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <InviteMember />
        </div>
      </div>;
      case 'invitation': return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <Invitation />
        </div>
      </div>;
      case "createPost": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreatePost />
        </div>
      </div>;
      case "notification-preference": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <Notification />
        </div>
      </div>;
      case "confirm-delete": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <ConfirmDelete />
        </div>
      </div>;
      case "confirm-delete-network": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <ConfirmDeleteNetwork />
        </div>
      </div>;
      case "post-comments": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <PostComments />
        </div>
      </div>;
      case "tagged-people": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <TaggedPeopleModal />
        </div>
      </div>;

      //networks
      case "create-attachment": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreateAttachment />
        </div>
      </div>;
      case "rename-attachment": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <RenameAttachment />
        </div>
      </div>;
      case "delete-attachment": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <DeleteAttachment />
        </div>
      </div>;
      case "create-course": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreateCourse />
        </div>
      </div>;

      //event
      case "create-event": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <CreateEvent />
        </div>
      </div>;

      //Edit about
      case "edit-about": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <EditAbout />
        </div>
      </div>;

      //Add Links
      case "addLinks": return <div style={modalCtx.open ? { display: 'flex' } : null} className="modal-root-div">
        <div className="modal-container">
          <AddLinks />
        </div>
      </div>;


      default: return null;
    }
  };

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;
