import React, { useState, useEffect, useContext } from 'react';

//assets

//context
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';

//firebase
import { ref, onValue, orderByChild, limitToLast, set, update, remove } from "firebase/database";
import { database } from "../../Components/FirebaseConfig";

//components
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage';

//external lib
import moment from 'moment';
import { VideocamOutlined } from '@mui/icons-material';

export default function LiveFeed({ post, index, array }) {
  //firebase db
  const db = database;

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  //states
  const [liveData, setLiveData] = useState([])

  useEffect(() => {
    addLiveListener()
  }, [post])


  //get posts from firebase
  const addLiveListener = () => {
    const dbRef = ref(db, `hubStreams/${post?.streamContent.hubId}/${post?.streamContent.streamIdTimeStamp}/`,)
    onValue(dbRef, (snapshot) => {
      setLiveData(snapshot.toJSON())
    })
  }


  //handle modal
  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  //get relative time
  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    // var lastIndex = relTime.lastIndexOf(" ");
    return relTime
  }

  //get tagged users
  const getTaggedUsers = (users) => {
    if (users) {
      return <span className='tagged-users' onClick={() => handleModal('tagged-people', users)}>
        <span style={{ fontWeight: '400' }}>&nbsp;with</span>
        &nbsp;{users[0].name}&nbsp;
        {users.length - 1 == 0
          ? null
          : <>
            <span style={{ fontWeight: '400' }}>&nbsp;and</span>
            &nbsp; {users.length - 1}&nbsp; others
          </>}
      </span>
    } else {
      return null
    }
  }
  // console.log(liveData)

  return (
    <div key={index} className="post streamfeed">
      <div className="post-profile">
        <div className="profile">
          <div className="profile-img">
            <AvatarChatImage title={post.creator} avatarRef={post.avatar} />
          </div>
          <div className="profile-name">
            <span className="user">
              {post.creatorId === authCtx.userId ? "you" : post.creator}
              {getTaggedUsers(post?.users)}
              <span className='live-indicator'>Live</span>
            </span>
            <span className="activity-time">
              {timestampToDate(post?.timestamp)}
            </span>
          </div>
        </div>
      </div>
      <div className="post-img">
        <video style={{ height: '450px' }} controls src={liveData?.video} />
      </div>
      <div className="live-creator-details">
        <span>{liveData?.creator}'s new live stream recording in Frequency</span>
      </div>
      <div className="watch-recording">
        <VideocamOutlined style={{ color: '#FFF' }} />
        <span>Watch The Recording</span>
      </div>
    </div>
  );
}
