import React, { useState, useEffect, useContext } from 'react';

//firebase
import { ref, onValue, orderByChild, limitToLast, update, query } from "firebase/database";
import { database } from "../../../Components/FirebaseConfig";

//assets
import pollPin from '../../../assets/img/networks/poll-pin.svg';
import clap from '../../../assets/img/reactions/chat_reaction_clap.svg'
import heart from '../../../assets/img/reactions/chat_reaction_heart.svg'
import laugh from '../../../assets/img/reactions/chat_reaction_laugh.svg'
import thumb from '../../../assets/img/reactions/chat_reaction_thumbs_up.svg'
import reviewImage from '../../../assets/img/avatar/avatar1.jpg'
import userImage1 from '../../../assets/img/avatar/avatar1.jpg'
import userImage2 from '../../../assets/img/avatar/avatar2.jpg'
import userImage3 from '../../../assets/img/avatar/avatar3.jpg'
import userImage4 from '../../../assets/img/avatar/avatar4.jpg'
import userImage5 from '../../../assets/img/avatar/avatar5.jpg'
import userImage6 from '../../../assets/img/avatar/avatar6.jpg'


//context
import AuthContext from '../../../store/AuthContext';
import ModalContext from '../../../store/ModalContext';
import FollowerContext from '../../../store/FollowerContext';

//mui
import { ChatBubbleOutline, CloseRounded, FavoriteBorder, FavoriteRounded, MoreVertRounded, SendOutlined, SentimentSatisfiedRounded } from '@mui/icons-material'
import CommentsDisabledRoundedIcon from '@mui/icons-material/CommentsDisabledRounded';
import { Button, CircularProgress } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

//components
import ChatAttachmentImage from '../../../Components/Chat/ChatAttachmentImage'
import AvatarChatImage from '../../../Components/Avatar/AvatarChatImage';
import AvatarImage from '../../../Components/Avatar/AvatarImage';
import GetImage from './GetImage'

//external lib
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Posts from '../../../Components/Wave/Posts';
import { NavLink } from 'react-router-dom';
import Poll from './Poll';
import CustomButton from '../../../common/CustomButton';


const Overview = ({ setActiveTab, networkData, membersArray, subNetworks, parentId, setIsNetworkMember, activeNetworkId }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const followerCtx = useContext(FollowerContext);

  const db = database;


  //states
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenReaction, setIsOpenReaction] = useState(false);
  const [chats, setChats] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState();
  const [reactionId, setReactionId] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [filterTab, setFilterTab] = useState(0);

  const [postArray, setPostArray] = useState([]);

  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)
  const [featuredProfileData, setFeaturedProfileData] = useState([]);


  //effects
  useEffect(() => {

    getFeaturedProfiles()
    addPostListener()

  }, [])

  useEffect(() => {

    getMembers()
    messageListener()
    setIsOpenReaction(false)

  }, [activeNetworkId])

  //functions

  const getMembers = () => {
    // fetching members
    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + activeNetworkId + '/members', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // console.log(data?.data)
        setMembers(data?.data)
        setAvatar(data.data)
      } else {
      }
    });
  }

  const messageListener = () => {

    if (activeNetworkId !== '' && activeNetworkId !== null) {
      const dbRef = ref(db, '/hubChat/' + activeNetworkId);
      const chatsQuery = query(dbRef, orderByChild('timestamp'), limitToLast(5))
      onValue(chatsQuery, (snapshot) => {
        const returnArr = [];
        snapshot.forEach((childSnapshot) => {
          const item = childSnapshot.val();
          returnArr.push(item);
        });
        setChats(returnArr.reverse());
        setIsLoading(false);
      });
    }

  }
  // console.log(chats)

  //handle modal
  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getFeaturedProfiles = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/homev4/' + authCtx.userId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          filterProfileData(data?.data.splice(1, 1))
        } else {
        }
      });
  }

  //filter profile data
  const filterProfileData = (data) => {
    if (data) {
      setFeaturedProfileData(data[0]?.users)
    } else {
      console.log("no profile data")
    }
  }


  //get posts from firebase
  const addPostListener = () => {
    const dbRef = ref(
      db,
      'waves',
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setPostArray(snapshotToArray(snapshot))
    })
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }

  //count likes
  const countLikes = (obj) => {
    if (obj) {
      const likes = Object.keys(obj).length
      return likes ? likes : 0
    }
  }

  //count comments
  const countComments = (obj) => {
    if (obj) {
      const comments = Object.keys(obj).length
      return comments ? comments : 0
    }
  }

  //get tagged users
  const getTaggedUsers = (users) => {
    if (users) {
      return <span className='tagged-users'> <span style={{ fontWeight: '400' }}>with</span> {users[0].name} <span style={{ fontWeight: '400' }}>and</span> {users.length} others </span>
    } else {
      return null
    }
  }

  //get tagged users
  const handlePostMedia = (media) => {
    if (media.length) {
      return <div className="post-img"><Carousel infiniteLoop={true} showStatus={media.length > 1 ? true : false} showIndicators={media.length > 1 ? true : false} >{media.map((item, index) => <GetImage key={index} imageRef={item} />)}</Carousel></div>
    } else {
      return null
    }
  }
  //get tagged users
  const handlePostImage = (imageRef) => {
    if (imageRef) {
      return <div className="post-img"> <GetImage imageRef={imageRef} /> </div>
    } else {
      return null
    }
  }

  // follow unfollow button handler
  const handleFollowUser = (id) => {
    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} />

    if (id === authCtx.userId) return null;
    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-small f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-small f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-small f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }
  // unfollowing a user
  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  //following user
  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  const passHub = (networkName, taggedHubs) => {
    let newNetwork = networkName.substring(1)
    for (let i = 0; i < taggedHubs?.length; i++) {
      if (taggedHubs[i].hubName === newNetwork) {
        return taggedHubs[i].hubId
      }
    }
  }

  const passUserName = (modal, userName) => {
    // console.log(userName)
    let newUserName = userName.substring(1)
    for (let i = 0; i < members?.length; i++) {
      // console.log(newUserName)
      if (members[i].username === newUserName) {
        // userProfileCtx.setDetails(newUserName, members[i].id) 
        // let path = `/user/${members[i].username}`; 
        // navigate(path);
        modalCtx.openModal();
        modalCtx.setDetails(modal, members[i]?.id);
      }
    }
  }

  const messageFunction = (data) => {
    if (data.replyMessage) {
      return <div className="reply-msg">
        <div className="reply-content">
          <div className="name">{data?.replyMessage.username}</div>
          <div className="content">{data?.replyMessage.content !== "" ? data?.replyMessage.content : <span style={{ background: "transparent", fontWeight: "bold", color: "#007C8990" }}>Media</span>}</div>
        </div>
      </div>

    } else if (data.previewPath) {
      return <ChatAttachmentImage attachment={data.attachment} previewPath={data.previewPath} />

    } else if (data.attachment) {
      return <ChatAttachmentImage attachment={data.attachment} />

    } else {
      return null;
    }
  }

  const prepareMentionMsg = (msg, taggedHubs) => {

    let finalMsg = ''
    let array = msg?.split(' ');

    const map1 = array?.map(x => {
      if (x.includes("@[")) {
        // console.log(x)
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return ` @${returnArr}`
        }
      } else if (/[@]/g.test(x)) {
        return ` ${x}`
      } else {
        return x
      }
    });


    // console.log(map1.join(" "))
    //finalMsg = map1.join(" ")
    return map1?.map((word) => {
      if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[@]/g.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[#]/g.test(word)) {
        return <NavLink to={`/networks/${passHub(word, taggedHubs)}`} className='mention-text'>{word}</NavLink>
      } else if (/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(word)) {
        return <a className='mention-text' target='_blank' rel="noreferrer" href={word} >{word}</a>
      } else {
        return " " + word + " "
      }
    })
  }

  const handleReaction = (data, index) => {
    setReactionId(index)
    setUserData(data)
    if (!isOpenReaction) {
      setIsOpenReaction(true)
    } else {
      setIsOpenReaction(false)
    }
  }

  const checkForAvatar = (id) => {

    for (let i = 0; i < avatar.length; i++) {
      if (id == avatar[i].id) {
        return avatar[i].avatar
      } else {
      }
    }

  }

  const showModal = (modal, video) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }

  const addReaction = (reaction) => {

    let hubId = userData?.hubId;
    let timeStamp = userData.timestamp;
    var clapCount = userData?.clapReactionCount ? userData?.clapReactionCount : 0;
    var heartCount = userData?.heartReactionCount ? userData?.heartReactionCount : 0;
    var laughCount = userData?.laughReactionCount ? userData?.laughReactionCount : 0;
    var thumbsUpCount = userData?.thumbsUpReactionCount ? userData?.thumbsUpReactionCount : 0;

    let payload = {}

    switch (reaction) {
      case "clap":
        clapCount++;
        payload["clapReactionCount"] = clapCount;
        break;

      case "heart":
        heartCount++;
        payload["heartReactionCount"] = heartCount;
        break;

      case "laugh":
        laughCount++;
        payload["laughReactionCount"] = laughCount;
        break;

      case "thumbs":
        thumbsUpCount++;
        payload["thumbsUpReactionCount"] = thumbsUpCount;
        break;

      default: return null;
    }

    const messageRef = ref(db, '/hubChat/' + hubId + '/' + timeStamp)
    update(messageRef, payload)
    setIsOpenReaction(false)
  }


  //console logs
  // console.log(postArray)
  // console.log(likes)
  // console.log(comments)



  // console.log(followerCtx)

  // console.log('membersArray', membersArray)

  return (
    <>
      {/* <div className='overview-top-container'>
        <Poll />
        <div className='center-border'></div>
        <div className="review-container">
          <div className="image">
            <img src={reviewImage} alt="" />
            <div className='name'>Mady</div>
          </div>
          <div className="message-box">
            <div className="message">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</div>
            <div className='time'>5 mins go</div>
          </div>
        </div>
      </div> */}
      <div className="discover-container">
        {/* <div className="posts-container">

          <Posts array={postArray?.reverse()} />

        </div> */}
        <div style={{ display: `${chats.length === 0 ? "none" : ""}` }} className="overview-chat-container">
          <div className="messages">
            {
              chats?.slice(0, 5).map((data, index) => (
                <>
                  <div key={index} className={data.userId === authCtx.userId ? "message message-mine" : "message"}>
                    <span className='message-text'>

                      {messageFunction(data)}

                      {data.message !== "" ? prepareMentionMsg(data.message, data.taggedHubs) : null}
                      {/* <span dangerouslySetInnerHTML={{ __html: data.message !== "" ? prepareMentionMsg(data.message) : null }}></span> */}
                      <div className='message-emote-time' >
                        <div className="time">{timestampToDate(data.timestamp)}</div>
                        {data.clapReactionCount ? <div className='reaction' ><img src={clap} alt="clap" /><span className='reaction-count'>{data.clapReactionCount}</span> </div> : null}
                        {data.thumbsUpReactionCount ? <div className='reaction' ><img src={thumb} alt="thumb" /><span className='reaction-count'>{data.thumbsUpReactionCount}</span> </div> : null}
                        {data.heartReactionCount ? <div className='reaction' ><img src={heart} alt="heart" /><span className='reaction-count'>{data.heartReactionCount}</span> </div> : null}
                        {data.laughReactionCount ? <div className='reaction' ><img src={laugh} alt="laugh" /><span className='reaction-count'>{data.laughReactionCount}</span> </div> : null}
                      </div>
                    </span>
                    <div className="message-emote-action">
                      <SentimentSatisfiedRounded onClick={() => handleReaction(data, index)} />
                      <MoreVertRounded onClick={() => showModal('ShowAction', data)} />
                    </div>
                    <div className='chat-avatar' style={{ minWidth: "35px" }} onClick={() => showModal("userProfile", data.userId)} >
                      <AvatarChatImage avatarRef={checkForAvatar(data.userId)} title={data.userName} />
                      <div className="name">
                        {data.userName}
                      </div>
                    </div>
                    {
                      isOpenReaction && reactionId == index
                        ? <div className="add-reaction" >
                          <div className="reactions">
                            <img src={clap} alt="clap" onClick={() => addReaction("clap", data)} />
                            <img src={heart} alt="heart" onClick={() => addReaction("heart", data)} />
                            <img src={laugh} alt="laugh" onClick={() => addReaction("laugh", data)} />
                            <img src={thumb} alt="thumb" onClick={() => addReaction("thumbs", data)} />
                            <CloseRounded className='close-reaction' onClick={handleReaction} />
                          </div>
                        </div>
                        : null
                    }
                  </div>
                </>
              ))
            }
            {
              chats.length === 0 || chats == null ? <div className="no-messages"><span className='top-span'><CommentsDisabledRoundedIcon /> No messages yet</span> <span>Start the conversation</span> </div> : null
            }
          </div>
          <div className='action-box'>
            <CustomButton onClick={() => setActiveTab('chat')}>View Chat</CustomButton>
          </div>
        </div>
        <div style={{ width: `${chats.length === 0 ? "100%" : "300px"}` }} className="recommend-container side-container">
          <div style={{ justifyContent: `${chats.length === 0 ? "space-between" : "space-around"}` }} className='heading'>
            <h1 style={{ paddingLeft: `${chats.length === 0 ? "15px" : ""}` }}>Members</h1>
            <p style={{ paddingRight: `${chats.length === 0 ? "15px" : ""}` }} onClick={() => setActiveTab('members')} >View more</p>
          </div>
          <div style={{display:`${chats.length===0?"flex":""}`,flexWrap:`${chats.length===0?"wrap":""}`}} className='contents'>
            {
              membersArray?.slice(0, 4)?.map((data, i) => (
                <div style={{width:`${chats.length===0?"25%":"100%"}`}} className='profile-content' key={data.id}>
                  <div className='profile-head'>
                    <AvatarChatImage title={data?.name} avatarRef={data?.avatar} alt='profile' />
                    <div className='profile-body'>
                      <h3>{data?.name}</h3>
                      <p className={`hub-${data?.role}`}>{data?.role}</p>
                      {handleFollowUser(data.id)}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

        </div>
      </div>
    </>
  )
}

export default Overview