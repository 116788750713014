//react
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//firebase
import { ref, onChildAdded, orderByChild, limitToLast } from "firebase/database";
import { database } from "../../Components/FirebaseConfig.jsx";

//assets
import CircularProgress from '@mui/material/CircularProgress';
import arrowSvg from '../../assets/img/arrow.svg';

//context
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

//components/libs
import moment from 'moment';
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage.jsx';
import DmChat from './components/DmChat.jsx';
import { MapsUgcRounded } from '@mui/icons-material';


const DirectMessages = () => {

  const { directMessageId } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);
  const db = database;


  const [searchQuery, setSearchQuery] = useState('');
  const [currentDirectMessageId, setCurrentDirectMessageId] = useState(0);
  const [directMessageData, setDirectMessageData] = useState([]);
  const [friendSearchList, setFriendSearchList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showFriendSearchResults, setShowFriendSearchResults] = useState(false)
  const [membersLoading, setMembersLoading] = useState(false)
  const [loadMoreMessages, setLoadMoreMessages] = useState(false);


  useEffect(() => {
    getDirectMessageUsers();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '' || searchQuery.trim().length <= 3) {
      setFriendSearchList([])
      setShowFriendSearchResults(false)
    } else {
      searchMembers();
    }
  }, [searchQuery]);


  const getDirectMessageUsers = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + '/directMessages', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      })
      const data = await res.json()

      if (data.status === "success") {
        let dms = data.data.sort((a, b) => b.lastTimeStamp - a.lastTimeStamp)
        setDirectMessageData(dms);
        if (directMessageId) {
          setCurrentDirectMessageId(directMessageId);
          setLoadMoreMessages(true)
        } else {
          setLoadMoreMessages(true)
          setCurrentDirectMessageId(dms[0].id);
          navigate("/messages/" + dms[0].id, { replace: false });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error while getting DMs :', error)
    }
  }

  const updateDms = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${authCtx.userId}/directMessages`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      })
      const data = await res.json()

      if (data.status === "success") {
        // console.log(data);
        let dms = data.data.sort((a, b) => b.lastTimeStamp - a.lastTimeStamp)
        setDirectMessageData(dms);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error while updating DMs :', error)
    }
  }

  const searchMembers = async () => {

    if (searchQuery.trim() === '') {
      setFriendSearchList([])
      setShowFriendSearchResults(false)
      return null
    }

    setMembersLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${authCtx.userName}/hub/0/search/hubInvite/${searchQuery}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, }
        }
      )
      const data = await res.json()

      if (data.status === 'success') {
        const limitedMemberData = data?.data.slice(0, 100);
        setFriendSearchList(limitedMemberData);
        setMembersLoading(false);
        setShowFriendSearchResults(true)
      }
    } catch (error) {
      console.log('error while getting members :', error)
    }

  }

  const startNewDm = async (data) => {

    const payload = {
      userIds: [data.id],
      title: data.name
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${authCtx.userId}/directMessages`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
          body: JSON.stringify(payload)
        }
      )
      const data = await res.json()

      if (data.status === 'success') {
        console.log(data)
        setCurrentDirectMessageId(data.dmId)
        setFriendSearchList([])
        setShowFriendSearchResults(false)
        setSearchQuery('')
        updateDms()
      } else if (data.status === 'exists') {
        setCurrentDirectMessageId(data.dmId)
        setFriendSearchList([])
        setShowFriendSearchResults(false)
        setSearchQuery('')
        updateDms()
      }
    } catch (error) {
      console.log('error while getting members :', error)
    }

  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }

  const selectDirectMessage = (id) => {
    setCurrentDirectMessageId(id);
    setLoadMoreMessages(true)
    navigate("/messages/" + id, { replace: false });
  }


  const retrieveLastMessage = (userId, lastMessage) => {
    if (userId == authCtx.userId) {
      return "me: " + lastMessage;
    }
    return lastMessage;
  }

  const getSearchInput = (e) => {
    const value = e.target.value
    setSearchQuery(value)
  }

  const getDirectMessagesUserList = () => {
    if (!directMessageData?.length > 0) return
    return directMessageData.map((data, index) => (
      <div className={data.id == currentDirectMessageId ? "friends active" : "friends"} key={data.id} onClick={() => selectDirectMessage(data.id)}>
        <AvatarChatImage avatarRef={data.avatar} title={data.username} />
        <div className='friend-name-container' >
          <div className="friend-name-time">
            <div className="friend-name">{data.username}</div>
            <span className='last-time'>{timestampToDate(data.lastTimeStamp)}</span>
          </div>
          <span className="last-message">{retrieveLastMessage(data.lastMessageUserId, data.lastMessage)}</span>
        </div>

        {data.unreadCount ? <span className='unread-msg'>{data.unreadCount}</span> : null}
        {membersLoading ? null : <div className='active-span' style={data.id == currentDirectMessageId ? { display: "block" } : { display: "none" }}><img src={arrowSvg} alt="svg" /></div>}
      </div>
    ))
  }
  const getSearchedUserList = () => {
    
    if (searchQuery.trim().length <= 3) {
      return (<div className="invalid-username">
        Please Enter A valid User Name
      </div>)
      } else if (!friendSearchList?.length > 0) {
      return (<div className="invalid-username">
        User Doesn't Exist
      </div>)
    }
    return friendSearchList.map((data, index) => (
      <div className="searched-friends" key={index}>
        <AvatarChatImage avatarRef={data.avatar} title={data.username} />
        <div className='friend-name-container' >
          <div className="friend-name-time">
            <div className="friend-name">{data.name}</div>
            <div className="friend-username">{data.username}</div>
          </div>
        </div>
        <MapsUgcRounded onClick={() => startNewDm(data)} className='add-to-dm' />
      </div>
    ))
  }

  if (isLoading) {
    return <div className="container loading"><CircularProgress style={{ 'color': '#007C89' }} size={60} /></div>
  }


  return (
    <>
      <div className="dm-container">
        <div className="friend-list-container">
          <div className="search-friends">
            <input value={searchQuery} type="text" placeholder='Search friends' onChange={(e) => getSearchInput(e)} />
            {/* <SearchRounded className='search-icon' /> */}
          </div>
          <div className="friend-list">
            {
              membersLoading
                ? <div className="members-loading"><CircularProgress size={24} style={{ 'color': '#007C89' }} /></div>
                : null
            }
            {
              (showFriendSearchResults && friendSearchList?.length > 0) || searchQuery.trim() !== ''
                ? getSearchedUserList()
                : getDirectMessagesUserList()
            }
          </div>
        </div>
        <div className="direct-message-chat-wrapper">
          <DmChat loadMoreMessages={loadMoreMessages} setLoadMoreMessages={setLoadMoreMessages} updateDms={updateDms} currentDirectMessageId={currentDirectMessageId} />
        </div>
      </div>
    </>
  );

}
export default DirectMessages;