import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import DrawerContext from '../store/DrawerContext.js';

import Logo from '../assets/img/home/logo.png';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MenuIcon from '@mui/icons-material/Menu';

import avatar4 from '../assets/img/avatar/avatar4.jpg'
import AvatarImage from './Avatar/AvatarImage.jsx';
import { AddRounded, CircleRounded, MenuRounded } from '@mui/icons-material';
import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database } from "../Components/FirebaseConfig";

import NewNetworkImg from '../assets/img/overview/network.png'
import CreateDM from '../assets/img/overview/user-group.png';

import EditProfileIcon from '../assets/img/navbar/edit-profile.svg';
import ManagePaidNetworksIcon from '../assets/img/navbar/manage-paid-networks.svg';
import TransactionsIcon from '../assets/img/navbar/transactions.svg';
import DeleteAccountIcon from '../assets/img/navbar/delete-account.svg';
import NetworkTopicsIcon from '../assets/img/navbar/network-topics.svg';
import LogoutIcon from '../assets/img/navbar/logout.svg';

import CloseIcon from '@mui/icons-material/Close';
import NetworkImage from './Networks/NetworkImage.jsx';
import AvatarChatImage from './Avatar/AvatarChatImage.jsx';

import '../Styles/Navbar.scss'
import { Menu, MenuList } from '@mui/material';
import moment from 'moment';


const Navbar = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [openOption, setOpenOption] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activityFeedOpen, setActivityFeedOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [mobNav, setMobNav] = useState(null)
  const dropDownRef = useRef(true)


  const DropdownMenuElements = [
    {
      label: 'Edit Profile',
      icon: EditProfileIcon,
      onClick: () => {
        setDropdownOpen(false);
        navigate('/profile/edit', { replace: true });
      }
    },
    {
      label: 'Manage Paid Networks',
      icon: ManagePaidNetworksIcon,
      onClick: () => {

      }
    },
    {
      label: 'Transactions',
      icon: TransactionsIcon,
      onClick: () => {

      }
    },
    {
      label: 'Delete Account',
      icon: DeleteAccountIcon,
      onClick: () => {

      }
    },
    {
      label: 'Network Topics',
      icon: NetworkTopicsIcon,
      onClick: () => {
        setDropdownOpen(false);
        navigate('/profile/topics', { replace: true });
      }
    },
    {
      label: 'Logout',
      icon: LogoutIcon,
      onClick: () => authCtx.logout()
    },
  ];

  useEffect(() => {
    setActivityArray([])
    addDmListener();
  }, [])

  const addDmListener = () => {
    const dbRef = ref(
      database,
      '/activity/' + authCtx.userId,
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setActivityArray(snapshotToArray(snapshot))
    })
  }

  const snapshotToArray = (snapshot) => {
    // console.log(snapshot)
    // const data = snapshot.val()
    // var result = Object.keys(data).map((key) => insertInObject(data[key], key))
    // console.log(result)
    // const returnArr = []
    // snapshot.forEach((childSnapshot) => {
    //   const item = childSnapshot.val()
    //   returnArr.push(item)
    // })
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.timestamp = +childSnapshot.key
      returnArr.push(item);
    });
    return returnArr.reverse()
  }

  const insertInObject = (obj, timestamp) => {
    return { ...obj, "timestamp": timestamp }
  }

  useEffect(() => {

    setSearchResults([])

    if (searchText.length >= 3) {
      fetch(process.env.REACT_APP_API_URI + `/v2/search?search=${searchText}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log(data)
          setSearchResults(data)
          setIsLoading(false);
        }
      });
    }
  }, [searchText])

  const clearSearchPanel = () => {
    setSearchResultOpen(false);
    setSearchText("");
  }

  const selectNetwork = (id, type, parentId) => {
    clearSearchPanel();
    navigate(`/networks/${id}/overview`, { replace: false });
  }

  const openDrawer = () => {
    drawerCtx.openDrawer();
  }

  // opens a mobile drawer
  const toggleDrawerHandler = (drawer, user) => {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  //  opens a modal for message actions
  const showModal = (modal, data) => {
    console.log(modal)
    setOpenOption(false)
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  // open hub action menu
  const toggleActionBtn = () => {
    if (!openOption) {
      setOpenOption(true)
    } else {
      setOpenOption(false)
    }
  }

  const handleSearch = (e) => {
    let searchTxt = e.target.value
    setSearchText(searchTxt)
    if (searchTxt.length >= 3) {
      setSearchResultOpen(true)
    } else {
      setSearchResultOpen(false)
    }
  }

  const handleRef = (e) => {
    if ((dropDownRef.current != null || undefined) && (e.target != null || undefined))
      if (dropdownOpen && !dropDownRef.current.contains(e.target) && !e.target.classList.contains('dropdown-btn-div') && !e.target.classList.contains('toggle-btn')) {
        setDropdownOpen(false)
      }
  }

  document.addEventListener('mousedown', handleRef)

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }


  // console.log(activityArray)


  return (
    <div className="top-navbar-container" style={location.pathname.includes("/home") || location.pathname.includes("/contact") ? { display: "none" } : {}}>
      <div className="top-navbar">
        <span className='menu-btn' onClick={() => {
          mobNav ? setMobNav(false) : setMobNav(true)
        }}>
          <MenuIcon />
        </span>
        <NavLink to="/featured"><div className="logo-freq"><img src={Logo} alt="logo" /></div></NavLink>
        <div className={`nav-container ${mobNav ? 'nav-opened' : ''}`}>
          {
            !location.pathname.includes("/account/setup/")
              ? <ul>
                <li className='mobile-img-container'>
                  <NavLink to="/home">
                    <img src={Logo} className='mob-nav-logo-img' alt='' />
                  </NavLink>
                </li>
                <li onClick={() => { setMobNav(false); }}><NavLink to="/featured">WAVE</NavLink></li>
                <li onClick={() => { setMobNav(false); }}><NavLink to="/networks">NETWORKS</NavLink></li>
                {/* <li><NavLink to="/explore">EXPLORE</NavLink></li> */}
                <li onClick={() => { setMobNav(false); }}><NavLink to="/messages">MESSAGES</NavLink></li>
                <li style={{flex: '1', paddingLeft: '2rem'}} >
                  {
                    !location.pathname.includes("/account/setup/")
                      ? (
                        <div className="nav-search">
                          <SearchRoundedIcon />
                          <input value={searchText} type="text" placeholder='SEARCH' onChange={handleSearch} />
                          {
                            searchText.length > 0 && (
                              <HighlightOffRoundedIcon onClick={clearSearchPanel} style={{ cursor: 'pointer' }} />
                            )
                          }

                          <div className="search-results-mask" style={searchResultOpen ? {} : { display: "none" }} onClick={() => setSearchResultOpen(false)} />

                          <div className="search-results" style={searchResultOpen ? {} : { display: "none" }}>
                            <div className="sr-networks">
                              <div className="heading">
                                <h1>NETWORKS</h1>
                              </div>
                              <div className="network-container">
                                {
                                  searchResults.networks?.map((item, index) => (
                                    <div key={item.id} className="networks" onClick={() => { selectNetwork(item.id, 'parent', item.parentHubId) }}>
                                      <div className="hub-img">
                                        <NetworkImage coverRef={item.coverRef == null || item.coverRef == "" ? null : item.coverRef} title={item.title} />
                                      </div>
                                      <div className="network-text">
                                        <span>{item.title.length >= 20 ? `${item.title.slice(0, 20)}...` : item.title}</span>
                                      </div>
                                    </div>
                                  ))
                                }
                                {
                                  searchResults.networks?.length === 0
                                    ? <div className="no-network">
                                      <span className="no-network-text">
                                        No Network Found
                                      </span>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>

                            <div className="sr-profiles">
                              <div className="heading">
                                <h1>PROFILES</h1>
                              </div>
                              <div className="profile-container">
                                {
                                  searchResults.users?.map((item, index) => (
                                    <div className="profile" key={index} onClick={() => { toggleDrawerHandler('user-details', item) }}>
                                      <div className="profile-img">
                                        <AvatarChatImage avatarRef={item.avatar} title={item.name} />
                                      </div>
                                      <div className="profile-name">
                                        <span>{item.name.length >= 20 ? `${item.name.slice(0, 20)}...` : item.name}</span>
                                      </div>
                                    </div>
                                  ))
                                }
                                {
                                  searchResults.users?.length === 0
                                    ? <div className="no-user">
                                      <span className="no-user-text">
                                        No User Found
                                      </span>
                                    </div>
                                    : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : null
                  }
                </li>
                {/* <li><NavLink to="/">UNIVERSE</NavLink></li> */}
                <span className='closeBtnMobNav' onClick={() => { setMobNav(false); }}> <CloseIcon /> </span>
              </ul>
              : null
          }
          {/* <div className="search">
              <input type="text" placeholder='Search for networks or profiles'/>
              <SearchRoundedIcon />
            </div> */}
        </div>
        {/* 
        {
          !location.pathname.includes("/account/setup/")
            ? (
              <div className="nav-search">
                <SearchRoundedIcon />
                <input value={searchText} type="text" placeholder='SEARCH' onChange={handleSearch} />
                {
                  searchResultOpen && (
                    <HighlightOffRoundedIcon onClick={clearSearchPanel} style={{ cursor: 'pointer' }} />
                  )
                }

                <div className="search-results-mask" style={searchResultOpen ? null : { display: "none" }} onClick={() => setSearchResultOpen(false)} />

                <div className="search-results" style={searchResultOpen ? null : { display: "none" }}>
                  <div className="sr-networks">
                    <div className="heading">
                      <h1>NETWORKS</h1>
                    </div>
                    <div className="network-container">
                      {
                        searchResults.networks?.map((item, index) => (
                          <div key={index} className="networks" onClick={() => { selectNetwork(item.id, 'parent', item.parentHubId) }}>
                            <div className="hub-img">
                              <NetworkImage coverRef={item.coverRef} title={item.title} />
                            </div>
                            <div className="network-text">
                              <span>{item.title.length >= 20 ? `${item.title.slice(0, 20)}...` : item.title}</span>
                            </div>
                          </div>
                        ))
                      }
                      {
                        searchResults.networks?.length === 0
                          ? <div className="no-network">
                            <span className="no-network-text">
                              No Network Found
                            </span>
                          </div>
                          : null
                      }
                    </div>
                  </div>

                  <div className="sr-profiles">
                    <div className="heading">
                      <h1>PROFILES</h1>
                    </div>
                    <div className="profile-container">
                      {
                        searchResults.users?.map((item, index) => (
                          <div className="profile" key={index} onClick={() => { toggleDrawerHandler('user-details', item) }}>
                            <div className="profile-img">
                              <AvatarChatImage avatarRef={item.avatar} title={item.name} />
                            </div>
                            <div className="profile-name">
                              <span>{item.name.length >= 20 ? `${item.name.slice(0, 20)}...` : item.name}</span>
                            </div>
                          </div>
                        ))
                      }
                      {
                        searchResults.users?.length === 0
                          ? <div className="no-user">
                            <span className="no-user-text">
                              No User Found
                            </span>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
            : null
        } */}

        {
          !location.pathname.includes("/account/setup/") ? (
            <div className="nav-icons">

              <div className="hub-actions" style={openOption ? {} : { display: "none" }}>
                <div className="action-btns">
                  <div className="arrow">
                    <div></div>
                  </div>
                  <div className="icon-img" onClick={() => showModal('CreateNetwork', null)}>
                    <img src={NewNetworkImg} alt="add" />
                    <span>Create Network</span>
                  </div>
                  <div className="icon-img">
                    <img src={CreateDM} alt="add" />
                    <span>Start DM</span>
                  </div>
                </div>
              </div>

              {/* <div className="nav-icon add">
                  <AddRounded onClick={toggleActionBtn} />
                </div> */}
              <div className="nav-icon">
                <NotificationsNoneRoundedIcon className='nav-icon-notification' onClick={() => setActivityFeedOpen(!activityFeedOpen)} />
              </div>

              <div className="activity-feed-modal" style={activityFeedOpen ? {} : { display: "none" }}>
                {/* <div className="activity-feed-modal-container"> */}
                <div className="activities">
                  <div className="activity-feed">
                    <div className="activity-header">
                      <h1>Activity Feed</h1>
                      <CloseIcon style={{ color: 'black' }} onClick={() => setActivityFeedOpen(false)} />
                    </div>
                    <div className="activity-container">
                      {
                        activityArray?.reverse()?.slice(0, 6)?.map((data, index) => (
                          <div className="activity" key={index}>
                            <div className={data.type == 'directMessage' ? "avatar" : "hub-avatar"}>
                              {data.type === 'hubChat' ? <NetworkImage coverRef={data.imageRef} /> : <AvatarChatImage avatarRef={data.imageRef} />}
                            </div>
                            <div className="activity-details">
                              <div className="user-name">{data.title}</div>
                              <div className="activity-description">
                                <span className='description'>{data.description}</span>
                                <CircleRounded className='dot' />
                                <div className="time">{timestampToDate(+data.timestamp)}</div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="activity-feed-modal-mask" onClick={() => setActivityFeedOpen(false)} />
              </div>

              <div className="dropdown-btn-div nav-icon">
                {/* <KeyboardArrowDownRoundedIcon onClick={() => authCtx.logout()} /> */}
                <KeyboardArrowDownRoundedIcon onClick={() => { setDropdownOpen(!dropdownOpen) }} className='toggle-btn' style={{ color: 'black' }} />
              </div>

              <div className="drowdown-menu" ref={dropDownRef} style={dropdownOpen ? {} : { display: "none" }}>
                {
                  DropdownMenuElements.map((item, index) => (
                    <div key={index} className="drowdown-menu-element" onClick={item.onClick}>
                      <img src={item.icon} alt='' />
                      <h1> {item.label} </h1>
                    </div>
                  ))
                }
              </div>



              {/* <div className="nav-icon" onClick={() => showModal("userProfile", authCtx.userId)}>
                  <AvatarImage onClick={() => showModal("userProfile", authCtx.userId)} avatarRef={authCtx.avatarRef} />
                  <div className="notify"></div>
                </div> */}
              <div className="nav-icon">
                <NavLink to={`/profile/${authCtx.userId}`}><AvatarImage avatarRef={authCtx.avatarRef} /></NavLink>
                <div className="notify"></div>
              </div>
              <div className="nav-icon">
                <div className="open-menu" onClick={() => toggleDrawerHandler('mobile-navbar', null)} ><MenuRounded /> </div>
              </div>

            </div>
          ) : null
        }

      </div>
    </div>
  )
}

export default Navbar