import React, { useContext, useState, useEffect } from 'react';

import {  ref, update, remove } from "firebase/database";
import { database }  from "../FirebaseConfig";


import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

const MessageActions = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  const [isMine, setIsMine] = useState(true)
  const [isOwner, setIsOwner] = useState(false)
  const [newMsg, setNewMsg] = useState('')
  const [showReaction, setShowReaction] = useState(false)

  const closeHandle = () => {
		modalCtx.closeModal();
	  }

    useEffect(() => {

      
      if (modalCtx.details.userId == authCtx.userId) {
        setIsMine(true)
      } else {
        setIsMine(false)
      }
    }, [modalCtx.details])
    

    const toggleShowReaction = () => {
      if (showReaction) {
        setShowReaction(false) 
      } else {
        setShowReaction(true)
      }
    }


    
    const updateMessage = () => {
      console.log(modalCtx.details)
      let msg = modalCtx.details;
      msgCtx.setDetails('edit', msg)
      modalCtx.closeModal();
    }


    const replyMessage = () => {
      msgCtx.setDetails("reply", modalCtx.details)
      modalCtx.closeModal();

    }



    const addReaction = (reaction) => {

      let hubId = modalCtx.details.hubId;
      let timeStamp = modalCtx.details.timestamp;
      var clapReactionCount =  modalCtx.details?.clapReactionCount ? modalCtx.details?.clapReactionCount : 0 ;
      var heartReactionCount = modalCtx.details?.heartReactionCount ? modalCtx.details?.heartReactionCount : 0 ;
      var laughReactionCount = modalCtx.details?.laughReactionCount ? modalCtx.details?.laughReactionCount : 0 ;
      var thumbsUpReactionCount = modalCtx.details?.thumbsUpReactionCount ? modalCtx.details?.thumbsUpReactionCount : 0 ;

      let payload = {}

      switch (reaction) {
        case "clap":
          clapReactionCount++;
          payload["clapReactionCount"] = clapReactionCount;
          break ;

        case "heart":
          heartReactionCount++;
          payload["heartReactionCount"] = heartReactionCount;
          break ;

        case "laugh":
          laughReactionCount++;
          payload["laughReactionCount"] = laughReactionCount;
          break ;

        case "thumbs":
          thumbsUpReactionCount++;
          payload["thumbsUpReactionCount"] = thumbsUpReactionCount;
        break ;

        default: return null;
      }

      const messageRef = ref(db, '/hubChat/' + hubId + '/' + timeStamp)
		  const dmMessageRef = ref(db, '/directMessages/' + msgCtx.userId + '/' + timeStamp)

		  update( msgCtx.origin === "netChat" ? messageRef : dmMessageRef, payload)
      modalCtx.closeModal();
    }

    

    const deleteMessage = () => {

      let timeStamp = modalCtx.details.timestamp
      let hubId = modalCtx.details.hubId

      const messageRef = ref(db, '/hubChat/' + hubId + '/' + timeStamp)
		  const dmMessageRef = ref(db, '/directMessages/' + msgCtx.userId + '/' + timeStamp)

		  remove(msgCtx.origin === "netChat" ? messageRef : dmMessageRef)
      modalCtx.closeModal();
    }

    const showModal = (modal, userId) => {
      modalCtx.openModal();
      modalCtx.setDetails(modal, userId);
      }

  return (
    <>
      <div className="action-container">
        <div className="actions">

          <List>

            {/* only if the message is sent by you */}
            { isMine &&
            <ListItem>
              <ListItemButton onClick={updateMessage}>
                <ListItemText  >Edit Message</ListItemText>
              </ListItemButton>
            </ListItem>
            }
            
            <ListItem>
              <ListItemButton onClick={replyMessage} >
                <ListItemText >Reply to {modalCtx.details.userName}</ListItemText>
              </ListItemButton>
            </ListItem>

            {/* only if the message is not sent by you */}
            { !isMine && 
            <>
            {/* <ListItem>
              <ListItemButton onClick={toggleShowReaction}>
                <ListItemText  >Add a reaction</ListItemText>
              </ListItemButton>
            </ListItem>
             { showReaction && 

            <ListItem>
              <ListItemButton onClick={() => addReaction("clap")}>
                <ListItemText>👏</ListItemText>
              </ListItemButton>

              <ListItemButton onClick={() => addReaction("thumbs")}>
                <ListItemText>👍</ListItemText>
              </ListItemButton>

              <ListItemButton onClick={() => addReaction("heart")}>
                <ListItemText>❤️</ListItemText>
              </ListItemButton>

              <ListItemButton onClick={() => addReaction("laugh")}>
                <ListItemText>😂</ListItemText>
              </ListItemButton>
            </ListItem>

            }  */}
            {msgCtx.origin !== "dmChat" && 
            <ListItem>
              <ListItemButton>
                <ListItemText>Send {modalCtx.details.userName} a direct message</ListItemText>
              </ListItemButton>
            </ListItem>
            }
            <ListItem>
              <ListItemButton onClick={() => showModal("userProfile", modalCtx.details.userId)}>
                <ListItemText>View {modalCtx.details.userName + "'s"} profile</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemText>Report Objectionable Content</ListItemText>
              </ListItemButton>
            </ListItem>
            </>
            }

            {/* only if the user is owner */}
            { isOwner && 
            <ListItem>
              <ListItemButton>
                <ListItemText style={{color: "#ff1111"}} >Block & Remove User</ListItemText>
              </ListItemButton>
            </ListItem>
            }

            {/* only if the message is sent by you */}
            { isMine &&
            <ListItem>
              <ListItemButton onClick={deleteMessage} >
                <ListItemText style={{color: "#ff1111"}}  >Delete Message</ListItemText>
              </ListItemButton>
            </ListItem>
            }

          </List>
        </div>
      <Button className='cancel' onClick={() => closeHandle()} >Cancel</Button>
      </div>
    </>
  )
}

export default MessageActions;
