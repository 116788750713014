import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'

//context
import AuthContext from '../../../../store/AuthContext.js';
import ModalContext from '../../../../store/ModalContext.js';

//components/libs
import ReactPlayer from 'react-player';
import GetImageFirebase from '../../../../Components/Networks/GetImageFirebase.jsx';
import Chat from './Chat.jsx';

import AddImg from '../../../../assets/img/attachment/add-img.png'
import AddVid from '../../../../assets/img/attachment/add-vid.png'
import AddGif from '../../../../assets/img/attachment/add-gif.png'

import Stream from '../../../../assets/img/stream.png'
import Avatar from '../../../../assets/img/avatar/avatar1.jpg'


import Attach from '../../../../assets/img/live/attach.svg'
import Send from '../../../../assets/img/live/send.svg'

import chat from '../../../../assets/img/live/chat.svg'

import Webcam from "react-webcam";

import { VisibilityOutlined, KeyboardBackspaceRounded, ScreenShareOutlined, CloseRounded, AttachFileRounded, SendRounded } from '@mui/icons-material';
import { Mention, MentionsInput } from 'react-mentions';

import { ref, onValue, orderByChild, limitToLast } from 'firebase/database'
import storage, { database } from '../../../../Components/FirebaseConfig.jsx'


const defaultStyle = {
  control: { backgroundColor: '#fff', fontSize: 14, fontWeight: 'normal', },
  '&multiLine': { control: { minHeight: 63, }, highlighter: { padding: 9, border: '1px solid transparent', }, input: { padding: 9, border: '1px solid silver', }, },
  '&singleLine': { display: 'inline-block', width: 180, highlighter: { padding: 1, border: '2px inset transparent', }, input: { padding: 1, border: '2px inset', }, },
  suggestions: { list: { backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.15)', fontSize: 14, }, item: { padding: '5px 15px', borderBottom: '1px solid rgba(0,0,0,0.15)', '&focused': { backgroundColor: '#cee4e5', }, }, },
}

const LiveViewer = ({ setSelectedLive, setLiveStreamViewOn, liveData, NetworkId }) => {

  const db = database;

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;

  const [members, setMembers] = useState([])
  const [messageText, setMessageText] = useState('');
  const [videoTimeData, setVideoTimeData] = useState({});

  const [isChatOn, setIsChatOn] = useState(false)
  const [openAttach, setOpenAttach] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [idleState, setIdleState] = useState(false);

  const handleChatOpen = () => {
    setIsChatOn(true)
  }

  const showTitleHandle = (time) => {
    if (idleState === true) {
      setShowTitle(false)
    }
    setIdleState(false)
    setTimeout(function () {
      setShowTitle(true)
      setIdleState(true)
    }, time);
  }

  // open attachment menu
  const toggleAttachBtn = () => {
    if (!openAttach) {
      setOpenAttach(true)
    } else {
      setOpenAttach(false)
    }
  }

  //getting meesage from input
  const getMessageText = (e) => {
    if (e.target.value == "\n") return;
    console.log(e)
    var megStr = e.target.value
    setMessageText(megStr)
  }

  //  opens a modal for message actions
  const showModal = (modal, video) => {
    setOpenAttach(false)
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && messageText) {
      setMessageText("")
    }
  }


  return (
    <>
      <div className="live-stream-viewer" >
        <div onMouseMove={() => showTitleHandle(5000)} className="live-stream" style={isChatOn ? { width: "calc(100% - 325px)" } : null} >
          <ReactPlayer height={'100%'} onProgress={(e) => setVideoTimeData(e)} className={'live-video-player'} controls url={liveData?.playback} />
          <div className="stream-details" style={showTitle ? { opacity: '0' } : { opacity: '1' }}>
            <KeyboardBackspaceRounded className='exit-stream' onClick={() => { setLiveStreamViewOn(false); setSelectedLive(null) }} />
            {/* <div className="title">Beginners Guide to Mobile Photography</div>
            <div className="live-indicator">LIVE</div>
            <div className="time-count">0:15</div>
            <div className="view-count">
              <VisibilityOutlined />
              <div className="count">400</div>
            </div> */}
          </div>

          {/* <div className="profile">
            <img src={Avatar} alt="avatar" />
            <div className="name">Steve Parker</div>
          </div> */}

          <div className="chat-icon" onClick={handleChatOpen} style={isChatOn ? { display: "none" } : null}>
            <img src={chat} alt="chat" />
            {/* <div className="chat-count">
              <span>15</span>
            </div> */}
          </div>
          {/* <div className="stream-controls">
            <div className="player"></div>
          </div> */}
        </div>
        <div className="live-chat" style={isChatOn ? { display: "flex" } : { display: "none" }} >
          <div className="chat-header">
            <h3>Live Chat</h3>
            <CloseRounded onClick={() => setIsChatOn(false)} />
          </div>
          <div className="live-chat-container">
            <Chat NetworkId={NetworkId} liveType={'live'} streamId={liveData.streamId} videoTimeData={videoTimeData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveViewer