import React, { useEffect, useState, useContext } from 'react';
import { getDatabase, ref, set, get, child, onValue, orderByChild, limitToLast, update, query } from "firebase/database";
import { database, firestore } from "../../../Components/FirebaseConfig";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import moment from 'moment';

import CircularProgress from '@mui/material/CircularProgress';

import CommentsDisabledRoundedIcon from '@mui/icons-material/CommentsDisabledRounded';

import clap from '../../../assets/img/reactions/chat_reaction_clap.svg'
import heart from '../../../assets/img/reactions/chat_reaction_heart.svg'
import laugh from '../../../assets/img/reactions/chat_reaction_laugh.svg'
import thumb from '../../../assets/img/reactions/chat_reaction_thumbs_up.svg'
import ChatSetting from '../../../assets/img/networks/chat-setting.svg'
import SearchIcon from '../../../assets/img/search.svg'
import image1 from '../../../assets/img/avatar/avatar1.jpg'
import image2 from '../../../assets/img/avatar/avatar2.jpg'
import image3 from '../../../assets/img/avatar/avatar3.jpg'
import image4 from '../../../assets/img/avatar/avatar4.jpg'
import tick from '../../../assets/img/networks/tick.svg'
import cross from '../../../assets/img/networks/wrong.svg'

import AuthContext from '../../../store/AuthContext.js';
import ModalContext from '../../../store/ModalContext.js';
import MessageContext from '../../../store/MessageContext.js';

import AvatarChatImage from '../../../Components/Avatar/AvatarChatImage';
import ChatAttachmentImage from '../../../Components/Chat/ChatAttachmentImage'
import MessageBox from './MessageBox';
import { CloseRounded, MoreVertRounded, SentimentSatisfiedRounded, TuneRounded } from '@mui/icons-material';
import CustomButton from '../../../common/CustomButton.jsx';

const Chat = ({ activeNetworkId, activeTab }) => {

  // console.log(activeNetworkId)

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const msgCtx = useContext(MessageContext);

  const db = database;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenReaction, setIsOpenReaction] = useState(false);
  const [chats, setChats] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState();
  const [reactionId, setReactionId] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [loadMessagesAmount, setLoadMessagesAmount] = useState(10)


  useEffect(() => {
    msgCtx.setOrigin(null, "netChat")
  }, [])

  useEffect(() => {

    getMembers()
    messageListener()
    setIsOpenReaction(false)

  }, [activeNetworkId])

  useEffect(() => {
      messageListener()
  }, [loadMessagesAmount])

  const getMembers = () => {
    // fetching members
    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + activeNetworkId + '/members', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // console.log(data?.data)
        setMembers(data?.data)
        setFilteredMembers(data?.data);
        setAvatar(data.data)
      } else {
      }
    });
  }

  const messageListener = () => {

    // if (activeNetworkId !== '' && activeNetworkId !== null) {
    //   const dbRef = ref(db, '/hubChat/' + activeNetworkId, orderByChild('timestamp'), limitToLast(50));
    //   onValue(dbRef, (snapshot) => {
    //     setChats([]);
    //     setChats(snapshotToArray(snapshot));
    //     setIsLoading(false);
    //   });
    //   // console.log(chats)
    // }

    if (activeNetworkId !== '' && activeNetworkId !== null) {
      const dbRef = ref(db, '/hubChat/' + activeNetworkId);
      const chatsQuery = query(dbRef, orderByChild('timestamp'), limitToLast(loadMessagesAmount))
      onValue(chatsQuery, (snapshot) => {
        const returnArr = [];
        snapshot.forEach((childSnapshot) => {
          const item = childSnapshot.val();
          returnArr.push(item);
        });
        setChats(returnArr.reverse());
        // setIsLoading(false);
      });
    }
    
  }


  const snapshotToArray = (snapshot) => {

    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });
    return returnArr.reverse();

  }

  const messageFunction = (data) => {
    if (data.replyMessage) {
      return <div className="reply-msg">
        <div className="reply-content">
          <div className="name">{data?.replyMessage.username}</div>
          <div className="content">{data?.replyMessage.content !== "" ? data?.replyMessage.content : <span style={{ background: "transparent", fontWeight: "bold", color: "#007C8990" }}>Media</span>}</div>
        </div>
      </div>

    } else if (data.previewPath) {
      return <ChatAttachmentImage attachment={data.attachment} previewPath={data.previewPath} />

    } else if (data.attachment) {
      return <ChatAttachmentImage attachment={data.attachment} />

    } else {
      return null;
    }
  }

  const handleReaction = (data, index) => {
    setReactionId(index)
    setUserData(data)
    if (!isOpenReaction) {
      setIsOpenReaction(true)
    } else {
      setIsOpenReaction(false)
    }
  }

  const prepareMentionMsg = (msg, taggedHubs) => {

    let finalMsg = ''
    let array = msg?.split(' ');

    const map1 = array?.map(x => {
      if (x.includes("@[")) {
        console.log(x)
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return ` @${returnArr}`
        }
      } else if (/[@]/g.test(x)) {
        return ` ${x}`
      } else {
        return x
      }
    });


    // console.log(map1.join(" "))
    //finalMsg = map1.join(" ")
    return map1?.map((word) => {
      if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[@]/g.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[#]/g.test(word)) {
        return <NavLink to={`/networks/${passHub(word, taggedHubs)}`} className='mention-text'>{word}</NavLink>
      } else if (/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(word)) {
        return <a className='mention-text' target='_blank' rel="noreferrer" href={word} >{word}</a>
      } else {
        return " " + word + " "
      }
    })
  }


  // TODO: compare the network name with all networks and add a link to the network
  const passHub = (networkName, taggedHubs) => {
    let newNetwork = networkName.substring(1)
    for (let i = 0; i < taggedHubs?.length; i++) {
      if (taggedHubs[i].hubName === newNetwork) {
        return taggedHubs[i].hubId
      }
    }
  }

  const passUserName = (modal, userName) => {
    console.log(userName)
    let newUserName = userName.substring(1)
    for (let i = 0; i < members?.length; i++) {
      console.log(newUserName)
      if (members[i].username === newUserName) {
        // userProfileCtx.setDetails(newUserName, members[i].id) 
        // let path = `/user/${members[i].username}`; 
        // navigate(path);
        modalCtx.openModal();
        modalCtx.setDetails(modal, members[i]?.id);
      }
    }
  }

  const checkForAvatar = (id) => {

    for (let i = 0; i < avatar.length; i++) {
      if (id == avatar[i].id) {
        return avatar[i].avatar
      } else {
      }
    }

  }

  const showModal = (modal, video) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }

  const addReaction = (reaction) => {

    let hubId = userData?.hubId;
    let timeStamp = userData.timestamp;
    var clapCount = userData?.clapReactionCount ? userData?.clapReactionCount : 0;
    var heartCount = userData?.heartReactionCount ? userData?.heartReactionCount : 0;
    var laughCount = userData?.laughReactionCount ? userData?.laughReactionCount : 0;
    var thumbsUpCount = userData?.thumbsUpReactionCount ? userData?.thumbsUpReactionCount : 0;

    let payload = {}

    switch (reaction) {
      case "clap":
        clapCount++;
        payload["clapReactionCount"] = clapCount;
        break;

      case "heart":
        heartCount++;
        payload["heartReactionCount"] = heartCount;
        break;

      case "laugh":
        laughCount++;
        payload["laughReactionCount"] = laughCount;
        break;

      case "thumbs":
        thumbsUpCount++;
        payload["thumbsUpReactionCount"] = thumbsUpCount;
        break;

      default: return null;
    }

    const messageRef = ref(db, '/hubChat/' + hubId + '/' + timeStamp)
    update(messageRef, payload)
    setIsOpenReaction(false)
  }

  const toogleFilterBox = () => {
    setShowFilters(showFilters => !showFilters);
  }

  // console.log(chats)
  //console.log(msgCtx.origin)

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    setFilteredMembers(members.filter(member => member.name.toLowerCase().includes(searchQuery.toLowerCase())
      ||
      member.username.toLowerCase().includes(searchQuery.toLowerCase())))
  }

  const handleMessagesScroll = (e) => {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = Math.trunc(Math.abs(e.target.scrollTop))
    const offsetHeight = e.target.offsetHeight
    if (scrollHeight - offsetHeight - scrollTop < 5) {
      setLoadMessagesAmount(prev => prev + 10)
    }

  }


  return (
    <div className="chat-container">
      <div className="chat">
        <div className="chat-filter-setting-icon">
          {/* <TuneRounded style={{ color: 'black' }} /> */}
          <img src={ChatSetting} alt="chat settings" onClick={toogleFilterBox} />
        </div>
        {showFilters && <div className="chat-filter-setting">
          <div className="filter-box">
            <div className="filter-header">
              <p>Filter By Flags</p>
              <img src={cross} alt="" onClick={toogleFilterBox}  />
            </div>
            <div className="filter-content">
              <div className="single-filter">
                <div className="filter-name">
                  <p>Show flagged messages</p>
                  <input type="checkbox" />
                </div>
              </div>
              <div className="single-filter">
                <div className="filter-name">
                  <p>Show messages by users</p>
                  <input type="checkbox" />
                </div>
                <div className="filter-section">
                  <div className='input-box'>
                    <input type="text" placeholder='Search members' onChange={handleSearchQuery} />
                    <img src={SearchIcon} alt="" />
                  </div>
                  <p className='suggestion-heading'>Suggestions</p>
                  {filteredMembers.map((member, index) => {
                    return (
                      index<50 &&
                      <div className='user-box' key={index}>
                        <div className="user-details">
                          <AvatarChatImage avatarRef={member.avatar} title={member.username} />
                          <div className="user-info">
                            <div className="name">{ member.name }</div>
                            <div className="username">{ member.username }</div>
                          </div>
                        </div>
                        <input type="checkbox" />
                      </div>
                    )
                  }) }
                </div>
              </div>
              <div className="single-filter">
                <div className="filter-name">
                  <p>Show messages with images</p>
                  <input type="checkbox" />
                </div>
              </div>
              <div className="single-filter">
                <div className="filter-name">
                  <p>Show messages with videos</p>
                  <input type="checkbox" />
                </div>
              </div>
              <div className="single-filter">
                <div className="filter-name">
                  <p>Show messages with GIFs</p>
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div className="filter-footer">
              <CustomButton className='f-btn-full-width'>Apply Filters</CustomButton>
            </div>
          </div>
        </div>}
        <div className="messages" onScroll={handleMessagesScroll}>
          {
            chats?.map((data, index) => (
                <div key={index} className={data.userId === authCtx.userId ? "message message-mine" : "message"}>
                  <span className='message-text'>

                    {messageFunction(data)}

                    {data.message !== "" ? prepareMentionMsg(data.message, data.taggedHubs) : null}
                    {/* <span dangerouslySetInnerHTML={{ __html: data.message !== "" ? prepareMentionMsg(data.message) : null }}></span> */}
                    <div className='message-emote-time' >
                      <div className="time">{timestampToDate(data.timestamp)}</div>
                      {data.clapReactionCount ? <div className='reaction' ><img src={clap} alt="clap" /><span className='reaction-count'>{data.clapReactionCount}</span> </div> : null}
                      {data.thumbsUpReactionCount ? <div className='reaction' ><img src={thumb} alt="thumb" /><span className='reaction-count'>{data.thumbsUpReactionCount}</span> </div> : null}
                      {data.heartReactionCount ? <div className='reaction' ><img src={heart} alt="heart" /><span className='reaction-count'>{data.heartReactionCount}</span> </div> : null}
                      {data.laughReactionCount ? <div className='reaction' ><img src={laugh} alt="laugh" /><span className='reaction-count'>{data.laughReactionCount}</span> </div> : null}
                    </div>
                  </span>
                  <div className="message-emote-action">
                    <SentimentSatisfiedRounded onClick={() => handleReaction(data, index)} />
                    <MoreVertRounded onClick={() => showModal('ShowAction', data)} />
                  </div>
                  <div className='chat-avatar' style={{ minWidth: "35px" }} onClick={() => showModal("userProfile", data.userId)} >
                    <AvatarChatImage avatarRef={checkForAvatar(data.userId)} title={data.userName} />
                    <div className="name">
                      {data.userName}
                    </div>
                  </div>
                  {
                    isOpenReaction && reactionId == index
                      ? <div className="add-reaction" >
                        <div className="reactions">
                          <img src={clap} alt="clap" onClick={() => addReaction("clap", data)} />
                          <img src={heart} alt="heart" onClick={() => addReaction("heart", data)} />
                          <img src={laugh} alt="laugh" onClick={() => addReaction("laugh", data)} />
                          <img src={thumb} alt="thumb" onClick={() => addReaction("thumbs", data)} />
                          <CloseRounded className='close-reaction' onClick={handleReaction} />
                        </div>
                      </div>
                      : null
                  }
                </div>
            ))
          }
          {
            chats.length === 0 || chats == null ? <div className="no-messages"><span className='top-span'><CommentsDisabledRoundedIcon /> No messages yet</span> <span>Start the conversation</span> </div> : null
          }
        </div>

      </div>

      <MessageBox activeNetworkId={activeNetworkId} />
    </div>
  )
}

export default Chat