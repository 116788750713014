import React, { useState } from 'react'
import { useContext } from 'react';
import ModalContext from '../../../store/ModalContext';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import avatar1 from '../../../assets/img/avatar/avatar1.jpg';
import avatar2 from '../../../assets/img/avatar/avatar2.jpg';
import avatar3 from '../../../assets/img/avatar/avatar3.jpg';
import avatar4 from '../../../assets/img/avatar/avatar4.jpg';
import avatar5 from '../../../assets/img/avatar/avatar5.jpg';

//assets

import courseImg from "../../../assets/img/course.png"
import CustomButton from '../../../common/CustomButton';

const Courses = ({ activeNetworkId }) => {

  //dummy data
  const TestData = [
    { drafted: false, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: 50 },
    { drafted: true, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: "Not Started" },
    { drafted: false, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: 50 },
    { drafted: true, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: 50 },
    { drafted: false, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: "Not Started" },
    { drafted: true, image: courseImg, enroled: 2, description: "Short description of course, Lorem ipsum dolor sit amet, consetetur sadipscing elitr.", status: 50 },
  ];

  const modalCtx = useContext(ModalContext)

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  const selectCourse = (id) => {

    window.history.replaceState(null, null, `/networks/${activeNetworkId}/courses/`)

  }

  return (
    <div className="course-container">
      <div className="course-wrapper">
        <div className='heading'>
          <h1 className='' >Courses</h1>
          <CustomButton  className = 'f-btn-big' onClick={() => handleModal("create-course", {})}>Create Course</CustomButton>
        </div>
        <div className='course-item-wrapper'>
          {
            TestData?.map((item, index) => (
              <div key={index} className='course-card'>
                {item?.drafted ?
                  <div className='c-type-draft'>DRAFT</div>
                  :
                  <p className='c-type-published'>Published</p>
                }
                <img src={item?.image} alt="img" />
                <div className='card-head'>
                  <h1>Title Of Course</h1>
                </div>
                <MoreInfo item={item} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Courses

function MoreInfo({ item }) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const imageArray = [avatar1, avatar2, avatar3, avatar4, avatar5];

  return (
    <div className='card-desc'>

      <div className='card-about'>
        <p className='item-desc'>{item?.description}</p>
        <div className='arrow-img'>
          <KeyboardArrowDownRoundedIcon style={{ color: 'black', fontSize: "24px", transform: showMoreInfo ? "rotate(0deg)" : "rotate(180deg)" }} onClick={() => setShowMoreInfo(!showMoreInfo)} />
        </div>
      </div>

      <div className={`more-info ${showMoreInfo ? "" : "hidden"}`}>
        <div className='modules'>
          <h1>Modules</h1>
          <div className='module-info'>
            <div className='image-stack'>
              <img src="" alt="" style={{ visibility: 'hidden' }} />
              {imageArray.map((image, index) => (<img key={index} src={image} alt={`Image ${index + 1}`} style={{ left: `${index * 20 + 20}px`, position: 'absolute', display: `${index > 2 ? 'none' : ''}` }} />))}
            </div>
            <p>{imageArray.length} Modules</p>
            <CustomButton className='f-btn-bordered'>Manage</CustomButton>
          </div>
        </div>
        <div className='students'>
          <h1>Students Enrolled</h1>
          <div className='student-info'>
            <div className='image-stack'>
              <img src="" alt="" style={{ visibility: 'hidden' }} />
              {imageArray.map((image, index) => (<img key={index} src={image} alt={`Image ${index + 1}`} style={{ left: `${index * 20 + 20}px`, position: 'absolute', display: `${index > 5 ? 'none' : ''}` }} />))}
            </div>
            <CustomButton className='f-btn-bordered'>View</CustomButton>
          </div>
        </div>
        <CustomButton className='f-btn' style={{margin : '24px 0'}}>Supporting Files</CustomButton>
      </div>

    </div>

  )
}
