import React, { useContext, useState, useEffect } from 'react';

import { ref, update, remove } from "firebase/database";
import { database, uploadBytes, ref as sRef, uploadString } from "../../FirebaseConfig.jsx";
import storage from "../../FirebaseConfig.jsx";
import { Button, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl, CircularProgress, Snackbar, Alert } from '@mui/material';

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import { AddRounded, CloseRounded } from '@mui/icons-material';
import { Wallpaper } from '@mui/icons-material';
import { Close } from '@mui/icons-material';

import NetworkImage from '../../Networks/NetworkImage.jsx';
import CustomButton from '../../../common/CustomButton.jsx';

const EditNetworkDetails = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [value, setValue] = useState();
  const [network, setNetwork] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = useState(false);

  //network updates
  const [hubImagePreview, setHubImagePreview] = useState();
  const [hubImage, setHubImage] = useState("");
  const [hubImageRef, setHubImageRef] = useState(modalCtx.details.coverRef);
  const [hubTitle, setHubTitle] = useState(modalCtx.details.title);
  const [hubType, setHubType] = useState(modalCtx.details.hubType);
  const [topicId, setTopicId] = useState(modalCtx.details.topicId);
  const [headline, setHeadline] = useState(modalCtx.details.headline);
  const [parentId, setParentId] = useState(modalCtx.details.parentId);
  const [description, setDescription] = useState(modalCtx.details.description);
  const [permissions, setPermissions] = useState(modalCtx.details.permissions);
  const [networkId, setNetworkId] = useState(modalCtx.details.id)
  const [paid, setPaid] = useState(modalCtx.details.paid)
  const [paymentFee, setPaymentFee] = useState(modalCtx.details.paymentFee)
  const [paymentType, setPaymentType] = useState(modalCtx.details.paymentType)
  const [paymentRecurring, setPaymentRecurring] = useState(modalCtx.details.paymentRecurring)
  // useEffect(() => {
  //   updateNetwork()
  // }, [])


  console.log(modalCtx.details.coverRef)

  //   const fetchResults = () => {

  //     fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + modalCtx.details.data.selectedNetwork, {
  //       method: 'GET',
  //     mode: 'cors',
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authCtx.token
  //     }
  //   }).then(response => {
  //     return response.json();
  //   }).then(data => {
  //     if(data.status === "success") {

  //       setNetwork(data.data);
  //       if(data.data) {
  //         setNetworkId(data.data?.id)
  //         setHubImageRef(data.data?.coverRef)
  //         setHubImagePreview(data.data?.coverRef)
  //         setHubImage(data.data?.coverRef)
  //         setHubTitle(data.data?.title)
  //         setHubType(data.data?.hubType)
  //         setTopicId(data.data?.topicId)
  //         setHeadline(data.data?.headline)
  //         setParentId(data.data?.parentId)
  //         setDescription(data.data?.description)
  //         setPermissions(data.data?.permissions)
  //       }
  //       setIsLoading(false);

  //     } else {
  //       // setOpenSnackbar(true);
  //     }
  //   });
  // }

  console.log(networkId);
  // const pay = {
  //   coverRef: "",
  //   description: "",
  //   headline: "",
  //   hubType: "public",
  //   id: 401,
  //   lastActivity: 1666265459076,
  //   lastActivityType: "new_message",
  //   messageCount: 109,
  //   paid: "no",
  //   parentId: 0,
  //   paymentFee: "20",
  //   paymentRecurring: "none",
  //   paymentType: "none",
  //   permissions: "open",
  //   shareUrl: "https://frequencyworld.page.link/S2My9YvfvnGK4jAu7",
  //   subscriberCount: 2,
  //   title: "testing images --",
  //   topicId: 3
  // }

  //const payload = {
  // "parentHubId": network.parentId,
  // "title": _nameController.text,
  // "headline": _headlineController.text,
  // "description": _descController.text,
  // "topicId": dropDownValue.id,
  // "type": hubType,
  // "coverRef": newimage,
  // "permissions": hubPrefs,
  // "paid": paid,
  // "paymentFee": paymentFee,
  // "paymentType": paymentType,
  // "paymentRecurring": paymentRecur,
  //};

  // snackbar

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
    setOpenSnackbarWarning(false)
  }

  const handleChangePermission = (event) => {
    setPermissions(event.target.value);
  };

  const handleChangeTopic = (event) => {
    setTopicId(event.target.value);
  };

  const handleFile = (e) => {
    let img = e.target.files[0];
    console.log(img)
    setHubImage(img)
    console.log(hubImage)
    const reader = new FileReader();

    reader.onload = function (e) {
      console.log(e)
      setHubImagePreview(e.target.result)
    };
    reader.readAsDataURL(img);

  }

  const handleTitle = (e) => {
    let newText = e.target.value
    setHubTitle(newText)
    console.log(hubTitle)
  }

  const handleHeadline = (e) => {
    let newText = e.target.value
    setHeadline(newText)
    console.log(headline)
  }

  const handleDesc = (e) => {
    let newText = e.target.value
    setDescription(newText)
    console.log(description)
  }

  const uploadImageModal = () => {
    modalCtx.setDetails("AddNewImage", { "from": 'Details', description: description, headline: headline, hubType: hubType, id: networkId, title: hubTitle, parentId: parentId, permissions: permissions, topicId: topicId, selectedNetwork: networkId, paid: paid, paymentFee: paymentFee, paymentType: paymentType, paymentRecurring: paymentRecurring, coverRef: hubImageRef });
    modalCtx.openModal()
  }


  // pushing image to firebase
  const pushImage = () => {

    if (hubImage == null) return;

    setLoading(true)
    setUploading(true)
    let coverRef = `/users/${authCtx.userId}/hubImages/${hubImage.name}`;

    const storageRef = sRef(storage, coverRef)

    uploadBytes(storageRef, hubImage).then((snapshot) => {
      let imagePayload = {
        coverRef: hubImageRef
      }
      setHubImageRef(coverRef)

      fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + modalCtx.details + '/uploadImage', {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(imagePayload)
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data?.status === "success") {
          console.log(data)
          setUploading(false)
          setOpenSnackbar(true)
        } else {
        }
      });
    }, (error) => console.log(error));

  }


  const closeHandle = () => {
    modalCtx.closeModal();
  }

  // /v2/hubs/:id:

  const updateNetwork = () => {

    if (!hubTitle) {
      setOpenSnackbarWarning(true)
      return;
    }

    const payload = {
      "parentHubId": parentId,
      "title": hubTitle,
      "headline": headline,
      "description": description,
      "topicId": topicId,
      "type": hubType,
      "coverRef": hubImageRef,
      "permissions": permissions,
      "paid": paid,
      "paymentFee": paymentFee,
      "paymentType": paymentType,
      "paymentRecurring": paymentRecurring,
    };

    fetch(process.env.REACT_APP_API_URI + `/v2/networks/${modalCtx.details.id}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data?.status === "success") {
        console.log("Data Added Successfully")
        // pushImage()
        setLoading(false)
      } else {
      }
    });
  }

  console.log(hubImageRef)

  return (
    <>
      <div className="create-network-container">
        <div className="create-network">
          <h2>Update Network Details</h2>
          {/* <div className="edit-network-steps">
            <h4>Details</h4>
          </div> */}
          <div className="net-title-img">
            {/* <div className="net-img" style={hubImage ? {display: "none"} : null}>
              <input type="file" className='net-get-img' accept="image/*" onChange={handleFile} />
              {<Wallpaper sx={{ width: '50px', height: '50px' }} color={'#CACACA'} />}
            </div> */}
            <div className="network-image-container">
              <div className="image-container" >
                {
                  hubImageRef
                    ? <NetworkImage coverRef={hubImageRef} className='image' />
                    : <Wallpaper sx={{ width: '50px', height: '50px' }} style={{ cursor: "pointer" }} color={'#CACACA'} onClick={() => { uploadImageModal() }} />
                }
              </div>
              {hubImageRef && <Close onClick={() => setHubImageRef('')} style={{ cursor: "pointer", position: "absolute", right: '-30px', top: '0' }} className='close' />}
            </div>
            {/* <div className="net-public-private">
              <div className={hubType === "public" ? "net-public active" : "net-public"} onClick={() => setHubType("public")}>Frequency Network</div>
              <div className={hubType === "shared" ? "net-private active" : "net-private"} onClick={() => setHubType("shared")} >Shared Network</div>
            </div> */}
            <div className="net-title">
              <div className="net-title-input">
                <label htmlFor="title">Title</label>
                <input type="text" name="title" id="title" placeholder='Title' value={hubTitle} onChange={handleTitle} />
              </div>
            </div>
          </div>

          <div className="net-topic">
            <div className="net-input">
              {/* <label htmlFor="topic">Select Topic</label>
              <input type="text" name="topic" id="topic" placeholder='Select topic' /> */}
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>

                <InputLabel id="select-topic-label">Topic</InputLabel>
                <Select
                  labelId="select-topic-label"
                  value={topicId}
                  label="Select topic"
                  onChange={handleChangeTopic}
                >
                  <MenuItem value={1}>Arts & Entertainment</MenuItem>
                  <MenuItem value={2}>health & Wellness</MenuItem>
                  <MenuItem value={3}>Live Sport</MenuItem>
                  <MenuItem value={4}>US News</MenuItem>
                  <MenuItem value={5}>Science</MenuItem>
                  <MenuItem value={6}>Sports</MenuItem>
                  <MenuItem value={7}>Support Groups</MenuItem>
                  <MenuItem value={8}>Education</MenuItem>
                  <MenuItem value={9}>Other</MenuItem>
                  <MenuItem value={10}>Politics</MenuItem>
                  <MenuItem value={11}>Social Justice</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="net-headline">
            <div className="net-input">
              <label htmlFor="headline">Headline</label>
              <input type="text" name="headline" id="headline" onChange={handleHeadline} defaultValue={headline} placeholder='Type your headline' />
            </div>
          </div>

          <div className="net-desc">
            <div className="net-desc-input">
              <label htmlFor="description">Description</label>
              <textarea cols="20" rows="5" type="text" name="description" onChange={handleDesc} defaultValue={description} id="description" placeholder='Type your description' />
            </div>
          </div>

          {/* <div className="net-join" style={hubType === "public" ? null : {display: "none"}} >
            <span className='net-join-title'>How may users join this network?</span>
            <FormControl>
              <RadioGroup value={value} onChange={handleChangePermission}>
                <FormControlLabel checked={permissions === "open" ? true : false} value="open" control={<Radio />} label="Open network, anyone may join without restriction" />
                <FormControlLabel  defaultChecked={hubType === "invite"} checked={permissions === "invite" ? true : false} value="invite" control={<Radio />} label="By request only but any member may invite" />
              </RadioGroup>
            </FormControl>
          </div> */}

          <div className="navigate-btn-container" style={{ width: '100%', marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            <CustomButton className="f-btn" onClick={() => !uploading && updateNetwork()} >
              {
                uploading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : "Save Changes"
              }
            </CustomButton>
          </div>

          <CloseRounded onClick={closeHandle} className="close-btn" />
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Network was updated.
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackbarWarning} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
            Please enter a title for the Network.
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default EditNetworkDetails;
