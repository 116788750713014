import { SearchRounded } from '@mui/icons-material';
import React from 'react';
import { NavLink } from 'react-router-dom';



const MobileDrawer = () => {

  return (
    <div className="container">
      <div className="drawer">
            <ul>
              <li><NavLink to="/home">HOME</NavLink></li>
              <li><NavLink to="/networks">NETWORKS</NavLink></li>
              <li><NavLink to="/explore">EXPLORE</NavLink></li>
              <li><NavLink to="/messages">MESSAGES</NavLink></li>
            </ul>
             
            <div className="search">
              <input type="text" placeholder='Search for networks or profiles'/>
              <SearchRounded />
            </div>

          <div className="nav-search">
            <SearchRounded />
            <input type="text" placeholder='SEARCH' />
          </div>
      </div>
    </div>
  );

}
export default MobileDrawer;

