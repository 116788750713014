import React, { useContext, useState, useEffect } from 'react';


// firbase
import {  ref, update, remove } from "firebase/database";
import { database, uploadBytes, ref as sRef, uploadString }  from "../FirebaseConfig";
import storage from "../FirebaseConfig";

// icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Copy from '../../assets/img/copy.png'

// context
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { KeyboardArrowDown , CloseRounded, Search, SearchRounded } from '@mui/icons-material';
import AvatarChatImage from '../Avatar/AvatarChatImage';

const InviteToNetwork = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState();
  const [emailList, setEmailList] = useState([]);
  const [shareUrl, setShareUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [membersLoading, setMembersLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [members, setMembers] = useState([])
  const [inviteSent, setInviteSent] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  // const members = [{
  //   "id": 1,
  //   "name": "Obed",
  //   "username": "osplaven0"
  // }, {
  //   "id": 2,
  //   "name": "Wyatt",
  //   "username": "wjime1"
  // }, {
  //   "id": 3,
  //   "name": "Allegra",
  //   "username": "akitteman2"
  // }, {
  //   "id": 4,
  //   "name": "Aristotle",
  //   "username": "aspratley3"
  // }, {
  //   "id": 5,
  //   "name": "Kiley",
  //   "username": "kklesl4"
  // }, {
  //   "id": 6,
  //   "name": "Cozmo",
  //   "username": "caldie5"
  // }, {
  //   "id": 7,
  //   "name": "Mariejeanne",
  //   "username": "mbellchamber6"
  // }, {
  //   "id": 8,
  //   "name": "Fernando",
  //   "username": "farchell7"
  // }, {
  //   "id": 9,
  //   "name": "Ilsa",
  //   "username": "icolthard8"
  // }, {
  //   "id": 10,
  //   "name": "Milo",
  //   "username": "mstaresmeare9"
  // }, {
  //   "id": 11,
  //   "name": "Ray",
  //   "username": "rcarwithena"
  // }, {
  //   "id": 12,
  //   "name": "Dalt",
  //   "username": "delrickb"
  // }, {
  //   "id": 13,
  //   "name": "Edie",
  //   "username": "ekarpovc"
  // }, {
  //   "id": 14,
  //   "name": "Rockie",
  //   "username": "rrapinettd"
  // }, {
  //   "id": 15,
  //   "name": "Isabel",
  //   "username": "ipughe"
  // }, {
  //   "id": 16,
  //   "name": "Haywood",
  //   "username": "hmorlingf"
  // }, {
  //   "id": 17,
  //   "name": "Demott",
  //   "username": "deverlyg"
  // }, {
  //   "id": 18,
  //   "name": "Reta",
  //   "username": "rkildaleh"
  // }, {
  //   "id": 19,
  //   "name": "Guthrey",
  //   "username": "gulyati"
  // }, {
  //   "id": 20,
  //   "name": "Isa",
  //   "username": "iminorj"
  // }, {
  //   "id": 21,
  //   "name": "Mae",
  //   "username": "mtoyek"
  // }, {
  //   "id": 22,
  //   "name": "Dorothea",
  //   "username": "dorhtl"
  // }, {
  //   "id": 23,
  //   "name": "Faye",
  //   "username": "fchongm"
  // }, {
  //   "id": 24,
  //   "name": "Deloria",
  //   "username": "dthwaitesn"
  // }, {
  //   "id": 25,
  //   "name": "Trish",
  //   "username": "trannieo"
  // }, {
  //   "id": 26,
  //   "name": "Abram",
  //   "username": "acominsp"
  // }, {
  //   "id": 27,
  //   "name": "Adeline",
  //   "username": "asapeyq"
  // }, {
  //   "id": 28,
  //   "name": "Mame",
  //   "username": "msarer"
  // }, {
  //   "id": 29,
  //   "name": "Daphna",
  //   "username": "drevingtons"
  // }, {
  //   "id": 30,
  //   "name": "Amalita",
  //   "username": "adewingt"
  // }, {
  //   "id": 31,
  //   "name": "Bourke",
  //   "username": "bstrainu"
  // }, {
  //   "id": 32,
  //   "name": "Alden",
  //   "username": "awiginv"
  // }, {
  //   "id": 33,
  //   "name": "Hallie",
  //   "username": "hrossonw"
  // }, {
  //   "id": 34,
  //   "name": "Opal",
  //   "username": "odeatonx"
  // }, {
  //   "id": 35,
  //   "name": "Kipper",
  //   "username": "kbrittainy"
  // }, {
  //   "id": 36,
  //   "name": "Lewes",
  //   "username": "lbeathemz"
  // }, {
  //   "id": 37,
  //   "name": "Karena",
  //   "username": "kfetherston10"
  // }, {
  //   "id": 38,
  //   "name": "Druci",
  //   "username": "djobern11"
  // }, {
  //   "id": 39,
  //   "name": "Giulietta",
  //   "username": "gshakle12"
  // }, {
  //   "id": 40,
  //   "name": "Binnie",
  //   "username": "bellph13"
  // }, {
  //   "id": 41,
  //   "name": "Magdalen",
  //   "username": "mhoodless14"
  // }, {
  //   "id": 42,
  //   "name": "Stafford",
  //   "username": "sshimony15"
  // }]

  useEffect(() => {
    fetchResults()
  }, [])

  useEffect(() => {
    if(searchQuery){
      searchMembers()
    }
  }, [searchQuery])

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + modalCtx.details, {
      method: 'GET',
    mode: 'cors',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authCtx.token
    }
  }).then(response => {
    return response.json();
  }).then(data => {
    if(data.status === "success") {

      setShareUrl(data.data.shareUrl)
      setIsLoading(false);
      console.log(data);
    } else {
      // setOpenSnackbar(true);
    }
  });
}

  const searchMembers = () => {

    if(!searchQuery){
      setMembers([])
    }
    setMembersLoading(true)
    
    fetch(process.env.REACT_APP_API_URI + `/v2/user/${authCtx.userName}/hub/${modalCtx.details}/search/hubInvite/${searchQuery}`, {
      method: 'GET',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
    if(data.status === "success") {
      console.log(data);
      setMembers(data?.data)
      setMembersLoading(false)
    } else {
      // setOpenSnackbar(true);
    }
  });
}

  const inviteMember = (user) => {

    console.log(user, modalCtx.details )

    let payload = {
      "userId": user,
      "hubId": modalCtx.details,
      "role": "invited",
      "notification": "invite-sent"
    }

    fetch(process.env.REACT_APP_API_URI + `/v2/hubs/${modalCtx.details}/membership`, {
      method: 'POST',
    mode: 'cors',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authCtx.token
    },
    body: JSON.stringify(payload)
  }).then(response => {
    return response.json();
  }).then(data => {
    if(data.status === "success") {
      setOpenSnackbar(true)
      setInviteSent(true)
      console.log(data);
    } else {
      // setOpenSnackbar(true);
    }
  });
  setInviteSent(false)

  // setTimeout(() => {
  //   setMembers([])
  //   setSearchQuery('')
  // }, 3000);
}


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }
 
  const closeHandle = () => {
		modalCtx.closeModal();
	  }

  const addEmail = (e) => {
    let emailStr = e.target.value
    setEmail(emailStr)
    console.log(emailStr)
  }

  const addToEmailArray = (e) => {
    console.log(e)
    if (e.charCode === 13 && /(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(email)) {
			// getEmail.push(email)
      setEmailList(prev => [...prev, email] );
      setEmail("")
		}
    console.log(emailList)
  }


  const removeFromEmailArray = (index) => {
    console.log("clicked")
    setEmailList((current) =>
    current.filter((_, id) => id !== index)
    );
    console.log(emailList)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl)
    setOpenSnackbar(true)
  }


  return (
    <>
      <div className="invite-team-container">
        <h1>Invite your team</h1>

        <div className="invite-by-link">
          <span>Share via link</span>
          <div className="link">
            <div className="link-box">{shareUrl}</div>
            <div className="link-copy" onClick={() => copyToClipboard()}><img src={Copy} alt="copy icon" /> Copy</div>
          </div>
        </div>

        <div className="invite-members">
          <span>Invite Members</span>
          <div className="search-member">
            <SearchRounded className='search-icon' />
            <input type="text" placeholder='Search frequency members' onChange={({target}) => setSearchQuery(target.value)} />
          </div>
        </div>

        <div className="frequency-members">

        {
          !membersLoading && members.map((member) => (
            <div className="member" key={member.id}>
              <div className="member-profile">
                <div className="member-img">
                  <AvatarChatImage avatarRef={member.avatar} title={member.name} />
                </div>
                <div className="member-deets">
                  <span className='user' >{member.name}</span>
                  <span className='username' >@{member.username}</span>
                </div>
              </div>
              <div className="invite" onClick={() => inviteMember(member.id)}>
                  <Button className='invite-btn'  >Inivite</Button>
              </div>
            </div>
          ))
        }
        {
        members.length > 3 ? <div className="more-btn">
          <KeyboardArrowDown />
        </div> : null 
        }
        {
        membersLoading ? <div className='loading' >
                            <CircularProgress  style={{'color': '#007C89'}} size={60} />
                         </div> : null 
        }
        </div>

        <div className="invite-by-email">
          <span>Invite by Email</span>
          <div className="add-email">
            {
              emailList.map((item, index) => (
              <div className='email' key={index} >
                {item}
                <div className='remove-email' onClick={() => removeFromEmailArray(index)}><CloseRounded className='rm-email' /></div>
              </div>
              ))
            }
            <input type="text" placeholder='Press enter to add new email' value={email} onChange={addEmail} onKeyPress={addToEmailArray} />
          </div>
        </div>

        <div className='send-btn-container' >
          <Button disabled={!emailList.length} className={emailList.length ? "send-btn active" : "send-btn"} >Send</Button>
        </div>
        <CloseRounded onClick={closeHandle} className="close-btn" />
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {inviteSent ? "Invite sent." : "Link copied to clipboad."}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default InviteToNetwork;
