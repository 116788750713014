import React, { createContext, useState } from 'react';

const ArticleContext = createContext({
  details: {},
  setDetails: (setDetails) => {},
});

export function ArticleContextProvider(props) {

  const [details, setDetails] = useState({});

  function setDetailsHandler(data) {
    setDetails(data);
  }



  const context = {
    details: details,
    setDetails: setDetailsHandler,
  };

  return <ArticleContext.Provider value={context}>
    {props.children}
  </ArticleContext.Provider>
}

export default ArticleContext;