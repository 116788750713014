import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//firebase
import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database }  from "../../Components/FirebaseConfig";

//assets
import message from '../../assets/img/overview/chatting.png'
import subNetwork from '../../assets/img/overview/network.png'
import members from '../../assets/img/overview/user-group.png'


//context
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';

//mui
import { CircleRounded, FavoriteRounded  } from '@mui/icons-material/';

//components
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage';
import NetworkImage from '../../Components/Networks/NetworkImage';

//external lib
import moment from 'moment';
import { TooltipIntroduction } from "../TooltipIntroduction";


const Networks = ({ setIsLoading }) => {

  
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);
	const modalCtx = useContext(ModalContext);

	const db = database;

	const [hubData, setHubData] = useState([]);
	const [featuredHubs, setFeaturedHubs] = useState([]);
	const [featuredProfileData, setFeaturedProfileData] = useState([]);
	const [dmData, setDmData] = useState([]);
	const [activityArray, setActivityArray] = useState([]);

	useEffect(() => {
		setActivityArray([])
		fetchDms();
		addDmListener();
	}, [])
	

	useEffect(() => {	
		
		fetch(process.env.REACT_APP_API_URI + '/v2/homev6/' + authCtx.userId + '/0', {
			method: 'GET',
			mode: 'cors',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authCtx.token
			}
		})
		.then(response => response.json())
		.then(data => {
			if (data.status === "success") {
				filterProfileData(data?.data.splice(1, 1))
				filterHubData(data?.data.splice(0, 1))
				setFeaturedHubs(data?.data)
				setIsLoading(false);

			} else {
			}
		});
				
	}, []);

  console.log(featuredHubs)

	const fetchDms = () => {

		fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + '/directMessages', {
				method: 'GET',
				mode: 'cors',
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + authCtx.token
				}
			}).then(response => {
				return response.json();
			}).then(data => {
				if (data.status === "success") {
					// console.log(data.data);
					
					let dms = data.data.sort((a, b) => b.lastTimeStamp - a.lastTimeStamp)
					setDmData(dms);
					// console.log(dms)
					setIsLoading(false);

				} else {
					// setOpenSnackbar(true);
				}
			});
	  }

	// const addDmListener = () => {
	// 	const dbRef = ref(db, '/activity/' + authCtx.userId, orderByChild('timestamp'), limitToLast(5));
	// 	onChildAdded(dbRef, (snapshot) => {
	// 		setActivityArray(prev => [...prev, snapshot.val()])
	// 	});
	// }

	// const snapshotToArray = (snapshot) => {		
	// 	// const returnArr = [];
	// 	// returnArr.push(snapshot?.val());
	// 	// console.log(snapshot?.val());
	// 	// console.log(returnArr)
	// 	// return returnArr;
	// 	let data = snapshot.val(); 
	// 	return data;
	// }

	const addDmListener = () => {
    const dbRef = ref(
      db,
      '/activity/' + authCtx.userId,
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setActivityArray(snapshotToArray(snapshot))
    })
    // console.log(dbRef)
  }

  const snapshotToArray = (snapshot) => {
    // console.log(snapshot)
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

	const timestampToDate = (t) => {
		var time = new Date(t)
		let relTime =  moment(time).fromNow()
		var lastIndex = relTime.lastIndexOf(" ");
		return relTime = relTime.substring(0, lastIndex);
	}

	const filterHubData = (data) => {
		if (data) {
			setHubData(data[0]?.hubs)
		} else {
			console.log("no hub data")
		}
	}

	const filterProfileData = (data) => {
		if (data) {
			setFeaturedProfileData(data[0]?.users)
		} else {
			console.log("no profile data")
		}
	}

	const showModal = (modal, data) => {
		modalCtx.openModal();
		modalCtx.setDetails(modal, data);
	};

  return (
    <div className="home-featured">
      <div className="featured">
        <div className="featured-hubs">
          <div className="featured-hub-header">
            <h1>Featured Networks</h1>
            {/* <div className="view-more">View more</div> */}
          </div>
          <div id="networks-container" className="networks-container">
			  {
				  hubData?.slice(0, 1).map(hub => (
					  <TooltipIntroduction tooltipIndex={2}>
						  <div key={0} className="networks" onClick={() => navigate("/networks/" + hub.id, {replace: true})}>
							  <div className="hub-img">
								  <NetworkImage coverRef={ hub.coverRef } title={hub.name} />
								  {/* <div className="join-btn">
                    <Button>Join</Button>
                  </div> */}
							  </div>
							  <div className="network-text">
								  <h2>{hub.name}</h2>
								  <h4>{hub.headline}</h4>
								  <div className="network-info">
									  <span><img src={message} alt='chat' /> {hub.messageCount} </span>
									  <span><img src={members} alt='chat' /> {hub.subscriberCount} </span>
									  <span><img src={subNetwork} alt='chat' /> {hub.subscriberCount} </span>
								  </div>
							  </div>
						  </div>
					  </TooltipIntroduction>
				  ))
			  }
			  <>
				  {
					  hubData?.slice(1, hubData.length).map((hub, i) => (
						  <div key={i} className="networks" onClick={() => navigate("/networks/" + hub.id, {replace: true})}>
							  <div className="hub-img">
								  <NetworkImage coverRef={ hub.coverRef } title={hub.name} />
								  {/* <div className="join-btn">
                    <Button>Join</Button>
                  </div> */}
							  </div>
							  <div className="network-text">
								  <h2>{hub.name}</h2>
								  <h4>{hub.headline}</h4>
								  <div className="network-info">
									  <span><img src={message} alt='chat' /> {hub.messageCount} </span>
									  <span><img src={members} alt='chat' /> {hub.subscriberCount} </span>
									  <span><img src={subNetwork} alt='chat' /> {hub.subscriberCount} </span>
								  </div>
							  </div>
						  </div>
					  ))
				  }
			  </>

          </div>
        </div>
        <div className="featured-profile">
          <div className="profile-wrapper">
            <div className="profile-title">
              <h1>Featured Profiles</h1>
              {/* <div className="view-more">View more</div> */}
            </div>
            <div className="profile-container">
              {
                featuredProfileData?.map((profile, i) => (
                  <>
                  <div className="profile" key={i}>
                    <div className="profile-img" onClick={() => showModal('userProfile', profile.id)} >
                      <AvatarChatImage avatarRef={profile.avatar} title={profile.name} />
                    </div>
                    <div className="profile-name">
                      <span>{profile.name}</span>
                    </div>
                  </div>
                  </>
                ))
              }
            </div>
          </div>

          <div className="hub-tab-container">
            {
              featuredHubs.map((item, i) => (
                <div className="featured-networks" key={i}>
                  <div className="heading">
                    <h1>{item.name}</h1>
                    {/* <div className="view-more">View more</div> */}
                  </div>
                  <div className="networks-container">
                    { item?.hubs.map((hub, index) => (
                    <div key={index} className={hub.id==91?'networks health-wellness-child' : hub.id==520 ? "networks share-platform-services-child" : "networks"} onClick={() => navigate("/networks/" + hub.id, {replace: true})}>
                      <div className="hub-img">
                        <NetworkImage coverRef={ hub.coverRef } title={hub.name} />
                        {/* <div className="favorite">
                          <FavoriteBorderRounded className='fav-hollow' />
                          <FavoriteRounded className='fav-filled' />
                        </div> */}
                      </div>
                      <div className="network-text">
                        <div className='network-name' >
                          {hub.name}
                          <span className="full-name" >
                            {hub.name}
                          </span>
                        </div>
                        <div className="network-info">
                          <span><img src={message} alt='chat' /> {hub.messageCount} </span>
                          <span><img src={members} alt='chat' /> {hub.subscriberCount} </span>
                          <span><FavoriteRounded style={{color: '#A6A6A6'}} /> {hub.subscriberCount} </span>
                        </div>
                      </div>
                    </div>
                  )) }
                  </div>
                </div>		
              ))
            }
            
          </div>
        </div>
      </div>

      <div className="activities">
        <TooltipIntroduction tooltipIndex={3} position="left">
            <div className="activity-feed">
            <div className="activity-header">
              <h1>Activity Feed</h1>
              <div className="view-more">View more</div>
            </div>
            <div className="activity-container">
              { 
                activityArray?.reverse()?.slice(0, 6)?.map((data, index) => (
                  <div className="activity" key={index}>
                    <div className={data.type == 'directMessage' ? "avatar" : "hub-avatar"}>
                      {data.type === 'hubChat' ? <NetworkImage coverRef={data.imageRef} /> : <AvatarChatImage avatarRef={data.imageRef} />}
                    </div>
                    <div className="activity-details">
                      <div className="user-name">{data.title}</div>
                      <div className="activity-description">
                        <span className='description'>{data.description}</span> 
                        <CircleRounded className='dot' />
                        <div className="time">5 min ago</div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </TooltipIntroduction>
        

        <TooltipIntroduction tooltipIndex={4} position="left">
        <div className="recent-messages">
          <div className="messages-header">
            <h1>Messages Feed</h1>
            <div className="view-more" onClick={() => navigate("/messages/", {replace: true})} >View more</div>
          </div>
          <div className="messages-container">
          {
            dmData.map((data, index) => (
              <div className="message" key={index}>
                <div className="avatar">
                  <AvatarChatImage avatarRef={data.avatar} title={data.userName} />
                  <div className="online"></div>
                </div>
                <div className="messages-details">
                  <div className="user-name">
                    <div className="name">{data.username}</div>
                    <div className="time">{timestampToDate(data.lastTimeStamp)}</div> 
                  </div>
                  <div className="messages-text">
                    {data.lastMessage}
                  </div>
                  {data.unreadCount ? <div className="message-count">{data.unreadCount}</div> : null }
                </div>
              </div>
            )
          )}
          </div>
        </div>
        </TooltipIntroduction>
      </div>

    </div>
  )
}

export default Networks