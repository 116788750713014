/* eslint-disable react/jsx-key */
import { CheckCircle, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, { useState } from 'react'

const TextInput = (props) => {
  const { disabled, label, name, type, value, placeholder, className, error, onKeyDown, onChange, onClick, validate, ...other } = props;

  const [showPassword, setShowPassword] = useState(false)
  const [focused, setFocused] = useState(false);

  return (
    <>
      <div style={label ? { height: '85px' } : null} className={className ? `${className} text-input` : "text-input"} >
        {label ? <div className="form-label">{label}</div> : null}
        <div className="form-input" >
          <input
            type={
              type
                ? showPassword
                  ? 'text'
                  : type
                : "text"
            }
            value={value ? value : ''}
            name={name ? name : ''}
            disabled={disabled ? disabled : false}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onClick={onClick}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholder ? placeholder : ''}
            style={(value?.length != 0 && !focused && error) ? { borderBottom: '2px solid #D63F15' } : null}
            {...other}
            />
          {
            (type !== 'text' || 'email') && (type == 'password')
              ? showPassword
                ? <VisibilityOffOutlined height={25} width={25} onClick={() => setShowPassword(false)} alt="show password" className="show-password" />
                : <VisibilityOutlined height={25} width={25} onClick={() => setShowPassword(true)} alt="show password" className="show-password" />
              : null
          }
          {
            (type == 'text' || 'email') && (type !== 'password') && validate
              ? 
              error || value?.length == 0 
                ? <CheckCircle height={25} width={25} style={{ color: '#CACACA' }} alt="show password" className="show-completion" />
                : <CheckCircle height={25} width={25} style={{ color: '#1AB53F' }} alt="show password" className="show-completion" />
              : null
          }
        </div>
        {/* {
          (!focused && error) || value?.length == 0
            ?null : <div className='form-error'>{error}</div>
        } */}
        {
          value?.length != 0 && !focused && error && <div className='form-error'>{error}</div>
        }
      </div>
    </>
  )
}

export default TextInput