import React, { useState, useEffect, useContext } from 'react';

//firebase
import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database } from "../../Components/FirebaseConfig";

//assets
import dog from '../../assets/img/Cute_dog.jpg'
import Send from '../../assets/img/wave/send.svg'
import Heart from '../../assets/img/wave/heart.svg'
import HeartEmpty from '../../assets/img/wave/heart-empty.svg'
import Comment from '../../assets/img/wave/comment.svg'

//context
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';
import FollowerContext from '../../store/FollowerContext';

//mui
import { ChatBubbleOutline, FavoriteBorder, FavoriteRounded, SendOutlined } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'

//components
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage';
import AvatarImage from '../../Components/Avatar/AvatarImage';
import GetImage from './GetImage'

//external lib
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Posts from './Posts';
import CustomButton from '../../common/CustomButton';


const WaveDiscover = ({ noFriends }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const followerCtx = useContext(FollowerContext);

  const db = database;

  //states
  const [postArray, setPostArray] = useState([]);

  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)
  const [featuredProfileData, setFeaturedProfileData] = useState([]);

  //effects
  useEffect(() => {

    getFeaturedProfiles()
    addPostListener()

  }, [])


  //functions
  //handle modal
  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getFeaturedProfiles = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/homev4/' + authCtx.userId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          filterProfileData(data?.data.splice(1, 1))
        } else {
        }
      });
  }

  //filter profile data
  const filterProfileData = (data) => {
    if (data) {
      setFeaturedProfileData(data[0]?.users)
    } else {
      console.log("no profile data")
    }
  }


  //get posts from firebase
  const addPostListener = () => {
    const dbRef = ref(
      db,
      'waves',
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setPostArray(snapshotToArray(snapshot))
    })
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    // var lastIndex = relTime.lastIndexOf(" ");
    return relTime
  }

  //count likes
  const countLikes = (obj) => {
    if (obj) {
      const likes = Object.keys(obj).length
      return likes ? likes : 0
    }
  }

  //count comments
  const countComments = (obj) => {
    if (obj) {
      const comments = Object.keys(obj).length
      return comments ? comments : 0
    }
  }

  //get tagged users
  const getTaggedUsers = (users) => {
    if (users) {
      return <span className='tagged-users'> <span style={{ fontWeight: '400' }}>with</span> {users[0].name} <span style={{ fontWeight: '400' }}>and</span> {users.length} others </span>
    } else {
      return null
    }
  }

  //get tagged users
  const handlePostMedia = (media) => {
    if (media.length) {
      return <div className="post-img"><Carousel infiniteLoop={true} showStatus={media.length > 1 ? true : false} showIndicators={media.length > 1 ? true : false} >{media.map((item, index) => <GetImage key={index} imageRef={item} />)}</Carousel></div>
    } else {
      return null
    }
  }
  //get tagged users
  const handlePostImage = (imageRef) => {
    if (imageRef) {
      return <div className="post-img"> <GetImage imageRef={imageRef} /> </div>
    } else {
      return null
    }
  }

  // follow unfollow button handler
  const handleFollowUser = (id) => {
    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} />

    if (id === authCtx.userId) return null;
    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }

  // unfollowing a user
  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  //following user
  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }



  //console logs
  // console.log(postArray)
  // console.log(likes)
  // console.log(comments)

  return (
    <>
      <div className="discover-container">
        <div className="posts-container">

          <Posts array={postArray?.reverse()} />

        </div>
        <div className="recommend-container">
          <div className="recommend-people">
            {
              featuredProfileData?.map((data, i) => (
                <div className="friend" key={i}>
                  <div className="profile">
                    <div className="profile-img">
                      <AvatarChatImage avatarRef={data.avatar} title={data.name} />
                    </div>
                    <div className="profile-name">
                      <span className='user'>{data.name}</span>
                      <span className='username'>@{data.username}</span>
                    </div>
                  </div>
                  {handleFollowUser(data.id)}
                </div>
              ))
            }
          </div>
          {/* <div className="recommend-hubs">
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
						</div> */}
        </div>
      </div>
    </>
  )
}

export default WaveDiscover