import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../store/AuthContext';
import ArticleContext from '../store/ArticleContext';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

//scss
import '../Styles/Explore.scss'

const Explore = () => {

	const [isLoading, setIsLoading] 						= useState(true);
	const authCtx 											= useContext(AuthContext);
	const articleCtx 										= useContext(ArticleContext);
	const [topics, setTopics] 								= useState([]);

	let navigate = useNavigate(); 

  const routeChange = (data) =>{ 
	console.log(data)
	articleCtx.setDetails(data)
    let path = `/article/${data.id}`; 
    navigate(path);
  }
	

	useEffect(() => {	
		fetch(process.env.REACT_APP_API_URI + '/v2/explore', {
					method: 'GET',
					mode: 'cors',
					headers: { 
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + authCtx.token
					}
				}).then(response => {
					return response.json();
				}).then(data => {
					if (data.status === "success") {
						console.log(data.data)
            			setTopics(data?.data)
						setIsLoading(false);
					} else {
						// setOpenSnackbar(true);
					}
				});
				
	}, [authCtx.userId]);

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

console.log(topics)

		if (isLoading) {
			return <div className="container assessment loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
		}

	return (
		<div className="explore-container">
			<div className="explore">
					<div className="topic-container">
						{
							topics.map((item, i) => (
								<div className="topic" key={i} >
									<div className="heading">
										<h1>{item.topic}</h1>
									</div>
									<div className="article-container">
										{ item?.articles.map((article, i) => (
										<div 
										key={i} 
										className="article" 
										// onClick={() => routeChange(article)} 
										onClick={() => openInNewTab(article.url)} 

										>
											<div className="article-img">
												<img src={article.image} alt="article" />
											</div>
											<div className="article-name">
												<span>{article.title}</span>
											</div>
											{/* <div className="article-date">
												<span>Created At : {moment(article.created_at.split(" ")[0]).format("MMMM d, YYYY")}</span>
											</div> */}
										</div>
									)) }
									</div>
								</div>		
							))
						}
						
					</div>
				</div>
      </div>
	);
}
export default Explore;