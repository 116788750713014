import React from 'react'

const CustomButton = ({ children, className, onClick, disabled,  style, ...options }) => {
  return (
    <>
      <button {...options} style={style} onClick={onClick} disabled={disabled} className={`f-btn ${className}`} >
        {children}
      </button>
    </>
  )
}

export default CustomButton