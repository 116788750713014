import { useState, useContext, useEffect } from 'react';
import { CloseRounded } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import style from "./PrivacyPolicy.module.scss";

import AuthContext from '../../store/AuthContext.js';

import AvatarImage from '../../Components/Avatar/AvatarImage';
import CircleIcon from "../../assets/img/banner/circle1.png";
import PentagonIcon from "../../assets/img/banner/pentagon.png";
import PolarIcon from "../../assets/img/banner/polar.png";
import SpiralIcon from "../../assets/img/banner/spiral.png";
import TriangleIcon from "../../assets/img/banner/triangle1.png";
import Logo from '../../assets/img/home/logo.png';
import FooterImage from '../../assets/img/footer/footer-img.png';

export const PrivacyPolicy = () => {


	const authCtx = useContext(AuthContext);
	const [openMenu, setOpenMenu] = useState(false)

	useEffect(() => {
		document.body.scrollTo(0, 0);
	}, []);


	return <div className={style.wrapper}>
		<div className={style.main}>
			<div
				className={'home-container ' + style.wrapper}
				style={openMenu ? {overflow: "hidden"} : null}
			>

				<section className="nav-container">
					<nav>
						<div className="logo"><img src={Logo} alt="logo" /></div>

						<div className="links">
							{
								authCtx.isLoggedIn ? (
									<div className="nav">
										<div className='nav-links'>
											<NavLink to="/networks"><span>Networks</span></NavLink>
											<NavLink to="/explore"><span>Explore</span></NavLink>
											<NavLink to="/messages"><span>Messages</span></NavLink>
										</div>
										<div className="profile">
											<span>{`Hi ${authCtx.userName}`}</span>
											<AvatarImage />
										</div>
									</div>
								) : (
									<div className='link-container'>
										<NavLink to="/login"><button className='login-button'>Login</button></NavLink>
										<NavLink to="/register"><button className='register-button'>Sign Up</button></NavLink>
									</div>
								)
							}
						</div>
						<MenuRoundedIcon className='menu-icon' style={openMenu ? {display: "none"} : null} onClick={() => setOpenMenu(true)} />
						<div className={openMenu ? "open mobile-links" : "mobile-links close"}>
							<CloseRounded onClick={() => setOpenMenu(false)} />
							{
								authCtx.isLoggedIn ?

									<div className="nav">
										<div className='nav-links'>
											<NavLink to="/home"><span>Home</span></NavLink>
											<NavLink to="/networks"><span>Networks</span></NavLink>
											<NavLink to="/explore"><span>Explore</span></NavLink>
											<NavLink to="/messages"><span>Messages</span></NavLink>
										</div>
										<div className="profile">
											<span>{`Hi ${authCtx.userName}`}</span>
											<AvatarImage />
										</div>
									</div>
									:
									<div className='link-container'>
										<NavLink to="/login"><button>Login</button></NavLink>
										<NavLink to="/register"><button>Register for Free</button></NavLink>
									</div>
							}
						</div>
					</nav>
				</section>

				<main className={"main-content " + style.wrapper__main}>
					<div className={"banner-bg " + style.wrapper__main__bg} >
						<svg width={"101%"} height={"100%"} viewBox="1 0 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<linearGradient gradientUnits="userSpaceOnUse" x1="250.199" y1="-0.089" x2="250.199" y2="273.474" id="gradient-1" gradientTransform="matrix(-0.000795, -0.999999, 1.833572, -0.001457, -0.237588, 380.700831)">
									<stop offset="0" style={{ stopColor: 'rgb(28, 157, 164)'}}/>
									<stop offset="0.245" style={{ stopColor: 'rgb(64, 110, 170)'}}/>
									<stop offset="0.496" style={{ stopColor: "rgb(145, 76, 132)"}}/>
									<stop offset="0.768" style={{ stopColor: 'rgb(199, 74, 90)'}}/>
									<stop offset="1" style={{ stopColor: "rgb(243, 132, 40)"}}/>
								</linearGradient>
							</defs>
							<path
								style={{
									paintOrder: 'fill',
									fill: 'url(#gradient-1)',
									fillRule: 'nonzero'
								}}
								d="M 500 271.166 L 500.399 0 L 0.399 -0.089 L 0 273.474 C 0 273.474 23.563 238.729 40.735 240.726 C 57.907 242.723 62.301 256.302 77.476 259.497 C 92.651 262.692 102.236 222.756 116.613 217.164 C 130.99 211.572 149.761 216.765 166.134 260.295 C 182.507 303.825 192.492 201.588 214.457 189.607 C 236.422 177.626 245.608 280.263 264.777 273.074 C 283.946 265.885 285.944 192.403 300.72 187.212 C 315.496 182.021 334.265 208.378 338.658 212.771 C 343.051 217.164 347.844 224.353 361.023 240.727 C 374.202 257.101 388.978 278.666 414.936 268.681 C 440.894 258.696 431.592 261.124 458.067 246.317 C 484.542 231.51 500 271.166 500 271.166 Z"/>
						</svg>
						<img className={"position-absolute"} style={{ left: '-75px', bottom: '55%'}} src={SpiralIcon} alt=""/>
						<img className={"position-absolute"} style={{ right: '-95px', top: '0'}} src={SpiralIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '8%', top: '100px'}} src={TriangleIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '10%', top: '100px'}} src={TriangleIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '64px', left: '35%', top: '80px'}} src={PolarIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '64px', right: '20%', top: '280px'}} src={PolarIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-20deg)', width: '64px', left: '25%', top: '35%'}} src={PolarIcon} alt=""/>

						<img className={"position-absolute"} style={{ width: '60px', left: '42%', top: '470px'}} src={CircleIcon} alt=""/>
						<img className={"position-absolute"} style={{ width: '60px', right: '9%', top: '490px'}} src={CircleIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '12%', top: '460px'}} src={PentagonIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '25%', top: '650px'}} src={PentagonIcon} alt=""/>
					</div>
					<div className={style.wrapper__main__content}>
						<div className={style.title}>Privacy Policy</div>
						<div className={style.updateInfo}>Last Updated June 23rd, 2022</div>
						<div className={style.form}>
							<div className={style.policy}>
								<div className={style.policy__title}>ACKNOWLEDGEMENT AND ACCEPTANCE OF TERMS</div>
								<p>The Frequency Platform is a product owned by SpokeHub, Inc. The terms of this Privacy Policy are applicable to both the
									Frequency Platform as well as SpokeHub, Inc.
									The Frequency Platform (“Frequency” or “we”) is committed to protecting your privacy. This privacy policy (“Privacy
									Policy”) sets forth our current privacy practices with regard to the information we collect when you are utilizing
									Frequency‘s Services (as defined in Frequency’s Terms of Use) and related platforms (i.e., website, mobile application,
									etc.). By utilizing Frequency’s Services, you acknowledge that you fully understand this Privacy Policy and freely consent
									to the information collection and use practices described in this Privacy Policy.</p>
							</div>
							<div className={style.policy}>
								<div className={style.policy__title}>PARTICIPATING MERCHANT POLICIES</div>
								<p>Related services and offerings with links from this website, including vendor sites, have their own privacy policies that can
									be viewed by clicking on the corresponding links within each respective website. Online merchants and others who
									participate in Frequency Services are encouraged to participate in industry privacy initiatives and to take a responsible
									attitude towards consumer privacy. However, since we do not have direct control over the policies or practices of
									participating merchants and other third parties, we are not responsible for the privacy practices or contents of those sites.
									We recommend and encourage that you always review the privacy policies of merchants and other third parties before
									you provide any personal information or complete any transaction with such parties.</p>
							</div>
							<div className={style.summary}>
								<div className={style.summary__title}>INFORMATION WE COLLECT AND HOW WE USE IT</div>
								<p>Frequency’s website collects certain information from and about its users in three ways: directly from our Web Server
									logs, directly from the user, and with Cookies.</p>
								<p>
									<span className={style.summary__subtitle}>1. Web Server Logs.</span><br/><p>When you visit our Website, we may track information to administer the site and analyze its usage. Examples of
									information we may track include:</p>
									<ul>
										<li>Your Internet protocol address.</li>
										<li>The kind of browser or computer you use.</li>
										<li>Number of links you click within the site.</li>
										<li>State or country from which you accessed the site.</li>
										<li>Date and time of your visit.</li>
										<li>Name of your Internet service provider.</li>
										<li>Web page you linked to our site from.</li>
										<li>Pages you viewed on the site.</li>
									</ul></p>
								<p>
									<span className={style.summary__subtitle}>2. Use of Cookies</span>
									<br/>
									<p>Frequency may use cookies to personalize or enhance your user experience. A cookie is a small text file that is placed on
									your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
									Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.</p>
									<p>One of the primary purposes of cookies is to provide a convenience feature to save you time. For example, if you
									personalize a web page, or navigate within a site, a cookie helps the site to recall your specific information on subsequent
									vi sits. Hence, this simplifies the process of delivering relevant content and eases site navigation by providing and saving
									your preferences and login information as well as providing personalized functionality.</p>
									<p>Frequency reserves the right to share aggregate d site statistics with partner companies but does not allow other
									companies to place cookies on our website unless there is a temporary, overriding customer value (such as merging a
									site that relies on third – party cookies into our website).</p>
									<p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually
										modify your browser setting to decline cookies. If you reject cookies by changing your browser settings then be aware
										that this may disable some of the functionality on our Website.</p>
								</p>
								<p><span className={style.summary__subtitle}>3. Personal Information Users</span>
									<br/>
									<p>Visitors to our website can register to purchase services. When you register, we will request some personal information
										such as name, address, email, telephone number or facsimile number, account number and other relevant information. If
										you are purchasing a service, we will request financial information. Any financial information we collect is used only to bill
										you for the services you purchased. If you purchase by credit card, this information m ay be forwarded to your credit card
										provider. For other types of registrations, we will ask for the relevant information. You may also be asked to disclose
										personal information to us so that we can provide assistance and information to you. For example, such data may be
										warranted in order to provide online technical support and troubleshooting.</p>
									<p>We may rent, sell, or share personal or transaction information with other people or non – affiliated companies for certain
									purposes.</p>
									<ul>
										<li>Service Providers. Slice may disclose your information, including personal information and transaction information, to
service providers for the purposes of managing, providing, and improving the Services, analyzing usage of the Services,
and for marketing purposes.</li>
										<li> Vendors and Suppliers. We may share information, including personal and transaction information, with vendors and
suppliers to collect additional information about you from them or to help us provide anonymized data to others.</li>
										<li>Anonymous Use and Sharing. We may share with others anonymized transaction information for market research, data
modeling, and analytics. Such information will not include personal information but may include unique identifiers such as
device identifiers or “hashes” of email addresses.</li>
										<li>Third-Party Support and Usage. We allow third parties to use the Services as part of their services. If you authorize a
third party using our Services to access and scan your email, the third party’s treatment of the data in your email,
including any personal information accessed by the third party, will be subject to that third party’s terms of service and
privacy policies, even if you have also directly signed up for a Slice account.</li>
										<li>For Legal Reasons. We may access, preserve, and disclose collected information if we believe doing so is required or
appropriate to comply with law enforcement requests and legal processes, such as a court order or subpoena; respond to
your requests; or protect the rights, property, or safety of you, Slice, or others.</li>
										<li>Merger or Sale. If all or a portion of Frequency or its assets are sold or transferred, personal information and other
information we have collected may be treated as an asset and transferred in accordance with this Policy.</li>
										<li>Your Permission. We may also disclose your personal information with your permission.</li>
										<li>A developer will act on objectionable content reports within 24 hours by removing content and ejecting the user who
provided the offending content.</li>
									</ul></p>
								<p><span className={style.summary__subtitle}>4. Data Subject Rights</span>
									<br/>
									<p>(Chapter 3 of the GDPR) each Data Subject has eight rights. These are:</p>
									<ol>
										<li>The right to be informed; This means anyone processing your personal data must make clear what they are
processing, why, and who else the data may be passed to.</li>
										<li>The right of access; this is your right to see what data is held about you by a Data Controller.</li>
										<li>The right to rectification; the right to have your data corrected or amended if what is held is incorrect in some way.</li>
										<li>The right to erasure; under certain circumstances, you can ask for your personal data to be deleted. This is also
called the Right to be Forgotten’. This would apply if the personal data is no longer required for the purposes it
was collected for, or your consent for the processing of that data has been withdrawn, or the personal data has
been unlawfully processed.</li>
										<li>The right to restrict processing; this gives the Data Subject the right to ask for a temporary halt to processing of
personal data, such as in the case where a dispute or legal case has to be concluded, or the data is being
corrected.</li>
										<li>The right to data portability; a Data Subject has the right to ask for any data supplied directly to the Data
Controller by him or her, to be provided in a structured, commonly used, and machine-readable format.</li>
										<li> The right to object; the Data Subject has the right to object to the further processing of their data which is
inconsistent with the primary purpose for which it was collected, including profiling, automation, and direct
marketing.</li>
										<li>Rights in relation to automated decision making and profiling; Data Subjects have the right not to be subject to a
decision-based solely on automated processing.</li>
									</ol>
								</p>
								<div className={style.policy}>
									<div className={style.policy__title}>USE OF WEB BEACONS OR GIF FILES</div>
									<p>Frequency’s website may contain electronic images known as web beacons – sometimes also called single-pixel gifs –
										that allow Frequency to count users who have visited those pages and to deliver co-branded services. Frequency may
										include web beacons in promotional e-mail messages or newsletters in order to determine whether messages have been
										opened and acted upon.</p>
									<p>Some of these Web beacons may be placed by third-party service providers to help determine the effectiveness of our
										advertising campaigns or email communications. These Web beacons may be used by these service providers to place a
										persistent cookie on your computer. This allows the service provider to recognize your computer each time you visit
										certain pages or emails and compile anonymous information in relation to those page views, which in turn enables us and
										our service providers to learn which advertisements and emails bring you to our website and how you use the site.
										Frequency prohibits Web beacons from being used to collect or access your personal information.</p>
								</div>
								<div className={style.policy}>
									<div className={style.policy__title}>ACCESSING WEB ACCOUNT INFORMATION</div>
									<p>We will provide you with the means to ensure that personally identifiable information in your web account file is correct
										and current. You may review this information by sending an email to our support attendant –</p>
									<p><a href="mailto:info@frequencyworld.co">info@frequencyworld.co</a></p>
								</div>
								<div className={style.policy}>
									<div className={style.policy__title}>CHANGES TO THIS STATEMENT</div>
									<p>Frequency has the discretion to occasionally update this Privacy Policy. When we do, we will also revise the “updated”
										date at the top of this Privacy page. We encourage you to periodically review this Privacy Policy to stay informed about
										how we are helping to protect the personal information we collect. Your continued use of the service constitutes your
										agreement to this Privacy Policy and any updates.</p>
								</div>
								<div className={style.policy}>
									<div className={style.policy__title}>CHANGES TO THIS STATEMENT</div>
									<p>Frequency has the discretion to occasionally update this Privacy Policy. When we do, we will also revise the “updated”
										date at the top of this Privacy page. We encourage you to periodically review this Privacy Policy to stay informed about
										how we are helping to protect the personal information we collect. Your continued use of the service constitutes your
										agreement to this Privacy Policy and any updates.</p>
								</div>
								<div className={style.policy}>
									<div className={style.policy__title}>CONTACTING US</div>
									<p>If you have questions regarding our Privacy Policy, its implementation, failure to adhere to this Privacy Policy, accessing
										or deleting your personal data, and/or our general practices, please contact us at</p>
									<p><a href="mailto:info@frequencyworld.co">info@frequencyworld.co</a></p>
									<p>Frequency will use commercially reasonable efforts to promptly respond and resolve any problem or question.</p>
								</div>
							</div>
						</div>
					</div>
				</main>


			</div>
			<footer className={"footer-section " + style.footer}>
				<div className="custom-container">
					<div className="footer-block">
						<img className={"footer-image"} src={FooterImage} alt=""/>
						<div className={"footer-link"}>
							<Link to={"/privacy-policy"}>Privacy Policy</Link>
							<Link to={"/terms-of-use"}>Terms of use</Link>
						</div>
					</div>
					<div className={"footer-copyright"}>Powered by SpokeHub, Inc. 2022 <sup>&copy;</sup></div>
				</div>
			</footer>
		</div>
	</div>
}

