import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import CustomButton from '../../../common/CustomButton'
//mui
import { styled } from '@mui/material/styles'
import { GppMaybeOutlined, Public, Close, DeleteOutlineOutlined } from '@mui/icons-material'
import { FormControl, RadioGroup, FormControlLabel, Radio, Switch, Modal, Box, Button, Typography, Alert, Snackbar, CircularProgress } from '@mui/material'


//context
import AuthContext from '../../../store/AuthContext'
import ModalContext from '../../../store/ModalContext'


const EditNetworkAccess = () => {

  const authCtx = useContext(AuthContext)
  const modalCtx = useContext(ModalContext)


  //state
  const [fees, setFees] = useState('');
  const [recurringType, setRecurringType] = useState('weekly')
  const [features, setFeatures] = useState([])
  const [featureItem, setFeatureItem] = useState("")
  const [addOpen, setAddOpen] = useState(false);
  const [oneTimeFeatures, setOneTimeFeatures] = useState([])
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessageText, setSnackBarMessageText] = useState("Please enter a title for the Network.")
  const [deleteopen, setDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1)
  const [hubImageRef, setHubImageRef] = useState(modalCtx.details.coverRef)
  const [hubTitle, setHubTitle] = useState(modalCtx.details.title)
  const [hubType, setHubType] = useState(modalCtx.details.hubType)
  const [topicId, setTopicId] = useState(modalCtx.details.topicId)
  const [headline, setHeadline] = useState(modalCtx.details.headline)
  const [parentId, setParentId] = useState(modalCtx.details.parentId)
  const [description, setDescription] = useState(modalCtx.details.description)
  const [permissions, setPermissions] = useState(modalCtx.details.permissions)
  const [paid, setPaid] = useState(modalCtx.details.paid)
  const [paymentFee, setPaymentFee] = useState(modalCtx.details.paymentFee)
  const [paymentType, setPaymentType] = useState(modalCtx.details.paymentType)
  const [paymentRecurring, setPaymentRecurring] = useState(modalCtx.details.paymentRecurring)

  const [updating, setUpdating] = useState(false)


  useEffect(() => {
    setHubImageRef(modalCtx.details.coverRef)
    setHubTitle(modalCtx.details.title)
    setHubType(modalCtx.details.hubType)
    setTopicId(modalCtx.details.topicId)
    setHeadline(modalCtx.details.headline)
    setParentId(modalCtx.details.parentId)
    setDescription(modalCtx.details.description)
    setPermissions(modalCtx.details.permissions)
    setPaid(modalCtx.details.paid)
    setPaymentFee(modalCtx.details.paymentFee)
    setPaymentType(modalCtx.details.paymentType)
    setPaymentRecurring(modalCtx.details.paymentRecurring)
  }, [modalCtx])




  //functions
  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);



  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const closeHandle = () => {
    modalCtx.setDetails('', {});
    modalCtx.closeModal();
  }

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  function handlePaymentTypeChange(e) {
    setPaymentType(e.target.value);
  }

  function handleRecurringType(e) {
    setRecurringType(e.target.value);
  }

  const updateAccess = async () => {

    const payload = {
      hubType: hubType,
      permissions: permissions,
      paid: paid ? "yes" : "no",
    }
    if (payload.paid === "yes") {
      payload.paymentFee = Number(fees);
      payload.paymentType = paymentType;
      payload.paymentRecurring = paymentType === "recurring" ? recurringType : '';
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/networks/${modalCtx.details.id}`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        body: JSON.stringify(payload)
      })

      const data = await res.json()
      console.log(data);

      if (data.status === 'success') {
        let featuresArr = [];
        if (paymentType === "recurring") {
          features.forEach(e => featuresArr.push(e.feature));
        }
        else {
          oneTimeFeatures.forEach(e => featuresArr.push(e.feature));
        }
        const payload = {
          features: featuresArr
        }
        console.log(payload);

        try {
          const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/networks/${modalCtx.details.id}/feature`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
            body: JSON.stringify(payload)
          })

          const data = await res.json()
          console.log(data);

          if (data.status === 'success') {
          } else if (data.status === 'failure') {
            setOpenSnackbar(true)
            setSnackBarMessageText(data.message)
          }

        } catch (error) {
          console.log('error while creating network : ', error)
        }
      } else if (data.status === 'failure') {
        setOpenSnackbar(true)
        setSnackBarMessageText(data.message)
      }

    } catch (error) {
      console.log('error while creating network : ', error)
    }
  }

  const updateNetwork = () => {

    setUpdating(true)

    const payload = {
      "parentHubId": parentId,
      "title": hubTitle,
      "headline": headline,
      "description": description,
      "topicId": topicId,
      "type": hubType,
      "coverRef": hubImageRef,
      "permissions": permissions,
      "paid": paid,
      "paymentFee": paymentFee,
      "paymentType": paymentType,
      "paymentRecurring": paymentRecurring,
    };

    fetch(process.env.REACT_APP_API_URI + `/v2/networks/${modalCtx.details.id}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data?.status === "success") {
        console.log("Data Added Successfully")
        setUpdating(false)
        closeHandle()
      } else {
        setUpdating(false)
      }
    });
  }

  const handleNetworkRestriction = (event) => {
    setPermissions(event.target.value);
  }

  const onAddFeature = () => {
    if (paymentType == "recurring") {
      if (featureItem.length === 0) {
        return
      }
      const newId = features.length === 0 ? 1 : Math.max(...features.map(item => item.id)) + 1;
      var obj = { id: newId, feature: featureItem };
      setFeatureItem("")
      setFeatures(features.concat(obj))
      handleAddClose()
    }
    else {
      if (featureItem.length === 0) {
        return
      }
      const newId = oneTimeFeatures.length === 0 ? 1 : Math.max(...oneTimeFeatures.map(item => item.id)) + 1;
      var obj = { id: newId, feature: featureItem };
      setFeatureItem("")
      setOneTimeFeatures(oneTimeFeatures.concat(obj))
      handleAddClose()
    }
  }

  const feature_modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const feature_modal_attributes_styles = [
    {
      marginTop: "1rem",
      width: "100%",
      height: "40px",
      border: "none",
      borderBottom: "2px solid #ccc",
      padding: "0 0.5rem",
      transition: "250ms ease",
      outline: "none"
    },
    {
      marginLeft: "auto",
      marginTop: "2rem",
      fontWeight: 600,
      textTransform: "capitalize",
      width: "100%",
      height: "40px",
      background: "#cd4a4a",
      color: "#fff"
    },
    {
      fontWeight: 600,
      marginTop: "1rem",
      textTransform: "capitalize",
      width: "100%",
      height: "40px",
      color: "#cd4a4a",
      border: "2px solid #cd4a4a",
    }
  ];

  const onDeleteFeature = (idToDelete) => {
    if (paymentType == "recurring") {
      const newFeatures = features.filter(item => item.id !== idToDelete);
      setFeatures(newFeatures)
      handleDeleteClose()
    }
    else {
      const newFeatures = oneTimeFeatures.filter(item => item.id !== idToDelete);
      setOneTimeFeatures(newFeatures)
      handleDeleteClose()
    }
  }

  return (
    <div className="create-network-modal-container">
      <div className="create-network">
        <div className="header-steps-container">
          <div className='modal-header'>
            <h2>Edit Network Access</h2>
            <Close onClick={closeHandle} style={{ cursor: "pointer" }} className='close' />
          </div>
        </div>
        <div className="steps" style={{ textAlign: "center" }}>
          <h3 style={{ padding: "1rem 0", backgroundColor: "#FFDFDF", color: "#CD4A4A" }}>Access</h3>
        </div>
        <div className="create-network-all-steps-content-container">
          <div className="network-access-container">
            <div className="network-access-inner-container">
              <div className="network-access">
                <div className={hubType === "public" ? "access-public active" : "access-public"} onClick={() => setHubType("public")}><Public /> Public</div>
                <div className={hubType === "private" ? "access-private active" : "access-private"} onClick={() => setHubType("private")}><GppMaybeOutlined /> Private</div>
              </div>
            </div>
            {hubType === "public" && <div className="network-type-container">
              <FormControl >
                <label style={{ fontWeight: "600", textAlign: "center" }} id="network-type-group-label">How may users join this network ?</label>
                <RadioGroup
                  aria-labelledby="network-type-group-label"
                  defaultValue="open"
                  name="radio-buttons-group"
                  onChange={handleNetworkRestriction}
                >
                  <FormControlLabel value="open" control={<Radio style={{ color: "#CD4A4A" }} />} label="Open network, anyone may join without restriction." />
                  <FormControlLabel value="invite" control={<Radio style={{ color: "#CD4A4A" }} />} label="By request only but any member may invite." />
                </RadioGroup>
              </FormControl>
            </div>}
            <div className="paid-network-toggle-container">
              <h1 className="paid-network-heading">
                Is this a paid network ?
              </h1>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.25rem"
              }} className='paid-switch-container'>
                <IOSSwitch checked={paid == "yes" ? true : false} onChange={(e) => setPaid(e.target.checked ? "yes" : "no")} />
                {paid == "yes" && <span style={{ fontSize: "20px" }}>Paid</span>}
              </div>
              <div className="paid-network-content-container">
                <Modal
                  sx={{ zIndex: 1000000 }}
                  open={addOpen}
                  onClose={handleAddClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={feature_modal_style}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Feature
                      </Typography>
                      <Close onClick={handleAddClose} />
                    </div>
                    <input style={feature_modal_attributes_styles[0]} type="text" className="feature-input" placeholder="Feature" onChange={(e) => setFeatureItem(e.target.value)} />
                    <Button onClick={onAddFeature} style={feature_modal_attributes_styles[1]} className="add-btn">
                      Add Feature
                    </Button>
                    <Button onClick={handleAddClose} style={feature_modal_attributes_styles[2]} className="cancel-btn">
                      Cancel
                    </Button>
                  </Box>
                </Modal>
                <Modal sx={{ zIndex: 1000000 }} open={deleteopen} onClose={handleDeleteClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                  <Box sx={feature_modal_style}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure you want to delete this feature ?
                      </Typography>
                      <Close onClick={handleDeleteClose} />
                    </div>
                    <Button onClick={() => onDeleteFeature(idToDelete)} style={feature_modal_attributes_styles[1]} className="add-btn">
                      Delete
                    </Button>
                    <Button onClick={handleDeleteClose} style={feature_modal_attributes_styles[2]} className="cancel-btn">
                      Cancel
                    </Button>
                  </Box>
                </Modal>
                {
                  paid == "yes" ? <>
                    <input type="number" name="" placeholder='Fee' id="" value={fees} onChange={(e) => setFees(e.target.value)} />
                    <div className="payment-type-container">
                      <div className="recurring-one-time-option-container">
                        <RadioGroup className='payment-type-radiogroup-container' defaultValue={'recurring'} value={paymentType} onChange={handlePaymentTypeChange}>
                          <FormControlLabel value="recurring" control={<Radio style={{ color: "#CD4A4A" }} />} label="Recurring" />
                          <FormControlLabel value="once_off" control={<Radio style={{ color: "#CD4A4A" }} />} label="One time" />
                        </RadioGroup>
                      </div>
                      {
                        paymentType === "recurring" ?
                          <div className="recurring-payment-container">
                            <RadioGroup className="recurring-payment-type-radiogroup-container" onChange={handleRecurringType} value={recurringType}>
                              <div className='recurring-payment-type-flexbox'>
                                <h1 className="recurring-payment-type-heading">
                                  Weekly
                                </h1>
                                <FormControlLabel value="weekly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                              </div>
                              <div className='recurring-payment-type-flexbox'>
                                <h1 className="recurring-payment-type-heading">
                                  Fortnightly
                                </h1>
                                <FormControlLabel value="Fortnightly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                              </div>
                              <div className='recurring-payment-type-flexbox'>
                                <h1 className="recurring-payment-type-heading">
                                  Yearly
                                </h1>
                                <FormControlLabel value="Yearly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                              </div>
                              <div className='recurring-payment-type-flexbox'>
                                <h1 className="recurring-payment-type-heading">
                                  Monthly
                                </h1>
                                <FormControlLabel value="Monthly" className='recurring-payment-frequency-option' control={<Radio style={{ color: "#CD4A4A" }} />} />
                              </div>
                              <div className="features-container">
                                <h3 className="features-heading">Features</h3>
                                <div className="add-feature-button-container">
                                  <CustomButton onClick={handleAddOpen}> <span>+</span> Add Feature</CustomButton>
                                </div>
                              </div>
                              <div className="added-features-container">
                                {features.map((feature, i) => {
                                  return (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='feature-item' key={i}>
                                      <p className="features-text">{Object.values(feature)[1]}</p>
                                      <div className="delete-feature-button-container">
                                        <DeleteOutlineOutlined style={{ cursor: "pointer" }} onClick={() => { handleDeleteOpen(); setIdToDelete(Object.values(feature)[0]) }} />
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </RadioGroup>
                          </div>
                          :
                          <div className="one-time-payment-container">
                            <div className="features-container">
                              <h3 className="features-heading">Features</h3>
                              <div className="add-feature-button-container">
                                <CustomButton onClick={handleAddOpen}> <span>+</span> Add Feature</CustomButton>

                              </div>

                            </div>
                            <div className="added-features-container">
                              {
                                oneTimeFeatures.map((feature, i) => {
                                  return (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='feature-item' key={i}>
                                      <p className="features-text">{Object.values(feature)[1]}</p>
                                      <div className="delete-feature-button-container">
                                        <DeleteOutlineOutlined style={{ cursor: "pointer" }} onClick={() => { handleDeleteOpen(); setIdToDelete(Object.values(feature)[0]) }} />
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                      }

                    </div>
                  </> : <></>
                }
              </div>
            </div>
          </div>
        </div>


        <div className="navigate-btn-container" style={{ justifyContent: "center" }}>
          <CustomButton className="f-btn" onClick={() => !updating && updateNetwork()} >
            {
              updating
                ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                : "Save Changes"
            }
          </CustomButton>
        </div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
          {snackBarMessageText}
        </Alert>
      </Snackbar>

    </div>
  )
}

export default EditNetworkAccess