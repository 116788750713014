import { useContext, useState, useEffect, useRef, useCallback } from 'react';

//react crop
import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Base64 } from 'js-base64';
import 'react-image-crop/src/ReactCrop.scss';

//mui
import { Button, CircularProgress, Slider } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

//dropzone
// import Dropzone from './Dropzone';

// import { canvasPreview } from './CropImage.js';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageCropper = ({ image, aspectRatio1, aspectRatio2  }) => {

  const ref = useRef()
  const cropRef = useRef()


  const [imageType, setImageType] = useState()
  // const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 200,
    height: 50
  })
  const [zoom, setZoom] = useState(1);
  const [completedCrop, setCompletedCrop] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  // const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%",
      // width: 90,
      height: 90,
      aspect: aspectRatio1 / aspectRatio2
    }
  );
  const [imageRef, setImageRef] = useState(null);

  // get cropped image 
  const cropImage = async () => {
    try {
      console.log("Are we here");
      console.log(ref.current);
      console.log(crop.width);
      console.log(crop.height);
      console.log(cropConfig);
      if (ref.current && cropConfig.width && cropConfig.height) {
        const croppedImage = await getCroppedImage(
          ref.current,
          cropConfig,
          "croppedImage.jpeg"
        );
        setCroppedImage(croppedImage);
      }
    } catch (e) {
      console.error(e)
    }
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    console.log(cropConfig.x);

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return canvas.toDataURL('image/png');
  }


  // this gets a file object which is passed to firebase
  const baseToFile = (base) => {    
    if (base) {
      let arr = base?.split(",")    
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]
  
      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)
    
      while(n--)  {
        dataArr[n] = dataStr.charCodeAt(n)
      }
    
      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, {type: mime})
      return file
    } else {
      return null
    }
  }


  return (
    <div className='react-crop-container'>
        <div className='easy-crop'>
            <ReactCrop
              ref={cropRef}
              crop={cropConfig}
              ruleOfThirds
              aspect={aspectRatio1 / aspectRatio2}
              // onImageLoad={(imageRef) => setImageRef(imageRef)}
              // onComplete={(cropConfig) => cropImage(cropConfig)}
              onChange={(cropConfig) => setCropConfig(cropConfig)}
              crossorigin="anonymous" // to avoid CORS-related problems
            >
              <img src={image} ref={ref} alt='crop'/>
            </ReactCrop>
        </div>
        {/* <div className='crop-btns'>
          <div className="slider">
            <h3> Zoom </h3>
            <Slider value={zoom} min={-1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom) => setZoom(zoom)} />
          </div>
        </div> */}
    </div>
  )
}

export default ImageCropper;
