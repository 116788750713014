import React, { useContext, useState, useEffect } from 'react';

//context
import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import FollowerContext from '../../../store/FollowerContext.js';
import AvatarImage from '../../Avatar/AvatarImage.jsx';
import CustomButton from '../../../common/CustomButton.jsx';

//scss
import '../../../Styles/Modal.scss'
import { Button, CircularProgress } from '@mui/material';
import AvatarChatImage from '../../Avatar/AvatarChatImage.jsx';
import { Close } from '@mui/icons-material';


const TaggedPeopleModal = () => {

  //context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const followerCtx = useContext(FollowerContext);

  //states
  const [userData, setUserData] = useState();
  //states
  const [taggedProfileData, setTaggedProfileData] = useState([]);

  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)

  useEffect(() => {

    setTaggedProfileData(modalCtx.details)

    // fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + "/profile", {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' + authCtx.token
    //   }
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status === "success") {
    //     setUserData(data.data)
    //   } else {
    //   }
    // });

  }, [])

  //close modal
  const closeHandle = () => {
    modalCtx.closeModal();
  }

  //check for following users post only
  const checkFollowers = (array) => {
    let sortedArray = []
    if (array && followerCtx?.following) {
      for (let i = 0; i < array.length; i++) {
        if (followerCtx?.following.some((el) => el.follow_user_id === array[i]?.creatorId)) {
          sortedArray.push(array[i])
        }
      }
    }
    return sortedArray.reverse()
  }

  // follow unfollow button handler
  const handleFollowUser = (id) => {
    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} />

    if (id === authCtx.userId) return null;
    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }
  // unfollowing a user
  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  //following user
  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <div className="tagged-people-container">
        <div className="tagged-header">
          <h2>Tagged Users</h2>
          <Close className='close' onClick={() => closeHandle()} />
        </div>
        <div className="tagged-people">
          {taggedProfileData &&
            taggedProfileData?.map((member, i) => (
              <div className="member" key={i}>
                <div className="member-profile">
                  <div className="member-img">
                    <AvatarChatImage
                      avatarRef={member.avatar}
                      title={member.name}
                    />
                  </div>
                  <div className="member-deets">
                    <span className="user">{member.name}</span>
                    <span className="username">@{member.username}</span>
                  </div>
                </div>
                {handleFollowUser(member.id)}
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

export default TaggedPeopleModal;
