import React, { useState, useEffect, useContext } from 'react';

//firebase
import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database } from "../../Components/FirebaseConfig";

//context
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';
import FollowerContext from '../../store/FollowerContext';

//mui
import { SearchRounded } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'

//components
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage';

//external lib
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Posts from './Posts';
import CustomButton from '../../common/CustomButton';

const WaveFollowing = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const followerCtx = useContext(FollowerContext);

  const db = database;

  //states
  const [searchQuery, setSearchQuery] = useState('');
  const [postArray, setPostArray] = useState([]);
  const [featuredProfileData, setFeaturedProfileData] = useState([]);

  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)

  const [membersLoading, setMembersLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [noFriends, setNoFriends] = useState(false)


  //effects
  useEffect(() => {

    getFeaturedProfiles()
    addPostListener()

  }, [])

  useEffect(() => {
    if (searchQuery != '') {
      searchMembers();
    } else {
      setMembers([])
    }
  }, [searchQuery]);


  //functions

  const getFeaturedProfiles = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/homev4/' + authCtx.userId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          filterProfileData(data?.data.splice(1, 1))
        } else {
        }
      });
  }

  //filter profile data
  const filterProfileData = (data) => {
    if (data) {
      setFeaturedProfileData(data[0]?.users)
    } else {
      console.log("no profile data")
    }
  }

  //get posts from firebase
  const addPostListener = () => {
    const dbRef = ref(
      db,
      'waves',
      orderByChild('timestamp'),
      limitToLast(50)
    )
    onValue(dbRef, (snapshot) => {
      setPostArray(snapshotToArray(snapshot))
    })
  }

  const snapshotToArray = (snapshot) => {
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  //check for following users post only
  const checkFollowers = (array) => {
    let sortedArray = []
    if (array && followerCtx?.following) {
      for (let i = 0; i < array.length; i++) {
        if (followerCtx?.following.some((el) => el.follow_user_id === array[i]?.creatorId)) {
          sortedArray.push(array[i])
        }
      }
    }
    return sortedArray.reverse()
  }

  // follow unfollow button handler
  const handleFollowUser = (id) => {
    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} style={{ marginRight: '2.2rem' }} />

    if (id === authCtx.userId) return null;

    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if(id !== followerCtx?.followers[i]?.follow_user_id){
        isFollower = <CustomButton className='f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton> 
      } else {
        return <CustomButton className='f-btn-bordered f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }

  // unfollowing a user
  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  //following user
  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  //search for users
  const searchMembers = () => {
    if (searchQuery == '') {
      setMembers([]);
    }
    setMembersLoading(true);

    fetch(
      process.env.REACT_APP_API_URI +
      `/v2/user/${authCtx.userId}/search/dms/${searchQuery}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          setMembers(data?.data);
          setMembersLoading(false);

          if (data?.data.length == 0) {
            setNoFriends(true)
          } else {
            setNoFriends(false)
          }
        } else {
        }
      });
  };


  //console logs
  // console.log(postArray)
  // console.log(followerCtx)
  // console.log(checkFollowers(postArray))
  // console.log(likes)
  // console.log(comments)

  return (
    <>
      <div className="following-container">
        <div className="find-friend-container">
          <div className="find-friend">
            <span>Find Your Friends</span>
            <div className="search-bar">
              <input type="text" placeholder='Search for friends...' onChange={({ target }) => setSearchQuery(target.value)} />
              <SearchRounded className='search-icon' />
            </div>
          </div>
          <div className="friends-container" style={searchQuery != '' ? null : { display: 'none' }} >
            <div className="no-friend" style={noFriends && members?.length == 0 ? null : { display: 'none' }}>
              <div className='not-avail'>Not Available</div>
              <Button className='invite-btn' >Invite</Button>
            </div>
            {members?.length != 0 && searchQuery != ''
              ? members?.map((data, i) => (
                <div className="friend" key={i}>
                  <div className="profile">
                    <div className="profile-img">
                      <AvatarChatImage avatarRef={data.avatar} title={data.name} />
                    </div>
                    <div className="profile-name">
                      <span className='user'>{data.name}</span>
                      <span className='username'>@{data.username}</span>
                    </div>
                  </div>
                  {handleFollowUser(data.id)}
                </div>
              ))
              : <div className="loading" style={membersLoading && members?.length == 0 ? null : { display: 'none' }}>
                <CircularProgress style={{ color: '#007C89', margin: '1rem 0' }} size={24} />
              </div>
            }
          </div>
        </div>
        <div className="discover-container">

          <div className="posts-container">
            {followerCtx.follower?.length >= 5
              ? <Posts array={checkFollowers(postArray) && checkFollowers(postArray)?.reverse()} />
              : <div className="follow-notice"><p>Please follow as least 5 profiles to see some posts here</p></div>
            }
          </div>

          <div className="recommend-container">
            <div className="recommend-people">
              {
                featuredProfileData?.map((data, i) => (
                  <div className="friend" key={i}>
                    <div className="profile">
                      <div className="profile-img">
                        <AvatarChatImage avatarRef={data.avatar} title={data.name} />
                      </div>
                      <div className="profile-name">
                        <span className='user'>{data.name}</span>
                        <span className='username'>@{data.username}</span>
                      </div>
                    </div>
                    {handleFollowUser(data.id)}
                  </div>
                ))
              }
            </div>
            {/* <div className="recommend-hubs">
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
								<div className="hub-container">
										<div className="hub">
												<div className="hub-img">
														<AvatarChatImage />
												</div>
												<div className="hub-name">
														<span className='user'>Stuart Dokidis</span>
														<span className='username'>@Stuart.dod</span>
												</div>
										</div>
										<Button className='follow-btn'>Follow</Button>
								</div>
						</div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default WaveFollowing