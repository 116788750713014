// react
import React, { useContext, useState, useCallback, useRef } from 'react';

//modules
import { Base64 } from 'js-base64';

// firebase
import { uploadBytes, ref as sRef, uploadString }  from "../FirebaseConfig";
import {ref, set} from "firebase/database";
import { database }  from "../FirebaseConfig";
import storage from "../FirebaseConfig";

// context
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

// mui
import { CloseRounded } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';

const AddVideo = () => {

  //context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  //refs
  const inputRef = useRef();

  const db = database;

  //states
  const [source, setSource] = useState();
  const [videoFile, setVideoFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailRef, setThumbnailRef] = useState();
  //boolean states
  const [uploading, setUploading] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(false);


  // get file from user
  const handleFileChange = (event) => {
    const video = event.target.files[0];
    if(video.size > 100000000) {
      return setSizeLimit(true)
    }
    setVideoFile(video)
    handleFileUpload(video)
    const url = URL.createObjectURL(video);
    setSource(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
  
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
  
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
  
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const handleFileUpload = async (video) => {
    const thumb =  await generateVideoThumbnail(video);
    let thumbFinal = baseToFile(thumb)
    setThumbnail(thumbFinal)
  }

   // this gets a file object which is passed to firebase
   const baseToFile = (base) => {
    
    if (base) {
        let arr = base?.split(",")
    
        let mime = arr[0].match(/:(.*?);/)[1]
        let data = arr[1]
    
        let dataStr = Base64.atob(data)
        let n = dataStr.length
        let dataArr = new Uint8Array(dataStr.length)
    
        while(n--)  {
            dataArr[n] = dataStr.charCodeAt(n)
          }
    
          let file = new File([dataArr], `img_cropper_${Date.now()}.png`, {type: mime})
      return file
      } else {
            return null
        }
    }

    
    // pushing video and thumbnail to firebase storage
    const pushVideo = () => {
      
    if (videoFile && thumbnail === null) return;

    setUploading(true)

    let coverRef = `/users/${authCtx.userId}/hubImages/${videoFile.name}`;
    let thumbRef = `/users/${authCtx.userId}/hubImages/${thumbnail.name}`;
    
    const storageRef = sRef(storage, coverRef)
    const thumbnailStorageRef = sRef(storage, thumbRef)
    
    // puts img in firebase storage
    uploadBytes(thumbnailStorageRef, thumbnail )


    // puts video in firebase storage
    uploadBytes(storageRef, videoFile ).then((snapshot) => {


      // pushses the refs to realtime db
      let timeStamp = Date.now();
      
      var messagePayload = {
        "avatar": authCtx.avatarRef,
        "userId": authCtx.userId,
        "hubId": modalCtx.details.selectedNetwork,
        "userName": authCtx.userName,
        "message": "",
        "attachment": coverRef,
        "previewPath": thumbRef,
        "timestamp": timeStamp
      };
  
      const messageRef = ref(db, '/hubChat/' + modalCtx.details.selectedNetwork + '/' + timeStamp)
      const dmMessageRef = ref(db, '/directMessages/' + msgCtx.userId + '/' + timeStamp);        
  
      set(msgCtx.origin === "netChat" ? messageRef : dmMessageRef, messagePayload).then((snapshot) => {
      

        if(msgCtx.origin === "dmChat") {
          msgCtx.setDetails("dmChat", {updated: "yes", dmId: modalCtx.details.selectedNetwork});
        } else {
          msgCtx.setDetails("", "");
        }

        setUploading(false)
        onClose()

      }, (error) => console.log(error))  
    
    }, (error) => console.log(error));
  }


    // colse video
  const onClose = () => {
    setVideoFile(null)
    closeHandle()
  }
    
    // colse modal
  const closeHandle = () => {
		modalCtx.closeModal();
  }
  
  return (
    <div className='video-upload-container'>
      <div className="title">
        <h2>Upload Video</h2>
        <CloseRounded onClick={() => onClose()} />
        {/* {thumbnail ? returnImg() : null} */}
      </div>
        <div className="video-input">
        {!source && <div className="choose-file">Choose File to upload</div> }
            <input
              ref={inputRef}
              className="video-input-input"
              type="file"
              onChange={handleFileChange}
              accept=".mov,.mp4,.webm"

            />
            {!source && <Button className='upload-video-btn' onClick={handleChoose}>Choose</Button>}
            {source && (
              <video
                className="video-input-video"
                width="100%"
                // height={height}
                controls
                src={source}
              />
            )}
            {/* <div className="VideoInput_footer">{source || "Nothing selectd"}</div> */}
        </div>
        <div className="upload-btn">
          <Button className="upload-btn-btn" onClick={() => pushVideo()}>{uploading ?  <CircularProgress style={{color: "#fff"}} /> : "Upload"}</Button>
        </div>
    </div>
  )
}

export default AddVideo;
