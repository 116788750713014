import { Button } from '@mui/material';
import React, { useContext } from 'react';

import ModalContext from '../../store/ModalContext.js';

const PlayVideo = () => {

  const modalCtx = useContext(ModalContext);

  const closeVid = () => {
		modalCtx.closeModal();
	  }

    console.log(modalCtx.details);

  return (
    <>
    <div className="video-container">

      <div className="video">
        <video style={{maxWidth: "100%", maxHeight: "100%" }} controls loop autoPlay>
          <source src= { modalCtx.details } type="video/mp4" />
        </video>
      </div>
      
      <Button className='close' onClick={() => closeVid()} >Close</Button>
    </div>
    </>
  )
}

export default PlayVideo;
