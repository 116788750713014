const AddTrailingDots = (string, limit) => {

  if (!string?.length) return null

  // Example Input
  // AddTrailingDots("Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim adipisci perspiciatis.", 50)
  // Output : "Lorem ipsum dolor sit amet consectetur..."

  let dots = "...";

  if (string?.length > limit) {
    string = string?.substring(0, limit) + dots;
  }

  return string;

}

export default AddTrailingDots