import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'

//context
import AuthContext from '../../../../store/AuthContext.js';
import ModalContext from '../../../../store/ModalContext.js';

//components/libs
import Chat from './Chat.jsx';
import ReactPlayer from 'react-player';
import Avatar from '../../../../assets/img/avatar/avatar1.jpg'

import chat from '../../../../assets/img/live/chat.svg'

import { KeyboardBackspaceRounded, CloseRounded, } from '@mui/icons-material';

import { ref, onValue, orderByChild, limitToLast } from 'firebase/database'
import { database } from '../../../../Components/FirebaseConfig.jsx'


const CompletedLiveViewer = ({ setSelectedLive, setCompletedStreamViewOn, liveData, NetworkId }) => {



  const db = database;

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);


  const [members, setMembers] = useState([])
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState([]);
  const [videoTimeData, setVideoTimeData] = useState({});
  const [idleTimer, setIdleTimer] = useState(null);

  const [isChatOn, setIsChatOn] = useState(false)
  const [showTitle, setShowTitle] = useState(false);
  const [idleState, setIdleState] = useState(false);

  useEffect(() => {
    addLiveStreamMessageListener()
  }, [liveData])


  const addLiveStreamMessageListener = () => {
    const dbRef = ref(db, `streamChat/${liveData.streamId}`, orderByChild('timestamp'), limitToLast(50))
    onValue(dbRef, (snapshot) => {
      setMessages(snapshotToArray(snapshot))
    })
    // console.log(dbRef)
  }

  const snapshotToArray = (snapshot) => {
    // console.log(snapshot)
    const returnArr = []
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      returnArr.push(item)
    })
    return returnArr.reverse()
  }

  const showTitleHandle = (time) => {
    if (idleState === true) {
      setShowTitle(false)
    }
    setIdleState(false)
    setTimeout(function () {
      setShowTitle(true)
      setIdleState(true)
    }, time);
  }


  const handleChatOpen = () => {
    setIsChatOn(true)
  }


  console.log(liveData)
  // console.log(messages)

  return (
    <>
      <div className="live-stream-viewer" >
        <div onMouseMove={() => showTitleHandle(5000)} className="live-stream" style={isChatOn ? { width: "calc(100% - 325px)" } : null} >
          <ReactPlayer height={'500px'} onProgress={(e) => setVideoTimeData(e)} className={'live-video-player'} controls url={liveData?.video} />
          <div className="stream-details" style={showTitle ? { opacity: '0' } : { opacity: '1' }}>
            <KeyboardBackspaceRounded className='exit-stream' onClick={() => { setCompletedStreamViewOn(false); setSelectedLive(null) }} />
            <div className="title"  ></div>
            {/* <div className="live-indicator">LIVE</div>
            <div className="time-count">0:15</div>
            <div className="view-count">
              <VisibilityOutlined />
              <div className="count">400</div>
            </div> */}
          </div>

          {/* <div className="profile">
            <img src={Avatar} alt="avatar" />
            <div className="name">Steve Parker</div>
          </div> */}

          <div className="chat-icon" onClick={handleChatOpen} style={isChatOn ? { display: "none" } : null}>
            <img src={chat} alt="chat" />
            {/* <div className="chat-count">
              <span>15</span>
            </div> */}
          </div>
          {/* <div className="stream-controls">
            <div className="player"></div>
          </div> */}
        </div>
        <div className="live-chat" style={isChatOn ? { display: "flex" } : { display: "none" }} >
          <div className="chat-header">
            <h3>Live Chat</h3>
            <CloseRounded onClick={() => setIsChatOn(false)} />
          </div>
          <div className="live-chat-container">
            <Chat NetworkId={NetworkId} liveType={'completed'} streamId={liveData.streamId} videoTimeData={videoTimeData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompletedLiveViewer