import React, { useContext, useState, useEffect } from 'react';

import AvatarChatImage from '../../../Components/Avatar/AvatarChatImage.jsx';

import AuthContext from '../../../store/AuthContext.js';
import ModalContext from '../../../store/ModalContext.js';
import FollowerContext from '../../../store/FollowerContext.js';

import { Button, CircularProgress } from '@mui/material';
import CustomButton from '../../../common/CustomButton.jsx';
import { useNavigate } from 'react-router-dom';

const Members = ({ activeNetworkId, membersArray, networkData }) => {
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const followerCtx = useContext(FollowerContext);
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);
  const [role, setRole] = useState('');
  const [followingUserId, setFollowingUserId] = useState()
  const [updatingFollower, setUpdatingFollower] = useState(false)
  // const [muRole, setMyRole] = useState('member')

  useEffect(() => {
    if (membersArray) {
      setMembers(membersArray);

      membersArray?.map((user) => {
        if (authCtx?.userId === user?.id) {
          setRole(user?.role);
          // console.log(user);
        } else {
          // console.log('first');
        }
      });
    }
  }, [membersArray]);

  // const fetchResults = () => {

  // 	// fetching members
  // 	fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + activeNetworkId + '/members', {
  // 		method: 'GET',
  // 		mode: 'cors',
  // 		headers: {
  // 			'Content-Type': 'application/json',
  // 			'Authorization': 'Bearer ' + authCtx.token
  // 		}
  // 	}).then(response => {
  // 		return response.json();
  // 	}).then(data => {
  // 		if (data.status === "success") {
  // 			setMembers(data.data)
  // 			console.log(data.data)
  // 		} else {
  // 		}
  // 	});

  // }
  // console.log(members);
  // console.log(role);

  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  };

  const chooseRole = (role) => {
    if (role === 'owner' || role === 'moderator') {
      return 'owner';
    } else {
      return 'member';
    }
  };

  const handleFollowUser = (id) => {
    if (followingUserId === id && updatingFollower) return <CircularProgress size={20} />

    if (id === authCtx.userId) return null;
    const length = followerCtx?.following?.length

    let isFollower = <CustomButton className='f-btn-full-width f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>

    for (let i = 0; i < length; i++) {
      if (id !== followerCtx?.following[i]?.follow_user_id) {
        isFollower = <CustomButton className='f-btn-full-width f-btn-follow' onClick={() => followUser(id)} >Follow</CustomButton>
      } else {
        return <CustomButton className='f-btn-bordered f-btn-full-width f-btn-unfollow' onClick={() => unFollowUser(id)} >Following</CustomButton>
      }
    }
    return isFollower
  }
  const unFollowUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/unfollow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()
      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  const followUser = async (id) => {
    setUpdatingFollower(true)
    setFollowingUserId(id)
    try {
      const res = await fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx?.userId + '/follow',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
          body: JSON.stringify({
            userId: authCtx?.userId,
            followUserId: id
          })
        })

      const data = await res.json()

      if (data.status === 'success') {
        followerCtx.updateFollowContext()
        setUpdatingFollower(false)
        setFollowingUserId()
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleInviteBtn = () => {
    if ((role === 'member' || role === 'invited') && (networkData.hubType === 'private' || networkData.permissions === 'invite' || networkData.paid === 'yes')) {
      return null
    } else {
      return <CustomButton className="f-btn-big" onClick={() => showModal('inviteMember', { activeNetworkId: activeNetworkId, tab: null })}>+ Invite Members</CustomButton>
    }
  }

  console.log(followerCtx)
  console.log(members)

  return (
    <>
      <div className="members-container">
        <div className="members-wrapper">
          <div className="members-header">
            <h2>Members</h2>
            {handleInviteBtn()}
          </div>
          <div className="members-array">
            <div className="members">
              {members?.map((item, i) => (
                <div className="member" key={i} >
                  <div className="member-role">
                    <div className={`hub-${item?.role}`} >{item.role}</div>
                  </div>
                  <div className="member-avatar" onClick={() => navigate(`/profile/${item.id}`)}>
                    <AvatarChatImage avatarRef={item.avatar} />
                  </div>
                  <div className="member-name" onClick={() => navigate(`/profile/${item.id}`)}>
                    <div className="name">{item.name}</div>
                    <div className="user-name">@{item.username}</div>
                  </div>
                  <div className="member-follow">
                    {handleFollowUser(item.id)}
                  </div>
                  {/* <div className="member-action">
										<div className={chooseRole(item?.role)}>{item.role}</div>
										{role === 'member' ? null : (
											<div
											className="member-btn"
											onClick={() =>
												showModal('memberAction', {
													member: item,
													network: activeNetworkId,
												})
												}
												style={item.role === 'owner' ? { display: 'none' } : null}
											>
											<MoreVertRounded />
											</div>
											)}
										</div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
