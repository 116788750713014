import React, { useState, useEffect, useContext,useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';



import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../../../Components/FirebaseConfig";
import AuthContext from '../../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';

import Placeholder from '../../../assets/img/networks/placeholder.png'
import ModalContext from '../../../store/ModalContext.js';
const noMenuRoutes = ["/login"];
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


const GetPdfFirebase = ({ imageRef, ratio, className, height, width }) => {

    const authCtx = useContext(AuthContext);
    const modalCtx = useContext(ModalContext);

    const { pathname } = useLocation();
    const [avatarImage, setAvatarImage] = useState("");
    const [loading, setLoading] = useState(true);
    const [thumb, setThumb] = useState(null)

    const [numOfPages, setNumOfPages] = useState(null)
    const[imageUrls,setImageUrls]=useState([])

    useEffect(() => {
        if (imageRef !== "" && imageRef !== null && imageRef !== undefined) {
            getImg(imageRef);
        } else {
            setLoading(false)
        }
    }, [imageRef]);

    const getImg = (coverRef) => {

        setLoading(true)

        const isPresent = coverRef.includes('https://');

        isPresent
            ? setAvatarImage(coverRef)
            : getDownloadURL(ref(storage, coverRef))
                .then((url) => {
                    setAvatarImage(url);
                    UrlUploader(url)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    setLoading(false)
                });
    }

    const showImg = (modal, image) => {
        modalCtx.openModal();
        modalCtx.setDetails(modal, image)
    }

    const UrlUploader = (url) => {
        fetch(url).then((response) => {
          response.blob().then((blob) => {
            let reader = new FileReader();
            reader.onload = (e) => {
              const data = atob(e.target.result.replace(/.*base64,/, ""));
              renderPage(data);
            };
            reader.readAsDataURL(blob);
          });
        });
    };

    const renderPage = async (data) => {
        setLoading(true);
        const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");
        const pdf = await pdfjsLib.getDocument({ data }).promise;
        for (let i = 1; i <= 1; i++) {
          var page = await pdf.getPage(i);
          var viewport = page.getViewport({ scale: 1.5 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          var render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          await page.render(render_context).promise;
          let img = canvas.toDataURL("image/png");
          imagesList.push(img);
        }
        setNumOfPages((e) => e + pdf.numPages);
        setImageUrls((e) => [...e, ...imagesList]);
    };



    if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

    // if (loading) return <CircularProgress size={20} style={{ color: '#eb4d4b' }} />


    return (
        <>
            {avatarImage ? <img style={height !== null && width !== null ? { height: `${height}px`, width: `${width}px`} : null} src={imageUrls[0]} alt="post" className={className} /> : <img style={height !== null && width !== null ? { height: `${height}px`, width: `${width}px`} : null} src={Placeholder} alt="post" className={className} />}
        </>
    );
}
export default GetPdfFirebase;