import React,{useContext} from 'react'
import { Button } from '@mui/material'

import ModalContext from '../../store/ModalContext'
import { Height } from '@mui/icons-material'

const ViewImage = () => {
    const modalCtx = useContext(ModalContext)

    const closeImg = () => {
        modalCtx.closeModal()
    }
return (
    <div className='chat-image-container' style={{display: "flex", flexDirection: 'column', height: "79vh", maxWidth: '70vw'}}>
        <div className="video" style={{maxWidth: "100%", height: "calc(100% - 30px)", objectFit: 'cover'}} >
            <img src={modalCtx.details} type='image/jpg' alt='img' style={{height: '100%', borderRadius: '1px' }} />
        </div>
        <span className='close' onClick={() => closeImg()} style={{color: '#fff', fontWeight: '600', textAlign: 'center', paddingTop: "8px", fontSize: '18px', cursor: "pointer" }}>Close</span>
    </div>
)}

export default ViewImage