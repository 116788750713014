import React, { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams, useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import CardSection from './CardSection';

import AuthContext from "../../store/AuthContext";

export default function CheckoutFormCard(props) {
  
  const stripe                    = useStripe();
  const elements                  = useElements();

  const [message, setMessage]     = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx 				          = useContext(AuthContext);
  let navigate                    = useNavigate();
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) { return; }
    setIsLoading(true);

    const result = await stripe.confirmCardPayment(props.stripeSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.userName,
          email: props.userEmail
        },
      }      
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        console.log(result.paymentIntent);

        let jsonBody = JSON.stringify({ userId: props.userId, hubId: props.hubId });

        fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + props.hubId + '/membershipFromPayment', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
          body: jsonBody,
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if(data.status == "success") {        
            navigate("/networks/" + props.hubId + "/paywall/" + props.userId + "/subscribed", {replace: true});
          } else {
            // setOpenSnackbar(true);
          }
        });
        
        
        // fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + props.hubId + '/createSubscription', {
        //   method: 'POST',
        //   mode: 'cors',
        //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        //   body: JSON.stringify({
        //     userId: props.userId,
        //     hubId: props.hubId
        //   }),
        // }).then(response => {
        //   return response.json();
        // }).then(data => {
        //   console.log(data);
        //   if(data.status == "success") {        
            //   } else {
        //     // setOpenSnackbar(true);
        //   }
        // });
      }
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "95%"}}>
      <CardSection />
      { isLoading ?
        <button><CircularProgress  style={{'color': '#ffffff'}} size={20} /></button> :
        <button disabled={!stripe}>Subscribe for ${props.paymentFee}</button>
      }
    </form>
  );
}