import React, { useRef, useEffect, useState, useContext } from 'react'

//context
import ModalContext from '../../../../store/ModalContext';
import AuthContext from '../../../../store/AuthContext';

//MUI
import { Alert, Button, CircularProgress, FormControlLabel, FormGroup, Snackbar, Switch, Tooltip } from '@mui/material';
import { Close, InfoRounded } from '@mui/icons-material';

//components
import Dropzone from '../../../ReactDrop/Dropzone';
import CustomButton from '../../../../common/CustomButton'

//firebase
import { ref, set } from 'firebase/database';
import { ref as sRef, uploadBytes } from '../../../FirebaseConfig';
import storage, { database } from '../../../FirebaseConfig';

const CreateAttachment = () => {

  //firebase db
  const db = database;

  //refs
  const FileRef = useRef(null)

  //handle file input
  const handleFileInput = () => {
    FileRef.current.click()
  }

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [title, setTitle] = useState('')
  const [file, setFile] = useState(null)
  const [previewFile, setPreviewFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const [isDownloadable, setIsDownloadable] = useState('yes')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [loading, setLoading] = useState(false)


  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  // console.log(fileSize)
  // console.log(fileExtension)

  //adding a post
  const addPost = () => {

    if (!file) return
    setLoading(true)

    let fileRef = `hubs/${modalCtx.details}/attachments/${file.name}`;
    const storageRef = sRef(storage, fileRef)

    uploadBytes(storageRef, file).then((snapshot) => {

      var postPayload = {
        userId: authCtx.userId,
        hubId: modalCtx.details,
        fileName: file.name,
        titleName: fileName,
        extension: fileExtension,
        fileRef: fileRef,
        size: fileSize,
        isDownloadable: isDownloadable
      };

      postAttachment(postPayload)

    }, (error) => console.log(error));
  }

  const postAttachment = async (payload) => {
    console.log(payload)

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${modalCtx.details}/attachments`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token
        },
        body: JSON.stringify(payload)
      })
      const data = await res.json()
      if (data.status === 'success') {
        // console.log(data)
        modalCtx.closeModal()
        setLoading(false)
      } else {
        setLoading(false)
      }

    } catch (error) {
      console.log('error getting sub networks', error)
      setLoading(false)
    }

  }


  // get dropped files
  const onDrop = (files) => {

    let file = files[0]
    if (!file) return

    setFileSize(fileSizeCalc(file.size))
    getExtension(file.name)

    let imageFileTooBig = false
    let videoFileTooBig = false
    let FileTooBig = false

    if (calculateFileSize(file) >= 25 && file.type.includes('image')) {
      imageFileTooBig = true
    } else if (calculateFileSize(file) > 25 && file.type.includes('video')) {
      videoFileTooBig = true
    } else if (calculateFileSize(file) > 25) {
      FileTooBig = true
    }

    if (videoFileTooBig) {
      setSnackbarMessage('Video size should below 25 MB')
      setOpenSnackBar(true)
    } else if (imageFileTooBig) {
      setSnackbarMessage('Image size should below 25 MB')
      setOpenSnackBar(true)
    } else if (FileTooBig) {
      setSnackbarMessage('File size should be below 25 MB')
      setOpenSnackBar(true)
    } else {
      setFile(file)
      returnFilePreviewObject(file)
    }
  };

  const handleFile = (e) => {

    let file = e.target.files[0]
    if (!file) return

    setFileSize(fileSizeCalc(file.size))
    getExtension(file.name)

    let imageFileTooBig = false
    let videoFileTooBig = false
    let FileTooBig = false

    if (calculateFileSize(file) >= 25 && file.type.includes('image')) {
      imageFileTooBig = true
    } else if (calculateFileSize(file) > 25 && file.type.includes('video')) {
      videoFileTooBig = true
    } else if (calculateFileSize(file) > 25) {
      FileTooBig = true
    }

    if (videoFileTooBig) {
      setSnackbarMessage('Video size should be below 25 MB')
      setOpenSnackBar(true)
      console.log('File size should be below 25 MB')
      FileRef.current.value = null;

    } else if (imageFileTooBig) {
      setSnackbarMessage('Image size should be below 25 MB')
      setOpenSnackBar(true)
      console.log('File size should be below 25 MB') 
      FileRef.current.value = null;

    } else if (FileTooBig) {
      setSnackbarMessage('File size should be below 25 MB')
      setOpenSnackBar(true)
      console.log('File size should be below 25 MB')
      FileRef.current.value = null;

    } else {
      setFile(file)
      console.log(file)
      returnFilePreviewObject(file)
    }
  }


  //calculates files size
  const calculateFileSize = (file) => {
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;

    return fileMb
  }

  const getExtension = (name) => {
    let fileName = name
    setFileExtension(fileName?.split('.').pop())
    const file = fileName?.split('.')?.slice(0, -1)
    setFileName(file.join('-'))
  }

  const fileSizeCalc = (size) => {

    var fSExt = ['Bytes', 'KB', 'MB', 'GB']
    let i = 0;
    //check if file is in GB,MB,KB or Bytes
    while (size > 900) {
      size /= 1024; //divide file size 
      i++;
    }
    //get exact size
    var exactSize = (Math.round(size * 100) / 100) + fSExt[i];
    return exactSize
  }

  const returnFilePreviewObject = (incomingFile) => {
    console.log(incomingFile)
    if (incomingFile) {
      var reader = new FileReader();
      reader.onload = (file) => {
        setPreviewFile({ name: incomingFile.name, type: incomingFile.type, file: file.target.result })
      }
      reader.readAsDataURL(incomingFile)
    }
  }

  const filePreviewHandler = (item) => {
    return checkFileType(item)
  }

  const checkFileType = (item) => {
    // console.log(item)
    let file
    if (item.type.includes('image')) {
      return file = <>
        <Close className='import-close' onClick={() => removeFile()} />
        {/* <ImageCropper image={item.file} aspectRatio1={9} aspectRatio2={16} /> */}
        <img className='file-preview-image' src={item.file} alt='post' />
      </>
    } else if (item.type.includes('video')) {
      return file = <>
        <Close className='import-close' onClick={() => removeFile()} />
        <video width='100%' height='100%' controls className='file-preview-video' >
          <source src={item.file} />
        </video>
      </>
    } else if (item.type.includes('text')) {
      return file = <>
        <Close className='import-close' onClick={() => removeFile()} />
        <div className="text-file-preview">
          <div className='file-preview-text'>{item.name}</div>
          <div className='file-preview-type'>Text File</div>
        </div>
      </>
    } else {
      return file = <>
        <Close className='import-close' onClick={() => removeFile()} />
        <div className="text-file-preview">
          <div className='file-preview-text'>{item.name}</div>
        </div>
      </>
    }
  }

  //remove the file from files array and preview array
  const removeFile = () => {
    if (file && previewFile) {
      setFile(null)
      setPreviewFile(null)
      setFileName('')
    }
  }

  //handle snackbar close
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false)
  }

  const handleDownloadable = (e) => {
    if (e.target.checked) {
      setIsDownloadable('yes')
    } else {
      setIsDownloadable('no')
    }
  }

  // console.log(isDownloadable)

  return (
    <>
      <div className='create-attachment'>
        <div className="import-media">
          {
            previewFile != null
              ? <div className="carousel-container">
                {filePreviewHandler(previewFile)}
              </div>
              : <>
                <Dropzone maxFiles={1} onDrop={onDrop} accept={"image/*, video/*"} />
                {/* <img src={PhotoVideoIconLarge} alt="import" /> */}
                <h3>Drag photos and videos here</h3>
                <CustomButton className='f-btn' onClick={() => handleFileInput()} >Select from computer</CustomButton>
                <Tooltip
                  title={
                    <>
                      <ol className='tooltip-for-posts' >
                        <li>File file should be under <strong>30</strong> MB </li>
                      </ol>
                    </>
                  }
                >
                  <InfoRounded className='info-btn' />
                </Tooltip>
                <input ref={FileRef} onChange={(e) => handleFile(e)} type="file" className='file-input' />
              </>
          }
        </div>
        <div className="confirm-delete-btns">
          <input value={fileName} onChange={(e) => setFileName(e.target.value)} type="text" className='attachment-rename-input' />
        </div>
        <div className="confirm-delete-btns">
          <FormGroup>
            <FormControlLabel control={<Switch onChange={handleDownloadable} className='download-checkbox' defaultChecked style={{ color: "#CD4A4A" }} />} label="Downloadable" />
          </FormGroup>
          <CustomButton className="f-btn-bordered" onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton className="f-btn" onClick={() => !loading && addPost()} >
            {
              loading
                ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                : "Upload"
            }
          </CustomButton>
        </div>
        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default CreateAttachment