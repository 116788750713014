import React, { useContext, useEffect, useRef, useState } from 'react'

// context
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import FollowerContext from '../../store/FollowerContext.js';

//components
import AvatarChatImage from '../Avatar/AvatarChatImage'

//mui 
import { SearchRounded } from '@mui/icons-material'

//assets
import BackIcon from '../../assets/img/wave/create-post/back-icon.svg'
import { Checkbox, CircularProgress } from '@mui/material'
// import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
// import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const TagPeople = ({ setActiveTab, setTaggedMembers, taggedMembers }) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [membersLoading, setMembersLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');



  //effects
  useEffect(() => {
    if (searchQuery) {
      searchMembers();
    }
  }, [searchQuery]);

  //functions

  // change tabs
  const handleClose = () => {
    setActiveTab('create')
  }

  //search for users
  const searchMembers = () => {
    if (!searchQuery) {
      setMembers([]);
    }
    setMembersLoading(true);

    fetch(
      process.env.REACT_APP_API_URI +
      `/v2/user/${authCtx.userId}/search/dms/${searchQuery}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 'success') {
          setMembers(data?.data);
          setMembersLoading(false);
        } else {
        }
      });
  };

  //adding and removing tagged members
  const addToTaggedMembers = (memberId, member) => {
    if (taggedMembers.some((item) => item.id === memberId)) {
      const newArray = taggedMembers.filter((item, i) => item.id !== memberId)
      setTaggedMembers(newArray)
    } else {
      setTaggedMembers(prev => [...prev, member])
    }
  }

  //adding and removing tagged members
  const checkSelectedUsers = (memberId) => {
    if (taggedMembers?.some((item) => item.id === memberId)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='tag-people-container'>
      {/* <div className="tag-people-header">
        <img src={BackIcon} alt='back' className='back' onClick={() => handleClose()} />
        <h3>Tag People</h3>
      </div> */}

      <div className="search-bar">
        <input type="text" placeholder="Search frequency members" onChange={({ target }) => setSearchQuery(target.value)} />
        <SearchRounded className="search-icon" />
      </div>

      <div className="tag-members">
        {!membersLoading &&
          members?.map((member) => (
            <div className="member" key={member.id}>
              <div className="member-profile" onClick={() => addToTaggedMembers(member.id, member)}>
                <div className="member-img">
                  <AvatarChatImage
                    avatarRef={member.avatar}
                    title={member.name}
                  />
                </div>
                <div className="member-deets">
                  <span className="user">{member.name}</span>
                  <span className="username">@{member.username}</span>
                </div>
              </div>
              <Checkbox
                checked={checkSelectedUsers(member.id)}
                // icon={<CircleUnchecked />}
                // checkedIcon={<CircleCheckedFille />}
                sx={{ '&.Mui-checked': { color: '#CD4A4A' } }}
                onClick={() => addToTaggedMembers(member.id, member)}
              />
            </div>
          ))}
        {membersLoading ? (
          <div className="loading">
            <CircularProgress style={{ color: '#007C89' }} size={22} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TagPeople