//react
import React, { useContext, useState, useEffect } from 'react';

//context
import ModalContext from '../../store/ModalContext.js';
import { Close } from '@mui/icons-material';
import CustomButton from '../../common/CustomButton.jsx';


const EditAbout = () => {

  //context
  const modalCtx = useContext(ModalContext);

  // colse modal
  const closeHandle = () => {
    modalCtx.closeModal();
  }

  return (
    <div className='edit-about-container'>
        <div className="header">
            <h1>Edit About</h1>
            <Close  onClick={() => closeHandle()}/>
        </div>
        <textarea name="" id="" cols="30" rows="10" placeholder='About'></textarea>
        <div className="buttons">
            <CustomButton className="f-btn-bordered" style={{ width: '100%' }} onClick={() => closeHandle()}>Cancel</CustomButton>
            <CustomButton className="f-btn-full-width">Edit About</CustomButton>
        </div>
    </div>
  )
}

export default EditAbout;
