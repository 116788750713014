import React, { useContext, useState, useEffect } from 'react';

import { ref, update, remove } from "firebase/database";
import { database } from "../FirebaseConfig";


import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';
import GlobalContext from '../../store/GlobalContext.js';

//assets
import editNetwork from '../../assets/img/settings/edit-network.svg';
import subNetwork from '../../assets/img/settings/create-sub-network.svg';
import inviteToNetwork from '../../assets/img/settings/invite.svg';
import share from '../../assets/img/settings/share.svg';
import notification from '../../assets/img/settings/notification.svg';
import deleteIcon from '../../assets/img/settings/delete.svg';
import closeIcon from '../../assets/img/settings/close.svg';

const Settings = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);
  const globalCtx = useContext(GlobalContext);

  const [closed, setClosed] = useState(false)


  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
    setClosed(true)
  }

  console.log(modalCtx)

  // leaving the network
  const leaveNetwork = () => {

    fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + '/hub/' + modalCtx?.details.selectedNetwork + '/membership', {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        closeHandle()
        globalCtx.setLeaveNetwork(true)
        console.log(data)
      } else {
      }
    });


  }

  console.log(modalCtx.details)

  return (
    <>
      <div className="action-container">
        <div className="actions">

          <div className="setting-header">
            <h2>Settings</h2>
            <img src={closeIcon} alt="close" className='close' onClick={() => closeHandle()} />
          </div>

          <List>
            {
              modalCtx?.details.role === "owner" ?
                <>
                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("editNetworkDetails", modalCtx.details.network)} >
                      <img src={editNetwork} alt="" />
                      <ListItemText  >Edit Network Details</ListItemText>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("editNetworkAccess" , modalCtx.details.network)} >
                      <img src={editNetwork} alt="" />
                      <ListItemText  >Edit Network Access</ListItemText>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("editNetworkModeration" , modalCtx.details.network)} >
                      <img src={editNetwork} alt="" />
                      <ListItemText  >Edit Network Moderation</ListItemText>
                    </div>
                  </ListItem>
                  {
                    modalCtx.details.parentId === 0 ?
                      <ListItem>
                        <div className='setting-option' onClick={() => showModal("subNetwork", null)} >
                          <img src={subNetwork} alt="" />
                          <ListItemText >Create a sub network</ListItemText>
                        </div>
                      </ListItem>
                      : null
                  }
                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("inviteMember", { selectedNetwork: modalCtx?.details.selectedNetwork, tab: "invite" })} >
                      <img src={inviteToNetwork} alt="" />
                      <ListItemText  >Invite to network</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("inviteMember", { selectedNetwork: modalCtx?.details.selectedNetwork, tab: null })}>
                      <img src={share} alt="" />
                      <ListItemText>Share</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("notification-preference", null)}>
                      <img src={notification} alt="" />
                      <ListItemText>Notification preferences</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option' onClick={() => showModal("confirm-delete-network", modalCtx?.details.selectedNetwork)}>
                      <img src={deleteIcon} alt="" />
                      <ListItemText>Delete network</ListItemText>
                    </div>
                  </ListItem>
                </>
                : null
            }
            {
              modalCtx?.details.role === "mod" ?
                <>
                  <ListItem>
                    <div className='setting-option'>
                      <img src={share} alt="" />
                      <ListItemText>Share</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option'>
                      <img src={notification} alt="" />
                      <ListItemText>Notification preferences</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option'>
                      <img src={editNetwork} alt="" />
                      <ListItemText onClick={() => leaveNetwork()} >Leave network</ListItemText>
                    </div>
                  </ListItem>
                </>
                : null
            }
            {
              modalCtx?.details.role === "member" ?
                <>
                  <ListItem>
                    <div className='setting-option'>
                      <img src={share} alt="" />
                      <ListItemText>Share</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option'>
                      <img src={notification} alt="" />
                      <ListItemText>Notification preferences</ListItemText>
                    </div>
                  </ListItem>

                  <ListItem>
                    <div className='setting-option'>
                      <img src={editNetwork} alt="" />
                      <ListItemText onClick={() => leaveNetwork()} >Leave network</ListItemText>
                    </div>
                  </ListItem>
                </>
                : null
            }
            {
              modalCtx?.details.role === "invited" ?
                <>
                  <ListItem>
                    <div className='setting-option'>
                      <img src={editNetwork} alt="" />
                      <ListItemText>Join</ListItemText>
                    </div>
                  </ListItem>
                </>
                : null
            }
          </List>
        </div>
        {/* <Button className='cancel' onClick={() => closeHandle()} >Cancel</Button> */}
      </div>
    </>
  )
}

export default Settings;
