import React, { useState, useEffect, useContext } from "react";

//assets
import Send from "../../assets/img/wave/send.svg";
import Trash from "../../assets/img/wave/trash.svg";
import Heart from "../../assets/img/wave/heart.svg";
import HeartEmpty from "../../assets/img/wave/heart-empty.svg";
import Comment from "../../assets/img/wave/comment.svg";
import Heart_Reaction from "../../assets/img/reactions_posts/heart_emoji.png";
import Clap_Reaction from "../../assets/img/reactions_posts/clap_emoji.png";
import Thumbs_Up_Reaction from "../../assets/img/reactions_posts/thumsUp_emoji.png";
import Laugh_Reaction from "../../assets/img/reactions_posts/laugh_emoji.png";

//context
import AuthContext from "../../store/AuthContext";
import ModalContext from "../../store/ModalContext";
import LiveFeed from "./LiveFeed";
import NormalFeed from "./NormalFeed";
import HubFeed from "./HubFeed";

//firebase
import {
  ref,
  onValue,
  orderByChild,
  limitToLast,
  set,
  update,
  remove,
} from "firebase/database";
import { database } from "../../Components/FirebaseConfig";

//mui
import { DeleteRounded, FavoriteBorder } from "@mui/icons-material";

//components
import AvatarChatImage from "../../Components/Avatar/AvatarChatImage";
import AvatarImage from "../../Components/Avatar/AvatarImage";
import GetImage from "./GetImage";

//external lib
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import NewsFeed from "./NewsFeed";

const Posts = ({ array }) => {
  //firebase db
  const db = database;

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  //states
  const [selectedPostId, setSelectedPostId] = useState(0);
  const [likeBoxOpen, setLikeBoxOpen] = useState(false);
  const [commentText, setCommentText] = useState("");

  //handle modal
  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  };

  //get relative time
  const timestampToDate = (t) => {
    var time = new Date(t);
    let relTime = moment(time).fromNow();
    // var lastIndex = relTime.lastIndexOf(" ");
    return relTime;
  };

  //count likes
  const countLikes = (obj) => {
    if (obj) {
      const likes = Object.keys(obj).length;
      return likes ? likes : 0;
    }
  };

  //count comments
  const countComments = (obj) => {
    if (obj) {
      const comments = Object.keys(obj).length;
      return comments ? comments : 0;
    }
  };

  //get tagged users
  const getTaggedUsers = (users) => {
    if (users) {
      return (
        <span
          className="tagged-users"
          onClick={() => handleModal("tagged-people", users)}
        >
          <span style={{ fontWeight: "400" }}>&nbsp;with</span>
          &nbsp;{users[0].name}&nbsp;
          {users.length - 1 == 0 ? null : (
            <>
              <span style={{ fontWeight: "400" }}>&nbsp;and</span>
              &nbsp; {users.length - 1}&nbsp; others
            </>
          )}
        </span>
      );
    } else {
      return null;
    }
  };

  //handle multiple media
  const handlePostMedia = (media, aspectRatio) => {
    if (media.length) {
      return (
        <div className="post-img">
          <Carousel
            infiniteLoop={true}
            showThumbs={false}
            showStatus={media.length > 1 ? true : false}
            showIndicators={media.length > 1 ? true : false}
          >
            {media.map((item, index) => (
              <GetImage key={index} imageRef={item} ratio={aspectRatio} />
            ))}
          </Carousel>
        </div>
      );
    } else {
      return null;
    }
  };
  //handle single media if single media
  const handlePostImage = (imageRef, aspectRatio) => {
    if (imageRef) {
      return (
        <div className="post-img">
          {" "}
          <GetImage imageRef={imageRef} ratio={aspectRatio} />{" "}
        </div>
      );
    } else {
      return null;
    }
  };

  // getting the height based on the aspectRatio
  const calculateHeight = () => { };

  //handle getting post likes
  const handleGetPostLikes = (likes, timeStamp) => {
    let arrayOfObj = [];
    let likeIcon;
    if (likes) {
      arrayOfObj = Object.entries(likes).map((e) => e[1]);
    }

    if (arrayOfObj) {
      for (let i = 0; i < arrayOfObj.length; i++) {
        const element = arrayOfObj[i];
        if (element.userId == authCtx.userId) {
          likeIcon = getLikeIcon(element.likeType, timeStamp);
        }
      }
    }
    return likeIcon;
  };

  const getLikeIcon = (likeType, timeStamp) => {
    switch (likeType) {
      case "heartEmoji":
        return (
          <img
            src={Heart_Reaction}
            alt="like"
            onClick={() => disLikePost(timeStamp, "heartEmoji")}
          />
        );

      case "clapEmoji":
        return (
          <img
            src={Clap_Reaction}
            alt="clap"
            onClick={() => disLikePost(timeStamp, "clapEmoji")}
          />
        );

      case "thumsUpEmoji":
        return (
          <img
            src={Thumbs_Up_Reaction}
            alt="thumbs up"
            onClick={() => disLikePost(timeStamp, "thumsUpEmoji")}
          />
        );

      case "laughEmoji":
        return (
          <img
            src={Laugh_Reaction}
            alt="laugh"
            onClick={() => disLikePost(timeStamp, "laughEmoji")}
          />
        );

      default:
        return null;
    }
  };

  const handleOpenLikeBox = (id) => {
    if (likeBoxOpen) {
      setLikeBoxOpen(false);
    } else {
      setLikeBoxOpen(true);
    }
  };

  //deleting a post
  const deletePost = (feedId) => {
    // console.log(feedId)
    // ref(db, `waves/${feedId}`)
    // ref.remove()
    // .then(() => {
    //   console.log('post deleted')
    // })
    // .catch((error) => {
    //   console.log(error)
    // });

    set(ref(db, `waves/${feedId}/`), null)
      .then(() => {
        console.log("post deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // liking a post
  const likePost = (feedId, likeType) => {
    const timestamp = Date.now();
    const userId = authCtx.userId;
    const updateObject = {};

    updateObject[userId] = {
      username: authCtx.userName,
      avatar: authCtx.avatarRef,
      timeStamp: timestamp,
      userId: authCtx.userId,
      likeType: likeType,
    };

    update(ref(db, `waves/${feedId}/likes`), updateObject)
      .then(() => {
        console.log("post liked");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disLikePost = (feedId) => {
    remove(ref(db, `waves/${feedId}/likes/${authCtx.userId}`))
      .then(() => {
        console.log("post like removed");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCommentInput = (e) => {
    let text = e.target.value;
    setCommentText(text);
  };

  const handleCommentSend = (e, feedId) => {
    const timestamp = Date.now();
    const updateObject = {};

    updateObject[timestamp] = {
      username: authCtx.userName,
      avatar: authCtx.avatarRef,
      timeStamp: timestamp,
      userId: authCtx.userId,
      comment: commentText,
    };
    console.log(updateObject);

    if (e.keyCode === 13 && commentText) {
      update(ref(db, `waves/${feedId}/comments`), updateObject)
        .then(() => {
          console.log("post comment added");
          setCommentText("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  console.log(array);

  return (
    <>
      {
        array?.map((item, index) => {
          if (item.feedType === "newsFeed" && item.newsContent) {
            return <NewsFeed key={index} post={item} index={index} />

          } else if (item.feedType === "hubFeed" && item.hubContent) {
            return <HubFeed key={index} post={item} index={index} />

          } else if (item.feedType === "streamFeed" && item.streamContent) {
            return <LiveFeed key={index} post={item} index={index} />

          } else if (item.feedType === "postFeed") {
            return <NormalFeed key={index} post={item} index={index} />;

          } else {
            // Handle unknown feed types or missing content
            return null;
          }
        })
      }
    </>
  );
};

export default Posts;
