import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { TextField, Tabs, Tab, Typography, Box } from '@mui/material';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function UsersDrawer(props) {

  console.log(props);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right">{props.userDetails.name}</h1>
        </header>
        <Tabs value={selectedTab} onChange={handleChange}>

          <Tab label="Details" />
          <Tab label="Translation" />
          <Tab label="Statistics" />

        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <div className="double-column-form">
            <TextField label="Full Name" value={props.userDetails.name} placeholder="Full Name" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
            <TextField label="Email Address" value={props.userDetails.email} placeholder="Email Address" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          List of translations active by this user
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          Statistics of this user
        </TabPanel>

      </div>
    </div>
  );

}
export default UsersDrawer;

