const LoadingFallback = () => {
  return (
    <>
      <div className="featured-loading">
        <div className="dashboard">
          <div className="dash-tabs">

            <div className="popular-networks">
              <div className="card-container">
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="popular-networks">
              <div className="card-container">
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="popular-networks">
              <div className="card-container">
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-part-1">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      <div className="home-featured">
        <div className="featured">
          <div className="networks-container">
              <div className="network">
                <div className="hub-img">
                  <div className="skeleton"></div>
                </div>
                <div className="hub-text">
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
              <div className="network">
                <div className="hub-img">
                  <div className="skeleton"></div>
                </div>
                <div className="hub-text">
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
              <div className="network">
                <div className="hub-img">
                  <div className="skeleton"></div>
                </div>
                <div className="hub-text">
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                  <div className="text">
                    <div className="skeleton"></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default LoadingFallback;