//react
import React, { useContext, useState, useEffect } from 'react';

//context
import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import MessageContext from '../../store/MessageContext.js';

//mui
import { CloseRounded } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';

import { database, uploadBytes, ref as sRef, uploadString }  from "../FirebaseConfig";
import {ref, set} from "firebase/database";
import storage from "../FirebaseConfig";

const AddImage = () => {

  const db = database;

  //context
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const msgCtx = useContext(MessageContext);

  //states
  const [gifs, setGifs] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [gifRef, setGifRef] = useState();

  const [isLoading, setIsLoading] = useState(false); 
  const [uploading, setUploading] = useState(false); 


  const getGifs = () => {
    setIsLoading(true)
    const api_key = 'jPWLTgEr7U6sQ4ZvgXtthv7BW71I1pk3';
    const config = {method: 'GET'}

    fetch(`https://api.giphy.com/v1/gifs/search?api_key=${api_key}&q=${searchQuery}`, config)
    .then(response =>  response.json())
    .then(data => {
          if (data.meta.status === 200) {
            setGifs(data?.data)
            setIsLoading(false)
            console.log(data?.meta)
          } else {
            console.log("error getting gifs!")
          }
        });
    
  }

  const handleGifClick = (url) => {
    pushImage(url)
  }

      // pushing gif to firebase
  const pushImage = (gifRef) => {
      
    if (gifRef == null) return;
    setUploading(true)

      let timeStamp = Date.now();
        
      var messagePayload = {
        "avatar": authCtx.avatarRef,
        "userId": authCtx.userId,
        "hubId": modalCtx.details.selectedNetwork,
        "userName": authCtx.userName,
        "message": "",
        "attachment": gifRef,
        "timestamp": timeStamp
      };
  
      const messageRef = ref(db, '/hubChat/' + modalCtx.details.selectedNetwork + '/' + timeStamp)
      const dmMessageRef = ref(db, '/directMessages/' + msgCtx.userId + '/' + timeStamp);        
  
      set(msgCtx.origin === "netChat" ? messageRef : dmMessageRef, messagePayload).then((snapshot) => {
      

        if(msgCtx.origin === "dmChat") {
          msgCtx.setDetails("dmChat", {updated: "yes", dmId: modalCtx.details.selectedNetwork});
        } else {
          msgCtx.setDetails("", "");
        }

        setUploading(false)
        closeHandle()

      }, (error) => console.log(error)
  )  
    
  }


  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearchQueryKey = (e) => {
    if (e.code === "Enter" && searchQuery) {
      getGifs()
    }
    console.log(e)
  }

  // colse modal
  const closeHandle = () => {
    modalCtx.closeModal();
  }

  console.log(gifs)

  return (
    <div className='gif-upload-container'>
      <div className="title">
        <h2>search gifs</h2>
        <CloseRounded  onClick={() => closeHandle()}/>
      </div>
      <div className="search-gifs-container">
        <div className="search-gifs">
          <input type="text" placeholder='Search for gifs...' onChange={handleSearchQuery} onKeyPress={handleSearchQueryKey} />
          <Button className='search-gif-btn' disabled={!searchQuery} onClick={() => getGifs()} >Search</Button>
        </div>
        {
            isLoading || uploading ? <div className="gif-results-loading"><CircularProgress  style={{'color': '#007C89'}} size={60} /></div> : null
        }
        <div className="gif-results" style={isLoading || uploading ? {display: "none"} : null}>
          {
            gifs?.map((gif, i) => (
              <div className="gif" key={gif.id} onClick={() => handleGifClick(gif?.images?.downsized_medium?.url)}>
                <img src={gif?.images?.fixed_width_small?.url} alt="gif" />
              </div>
            ))
          }
        </div>
        {gifs?.length === 0 ? <div style={isLoading ? {display: "none"} : null} className="search-the-gif" >Press Enter to search GIFs</div> : null}
      </div>
    </div>
  )
}

export default AddImage;
