import { useState, useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { CloseRounded } from '@mui/icons-material';

import AuthContext from '../../store/AuthContext.js';

import style from "./TermsOfUse.module.scss";

import Logo from '../../assets/img/home/logo.png'
import CircleIcon from "../../assets/img/banner/circle1.png";
import PentagonIcon from "../../assets/img/banner/pentagon.png";
import PolarIcon from "../../assets/img/banner/polar.png";
import SpiralIcon from "../../assets/img/banner/spiral.png";
import TriangleIcon from "../../assets/img/banner/triangle1.png";
import AvatarImage from '../../Components/Avatar/AvatarImage';
import FooterImage from '../../assets/img/footer/footer-img.png';

export const TermsOfUse = () => {

	const authCtx = useContext(AuthContext);
	const [openMenu, setOpenMenu] = useState(false);

	useEffect(() => {
		document.body.scrollTo(0, 0);
	}, []);


	return <div className={style.wrapper}>
		<div className={style.main}>
			<div
				className={'home-container ' + style.wrapper}
				style={openMenu ? {overflow: "hidden"} : null}
			>

				<section className="nav-container">
					<nav>
						<div className="logo"><img src={Logo} alt="logo" /></div>

						<div className="links">
							{
								authCtx.isLoggedIn ? (
									<div className="nav">
										<div className='nav-links'>
											<NavLink to="/networks"><span>Networks</span></NavLink>
											<NavLink to="/explore"><span>Explore</span></NavLink>
											<NavLink to="/messages"><span>Messages</span></NavLink>
										</div>
										<div className="profile">
											<span>{`Hi ${authCtx.userName}`}</span>
											<AvatarImage />
										</div>
									</div>
								) : (
									<div className='link-container'>
										<NavLink to="/login"><button className='login-button'>Login</button></NavLink>
										<NavLink to="/register"><button className='register-button'>Sign Up</button></NavLink>
									</div>
								)
							}
						</div>
						<MenuRoundedIcon className='menu-icon' style={openMenu ? {display: "none"} : null} onClick={() => setOpenMenu(true)} />
						<div className={openMenu ? "open mobile-links" : "mobile-links close"}>
							<CloseRounded onClick={() => setOpenMenu(false)} />
							{
								authCtx.isLoggedIn ?

									<div className="nav">
										<div className='nav-links'>
											<NavLink to="/home"><span>Home</span></NavLink>
											<NavLink to="/networks"><span>Networks</span></NavLink>
											<NavLink to="/explore"><span>Explore</span></NavLink>
											<NavLink to="/messages"><span>Messages</span></NavLink>
										</div>
										<div className="profile">
											<span>{`Hi ${authCtx.userName}`}</span>
											<AvatarImage />
										</div>
									</div>
									:
									<div className='link-container'>
										<NavLink to="/login"><button>Login</button></NavLink>
										<NavLink to="/register"><button>Register for Free</button></NavLink>
									</div>
							}
						</div>
					</nav>
				</section>

				<main className={"main-content " + style.wrapper__main}>
					<div className={"banner-bg " + style.wrapper__main__bg} >
						<svg width={"101%"} height={"100%"} viewBox="1 0 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<linearGradient gradientUnits="userSpaceOnUse" x1="250.199" y1="-0.089" x2="250.199" y2="273.474" id="gradient-1" gradientTransform="matrix(-0.000795, -0.999999, 1.833572, -0.001457, -0.237588, 380.700831)">
									<stop offset="0" style={{ stopColor: 'rgb(28, 157, 164)'}}/>
									<stop offset="0.245" style={{ stopColor: 'rgb(64, 110, 170)'}}/>
									<stop offset="0.496" style={{ stopColor: "rgb(145, 76, 132)"}}/>
									<stop offset="0.768" style={{ stopColor: 'rgb(199, 74, 90)'}}/>
									<stop offset="1" style={{ stopColor: "rgb(243, 132, 40)"}}/>
								</linearGradient>
							</defs>
							<path
								style={{
									paintOrder: 'fill',
									fill: 'url(#gradient-1)',
									fillRule: 'nonzero'
								}}
								d="M 500 271.166 L 500.399 0 L 0.399 -0.089 L 0 273.474 C 0 273.474 23.563 238.729 40.735 240.726 C 57.907 242.723 62.301 256.302 77.476 259.497 C 92.651 262.692 102.236 222.756 116.613 217.164 C 130.99 211.572 149.761 216.765 166.134 260.295 C 182.507 303.825 192.492 201.588 214.457 189.607 C 236.422 177.626 245.608 280.263 264.777 273.074 C 283.946 265.885 285.944 192.403 300.72 187.212 C 315.496 182.021 334.265 208.378 338.658 212.771 C 343.051 217.164 347.844 224.353 361.023 240.727 C 374.202 257.101 388.978 278.666 414.936 268.681 C 440.894 258.696 431.592 261.124 458.067 246.317 C 484.542 231.51 500 271.166 500 271.166 Z"/>
						</svg>
						<img className={"position-absolute"} style={{ left: '-75px', bottom: '55%'}} src={SpiralIcon} alt=""/>
						<img className={"position-absolute"} style={{ right: '-95px', top: '0'}} src={SpiralIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '8%', top: '100px'}} src={TriangleIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '10%', top: '100px'}} src={TriangleIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '64px', left: '35%', top: '80px'}} src={PolarIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '64px', right: '20%', top: '280px'}} src={PolarIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-20deg)', width: '64px', left: '25%', top: '35%'}} src={PolarIcon} alt=""/>

						<img className={"position-absolute"} style={{ width: '60px', left: '42%', top: '470px'}} src={CircleIcon} alt=""/>
						<img className={"position-absolute"} style={{ width: '60px', right: '9%', top: '490px'}} src={CircleIcon} alt=""/>

						<img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '12%', top: '460px'}} src={PentagonIcon} alt=""/>
						<img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '25%', top: '650px'}} src={PentagonIcon} alt=""/>
					</div>
					<div className={style.wrapper__main__content}>
						<div className={style.title}>Terms of Use</div>
						<div className={style.updateInfo}>Last Updated June 23rd, 2022</div>
						<div className={style.form}>
							{/* <div className={style.policy}>
								<div className={style.policy__title}>Accepting the terms</div>
								<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
							</div> */}
							<div className={style.policy}>
								<div className={style.summary}>
									<div className={style.summary__title}>READ TERMS OF USE BEFORE ACCESSING WEB SITE AND MOBILE APP</div>
									<p>The Frequency Platform is a product owned by SpokeHub, Inc. The terms of this Privacy Policy are applicable to both the Frequency Platform as well as SpokeHub, Inc.</p>
									<p>This is an agreement (the “Agreement”) between you and the Frequency Platform (“Frequency” or “we”). Please read the following terms and conditions carefully. These terms, along with the Privacy Policy discussed below, are called the “Terms,” and they govern your access to and use of the Frequency website or mobile application (the “Application”) and any related interactive and informational services made available to consumers on any media platform (the “Services”). Together, the Application and the Services are called the “Platform.”</p>
									<p>This Agreement constitutes a binding legal agreement between you as a user of the Platform, and Frequency as the provider of the Platform. Your access to and use of the Platform requires your compliance with this Agreement and the Terms.</p>
									<p>By downloading the Application or accessing and/or using any portion of the Platform, you agree to be bound by this Agreement.</p>
									<p>ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND FREQUENCY AGREE THAT DISPUTES BETWEEN YOU AND FREQUENCY WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND FREQUENCY WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS – ACTION LAWSUIT OR CLASS – WIDE ARBITRATION.</p>
									
									<div className={style.summary__title}>LICENSE</div>
									<p>Subject to your compliance with the Terms, you may download and install one (1) copy of the Application to your supported device, as well as access and use the Services, solely for your own personal and non – commercial use.</p>
									<p>You may not:</p>
									<ol>
										<li>copy, modify, or distribute the Application for any purpose;</li>
										<li>transfer, sublicense, lease, lend, rent, or otherwise distribute the Application or the Services to anyone else;</li>
										<li>reverse – engineer, decompile, or create derivative works of the Application or the Services;</li>
										<li>make the functionality of the Application or the Services available to multiple users through any means;</li>
										<li>use multiple accounts for any single individual user; or</li>
										<li>use the Services in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with the Terms.</li>
									</ol>
									<p>No other licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Frequency or its licensors.</p>

									<div className={style.summary__title}>MESSAGING</div>
									<p>While we generally try to avoid push notifications and messages, as part of the Services we provide, you may receive occasional push notifications, text messages, picture messages, alerts, emails, or other types of messages directly sent to you outside or inside the Application (“Push Messages”). You have control over the Push Messages settings and can opt in or out of these Push Messages through the Services, except for infrequent important service announcements and administrative messages. Please be aware that messaging fee s may apply depending on the message plan you have with your wireless carrier.</p>
								
									<div className={style.summary__title}>REGISTRATION</div>
									<p>In order to access certain features of the Services, you must create a file with us (an “Account”). Even if you do not register with a username and/or password, we still need to create an Account for you to customize your Application experience and show you more relevant information. To create an Account and participate in the Platform, you must be at least 13 years of age or older at the time of registration. If you are using the Services on behalf of a business or some other entity, you represent that you are authorized to grant all licenses set forth in these Terms and to agree to these Terms on behalf of such business or entity.</p>								
									<p>If you register, you will be asked to choose a username and a password. You agree not to disclose your password to anyone else and that you will be solely responsible for any activities or actions taken under your Account, whether or not authorized by you. Please notify us immediately of any unauthorized use of your password or your Account. We are not liable for any loss or damage from your failure to comply with these requirements.</p>
									<p>You agree that the information you provide us will be true, accurate, current, and complete. By creating an Account, you consent to the use of: (i) electronic means to complete the Terms and to provide you with any notices given pursuant to the Terms; and (ii) electronic records to store information related to the Terms or your use of the Services.</p>
								
									<div className={style.summary__title}>PRIVACY</div>
									<p>Our goal is to make the Platform as useful and rewarding for you as possible. To do that, we need to collect and process information from you when you use the Platform.</p>
									<p>We will collect certain personally identifiable information from you as set forth in more detail in our Privacy Policy, which is part of the Terms. When you use the Platform, Frequency has access to your usage of the Platform as you send and receive Content (as defined below) and in many cases will monitor your usage. By accessing and using the Platform, you agree that we may collect, use, and disclose certain information you provide during your access to or use of the Platform, as set forth in the Privacy Policy.</p>
									<p>As part of providing you the Platform we may need to provide you with certain infrequent service communications, such as non – advertisement service announcements and administrative messages (“Service Communications”). These Service Communications are considered part of the Services and your Account, and you will not be able to opt – out from receiving them.</p>
								
									<div className={style.summary__title}>PAYMENT</div>
									<p>The use of the Services and Applications is currently made available to users free of charge; however, while it is not currently intended, we reserve the right to charge a fee for using the Services at any time. If we begin charging a fee for your continued access to the Services, we will give you at leas t 15 days prior notice so you can opt out of the Services.</p>
								
									<div className={style.summary__title}>CONTENT</div>
									<p>Certain portions of the Platform may allow you to post or upload text, graphics, images, and other content for display or publication through the Services (“Content”). By submitting Content to Frequency , you hereby grant Frequency a worldwide, royalty – free, sublicenseable , and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the Content in connection with the Services and Frequency ‘s (and its successor’s) business, including without limitation for promoting and redistributing part or all of the Platform (and derivative works thereof) in any media formats and through any media channels. You hereby grant Frequency the non – exclusive right to use your name, likeness, images , and biographical information in its discretion in association with your content. Based on your settings, if you publish images or other such content through the Services , they may be viewed by and shared with other users.</p>
									<p>You agree not to:</p>
									<ol>
										<li>submit material that violates anyone else’s proprietary rights, including privacy and publicity rights, or can be considered spam or junk mail;</li>
										<li>knowingly publish false or inaccurate information;</li>
										<li>submit material that is unlawful, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate;</li>
										<li>post advertisements, “pyramid schemes,” chain letters or other solicitations ; or</li>
										<li>impersonate another person</li>
									</ol>
									<p>Frequency does not endorse any Content or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with any Content. We reserve the right to decide whether Content is appropriate and complies with the Terms, and may remove such Content and/or terminate your access for uploading material in violation of the Terms at any time without prior notice. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting, publishing, or otherwise making available Content or other materials that are believed to violate the Terms. We also reserve the right to reclaim user accounts on behalf of busi nesses or individuals that own a legal claim or trademark on the user’s name.</p>

									<p>It is Frequency ‘s policy to terminate your access to the Services if you infringe the copyright rights of others, upon receipt of proper notification to Frequency by the copyright owner or the copyright owner’s legal agent.</p>
									<p>Without limiting the foregoing, if you believe that your work has been copied and posted on the Services in a way that constitutes copyright infringement, please provide our Support <a href="mailto:support@spokehub.co">info@frequencyworld.co</a> with the following information:</p>
									<ol>
										<li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
										<li>a description of the copyrighted work that you claim has been infringed;</li>
										<li> a description of where the material that you claim is infringing is located on the Services;</li>
										<li> your address, telephone number, and email address; </li>
										<li>a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</li>
										<li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
									</ol>
									<p>You may contact our Support at <a href="mailto:support@spokehub.co">info@frequencyworld.co</a></p>								
								
									<div className={style.summary__title}>SUSPENSION AND TERMINATION</div>
									<p>You may terminate your Account at any time and for any reason by deleting your account via the Application or by sending Frequency notice via email to</p>
									<p>You may contact our Support at <a href="mailto:support@spokehub.co">info@frequencyworld.co</a></p>
									<p>Upon any termination by you, your Account may no longer be accessible. Any termination request will be handled within 30 days of receipt of such a request by Frequency . Any suspension, termination , or cancellation will not affect your obligations to Frequency under the Terms (including but not limited to ownership, indemnification , and limitation of liability), which by their sense and context are intended to survive such suspension, termination , or cancellation.</p>
									<p>We may immediately terminate or suspend your access to the Services and remove any material (including any Content provided by you) from the Services or our servers in the event that you breach the Terms, at our sole discretion and without prior notice to you. We also reserve the right to terminate the Services or your access at any time and for any reason, including but not limited to the need to conduct maintenance or if we discontinue any portion of the Services. Upon termination, all licenses and other rights granted to you under the Terms will immediately cease. We will not be liable to you or any other person for termination of the Services or termination of your use of the Services.</p>
									<p>Upon any termination or suspension, any information (including Content) that you have submitted to the Services may no longer be accessed by you. Furthermore, we will have no obligation to maintain any information stored in our database related to your Account or to forwar d any information to you or any other person.</p>
								
									<div className={style.summary__title}>OWNERSHIP</div>
									<p>The Services and Application are protected by copyright, trademark, and other laws of the United States and foreign countries. SpokeHub and its licensors exclusively own all right, title , and interest in and to the Services and Application, including all associated intellectual property rights. You may not remove, alter , or obscure any copyright, trademark,service mark , or other proprietary rights or notices incorporated into or accompany ing the Services or Application. You may not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast , or otherwise exploit the Services, except as expressly permitted in the Terms. Any feedback, comments , and suggestions you may provide for improvements to the Services or Applications (“Feedback”) will be the sole and exclusive property of Frequency and yo u hereby irrevocably assign to Frequency all of your right, title, and interest in and to all Feedback.</p>
								
									<div className={style.summary__title}>THIRD PARTY SERVICES , WEBSITES, OR RESOURCES</div>
									<p>The Services may contain content or services provided by third parties and/or links to third party services, websites, or resources. We do not endorse such websites, services, or resources or the content, products, or services on or available from such websites, services, or resources, and we are not responsible or liable for the availability, accuracy, functionality, adherence to third party policies or Frequency policies, or legality of such websites, services, and resources. You are solely responsible for and assume all risk arising from your use of any such websites, services , or resources.</p>
								
									<div className={style.summary__title}>DISCLAIMERS</div>
									<p>Your use of the Platform is at your sole discretion and risk. We will not be responsible for any harm to your computer or mobile device, loss of data, personal injury, property damage, or other harm that results from your use of the Services and/or Application. We do not make any warranties: (i) regarding the security, accuracy, reliability, timeliness , and performance of the Services; or (ii) that the Services and/or Applications will be error – free or that any errors will be corrected. SpokeHub will not be liable, and you are solely responsible, for any access or usage charges charged by your wireless carrier related to any device that you use to access or use the Application or Services.</p>
									<p>We are not responsible for;</p>
									<ol>
										<li> incorrect or inaccurate transcription of information</li>
										<li> problems related to any of the equipment or software associated with the Services or Application or used by you;</li>
										<li> human error outside our reasonable control</li>
										<li>any interruption, deletion, omission, degradation, defect, or line failure of any telephone network or electronic transmission, or</li>
										<li>problems relating to inability to access an Application or the Services.</li>
									</ol>
									<p>We disclaim all liability for any technical failures or errors of, on, or connected to the Services and/or Application, including but not limited to Push Messages sent erroneously due to technical failures or errors. We will use reasonable efforts to correct any such failures swiftly upon discovering them or being informed about them. If you think you have received Push Messages in error or experienced other technical failures, please contact us immediately</p>
									<p>The Services and Application, and all materials, information, products , and services included therein, are provided on an “AS IS” and “AS AVAILABLE” basis without warranties of any kind.</p>
								
									<p>WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED , OR STATUTORY, RELATING TO THE SERVICES AND APPLICATIONS, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON – IN FRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING , OR COURSE OF PERFORMANCE.</p>
									<p>No advice or information, whether oral or written, obtained by you from Frequency will create any warranty not expressly stated in this Agreement.</p>
								
									<div className={style.summary__title}>INDEMNITY</div>
									<p>You agree to defend, indemnify, and hold SpokeHub and its officers, directors, employees , and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney's fees and costs, arising out of or in any w ay connected with (i) your access to or use of the Application or Services; (ii) your violation of the Terms; or (iii) your violation of any third party right, including without limitation any intellectual property right, publicity, confidentiality, property , or privacy right.</p>

									<div className={style.summary__title}>EXCLUSIVE VENUE</div>
									<p>IN NO EVENT WILL SpokeHub , ITS OFFICERS, DIRECTORS, EMPLOYEES , OR AGENTS, BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE , OR</p>
									<p>CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES AND/OR APPLICATION, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT SpokeHub HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL FREQUENCY ‘S CUMULATIVE LIABILITY TO YOU FOR ALL CLAIMS , REGARDLESS OF TYP E, EXCEED TWENTY UNIT ED STATES DOLLARS ($20.00 USD).</p>
									
									<div className={style.summary__title}>ARBITRATION, CLASS – A CTION WAIVER, AND JURY WAIVER</div>
									<p>PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU AND FREQUENCY TO AGREE TO RESOLVE ALL DISPUTES BETWEEN YOU AND FREQUENCY THROUGH BINDING INDIVIDUAL ARBITRATION.</p>
									<p>You and Frequency agree that all claims and disputes (whether contract, tort, or otherwise), including all statutory claims and disputes, arising out of or relating to these Terms or the use of the Services that cannot be resolved in small claims court will be resolved by binding arbitration on an individual basis, except that you and Frequency are not required to arbitrate any dispute in which either party seeks equitable relief for the alleged unlawful use of copyrig hts, trademarks, trade names, logos, trade secrets, or patents. To be clear: The phrase “all claims and disputes” also includes claims and disputes that arose between us before the effective date of these Terms.</p>
									<p>The Federal Arbitration Act governs the interpretation and enforcement of this dispute – resolution provision. Arbitration will be initiated through the American Arbitration Association (“AAA”) and will be governed by the AAA Consumer Arbitration Rules, available here (link: https://www.adr.org/Active Rules ) as of the date of these Terms, or by calling the AAA at 1 – 800 – 778 – 7879. If the AAA is not available to arbitrate, the parties will select an alternative arbitral forum. The rules of the arbitral forum will govern all aspects of this arbitration, exc ept to the extent those rules conflict with these Terms. The arbitration will be conducted by a single neutral arbitrator. Any claims or disputes where the total amount sought is less than $10,000 .00 USD may be resolved through binding non – appearance – based arbitration, at the option of the party seeking relief. For claims or disputes where the total amount sought is $10,000 .00 USD or more, the right to a hearing will be determined by the arbitral forum’s rules. Any judgment on the award rendered by the arbi trator may be entered in any court of competent jurisdiction .</p>
									<p>If non – appearance arbitration is elected, the arbitration will be conducted by telephone, online, written submissions, or any combination of the three; the specific manner will be chosen by the party initiating the arbitration. The arbitration will not involve any personal appearance by the parties or witnesses unless the parties mutually agree otherwise .</p>
									<p>The arbitrator will decide the jurisdiction of the arbitrator and the rights and liabilities, if any, of you and Frequency . The dispute will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator will have the a uthority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non – monetary remedy or relief available to an individual under law, the arbitral forum’s rules, and the Terms. The arbitrator will issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to awa rd relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and Frequency.</p>
									<p>YOU AND FREQUENCY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and SpokeHub are instead electing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited r eview by a court. In any litigation between you and Frequency over whether to vacate or enforce an arbitration award, YOU AND FREQUENCY WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</p>
									<p>ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor we are entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth below.</p>
									<p>Any rights and limitations set forth in this arbitration agreement may be waived by the party against whom the claim is asserted. Such waiver will not waive or affect any other portion of this arbitration agreement .</p>
									<p>You may opt out of this arbitration agreement. If you do so, ne ither you nor Frequency can force the other to arbitrate. To opt out, you must notify Frequency in writing no later than 30 days after first becoming subject to this arbitration agreement. Your notice must include your name and address, your Frequency usernam e and the email address you used to set up your Frequency account (if you have one), and an unequivocal statement that you want to opt out of this arbitration agreement.</p>
									<p>IN NO EVENT WILL SPOKEHUB , ITS OFFICERS, DIRECTORS, EMPLOYEES , OR AGENTS, BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE , OR</p>
									<p>This arbitration agreement will survive the termination of your relationship with Spoke Hub.</p>

									<div className={style.summary__title}>GENERAL</div>
									<p>The Terms shall be governed by North Carolina law, without respect to its conflict of laws principles.</p>
									<p>The Terms are the entire agreement between you and SpokeHub concerning the Platform, and supersede all prior or contemporaneous communications between you and SpokeHub with respect to the Platform. If any provision of the Terms is deemed invalid by a court of competent jurisdiction, the remaining provisions will remain in full force and effect. No waiver of any term of the Terms shall be deemed a further or continuing w aiver of such term or any other term, and SpokeHub’ s failure to assert any right or provision under the Terms shall not constitute a waiver of such right or provision.</p>
									<p>We reserve the right, in our sole discretion, to modify, discontinue , or terminate any o r all of the Platform at any time, or modify the Terms without notice. All modified terms and conditions will be immediately effective as of the date the modified Term s are posted on the Frequency website and will apply to all Accounts and customers enrolled in the Platform. If any modified terms and conditions are not acceptable to you, your sole remedy is to cease using the Services, and if applicable, terminate or cancel your Account. By continuing to access or use the Application and/or the Services afte r we have posted the new terms and conditions on the site, you agree to be bound by such changes. Customers will be notified by email or in the Application or through other available means if the Platform is suspended or cancelled. Notice of cancellation w ill also appear on the SpokeHub website.</p>
									<p>If you have any questions about the Terms, please contact us at</p>
									<p><a href='mailto:info@frequencyworld.co'>info@frequencyworld.co</a>info@frequencyworld.co</p>
									<p>We hope you enjoy Frequency ‘s Platform!</p>

								</div>
							</div>
							
						</div>
					</div>
				</main>


			</div>
			<footer className={"footer-section " + style.footer}>
				<div className="custom-container">
					<div className="footer-block">
						<img className={"footer-image"} src={FooterImage} alt=""/>
						<div className={"footer-link"}>
							<Link to={"/privacy-policy"}>Privacy Policy</Link>
							<Link to={"/terms-of-use"}>Terms of use</Link>
						</div>
					</div>
					<div className={"footer-copyright"}>Powered by SpokeHub, Inc. 2022 <sup>&copy;</sup></div>
				</div>
			</footer>
		</div>
	</div>
}

