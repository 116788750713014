import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage  from "../FirebaseConfig";
import AuthContext from '../../store/AuthContext.js';


const noMenuRoutes = ["/login"];

function AvatarChatImage({ avatarRef, title }) {

	const authCtx 							= useContext(AuthContext);
	const { pathname } 						= useLocation();
	const [avatarImage, setAvatarImage] 	= useState("");


	useEffect(() => {
		if(avatarRef !== "" && avatarRef !== null && avatarRef !== undefined) {
			getImg(avatarRef);
		}
  }, [avatarRef]);

  function getRandomColor(name) {
		
		let bgColorForSpan 	= 'rgb(0, 0, 0, 0.3)';
		let firstAlphabet		= "A";
		if(name) {
			// get first alphabet in upper case
			firstAlphabet			= name.charAt(0).toLowerCase();

			// get the ASCII code of the character
			const asciiCode = firstAlphabet.charCodeAt(0);

			// number that contains 3 times ASCII value of character -- unique for every alphabet
			const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

			var num = Math.round(0xffffff * parseInt(colorNum));
			var r = num >> 16 & 255;
			var g = num >> 8 & 255;
			var b = num & 255;
			bgColorForSpan = 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)'
		}
		return  <span className='initial' style={{background: bgColorForSpan}}>{firstAlphabet.toUpperCase()}</span>

	}
	


	const getImg = (coverRef) => {
		getDownloadURL(ref(storage, coverRef))
			.then((url) => {
				setAvatarImage(url);
			})
			.catch((error) => {
				console.log(error)
			});
  }

	
	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<>
			{avatarImage == "" ? getRandomColor(title)  : <img className='user-avatar' src={avatarImage} alt="avatar" />}
		</>
	);
}
export default AvatarChatImage;