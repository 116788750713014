import React, { useContext, useState, useEffect } from 'react';

import {  ref, update, remove } from "firebase/database";
import { database }  from "../FirebaseConfig";


import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import MessageContext from '../../store/MessageContext.js';

const MemberAction = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);


  const closeHandle = () => {
		modalCtx.closeModal();
    modalCtx.setDetails(null, null)
	  }

    // leaving the network
    const leaveNetwork = () => {
      
      fetch(process.env.REACT_APP_API_URI +   '/v2/hubs/' +  modalCtx.details.network + '/users/' + modalCtx.details.member.id + '/memberships', {
        method: 'DELETE',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        }
      }).then(response => {
          return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log(data)
          closeHandle()
        } else {
        }
      });
    }  


    // promote to moderator
    const promoteToMod = () => {

      let hubId = modalCtx.details.network
      var payload = {
              "userId": modalCtx.details.member.id,
              "hubId": hubId,
              "role": "moderator",
              "notification": "promoted-to-moderator"
            };
      
      fetch(process.env.REACT_APP_API_URI +   '/v2/hubs/' + hubId + '/membership', {
        method: 'PUT',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(payload)
      }).then(response => {
          return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log("promoted to mod")
          closeHandle()
        } else {
        }
      });
    }  


    // promte to owner
    const promoteToOwner = () => {

      let hubId = modalCtx.details.network
      var payload = {
        "userId": modalCtx.details.member.id,
        "hubId": hubId,
        "role": "owner",
        "notification": "promoted-to-owner"
      };
      
      fetch(process.env.REACT_APP_API_URI +   '/v2/hubs/' + hubId + '/membership', {
        method: 'PUT',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(payload)
      }).then(response => {
          return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log("promoted to owner")
          closeHandle()
        } else {
        }
      });
    }  
    
    // block user
    const blockUser = () => {

      let hubId = modalCtx.details.network
      var payload = {
        "userId": modalCtx.details.member.id,
        "hubId": hubId,
        "role": "blocked",
        "notification": "blocked-member"
      };
      
      fetch(process.env.REACT_APP_API_URI +   '/v2/hubs/' + hubId + '/membership', {
        method: 'PUT',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(payload)
      }).then(response => {
          return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log(data)
          closeHandle()
        } else {
        }
      });
    }  

    console.log(modalCtx)


  return (
    <>
      <div className="action-container">
        <div className="actions">

          <List>
              <ListItem>
                <ListItemButton onClick={() => promoteToMod()} >
                  <ListItemText  >Promote to moderator</ListItemText>
                </ListItemButton>
              </ListItem>
              
              <ListItem>
                <ListItemButton onClick={() => promoteToOwner()} >
                  <ListItemText >Promote to owner</ListItemText>
                </ListItemButton>
              </ListItem>
              
              <ListItem>
                <ListItemButton onClick={() => blockUser()} >
                  <ListItemText  >Block and remove</ListItemText>
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton onClick={() => leaveNetwork()}>
                  <ListItemText>Remove member</ListItemText>
                </ListItemButton>
              </ListItem>
          </List>
        </div>
      <Button className='cancel' onClick={() => closeHandle()} >Cancel</Button>
      </div>
    </>
  )
}

export default MemberAction;
