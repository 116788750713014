import React, { createContext, useState } from 'react';

const MessageContext = createContext({
  messageType: '',
  userId: '',
  origin: '',
  details: {},
  setDetails: (setDetails) => {},
  setOrigin: (setOrigin) => {},
});

export function MessageContextProvider(props) {

  const [messageType, setMessageType] = useState('');
  const [userId, setUserId] = useState('');
  const [origin, setOrigin] = useState('');
  const [details, setDetails] = useState({});

  function setDetailsHandler(message, data) {
    setMessageType(message);
    setDetails(data);
  }

  function setOriginHandler(id, data) {
    setUserId(id)
    setOrigin(data);
  }

  const context = {
    messageType: messageType,
    userId: userId,
    details: details,
    origin: origin,
    setDetails: setDetailsHandler,
    setOrigin: setOriginHandler,
  };

  return <MessageContext.Provider value={context}>
    {props.children}
  </MessageContext.Provider>
}

export default MessageContext;