import React, { useState, useEffect, useContext } from 'react';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../../../Components/FirebaseConfig";
import AuthContext from '../../../store/AuthContext';
import { NULL } from 'sass';
import Carousel from 'react-material-ui-carousel';

const NetworkBanner = ({ bannerLink, activeNetworkId }) => {

  const authCtx = useContext(AuthContext)

  const [networkBanners, setNetworkBanners] = useState({ urls: [] });
  const [bannerData, setBannerData] = useState([]);


  useEffect(() => {
    getBannerData()
  }, [activeNetworkId]);

  useEffect(() => {
    if (bannerData.length !== 0) {
      for (let i = 0; i < bannerData.length; i++) {
        getImg(bannerData[i].banner_ref)
      }
    }
  }, [bannerData]);



  const getImg = (bannerRef) => {
    getDownloadURL(ref(storage, bannerRef))
      .then((url) => {
        setNetworkBanners(prev => ({ urls: [...prev.urls, url] }));
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getBannerData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${activeNetworkId}/banners`, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
    })
    const data = await response.json()

    if (data.status == 'success') {
      if (bannerData.length == 0) {
        setBannerData(data.banners)
      }
    }
  }

  const checkBannerType = (networkBanners) => {
    if (networkBanners?.urls?.length) {
      return networkBanners?.urls?.map((item, i) => (
        <img key={i} src={item} alt="banner" />
      ))
    }
  }

  console.log(networkBanners)

  return (
    <div className='network-banner' >
      <Carousel height={425} autoPlay >
        {checkBannerType(networkBanners)}
      </Carousel>
    </div>
  )
}

export default NetworkBanner