import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import EqualizerIcon from '@mui/icons-material/Equalizer'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AssessmentIcon from '@mui/icons-material/Assessment'
import TranslateIcon from '@mui/icons-material/Translate'
import ExploreIcon from '@mui/icons-material/Explore'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

import avatar4 from '../assets/img/avatar/avatar4.jpg'

import { Link } from 'react-router-dom'

import AuthContext from '../store/AuthContext.js'

// import '../App.scss'
// import '../Styles/NetworkLeft.scss'

const noSideBarRoutes = ['/login', '/register']

function SideBar() {
  //collaps state
  const [collaps, setCollaps] = useState(false)

  //collaps toggle
  const toggleCollaps = () => {
    if (collaps === false) {
      setCollaps(true)
    } else {
      setCollaps(false)
    }
  }
  //Styles that will be applied when collaps button is clicked
  const setHidden = {
    display: collaps ? 'none' : 'block',
  }
  const flexShrink = {
    flex: collaps ? '0 1 50px' : '0 1 300px',
  }
  const rotate = {
    transform: collaps ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
  }
  const addMargin = {
    marginBottom: collaps ? '42px' : '10px',
  }

  const authCtx = useContext(AuthContext)
  const { pathname } = useLocation()

  const isLoggedIn = authCtx.isLoggedIn

  if (noSideBarRoutes.some((item) => pathname.includes(item))) return null

  return (
    <div style={flexShrink} className="menu">
      {/* <div style={addMargin} className="profile-wrapper">
				<div className="profile">
					<div  className="profilePicture">
						<img src={avatar4} alt="" />
						<div className="notify"></div>
					</div>

					<span style={setHidden}>Darlene Robertson</span>

					<div style={setHidden} className="edit">
						<EditOutlinedIcon />
					</div>
				</div>
			</div> */}
      <nav>
        {!isLoggedIn && (
          <Link to="/login">
            <EqualizerIcon />
            <span style={setHidden}>Login</span>{' '}
          </Link>
        )}
        {isLoggedIn && (
          <Link to="/home">
            <HomeRoundedIcon />
            <span style={setHidden}>Home</span>
          </Link>
        )}
        {isLoggedIn && (
          <Link to="/networks">
            <EqualizerIcon />
            <span style={setHidden}>Networks</span>
          </Link>
        )}
        {isLoggedIn && (
          <Link to="/explore">
            <PeopleAltIcon />
            <span style={setHidden}>Explore</span>
          </Link>
        )}
        {isLoggedIn && (
          <Link to="/profile">
            <AssessmentIcon />
            <span style={setHidden}>Profile</span>
          </Link>
        )}
        {isLoggedIn && (
          <a onClick={() => authCtx.logout()}>
            <ExitToAppIcon />
            <span style={setHidden}>Logout</span>
          </a>
        )}
        {isLoggedIn && (
          <a onClick={toggleCollaps}>
            <ArrowForwardRoundedIcon style={rotate} />
          </a>
        )}
      </nav>
    </div>
  )
}
export default SideBar
