import styles from "./PhoneSlider.module.scss";

import OnePhone from "../../assets/img/home/waves-discover.png";
import TwoPhone from "../../assets/img/home/waves-network.png";
import ThreePhone from "../../assets/img/home/universe-home.png";
import FourPhone from "../../assets/img/home/universe.png";
import { useRef, useEffect } from "react";


const PhoneSlider = () => {
  const timeoutClearCollector = useRef(null);
  const isAnimationFinished = useRef(true);
  const wrapper = useRef(null);

  const clearAnimationClass = (elements) => {
    elements.forEach((element) => {
      element.classList.remove(styles.toCenter__transform);
      element.classList.remove(styles.toRight__transform);
      element.classList.remove(styles.toLeft__transform);
      element.classList.remove(styles.toBehind__transform);
      element.classList.remove(styles.wrapper__images__one);
      element.classList.remove(styles.wrapper__images__two);
      element.classList.remove(styles.wrapper__images__three);
      element.classList.remove(styles.wrapper__images__four);
    });
  };

  const forwardScroll = () => {
    clearTimeout(timeoutClearCollector.current);

    if ( wrapper.current ) {
      wrapper.current.classList.add(styles.wrapperRotatorClass);

      const firstImage = document.getElementsByClassName(
        styles.wrapper__images__one
      )[0];
      //
      const secondImage = document.getElementsByClassName(
        styles.wrapper__images__two
      )[0];
  
      const thirdImage = document.getElementsByClassName(
        styles.wrapper__images__three
      )[0];
  
      const fourImage = document.getElementsByClassName(
        styles.wrapper__images__four
      )[0];
  
  
      isAnimationFinished.current = false;
  
      firstImage.classList.add(styles.toCenter__transform);
  
      secondImage.classList.add(styles.toRight__transform);
  
      thirdImage.classList.add(styles.toBehind__transform);
  
      fourImage.classList.add(styles.toLeft__transform);
  
      timeoutClearCollector.current = setTimeout(() => {
        clearAnimationClass([firstImage, secondImage, thirdImage, fourImage]);
  
        firstImage.classList.add(styles.wrapper__images__two);
  
        secondImage.classList.add(styles.wrapper__images__three);
  
        thirdImage.classList.add(styles.wrapper__images__four);
  
        fourImage.classList.add(styles.wrapper__images__one);
  
        isAnimationFinished.current = true;
      }, 400);
    }
  };
  
  const animationInvoker = (e) => {
    if (isAnimationFinished.current ) {
      forwardScroll()
    }
  };

  useEffect(() => {
    setInterval(animationInvoker, 8000);
  }, [])
  

  return (
    <div className={styles.wrapper} ref={wrapper}>
      <div
        className={"banner-images d-flex " + styles.wrapper__images}
      >
        <div className={"banner-image-item"}>
          <div 
            className={
              "banner-image banner-image-one " +
              styles.wrapper__images__one +
              " " +
              styles.wrapper__images__image
            }
          > 
            <img
              style={{
                height: '100%',
                width: '100%'
              }}
              src={OnePhone}
              alt=""
            />
            <div className={styles.slider__slide__description}>
              <h1> Wave Feed - Discover </h1>
              <p> View all posts, react and comment on it. </p> 
            </div>

          </div> 
        </div>
        <div className={"banner-image-item"}>
          <div 
            className={
              "banner-image banner-image-two " +
              styles.wrapper__images__two +
              " " +
              styles.wrapper__images__image
            }
          > 
            <img
              style={{
                height: '100%',
                width: '100%'
              }}
              src={TwoPhone}
              alt=""
            />
            <div className={styles.slider__slide__description}>
              <h1> Wave Feed - Networks </h1>
              <p> View recommended and featured networks and profiles. </p> 
            </div>
          </div> 
        </div>
        <div className={"banner-image-item"}>
          <div 
            className={
              "banner-image banner-image-three " +
              styles.wrapper__images__three +
              " " +
              styles.wrapper__images__image
            }
          > 
            <img
              style={{
                height: '100%',
                width: '100%'
              }}
              src={ThreePhone}
              alt=""
            />
            <div className={styles.slider__slide__description}>
              <h1> Universe - Home </h1>
              <p> View universes recommended and featured networks and profiles. </p> 
            </div>
          </div> 
        </div>
        <div className={"banner-image-item"}>
          <div 
            className={
              "banner-image banner-image-four " +
              styles.wrapper__images__four +
              " " +
              styles.wrapper__images__image
            }
          > 
            <img
              style={{
                height: '100%',
                width: '100%'
              }}
              src={FourPhone}
              alt=""
            />
            <div className={styles.slider__slide__description}>
              <h1> Universe </h1>
              <p> Explore the universes and create your own. </p> 
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default PhoneSlider;
