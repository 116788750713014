import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../../../Components/FirebaseConfig";
import AuthContext from '../../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';
import Download from '../../../assets/img/networks/download.svg'

const noMenuRoutes = ["/login"];

function GetFirebaseUrl({ fileRef, file }) {

  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const [avatarImage, setAvatarImage] = useState("");

  useEffect(() => {
    if (fileRef !== "" && fileRef !== null && fileRef !== undefined) {
      getImg(fileRef);
    }
  }, [fileRef]);

  const getImg = (coverRef) => {

    const isPresent = coverRef.includes('https://');

    isPresent ?
      setAvatarImage(coverRef)
      :
      getDownloadURL(ref(storage, coverRef))
        .then((url) => {
          setAvatarImage(url);
        })
        .catch((error) => {
          console.log(error)
        });
  }




  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {
        avatarImage
          ? <a href={avatarImage} rel="noreferrer" download={file?.titleName ? file?.titleName : file?.fileName} target='_blank'>
            <img className='attachment-action' src={Download} alt="download attachment" />
          </a>
          : <img className='attachment-action' src={Download} alt="download attachment" />
      }
    </>
  );
}
export default GetFirebaseUrl;