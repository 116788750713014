import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CircularProgress from '@mui/material/CircularProgress';


import AuthContext from '../store/AuthContext.js';


import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { CheckCircle } from "@mui/icons-material";
AOS.init();

// const stripePromise = loadStripe("pk_test_51L8kJIByqzP5Ykgqjb3owFykoKDKTdegzQZfrR87rcad5BbofFacQMp5C3SiIAAXqk0Krvlb80tgYMuAmygbZVQ700dmhrX5dD");
const stripePromise = loadStripe("pk_live_51L8kJIByqzP5YkgqTBWAOEftejoIMs29tf9IlXFZWb0YvcD82gSjuteTL6W1IgW6hkscGvvC3zm8EsKpmwqw1uPd00uTlamd1C");

const StripeConnect = () => {

  const { userId, source }  = useParams();
	const authCtx = useContext(AuthContext);

	const [isLoading, setIsLoading] = useState(true);
	const [setupComplete, setSetupComplete] = useState(false);

  useEffect(() => {
    getSetupProgress();
  }, []);	

	const getSetupProgress = () => {
		
		fetch(process.env.REACT_APP_API_URI + '/v2/stripe/connect/check/' + userId + '/progress', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        if(data.connectAccount == "setup") {
          setIsLoading(false);
          setSetupComplete(true);
        } else if(data.connectAccount == "process") {
          setTimeout(() => {getAccountSetup()}, 2000);
        }
        
        // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

  const getAccountSetup = () => {

		fetch(process.env.REACT_APP_API_URI + '/v2/stripe/connect/check/' + userId + '/stripeprogress', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        if(data.connectAccount == "setup") {
          setIsLoading(false);
          setSetupComplete(true);
        } else if(data.connectAccount == "process") {
          setTimeout(() => {getAccountSetup()}, 2000);
        }
        
        // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

	const createConnectedAccount = () => {

		let jsonBody = JSON.stringify({ userId: authCtx.userId });

		fetch(process.env.REACT_APP_API_URI + '/v2/stripe/connect', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        window.open(data.data.url);
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

  const showCurrentStatus = () => {

    if(isLoading) {
      return <>
        <CircularProgress size={60} />
        <br/><br/>
        Checking account setup.<br/>Note this can take a few moments
      </>
    } else if(setupComplete) {
      return <>
        <CheckCircle fontSize={"large"} />
        <br/><br/>
        <strong>Congratulations!</strong><br/>
        Your account setup is complete!<br/>
        Return to the app and start creating paid networks.<br/><br/>
        <button><a href="https://frequencyworld.page.link/user-profile">Start Creating</a></button>
      </>
    } else {
      return <>
        <CircularProgress size={60} />
        <br/><br/>
        <strong>Account not yet setup.</strong><br/><br/>
        If you've you completed all account setup you can wait for an email for account approval.<br/>
        You can also check or update your details by clicking on the button below.<br/><br/>
        <button onClick={() => createConnectedAccount()}>Continue setup</button>
      </>
    }

  }

	return (
		<div className="container loading connect-setup">
      { showCurrentStatus() }   
		</div>
	);

}
export default StripeConnect;