import React from "react";
import { CheckCircle } from "@mui/icons-material";

const CreatorPaymentComplete = () => {

	return (
		<div className="container loading connect-setup">
      <>
        <CheckCircle fontSize={"large"} />
        <br/>
        <strong>Payment completed.</strong><br/>
        
        You now need to setup a Stripe Connect account. This will allow you to receive payments into your account.
        Tap on the button below to return to the app.<br/><br/>

        If it doesn't start the process automatically, please go to your profile settings
        and tap on the "Manage paid networks" option again. 
        After this setup is done you will be able to create paid networks.<br/><br/>
        <button><a href="https://frequencyworld.page.link/creator-connect-setup">Start Creating</a></button>
      </>
		</div>
	);

}
export default CreatorPaymentComplete;