
import React, { useState, useEffect } from 'react'
import { ref, onValue, orderByChild, limitToLast } from "firebase/database";
import { database } from "../FirebaseConfig";

const LastMessage = ({ selectedNetwork }) => {



  const db = database;

  const [network, setNetwork] = useState(selectedNetwork)
  const [chats, setChats] = useState([]);
  const [lastMessage, setLastMessage] = useState('');
  const [userName, setUserName] = useState('');

  let chat = []

  useEffect(() => {

    if (network) {
      const dbRef = ref(db, '/hubChat/' + network, orderByChild('timestamp'), limitToLast(50));
      onValue(dbRef, (snapshot) => {
        setChats([]);
        setChats(snapshotToArray(snapshot));
      });

    }

  }, [network, db])

  const snapshotToArray = (snapshot) => {
    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      //console.log(item)
      returnArr.push(item);
    });
    chat = []
    chat.push(returnArr.reverse())
    setLastMessage(chat[0][0]?.message)
    setUserName(chat[0][0]?.userName)
    return returnArr.reverse();
  }


  return (
    <>
      {!lastMessage ?
        <span className='network-last-message' >No message</span>
        :
        <div className='network-last-message' >{userName ? userName : "Anonymous"}: <span className='message'  >{chat[0]?.attachment ? "Media" : lastMessage}</span></div>}
    </>
  )
}

export default LastMessage