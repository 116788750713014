import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '../store/AuthContext.js';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { ProfileDetails } from './ProfileDetails.jsx';
import { NotificationSetting } from '../Components/Profile/NotificationSetting.jsx';
import { useLocation, useParams } from 'react-router-dom';
AOS.init();

const Profile = () => {

	const authCtx 			= useContext(AuthContext);
	const params = useParams();
	const id = params.id;
	console.log(id);
	console.log(authCtx.userId);
	const createConnectedAccount = () => {

		let jsonBody = JSON.stringify({ userId: authCtx.userId });

		fetch(process.env.REACT_APP_API_URI + '/v2/stripe/connect', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
			console.log(data);
      if(data.status == "success") {
        // navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

	}

	const [ownProfile, setOwnProfile] = useState(false);

	useEffect(() => {
		if(id && authCtx.userId && (id === authCtx.userId)){
			setOwnProfile(true);
		}
	}, [id, authCtx.userId])

	useEffect(() => {
		console.log(ownProfile);
	}, [ownProfile])

	// useEffect(() => {
	// 	getUserData();
	// }, [])

	// const getUserData = () => {
		
	// 	let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, }, };
	// 	Promise.all([
	// 	  fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId + "/memberships", options)
	// 		.then((response) => response.json()), 
	// 	//   fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId + "/networkMembership", options)
	// 	// 	.then((response) => response.json()),
	// 	  fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId + "/banners", options)
	// 		.then((response) => response.json()),
	// 	  fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId + "/links", options)
	// 		.then((response) => response.json()),
	// 	  fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId +  "/followers", options)
	// 		.then((response) => response.json()),
	// 	  fetch(process.env.REACT_APP_API_URI + "/v2/user/" + authCtx.userId +  "/following", options)
	// 		.then((response) => response.json()),
	// 	])
	// 	.then((results) => {
	// 		// console.log(results)
	// 	const data = results
	// 	console.log(data)
	// 	}).catch((err) => {
	// 		console.log(err);
	// 	  }); 
	//   };
	
	

	return (
		<div className="container">
			{/* <header>
				<div data-aos="fade-up">
					<h1>Profile</h1>
				</div>
			</header> */}

			{/* <div className="profile-update-container"> */}
				<ProfileDetails ownProfile={ownProfile} id={id}  />
				{/* <NotificationSetting /> */}
			{/* </div> */}
			
			{/* <button onClick={() => createConnectedAccount()}>Earn from your networks</button> */}
		</div>
	);

}
export default Profile;