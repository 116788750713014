import React, { createContext, useState } from 'react';

const ModalContext = createContext({
  open: false,
  modal: 'none',
  details: {},
  pageUpdate: {},
  setDetails: (setDetails) => {},
  setPageUpdate: (setPageUpdate) => {},
  openModal: (openModal) => {},
  closeModal: (closeModal) => {}
});

export function ModalContextProvider(props) {

  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('none');
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});

  function openModalHandler() {
    setOpen(true);
  }
 
  function closeModalHandler() {
    setOpen(false);
  }

  function setDetailsHandler(modal, data) {
    setModal(modal);
    setDetails(data);
  }

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  const context = {
    open: open,
    modal: modal,
    details: details,
    pageUpdate: pageUpdate,
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    openModal: openModalHandler,
    closeModal: closeModalHandler
  };

  return <ModalContext.Provider value={context}>
    {props.children}
  </ModalContext.Provider>
}

export default ModalContext;