import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';

//assets
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomButton from '../../../common/CustomButton.jsx';
import { InfoOutlined } from '@mui/icons-material';

const ConfirmDeleteNetwork = () => {

  const navigate = useNavigate()

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [loading, setLoading] = useState(false)

  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  const deleteNetwork = async () => {

    setLoading(true)

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${modalCtx.details}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + authCtx.token, },
        }
      )
      const data = await res.json()

      if (data.status === 'success') {
        closeHandle()
        navigate("/networks");
        setLoading(false)
      }
    } catch (error) {
      console.log('error while getting members :', error)
    }
  }


  return (
    <>
      <div className='confirm-delete-network'>
        <div>
          <h3>Are you sure you want to delete this network ?</h3>
          <div className="notice">
            <InfoOutlined style={{color: "#c98300"}} />
            <span>
              This network will be deactivated for 30 day after which it will be deleted permanently.
            </span>
          </div>
            <h3>Deleting a network results in the following:</h3>
            <ul>
              <li>All network messages deleted.</li>
              <li>All live stream deleted.</li>
              <li>All members removed from the network.</li>
              <li>All attachments deleted.</li>
            </ul>
        </div>
        <div className="confirm-delete-btns">
          <CustomButton className="f-btn-bordered f-btn-half-width" onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton onClick={() => deleteNetwork()} className="f-btn f-btn-half-width">
            {
              loading
                ? <CircularProgress size={20} style={{ color: '#FFF' }} />
                : "Delete"
            }
          </CustomButton>
        </div>
      </div>
    </>
  )
}

export default ConfirmDeleteNetwork;
