import React, { useContext, useState, useEffect, useRef } from 'react';

//components
import TagPeople from '../../Wave/TagPeople'
import AddLocation from '../../Wave/AddLocation'
import ImportMedia from '../../Wave/ImportMedia'

//context
import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import AvatarImage from '../../Avatar/AvatarImage.jsx';

//mui
import { Close, CloseRounded, InfoRounded } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Snackbar, Tooltip } from '@mui/material';

//firebase
import { ref, push, child, update, set } from "firebase/database";
import storage, { database, uploadBytes, ref as sRef } from "../../../Components/FirebaseConfig";

//assets 
import PhotoVideoIconLarge from '../../../assets/img/wave/create-post/photo-video-icon-large.svg'
import PhotoVideoIconSmall from '../../../assets/img/wave/create-post/photo-video-icon-small.svg'
import PhotoVideoRedIconSmall from '../../../assets/img/wave/create-post/photo-video-red-icon.svg'
import LocationIcon from '../../../assets/img/wave/create-post/location-icon.svg'
import TagPeopleIcon from '../../../assets/img/wave/create-post/tag-people-icon.svg'

//scss
import '../../../Styles/Modal.scss'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { uploadBytesResumable } from 'firebase/storage';
import CustomButton from '../../../common/CustomButton.jsx';

const CreatePost = () => {

  //firebase db
  const db = database;

  //refs
  const FileRef = useRef(null)

  //context
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  //post states
  const [postText, setPostText] = useState('');
  const [files, setFiles] = useState([]);
  const [taggedMembers, setTaggedMembers] = useState([]);
  const [imageRef, setImageRef] = useState("");
  const [aspectRatio, setAspectRatio] = useState([]);
  const [media, setMedia] = useState([]);
  const [universeId, setUniverseId] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploadingMedia, setUploadingMedia] = useState(true);

  //states
  const [userData, setUserData] = useState();
  const [activeTab, setActiveTab] = useState('create');
  const [previewArray, setPreviewArray] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('Image size should below 3 MB');
  //boolean state
  const [importVisible, setImportVisible] = useState(false);
  const [tagUserOn, setTagUserOn] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [noPost, setNoPost] = useState(false);
  const [posting, setPosting] = useState(false);


  //effects
  // useEffect(() => {
  //   setTimeout(() => {
  //     modalCtx.closeModal()
  //   }, 5000);
  // }, [])
  
  useEffect(() => {

    Aos.init({ duration: 1000 });
    Aos.refresh();

    fetch(process.env.REACT_APP_API_URI + '/v2/user/' + authCtx.userId + "/profile" , {
      method: 'GET',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setUserData(data.data)
      } else {
      }
    });

  }, [])

  //close modal
  const closeHandle = () => {
		modalCtx.closeModal();
	}

  //handle import media visibility
  const handleImport = () => {
    setActiveTab('create')
    if(importVisible){
      setImportVisible(false)
    } else {
      setImportVisible(true)
    }
  }

  //remove the file from files array and preview array
  const removeFile = (removeIndex) => {

    if(files?.length > 0 && previewArray?.length > 0){
      const filteredFiles = files?.filter(function (file, i) {
        return file.name !== removeIndex;
      });
      const filteredArray = previewArray?.filter(function (file, i) {
        return file.name !== removeIndex;
      });
      setFiles(filteredFiles)
      setPreviewArray(filteredArray)
    }
  }


  //handle snackbar close
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false)
  }

  // handle post text
  const handlePostText = (e) => {
    let text = e.target.value
    setPostText(text)
  }

  //open tag people and close image and text
  const handleTagPeople = () => {
    setActiveTab('tag')
    setImportVisible(false)
  }

  //upload media
  const uploadMedia = () => {

    setPosting(true)

    if(files){

      const timestamp = Date.now();
      const promises = []
      let aspect = 1.0
      const mediaArr = []
      let aps = [1.0,1.91,0.8,0.56]

      files.map((file, i) => {
  
        let extension = file?.name.split('.').slice(-1)
        let filename = `${i}-${timestamp.toString()}.${extension}`
        let newImageRef = `waves/${authCtx.userId}/${filename}`;
        let ap = 1.0;
  
        // calculate aspect ratios
        if(file.type.includes('image')){
          const reader = new FileReader();
          reader.onload = (event) => {
            var img = new Image();
            img.src = event.target.result;
            ap = ((img.width / img.height).toString().substring(0,4))*1
            // console.log("ap before ", ap)
            // console.log( 'width  ', img.width) 
            // console.log( 'height ', img.height) 
            if(i === 0){
              let minDiff = Math.abs(ap - aps[0]);
              for(let j=1;j<4;j++){
                let diff =  Math.abs(ap - aps[j]);
                if(diff<minDiff){
                  ap = aps[j];
                  minDiff = diff;
                  aspect = ap;
                  console.log("ap after ", ap)
                  }
                }
              }
          }
          reader.readAsDataURL(file);
        }
  
        mediaArr.push(newImageRef);
  
        const storageRef = sRef(storage, newImageRef);
  
        const uploadTask = uploadBytesResumable(storageRef, file);
        promises.push(uploadTask)
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(prog);
            },
            (error) => console.log(error),
        );
      })
      Promise.all(promises)
          .then(() => {
            // console.log("tagged members", taggedMembers)
            // console.log("files", files)
            // console.log("media", mediaArr)
            // console.log("ap", aspect)
            // setAspectRatio(aspect)
            // setMedia(mediaArr)
            addPost(mediaArr, aspect)
          })
          .catch(err => console.log(err))

    } else {
      // console.log('didn"t  ran')
      addPost()
    }

  }

  //adding a post
  const addPost = (mediaArr, aspect) => {

    console.log(aspect)
    console.log(mediaArr)

    const timestamp = Date.now();
    let taggedHubs = [];
    let peoples = [];

    if(taggedMembers.length != 0){
      for(let i = 0; i < taggedMembers.length; i++){
        peoples.push({
          "id": taggedMembers[i].id,
          "name": taggedMembers[i].name,
          "username": taggedMembers[i].username,
          "avatar": taggedMembers[i].avatar,
        });
      }
    }

    // A post entry.
    var postPayload = {
      creator: authCtx.userName,
      creatorId: authCtx.userId,
      avatar: authCtx.avatarRef,
      textContent: postText,
      imageRef: mediaArr ? mediaArr[0] : "",
      users: peoples,
      locations: "",
      likes:{},
      comments:{},
      timestamp: timestamp,
      media: mediaArr,
      aspectRatio: aspect,
      taggedHubs: taggedHubs,
      universeId: universeId
    };
    console.log(postPayload)
    
    set(ref(db, `waves/${timestamp}/`), postPayload)
    .then(() => {
      console.log('success..../')
      setPostText('')
      setFiles([])
      setTaggedMembers([])
      setAspectRatio([])
      setMedia([])
      setProgress(0)
      setPreviewArray([])
      setPosting(false)
      modalCtx.closeModal();
    })
    .catch((error) => {
      console.log('error posting ', error)
    });
  }


// console.log(progress)

  // console.log("files", files)
  // console.log("media", media)
  // console.log("ap", aspectRatio)
  // console.log(previewArray)
  // console.log(openSnackBar)
  // console.log(snackbarMessage)
  console.log("tagged members", taggedMembers)


  if(noPost) return <h2>Creating posts is limited to smartphones for now.</h2>

  return (
    <>
      <div className="create-post-container">
          {/* <div className="create-post" style={activeTab == 'create' ? null : {display: 'none'} }> */}
          <div className="create-post" >
            <div className="post-header">
              <h3>Create</h3>
              <CloseRounded className='close' onClick={() => closeHandle()} />
            </div>

            <div className="profile-container">
              <div className="profile">
                  <div className="profile-img">
                      <AvatarImage />
                  </div>
                  <div className="profile-name">
                      <span className='user'>{userData?.name}</span>
                      <span className='username'>@{userData?.username}</span>
                  </div>
              </div>
            </div>

            <div className="post-text" style={importVisible || activeTab == 'tag' ? null : {flexGrow: '1'} }>
              <textarea className='post-input' placeholder="What's on your mind?" onChange={(e) => handlePostText(e)} />
            </div>

            <div className="import-media" style={activeTab == 'create' && importVisible ? null : {display: 'none'} } >
              <ImportMedia 
              removeFile={removeFile} 
              files={files} 
              setFiles={setFiles} 
              previewArray={previewArray} 
              setPreviewArray={setPreviewArray}
              setSnackbarMessage={setSnackbarMessage}
              setOpenSnackBar={setOpenSnackBar}
              />
              {/* <img src={CloseIcon} alt="close" className='import-close' onClick={() => removeFile()} /> */}
            </div>

            <div className="tag-users" style={activeTab == 'tag' ? null : {display: 'none'} } >
              <TagPeople taggedMembers={taggedMembers} setTaggedMembers={setTaggedMembers} setActiveTab={setActiveTab} />
            </div>

            {/* <div className="import-media-null" style={importVisible ? {display: 'none'} : null } ></div> */}

            <div className="add-to-post">
              <span>Add to Post</span>
              <img onClick={handleImport} src={importVisible ? PhotoVideoRedIconSmall : PhotoVideoIconSmall} alt="import media" />
              <img src={TagPeopleIcon} alt="Tag people" onClick={() => handleTagPeople()} />
              {/* <img src={LocationIcon} alt="location" onClick={() => setActiveTab('add-location')} /> */}
            </div>

            <div className="post-btn">
              {
                posting 
                ? <CircularProgress size={22} style={{color: '#fff'}} />
                : <CustomButton className='f-btn-full-width' onClick={() => uploadMedia()}>Post</CustomButton>
              }
              
            </div>
              {
                posting 
                ? <div className="cover-over"></div>
                : null
              }
          </div>



        {/* { activeTab == 'add-location' ? <AddLocation setActiveTab={setActiveTab} /> : null} */}
        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top',horizontal: 'center'}}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>


    </>
  )
}

export default CreatePost;