import React from 'react';
import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref, uploadString } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp ({
  apiKey: "AIzaSyCzWiJRsOZz3jM5E4USs1OZxGxonM68w0k",
  authDomain: "frequency-world.firebaseapp.com",
  databaseURL: "https://frequency-world-default-rtdb.firebaseio.com",
  projectId: "frequency-world",
  storageBucket: "frequency-world.appspot.com",
  messagingSenderId: "603210809948",
  appId: "1:603210809948:web:87e34c0ac0658116391c99",
  measurementId: "G-97BQE3BYGE"
});
 
// Firebase storage reference
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

export { database, firestore, uploadBytes, ref, uploadString };

export default storage;
