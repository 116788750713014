import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage  from "../FirebaseConfig";
//import Placeholder from "../../assets/img/placeholder.webp"

// import Public from '../assets/img/logo-public.png';

const noMenuRoutes = ["/login"];

function NetworkImage({ coverRef, title }) {


	const { pathname } = useLocation();

	const [networkImage, setNetworkImage] = useState("");

	let bgColorForSpan = ''

	useEffect(() => {
		if(coverRef != "" || coverRef != null) {
			getImg(coverRef);
		}
  }, [coverRef]);

	const getRandomColor = (name) => {
		let bgColorForSpan 	= 'rgb(0, 0, 0, 0.3)';
		let firstAlphabet		= "A";
		if(name) {
			// get first alphabet in upper case
			firstAlphabet			= name.charAt(0).toLowerCase();

			// get the ASCII code of the character
			const asciiCode = firstAlphabet.charCodeAt(0);

			// number that contains 3 times ASCII value of character -- unique for every alphabet
			const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

			var num = Math.round(0xffffff * parseInt(colorNum));
			var r = num >> 16 & 255;
			var g = num >> 8 & 255;
			var b = num & 255;
			bgColorForSpan = 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)'
		}
		return  <div className='initial' style={{background: bgColorForSpan}}>{firstAlphabet.toUpperCase()}</div>

	}


	const getImg = (coverRef) => {
    if(coverRef == null || coverRef == '') return 
		getDownloadURL(ref(storage, coverRef))
			.then((url) => {
				setNetworkImage(url);
			})
			.catch((error) => {
				console.log(error)
			});
  }

	
	if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<>
			{networkImage == "" ? getRandomColor(title)  : <img src={networkImage} alt="Network image" />}
		</>
	);
}
export default NetworkImage;