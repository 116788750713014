import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import style from "./TooltipIntroduction.module.scss";
import "./style.scss";

import { FeaturesActiveTabSwitch } from "../../Pages/Featured";

const tooltipData = {
  1: {
    title: "Paid Network Earnings",
    subtitle: "Become a paid creator and earn from networks.",
  },
  2: {
    title: "Featured Network",
    subtitle: "Join featured networks and build connections.",
  },
  3: {
    title: "Activity Feed",
    subtitle: "Get notified of all your latest activities.",
  },

  4: {
    title: "Recent Messages",
    subtitle: "Get notified of all your latest messages.",
  },
};

const tooltipStates = {
  1: {
    displayed: false,
    nextLoad: () => {},
    onLoad: () => {},
    next: () => {
      tooltipStates[1].nextLoad();
      FeaturesActiveTabSwitch("networks");
    },
  },
  2: {
    displayed: false,
    nextLoad: () => {},
    onLoad: () => {
      document.getElementById("networks-container").style.overflow = "visible";
      document.body.scrollTo({
        top: 220,
        left: 0,
        behavior: "smooth",
      });
    },
    next: () => {
      tooltipStates[2].nextLoad();
      document.getElementById("networks-container").style.overflow = "scroll";
    },
  },
  3: {
    displayed: false,
    nextLoad: () => {},
    onLoad: () => {},
    next: () => {
      tooltipStates[3].nextLoad();
	  document.body.scrollTo({
        top: 720,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};

export const TooltipIntroduction = ({ children, tooltipIndex, position }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const tip = useRef(null);
  const currentData = tooltipData[tooltipIndex];

  const musk = useRef(document.createElement("div")).current;
  musk.className = "tooltip__musk";

  const isSkipped = localStorage.getItem("isTipsSkipped") ?? false;

  const display = () => {
    document.body.appendChild(musk);
    setIsDisplayed(true);
    tooltipStates[tooltipIndex] &&
      (tooltipStates[tooltipIndex].displayed = true);
  };

  useEffect(() => {
    if (
      !isSkipped &&
      (tooltipIndex === 1 ||
        (!tooltipStates[tooltipIndex]?.displayed &&
          tooltipStates[tooltipIndex - 1]?.displayed))
    ) {
      tooltipStates[tooltipIndex] && tooltipStates[tooltipIndex].onLoad();
      display();
    }
    if (isDisplayed) {
      !musk.isConnected && document.body.appendChild(musk);
    }
    if (tooltipIndex > 1) {
      tooltipStates[tooltipIndex - 1].nextLoad = () => {
        display();
      };
    }
  }, []);

  if (!isDisplayed) {
    return children;
  }

  return (
    <div className={style.wrapper} ref={tip}>
      <div className={style.wrapper__element}>{children}</div>
      <div
        className={
          position === "left"
            ? [style["wrapper__tip-left"], style.wrapper__tip].join(" ")
            : style.wrapper__tip
        }
      >
        <div className={style.wrapper__tip__title}>{currentData?.title}</div>
        <div className={style.wrapper__tip__subtitle}>
          {currentData?.subtitle}
        </div>
        <div className={style.controls}>
          <Button
            onClick={() => {
              localStorage.setItem("isTipsSkipped", true);
              setIsDisplayed(false);
              document.body.removeChild(musk);
            }}
            variant="outlined"
          >
            Skip
          </Button>
          <div className={style.counter}>{tooltipIndex} / 11</div>

          <Button
            onClick={() => {
              setIsDisplayed(false);
              document.body.removeChild(musk);
              tooltipStates[tooltipIndex] &&
                (tooltipStates[tooltipIndex].displayed = true);
              tooltipStates[tooltipIndex] && tooltipStates[tooltipIndex].next();
			  if(tooltipIndex === Object.entries(tooltipData).length) {
				localStorage.setItem("isTipsSkipped", true);
			  }
            }}
            variant="contained"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
