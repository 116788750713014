import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//context
import AuthContext from "../../store/AuthContext";

//components
import Sidebar from "./components/Sidebar";
import Overview from "./components/Overview";
import Chat from "./components/Chat";
import SubNetworks from "./components/SubNetworks";
import Folder from "./components/Folder";
import Events from "./components/Events";
import LiveStream from "./components/LiveStream";
import Courses from "./components/Courses";
import NetworkNav from "./components/NetworkNav";

//libs
import AOS from "aos";

//assets
import CircularProgress from "@mui/material/CircularProgress";
import bannerImg from '../../assets/img/networks/bannerImg.jpg'
//images
import MembersIcon from '../../assets/img/networks/members.svg'
import MessagesIcon from '../../assets/img/networks/messages.svg'
import NetworksIcon from '../../assets/img/networks/networks.svg'

//css
import "aos/dist/aos.css";
import { Alert, Button, Snackbar } from "@mui/material";
import GetImageFirebase from "./components/GetImageFirebase";
import Placeholder from '../../assets/img/networks/placeholder.png'
import Members from "./components/Members";
import NetworkBanner from "./components/NetworkBanner";
import CustomButton from "../../common/CustomButton";

AOS.init();

const Networks = () => {

  //MISC
  const navigate = useNavigate();
  const { networkId } = useParams();
  const location = useLocation();

  //context
  const authCtx = useContext(AuthContext)

  //states data
  const [role, setRole] = useState('member');
  const [networkData, setNetworkData] = useState([]);
  const [subNetworks, setSubNetworks] = useState([]);
  const [parentId, setParentId] = useState(0);
  const [userNetworks, setUserNetworks] = useState([]);
  const [activeParentNetworkId, setActiveParentNetworkId] = useState(0);
  const [activeNetworkId, setActiveNetworkId] = useState(0);
  const [activeNetworkType, setActiveNetworkType] = useState('parent');
  const [activeSubNetworkId, setActiveSubNetworkId] = useState(0);
  const [members, setMembers] = useState([]);
  const [banners, setBanners] = useState([]);
  const [showBanners, setShowBanners] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [snackbarText, setSnackbarText] = useState('');
  const [networkModal, setNetworkModal] = useState('invite');
  const [universeId, setUniverseId] = useState('0');

  //states boolean
  const [isLoading, setIsLoading] = useState(true);
  const [isSubNetwork, setIsSubNetwork] = useState(false);
  const [isNetworkLoading, setIsNetworkLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isNetworkMember, setIsNetworkMember] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isJoiner, setIsJoiner] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [hubIsPrivate, setHubIsPrivate] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  const [sendingPaymentEmail, setSendingPaymentEmail] = useState(false);

  //useEffect

  useEffect(() => {
    fetchResults()
  }, [networkId])

  useEffect(() => {
    const mainContainer = document.querySelector(".network-main-container")
    if (isNetworkLoading && mainContainer) {
      mainContainer.scroll(0, 0)
      mainContainer.style.overflow = 'hidden'
    }
    return () => {
      if (mainContainer) {
        mainContainer.style.overflow = 'auto'
      }
    }
  }, [isNetworkLoading])

  useEffect(() => {
    if (banners?.length == 0 || banners == [] || activeTab !== 'overview') {
      setShowBanners(false)
    } else {
      setShowBanners(true)
    }

    window.history.replaceState(null, null, `/networks/${activeNetworkId}/${activeTab}`)

  }, [banners, activeTab])


  //API calls

  const fetchResults = async () => {

    setIsLoading(true);

    const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/user/${authCtx.userId}/universe/0/memberships`, { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, }, })
    const data = await res.json()
    console.log(data)

    if (data.status === "success") {
      setUserNetworks(data.data);
      if (networkId) {
        const currentNetwork = data.data.find((item) => item.id == networkId);
        let parentSubId;
        let isSubNetwork = false;
        if (!currentNetwork) {
          data.data.forEach((data) => {
            if (!data?.length) return;
            const subNetwork = subNetworks.find((sn) => sn.id == networkId ? true : false);
            isSubNetwork = subNetwork
            if (subNetwork) parentSubId = subNetwork.parentHubId;
          });
        }
        !currentNetwork && isSubNetwork
          ? changeNetwork(networkId, "sub-network", parentSubId)
          : changeNetwork(networkId, "parent", networkId);
      } else {
        changeNetwork(data.data[0].id);
      }
      setIsLoading(false);
    }
  };


  const changeNetwork = (id, type, parentId) => {
    console.log(id, type, parentId);
    setIsNetworkLoading(true);
    setParentId(parentId);

    if (type === "sub-network") {
      if (activeTab == 'sub-networks') {
        setActiveTab('overview')
      }
      // else{
      //   setActiveTab('sub-networks')
      // }
      setActiveNetworkType('sub-network')
      // setActiveNetworkType('parent')
      setIsSubNetwork(true);
      setActiveNetworkId(id)
      setActiveParentNetworkId(parentId)
      setActiveSubNetworkId(id)
    } else {
      setActiveTab('overview');
      setActiveNetworkType('parent')
      setActiveParentNetworkId(id)
      setActiveNetworkId(id)
      setIsSubNetwork(false);
    }

    let options = { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, }, };
    Promise.all([
      fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${id}`, options)
        .then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${id}/subhubs`, options)
        .then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${id}/members`, options)
        .then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${id}/users/${authCtx.userId}/membership`, options)
        .then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${id}/banners`, options)
        .then((response) => response.json()),
    ])
      .then((results) => {

        setNetworkData(results[0].data)
        //check if network is a sub-network
        if (results[0].data.parentId > 0) {
          setActiveNetworkType('sub-network')
        } else {
          setActiveNetworkType('parent')
        }
        setSubNetworks(results[1].data);
        setMembers(results[2].data);

        if (results[3]?.role === "blocked") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setIsBlocked(true);
          setIsMember(false);

        } else if (results[3]?.role === "non-member") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setRole("non-member");
          setIsMember(false);

        } else if (results[3]?.role === "invited") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          if (results[0].data.paid == "yes") {
            setNetworkModal('paid')
            setOpenNetworkModal(true)
          } else {
            setNetworkModal('invite')
            setOpenNetworkModal(true)
          }
          setIsInvited(true);
          setRole("invited");
          setIsMember(false);

        } else if (results[3]?.role === "joiner") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setIsJoiner(true);
          setRole("joiner");
          setIsMember(false);

        } else if (results[3]?.role === "member") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setRole("member");
          setIsMember(true);

        } else if (results[3]?.role === "moderator") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setIsMember(true);
          setRole("moderator");

        } else if (results[3]?.role === "owner") {

          if (results[0].data.type === "private") {
            setHubIsPrivate(true);
          }
          setIsMember(true);
          setRole("owner");

        }


        setBanners(results[4].banners);
        setIsNetworkLoading(false);

        console.log(results)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const joinHub = async () => {

    if (networkData.paid == "yes") {
      setNetworkModal('paid')
      setOpenNetworkModal(true)
    } else {

      setIsJoining(true);

      try {

        if (isInvited) {
          let payload = {
            "userId": authCtx.userId,
            "hubId": networkData.id,
            "role": "member",
            "notification": "accepted-request"
          };
          let options = {
            method: "PUT",
            mode: "cors",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
            body: JSON.stringify(payload)
          };
          const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${networkData.id}/membership`, options);
          const data = await res.json()

          if (res.status == 201) {
            if (data.status == 'success') {
              setIsJoining(false);
              setIsMember(true);
            }
          } else {
            setIsJoining(false);
            setSnackbarText(`Sorry, something went wrong. Please try again.`)
            setOpenSnackbar(true)
          }

        } else if (networkData.permissions == "request") {
          let payload = {
            "userId": authCtx.userId,
            "hubId": networkData.id,
            "role": "joiner",
            "notification": "request-sent"
          };
          let options = {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
            body: JSON.stringify(payload)
          };

          const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${networkData.id}/membership`, options);
          const data = await res.json()

          if (res.status == 201) {

            if (data.status == "blocked") {
              setSnackbarText(`Sorry, you have been blocked from this network.`)
              setOpenSnackbar(true)
            } else {

              setIsJoining(false);
              setIsJoiner(true);

              setSnackbarText(`Thank you for joining ${networkData.name}`)
              setOpenSnackbar(true)
            }
          } else {
            setSnackbarText(`Sorry, something went wrong. Please try again.`)
            setOpenSnackbar(true)
          }

        } else {
          let payload = {
            "userId": authCtx.userId,
            "hubId": networkData.id,
            "role": "member"
          };
          let options = {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
            body: JSON.stringify(payload)
          };
          const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${networkData.id}/membership`, options);
          const data = await res.json()


          if (res.status == 201) {
            if (data.status == "blocked") {
              setSnackbarText(`Sorry, you have been blocked from this network.`)
              setOpenSnackbar(true)
            } else {
              setIsJoining(false);
              setIsMember(true);

              changeNetwork(networkData.id, activeNetworkType, networkData.parentId)

              setSnackbarText(`Thank you for joining ${networkData.name}`)
              setOpenSnackbar(true)
            }
          } else {
            setSnackbarText(`Sorry, something went wrong. Please try again.`)
            setOpenSnackbar(true)
          }
        }
      } catch (e) {
        setIsJoining(false);
        console.log(`Sorry, something went wrong: ${e}`)
      }
    }
  }


  const sendPaymentEmail = async () => {

    setSendingPaymentEmail(true)
    try {
      var payload = { "userId": authCtx.userId };
      let options = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, },
        body: JSON.stringify(payload)
      };

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${networkData.id}/sendPaymentRequest`, options);
      const data = await res.json()

      if (data.status == "success") {
        setSnackbarText(`Thank you. Check your email for instructions`)
        setOpenSnackbar(true)
        setSendingPaymentEmail(false)
        setOpenNetworkModal(false)
      } else {
        setSnackbarText(`Sorry you cannot join this network`)
        setOpenSnackbar(true)
        setSendingPaymentEmail(false)
        setOpenNetworkModal(false)
      }

    } catch (error) {
      console.log('error while sending payment request: ', error)
    }
  }


  //functions
  const checkUserHubStatus = () => {
    if (!isMember) {
      if (isBlocked) {
        setSnackbarText(`Sorry, you have been blocked from this network`)
        setOpenSnackbar(true)
      } else if (networkData.paid == "yes") {
        setOpenNetworkModal(true)
        setNetworkModal('paid')
      } else if (networkData.permissions == "open") {
        joinHub();
      } else if (networkData.permissions == "request" && isJoiner) {
        setSnackbarText(`You've requested to join this hub, but we are still waiting for approval from the owners and moderators`)
        setOpenSnackbar(true)
      } else if (networkData.permissions == "request") {
        console.log("You've requested to join this hub, but we are still waiting for approval from the owners and moderators")
        // showRequestModal();
      } else {
        setSnackbarText(`Sorry you cannot join hub without an invite. Only owners and moderators can invite you to this network.`)
        setOpenSnackbar(true)
      }
    } else {

    }
  }


  const getNetworkModal = () => {
    switch (networkModal) {

      case 'join':
        return <div className='confirm-delete'>
          <div className="text">
            Oops, you haven't joined this network, BUT you're in luck! Anyone can join, just tap on the 'JOIN' button.
          </div>
          <div className="confirm-delete-btns">
            <CustomButton className="f-btn-bordered f-btn-half-width" onClick={() => setOpenNetworkModal(false)} >
              Cancel
            </CustomButton>
            <CustomButton className="f-btn f-btn-half-width" onClick={() => checkUserHubStatus()} >JOIN</CustomButton>
          </div>
        </div>

      case 'invite':
        return <div className='confirm-delete'>
          <div className="text">
            You have been invited to join this network, Tap on the join button to accept the invite.
          </div>
          <div className="confirm-delete-btns">
            <CustomButton className="f-btn-bordered f-btn-half-width" onClick={() => setOpenNetworkModal(false)} >
              Cancel
            </CustomButton>
            <CustomButton className="f-btn f-btn-half-width" onClick={() => checkUserHubStatus()} >JOIN</CustomButton>
          </div>
        </div>

      case 'paid':
        return <div className='paid-network-modal'>
          <div className="text">
            <h1>Join {networkData.title}</h1>
            <p>This network requires a fortnightly fee of</p>
            <h2>$20.00</h2>
            <p>Click the button below to receive an email with further instructions on how to join</p>
          </div>
          <div className="modal-btns">
            <CustomButton className="f-btn-bordered f-btn-half-width" onClick={() => setOpenNetworkModal(false)} >Close</CustomButton>
            <CustomButton className="f-btn-half-width" onClick={() => sendPaymentEmail()} >
              {
                sendingPaymentEmail
                  ? <CircularProgress size={20} style={{ color: "#FFFFFF" }} />
                  : "Send me the email"
              }
            </CustomButton>
          </div>
        </div>

      default:
        break;
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  if (isLoading) {
    return (
      <div className="container ">
        <div className="network-wrapper loading">
          <CircularProgress style={{ color: "#1BA0A2" }} size={60} />
        </div>
      </div>
    );
  }

  //logs
  // console.log('networkData :', networkData)
  // console.log('isMember :', isMember)
  // console.log('isInvited :', isInvited)
  // console.log('isJoiner :', isJoiner)
  // console.log('isBlocked :', isBlocked)

  return (
    <div className="container">

      <div className="networks-page-container">
        <Sidebar userNetworks={userNetworks} setSidebarOpen={setSidebarOpen} setActiveTab={setActiveTab} sidebarOpen={sidebarOpen} activeParentNetworkId={activeParentNetworkId} isSubNetwork={isSubNetwork} changeNetwork={changeNetwork} activeSubNetworkId={activeSubNetworkId} />
        <main className="network-main-container" style={sidebarOpen ? null : { width: 'calc(100% - 121px)' }} >
          {
            isNetworkLoading
              ? <div className="changing-network-overlay">
                <CircularProgress style={{ color: "#1BA0A2" }} size={40} />
              </div>
              : null
          }
          <section className="network-main">
            <article className="network-details-container" style={activeTab !== 'overview' ? { height: '125px' } : null} >
              <div className="network-details" style={activeTab !== 'overview' ? { height: '125px', minHeight: '125px' } : null}>
                {
                  showBanners
                    ? <div className="network-banner-container">
                      <NetworkBanner activeNetworkId={activeNetworkId} />
                    </div>
                    : null
                }
                <div className="network-info-container" style={activeTab !== 'overview' ? { height: '125px', padding: '0 1rem' } : null}>
                  <div className="network-info" style={showBanners || activeTab == 'overview' ? null : { height: '55px', alignItems: 'center', paddingBottom: '0' }} >
                    <div className="network-title-image" style={showBanners ? null : { marginTop: '0px', flexDirection: 'row', alignItems: 'center' }} >
                      <div className="network-image" style={activeTab !== 'overview' ? { height: '50px', width: '50px' } : null} >
                        {
                          networkData?.coverRef !== ''
                            ? <GetImageFirebase height={activeTab !== 'overview' ? 40 : null} width={activeTab !== 'overview' ? 40 : null} className="network-img" imageRef={networkData?.coverRef} />
                            : <img src={Placeholder} alt="placeholder" />
                        }
                      </div>
                      <div className="network-title-desc">
                        <div className="network-title">{networkData?.title}</div>
                        <div className="network-headline" style={activeTab !== 'overview' ? { display: 'none' } : null} >{networkData?.headline}</div>
                        <div className="network-description" style={activeTab !== 'overview' ? { display: 'none' } : null} >{networkData?.description}</div>
                      </div>
                    </div>
                    <div className="network-followers-join" style={activeTab !== 'overview' ? { flexDirection: 'row', justifyContent: 'center' } : null}>
                      {
                        isMember
                          ? null
                          : <div className="join-network-btn">
                            <CustomButton className={"f-btn-big"} onClick={() => checkUserHubStatus()}>JOIN NETWORK</CustomButton>
                          </div>
                      }

                      <div className="network-activity-details">
                        <div className="activity">
                          <img src={MembersIcon} alt="members" />
                          <span>{networkData?.subscriberCount}</span>
                        </div>
                        <div className="activity">
                          <img src={MessagesIcon} alt="messages" />
                          <span>{networkData?.messageCount}</span>
                        </div>
                        <div className="activity">
                          <img src={NetworksIcon} alt="networks" />
                          <span>{subNetworks?.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="divider-div"></div>

                  <NetworkNav parentId={parentId} role={role} activeNetworkId={activeNetworkId} activeNetworkType={activeNetworkType} activeTab={activeTab} setActiveTab={setActiveTab} networkData={networkData} />

                </div>
              </div>
            </article>

            <article className="network-tab-container" style={activeTab !== 'overview' && activeTab === 'chat' ? { height: 'calc(100% - 125px)' } : null}>
              <div className="network-tab" style={activeTab !== 'overview' && activeTab === 'chat' ? { height: '100%' } : null} >
                {activeTab === "overview" ? <Overview setActiveTab={setActiveTab} networkData={networkData} membersArray={members} subNetworks={subNetworks} parentId={parentId} setIsNetworkMember={setIsNetworkMember} activeNetworkId={activeNetworkId} /> : null}
                {activeTab === "chat" ? <Chat activeTab={activeTab} activeNetworkId={activeNetworkId} /> : null}
                {activeTab === "sub-networks" ? <SubNetworks NetworkId={activeNetworkId} changeNetwork={changeNetwork} /> : null}
                {activeTab === "folder" ? <Folder role={role} NetworkId={activeNetworkId} /> : null}
                {activeTab === "events" ? <Events role={role} NetworkId={activeNetworkId} changeNetwork={changeNetwork} /> : null}
                {activeTab === "live" ? <LiveStream NetworkId={activeNetworkId} /> : null}
                {activeTab === "courses" ? <Courses /> : null}
                {activeTab === "members" ? <Members activeNetworkId={activeNetworkId} membersArray={members} networkData={networkData} /> : null}
              </div>
            </article>
          </section>
        </main>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }} >
          {snackbarText}
        </Alert>
      </Snackbar>
      <div className="network-join-modal" style={!openNetworkModal ? { display: 'none' } : null}>
        {getNetworkModal()}
      </div>
    </div>
  );
};
export default Networks;
