import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ref, getDownloadURL, listAll } from "firebase/storage";
import storage from "../FirebaseConfig";
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress } from '@mui/material';


const noMenuRoutes = ["/login"];

function GetImage({ imageRef, ratio }) {

  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const [avatarImage, setAvatarImage] = useState("");
  const [aspectRatio, setAspectRatio] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [extension, setExtension] = useState('');

  useEffect(() => {
    if (imageRef !== "" && imageRef !== null && imageRef !== undefined) {
      setExtension(imageRef.split('.').slice(-1))
      getImg(imageRef);
    }
  }, [imageRef]);

  const getImg = (coverRef) => {
    if (coverRef == null || coverRef == '') return

    let isPresent = false

    if (coverRef.includes('https://')) {
      isPresent = true
    } else if (coverRef.includes('http://')) {
      isPresent = true
    } else {
      isPresent = false
    }

    isPresent
      ? setAvatarImage(coverRef)
      : getDownloadURL(ref(storage, coverRef))
        .then((url) => {
          setAvatarImage(url);
        })
        .catch((error) => {
          console.log(error)
        });
  }

  // let aps = [1.0,1.91,0.8,0.56]

  const getStyle = (ratio) => {
    if (ratio == 0.6) {
      return { height: '700px', objectFit: 'contain' }
    } else if (ratio == 0.8) {
      return { height: '700px', objectFit: 'contain' }
    } else if (ratio == 1.0) {
      return { height: '700px', objectFit: 'cover' }
    } else if (ratio == 1.91) {
      return { height: '394px', objectFit: 'cover' }
    } else if (ratio > 1 && ratio <= 1.5) {
      return { height: '400px', objectFit: 'cover' }
    } else {
      return { height: '394px', objectFit: 'contain' }
    }
  }

  const decideMedia = (avatarImage) => {

    if (extension == 'mov' || extension == 'MOV' || extension == 'MP4' || extension == 'WMV' || extension == 'AVI' || extension == 'WEBM' || extension == 'mp4' || extension == 'm4v') {
      return <video style={{ height: '394px', objectFit: 'contain' }} src={avatarImage} controls />
    }
    else {
      // return <img src={avatarImage} alt="post" />
      return <img style={getStyle(ratio)} src={avatarImage} alt="post" />
    }
  }

  // console.log(aspectRatio)
  // console.log(getStyle(aspectRatio))

  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {avatarImage ? decideMedia(avatarImage) : <CircularProgress />}
    </>
  );
}
export default GetImage;