import { Button } from '@mui/material';
import React, { useContext } from 'react';
import ArticleContext from '../store/ArticleContext';

const Article = () => {

	const articleCtx                            = useContext(ArticleContext);

   
  return (
    <div className="article-container">
        <iframe  src={articleCtx.details.url} title={articleCtx.details.title}></iframe>
        <div className="start-discussion"><Button >Start Discussion</Button></div>
    </div>
  )
}

export default Article