import React from 'react'

const ReplyMessage = ({ data }) => {

  return (
    <>
    <div className="reply-msg">
        <div className="reply-content">
            <div className="name">{data?.replyMessage.username}</div>
            <div className="content">{data?.replyMessage.content !== "" ? data?.replyMessage.content : <span style={{background: "transparent", fontWeight: "bold", color: "#007C8990"}}>Media</span> }</div>
        </div>
        {/* <div className="msg">
            {data.message}
        </div> */}
    </div>
    </>
  )
}

export default ReplyMessage