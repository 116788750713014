import React, { useContext } from 'react'

import ChatIcon from '../../../assets/img/networks/nav/chat-icon.svg'
import CourseIcon from '../../../assets/img/networks/nav/courses-icon.svg'
import EventIcon from '../../../assets/img/networks/nav/events-icon.svg'
import FolderIcon from '../../../assets/img/networks/nav/folder-icon.svg'
import LiveIcon from '../../../assets/img/networks/nav/live-streaming-icon.svg'
import OverviewIcon from '../../../assets/img/networks/nav/overview-icon.svg'
import SubNetworkIcon from '../../../assets/img/networks/nav/sub-networks.svg'
import ChatIcon2 from '../../../assets/img/networks/nav/chat-icon-2.svg'
import CourseIcon2 from '../../../assets/img/networks/nav/courses-icon-2.svg'
import EventIcon2 from '../../../assets/img/networks/nav/events-icon-2.svg'
import FolderIcon2 from '../../../assets/img/networks/nav/folder-icon-2.svg'
import LiveIcon2 from '../../../assets/img/networks/nav/live-streaming-icon-2.svg'
import OverviewIcon2 from '../../../assets/img/networks/nav/overview-icon-2.svg'
import SubNetworkIcon2 from '../../../assets/img/networks/nav/sub-networks-2.svg'

import { PeopleRounded, SettingsRounded } from '@mui/icons-material'

import ModalContext from '../../../store/ModalContext'

const NetworkNav = (props) => {

  const modalCtx = useContext(ModalContext)

  const { activeNetworkType, activeTab, setActiveTab, role, parentId, activeNetworkId, networkData } = props;

  const showModal = (modal, data) => {
    if (role === "member") {
      modalCtx.openModal();
      modalCtx.setDetails(modal, {
        role: "member",
        selectedNetwork: activeNetworkId,
        parentId: parentId,
        network: networkData,
      });
    } else if (role === "moderator") {
      modalCtx.openModal();
      modalCtx.setDetails(modal, {
        role: "mod",
        selectedNetwork: activeNetworkId,
        parentId: parentId,
        network: networkData
      });
    } else if (role === "owner") {
      modalCtx.openModal();
      modalCtx.setDetails(modal, {
        role: "owner",
        selectedNetwork: activeNetworkId,
        parentId: parentId,
        network: networkData
      });
    } else if (role === "invited") {
      modalCtx.openModal();
      modalCtx.setDetails(modal, {
        role: "invited",
        selectedNetwork: activeNetworkId,
        parentId: parentId,
        network: networkData
      });

    }
  }

  return (
    <>
      <div className="network-navigation-tabs">
        <div className="nav-tabs">
          <div onClick={() => setActiveTab('overview')} className={activeTab === 'overview' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'overview' ? OverviewIcon2 : OverviewIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="overview_icon" data-name="overview icon" transform="translate(-9811 2682)">
                <g id="information" transform="translate(9810.75 -2682.25)">
                  <path id="Path_721" data-name="Path 721" d="M17.071,21.054V14.625H14.214v1.429h1.429v5H13.5v1.429h5.714V21.054Z" transform="translate(-4.107 -4.518)" fill={activeTab === 'overview' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_722" data-name="Path 722" d="M17.384,7.875a1.072,1.072,0,1,0,1.072,1.072A1.072,1.072,0,0,0,17.384,7.875Z" transform="translate(-5.134 -2.054)" fill={activeTab === 'overview' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_723" data-name="Path 723" d="M12.25,22.25a10,10,0,1,1,10-10A10,10,0,0,1,12.25,22.25Zm0-18.571a8.571,8.571,0,1,0,8.571,8.571A8.571,8.571,0,0,0,12.25,3.679Z" fill={activeTab === 'overview' ? "#FFFFFF" : "#707070"} />
                </g>
                <rect id="Rectangle_216" data-name="Rectangle 216" width="24" height="24" transform="translate(9811 -2682)" fill="none" />
              </g>
            </svg>

            <span className="tab-name">Overview</span>
          </div>
          <div onClick={() => setActiveTab('chat')} className={activeTab === 'chat' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'chat' ? ChatIcon2 : ChatIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="chat_icon" data-name="chat icon" transform="translate(-1124 -727)">
                <g id="chat-left-text" transform="translate(1126.3 729.729)">
                  <g id="Group_4699" data-name="Group 4699">
                    <path id="Path_2668" data-name="Path 2668" d="M16.975,1.212a1.212,1.212,0,0,1,1.212,1.212v9.7a1.212,1.212,0,0,1-1.212,1.212H5.352a2.425,2.425,0,0,0-1.714.71L1.212,16.473V2.425A1.212,1.212,0,0,1,2.425,1.212ZM2.425,0A2.425,2.425,0,0,0,0,2.425V17.936a.606.606,0,0,0,1.035.428l3.459-3.459a1.212,1.212,0,0,1,.857-.355H16.975A2.425,2.425,0,0,0,19.4,12.125v-9.7A2.425,2.425,0,0,0,16.975,0Z" fill={activeTab === 'chat' ? "#FFFFFF" : "#707070"} />
                    <path id="Path_2669" data-name="Path 2669" d="M9,9.606A.606.606,0,0,1,9.606,9H20.519a.606.606,0,0,1,0,1.212H9.606A.606.606,0,0,1,9,9.606Zm0,3.031a.606.606,0,0,1,.606-.606H20.519a.606.606,0,1,1,0,1.212H9.606A.606.606,0,0,1,9,12.637Zm0,3.031a.606.606,0,0,1,.606-.606h6.062a.606.606,0,1,1,0,1.212H9.606A.606.606,0,0,1,9,15.669Z" transform="translate(-5.363 -5.363)" fill={activeTab === 'chat' ? "#FFFFFF" : "#707070"} />
                  </g>
                </g>
                <rect id="Rectangle_1624" data-name="Rectangle 1624" width="24" height="24" transform="translate(1124 727)" fill="none" />
              </g>
            </svg>
            <span className="tab-name">Chat</span>
          </div>
          {
            activeNetworkType == 'parent'
              ? <div onClick={() => setActiveTab('sub-networks')} className={activeTab === 'sub-networks' ? 'tab active-tab' : 'tab'}>
                {/* <img src={activeTab === 'subNetwork' ? SubNetworkIcon2 : SubNetworkIcon} alt="" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g id="Sub_Networks" data-name="Sub Networks" transform="translate(15693 14867)">
                    <path id="Path_1359" data-name="Path 1359" d="M111.966,33.055a9.212,9.212,0,0,1,6.287,2.45,8.092,8.092,0,0,1,0,11.867c-5.62,5.3-15.194,1.461-15.194-5.934a8.65,8.65,0,0,1,8.906-8.383ZM110.18,43.438a2.241,2.241,0,0,1,1.857.966,11.012,11.012,0,0,0,3.358-2.517,1.974,1.974,0,0,1-.4-2.292,11.678,11.678,0,0,0-3.786-1.259,2.155,2.155,0,0,1-2,1.551,10.789,10.789,0,0,0,.5,3.6,3.419,3.419,0,0,1,.476-.045Zm1.548,3.573c-.167.157,1.691,1.731,1.929,1.888a7.894,7.894,0,0,0,5.906-4.877,10.144,10.144,0,0,0-1.31-1.843,2.362,2.362,0,0,1-2.191.157,12.563,12.563,0,0,1-3.715,2.764,2.042,2.042,0,0,1-.619,1.91Zm-.714.449a2.246,2.246,0,0,1-2.882-1.124,11.583,11.583,0,0,1-2.334.045,8.286,8.286,0,0,0,6.787,2.674A12.806,12.806,0,0,1,111.014,47.461ZM107.966,45.6a2.066,2.066,0,0,1,1-1.821,11.6,11.6,0,0,1-.572-3.933V39.8a2.187,2.187,0,0,1-1.453-1.393,12.831,12.831,0,0,0-2.739.854,7.348,7.348,0,0,0,.953,6.316l.643.067a13.949,13.949,0,0,0,2.167-.045ZM110.18,44.2a1.37,1.37,0,0,0-1.405,1.326,1.426,1.426,0,0,0,2.405.944,1.328,1.328,0,0,0-1-2.27Zm7.406-5.776a11.1,11.1,0,0,0,.524-1.933,8.313,8.313,0,0,0-6.74-2.652,10.71,10.71,0,0,0-1.262,2.113,2.112,2.112,0,0,1,1.167,1.618,12.765,12.765,0,0,1,4.167,1.393,2.328,2.328,0,0,1,2.143-.539Zm1.238,3.214a11.589,11.589,0,0,1,1.048,1.348,7.249,7.249,0,0,0-1.119-5.664,11.124,11.124,0,0,1-.452,1.438,2.021,2.021,0,0,1,.524,2.877Zm-.834-2.135a1.458,1.458,0,0,0-2.143.18,1.279,1.279,0,0,0,.167,1.708,1.453,1.453,0,0,0,1.977,0,1.29,1.29,0,0,0,0-1.888ZM110.3,33.976a7.941,7.941,0,0,0-5.692,4.293,12.355,12.355,0,0,1,2.262-.629,2.165,2.165,0,0,1,2.477-1.91,12.631,12.631,0,0,1,.953-1.753Zm-.238,2.877c-1.31-1.236-3.286.652-2,1.888C109.394,39.977,111.371,38.09,110.061,36.853Z" transform="translate(-15792.042 -14896.037)" fill={activeTab === 'sub-networks' ? "#FFFFFF" : "#707070"} stroke={activeTab === 'sub-networks' ? "#FFFFFF" : "#707070"} stroke-width="0.25" />
                    <rect id="Rectangle_685" data-name="Rectangle 685" width="24" height="24" transform="translate(-15693 -14867)" fill="none" />
                  </g>
                </svg>

                <span className="tab-name">Sub Networks</span>
              </div>
              : null
          }
          <div onClick={() => setActiveTab('folder')} className={activeTab === 'folder' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'folder' ? FolderIcon2 : FolderIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="folder_icon" data-name="folder icon" transform="translate(-140 -238)">
                <g id="folder" transform="translate(141.573 239.427)">
                  <path id="Path_421" data-name="Path 421" d="M13.068,6.091a3.841,3.841,0,0,1-2.714-1.126L9.292,3.905a1.28,1.28,0,0,0-.9-.375h-4.7a1.28,1.28,0,0,0-1.28,1.256l.059,1.3H1.184l-.059-1.28A2.561,2.561,0,0,1,3.686,2.25h4.7A2.561,2.561,0,0,1,10.2,3l1.06,1.06a2.561,2.561,0,0,0,1.811.75Z" transform="translate(-0.301)" fill={activeTab === 'folder' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_422" data-name="Path 422" d="M17.866,8.03H2.989a1.28,1.28,0,0,0-1.275,1.4l.815,8.963A1.28,1.28,0,0,0,3.8,19.554H17.052a1.28,1.28,0,0,0,1.274-1.165l.815-8.963a1.28,1.28,0,0,0-1.275-1.4ZM2.989,6.75A2.561,2.561,0,0,0,.438,9.542L1.254,18.5A2.561,2.561,0,0,0,3.8,20.834H17.052A2.561,2.561,0,0,0,19.6,18.5l.815-8.963A2.561,2.561,0,0,0,17.866,6.75Z" transform="translate(0 -1.939)" fill={activeTab === 'folder' ? "#FFFFFF" : "#707070"} fill-rule="evenodd" />
                </g>
                <rect id="Rectangle_92" data-name="Rectangle 92" width="24" height="24" transform="translate(140 238)" fill="none" />
              </g>
            </svg>

            <span className="tab-name">Folder</span>
          </div>
          <div onClick={() => setActiveTab('events')} className={activeTab === 'events' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'events' ? EventIcon2 : EventIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="events_icon" data-name="events icon" transform="translate(-10455 3063)">
                <g id="event-line" transform="translate(10455.175 -3062.211)">
                  <path id="Path_741" data-name="Path 741" d="M14.009,21.329l-3.291-3.291a.614.614,0,0,1,.866-.866l2.426,2.4,5.306-5.306a.614.614,0,0,1,.866.866Z" transform="translate(-3.307 -4.677)" fill={activeTab === 'events' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_742" data-name="Path 742" d="M20.576,6h-2V7.228h1.842v13.51H3.228V7.228H5.07V6h-2A1.093,1.093,0,0,0,2,7.111V20.854a1.093,1.093,0,0,0,1.075,1.111h17.5a1.093,1.093,0,0,0,1.075-1.111V7.111A1.093,1.093,0,0,0,20.576,6Z" transform="translate(0 -1.544)" fill={activeTab === 'events' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_743" data-name="Path 743" d="M9.614,6.913a.614.614,0,0,0,.614-.614V2.614A.614.614,0,1,0,9,2.614V6.3A.614.614,0,0,0,9.614,6.913Z" transform="translate(-2.702)" fill={activeTab === 'events' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_744" data-name="Path 744" d="M25.614,6.913a.614.614,0,0,0,.614-.614V2.614a.614.614,0,0,0-1.228,0V6.3A.614.614,0,0,0,25.614,6.913Z" transform="translate(-8.876)" fill={activeTab === 'events' ? "#FFFFFF" : "#707070"} />
                  <path id="Path_745" data-name="Path 745" d="M13,6h6.141V7.228H13Z" transform="translate(-4.245 -1.544)" fill={activeTab === 'events' ? "#FFFFFF" : "#707070"} />
                </g>
                <rect id="Rectangle_248" data-name="Rectangle 248" width="24" height="24" transform="translate(10455 -3063)" fill="none" />
              </g>
            </svg>

            <span className="tab-name">Events</span>
          </div>
          <div onClick={() => setActiveTab('live')} className={activeTab === 'live' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'live' ? LiveIcon2 : LiveIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="Live_Streaming_icon" data-name="Live Streaming icon" transform="translate(-9935 2780)">
                <path id="video-camera-line" d="M22.25,8.746a1.335,1.335,0,0,0-.734-1.2,1.266,1.266,0,0,0-1.348.095l-2.98,1.9V7.266A1.266,1.266,0,0,0,15.922,6H4.531A2.531,2.531,0,0,0,2,8.531V18.656a2.531,2.531,0,0,0,2.531,2.531h11.39a1.266,1.266,0,0,0,1.266-1.266V17.643l2.936,1.9a1.31,1.31,0,0,0,1.392.127,1.335,1.335,0,0,0,.734-1.228ZM20.94,18.51a.167.167,0,0,1-.12-.038l-4.9-3.126v4.575H4.531a1.266,1.266,0,0,1-1.266-1.266V8.531A1.266,1.266,0,0,1,4.531,7.266h11.39v4.575l4.936-3.164a.086.086,0,0,1,.127.07v9.694a.07.07,0,0,1-.044.07Z" transform="translate(9934.875 -2781.594)" fill={activeTab === 'live' ? "#FFFFFF" : "#707070"} />
                <rect id="Rectangle_215" data-name="Rectangle 215" width="24" height="24" transform="translate(9935 -2780)" fill="none" />
              </g>
            </svg>

            <span className="tab-name">Live Stream</span>
          </div>
          <div onClick={() => setActiveTab('courses')} className={activeTab === 'courses' ? 'tab active-tab' : 'tab'}>
            {/* <img src={activeTab === 'courses' ? CourseIcon2 : CourseIcon} alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="courses_icon" data-name="courses icon" transform="translate(-2076 -481)">
                <path id="noun-courses-4026107" d="M28.521,16.61a.661.661,0,0,1,0,.949.721.721,0,0,1-.983,0,.661.661,0,0,1,0-.949.745.745,0,0,1,.983,0ZM18.776.09a.752.752,0,0,1,.713,0L30.107,5.843a.68.68,0,0,1,0,1.208l-1.483.8,0,.048v6.955a.727.727,0,0,1-1.441.094l-.007-.094,0-6.219-1.391.754V14.4a.624.624,0,0,1-.364.562,14.771,14.771,0,0,1-12.582,0,.629.629,0,0,1-.357-.473l-.006-.088V9.391L8.158,7.052A.679.679,0,0,1,8.067,5.9l.092-.058Zm5.7,10.01L19.489,12.8a.752.752,0,0,1-.713,0L13.784,10.1V14l.318.135a13.431,13.431,0,0,0,10.062,0L24.48,14ZM19.132,1.491,9.985,6.447,19.132,11.4l6.6-3.574L20.253,5.766a.688.688,0,0,1-.439-.81l.03-.09a.732.732,0,0,1,.845-.421l.094.029,6.57,2.474.926-.5Z" transform="translate(2068.867 484.124)" fill={activeTab === 'courses' ? "#FFFFFF" : "#707070"} />
                <rect id="Rectangle_7" data-name="Rectangle 7" width="24" height="24" transform="translate(2076 481)" fill="none" />
              </g>
            </svg>

            <span className="tab-name">Courses</span>
          </div>
        </div>
        <div className="network-settings">
          <div onClick={() => setActiveTab('members')} className={activeTab === 'members' ? 'setting-tab active-setting-tab' : 'setting-tab'}>
            <PeopleRounded style={activeTab === 'members' ? { color: '#FFF' } : null} />
          </div>
          <div onClick={() => showModal("settings")} className={activeTab === 'settings' ? 'setting-tab active-setting-tab' : 'setting-tab'}>
            <SettingsRounded style={activeTab === 'settings' ? { color: '#FFF' } : null} />
          </div>
        </div>
      </div>
    </>
  )
}

export default NetworkNav