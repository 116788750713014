import React from 'react'
import { useContext } from 'react';
import ModalContext from '../../../../store/ModalContext';
import AuthContext from '../../../../store/AuthContext';
import { useState } from 'react';
import CustomButton from '../../../../common/CustomButton';

const DeleteAttachment = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [loading, setLoading] = useState(false)

  const showModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const closeHandle = () => {
    modalCtx.closeModal();
    modalCtx.setDetails(null, null)
  }

  const handleDelete = async () => {

    setLoading(true)

    let options = { method: "DELETE", mode: "cors", headers: { "Content-Type": "application/json", Authorization: "Bearer " + authCtx.token, } }
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v2/hubs/${modalCtx.details.hubId}/attachments/${modalCtx.details.id}`, options)
      const data = await res.json()
      if (data.status === 'success') {
        console.log(data)
        modalCtx.closeModal()
        setLoading(false)
      }
    } catch (error) {
      console.log('error deleting attachment', error)
      setLoading(false)
    }

  }

  // console.log(modalCtx.details)

  return (
    <>
      <div className='confirm-delete'>
        <div className="text">
          Are you sure you want to delete this File ?
        </div>
        <div className="confirm-delete-btns">
          <CustomButton className="f-btn-bordered" style={{ width: '50%' }} onClick={closeHandle}>
            Cancel
          </CustomButton>
          <CustomButton className="f-btn-half-width" onClick={() => handleDelete()} >Delete</CustomButton>
        </div>
      </div>
    </>
  )
}

export default DeleteAttachment