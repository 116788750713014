import React, { useContext, useState, useCallback } from 'react';
import { Button, CircularProgress, Slider } from '@mui/material';
import { database, uploadBytes, ref as sRef, uploadString }  from "../../FirebaseConfig";
import {ref, set} from "firebase/database";
import storage from "../../FirebaseConfig";
import Cropper from 'react-easy-crop'
import { Base64 } from 'js-base64';

import getCroppedImg from './CropImage.js'

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import MessageContext from '../../../store/MessageContext.js';

import Dropzone from '../../ReactDrop/Dropzone';


import { CloseRounded } from '@mui/icons-material';

const AddImage = () => {

  const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const msgCtx = useContext(MessageContext);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspectY, setAspectY] = useState(1)
  const [aspectX, setAspectX] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState();
  const [uploading, setUploading] = useState(false);


  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);  
  
  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  // get cropped image 
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg (image, croppedAreaPixels) 
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }
  
  // this gets a file object which is passed to firebase
  const baseToFile = (base) => {
    
    if (base) {
        let arr = base?.split(",")
    
        let mime = arr[0].match(/:(.*?);/)[1]
        let data = arr[1]
    
        let dataStr = Base64.atob(data)
        let n = dataStr.length
        let dataArr = new Uint8Array(dataStr.length)
    
        while(n--)  {
            dataArr[n] = dataStr.charCodeAt(n)
          }
    
          let file = new File([dataArr], `img_cropper_${Date.now()}.png`, {type: mime})
      return file
      } else {
            return null
        }
    }
          
          
  // puts image into a state
  const confirmImg = () => {
    showCroppedImage()
    let img = baseToFile(croppedImage)
    if (img) {
      console.log(img)
      setFinalImage(img)
    } else {
      console.log(img)
      console.log("no img")
    }
  }
          
          
  // pushing image to firebase
  const pushImage = () => {
      
    if (finalImage == null) return;
    setUploading(true)
    let ImgPath = ''
    let timeStamp = Date.now();
    let coverRef = `/users/${authCtx.userId}/hubImages/${finalImage.name}`;
    
    const storageRef = sRef(storage, coverRef)

    
    // set img reference in datatbase 
    ImgPath = storageRef.fullPath

    // puts img in firebase storage
    uploadBytes(storageRef, finalImage ).then((snapshot) => {
      let imagePayload = {
        coverRef: ImgPath
      }
      
      let timeStamp = Date.now();
        
      var messagePayload = {
        "avatar": authCtx.avatarRef,
        "userId": authCtx.userId,
        "hubId": modalCtx.details.selectedNetwork,
        "userName": authCtx.userName,
        "message": "",
        "attachment": coverRef,
        "timestamp": timeStamp
      };
  
      const messageRef = ref(db, '/hubChat/' + modalCtx.details.selectedNetwork + '/' + timeStamp)
      const dmMessageRef = ref(db, '/directMessages/' + msgCtx.userId + '/' + timeStamp);        
  
      set(msgCtx.origin === "netChat" ? messageRef : dmMessageRef, messagePayload).then((snapshot) => {
      

        if(msgCtx.origin === "dmChat") {
          msgCtx.setDetails("dmChat", {updated: "yes", dmId: modalCtx.details.selectedNetwork});
        } else {
          msgCtx.setDetails("", "");
        }

        setUploading(false)

      }, (error) => console.log(error)
  )  
    
    }, (error) => console.log(error));
  }


    // colse image
  const onClose = useCallback(() => {
    setCroppedImage(null)
    closeHandle()
  }, [])
    
    // colse modal
  const closeHandle = () => {
		modalCtx.closeModal();
  }

  console.log(modalCtx)

  return (
    <div className='crop-container'>
      <div className="title">
        <h2>Edit Photo</h2>
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className='easy-crop'>
        { 
          image ? 
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspectX / aspectY}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          :
          <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      { image ? <span>Drag to reposition photo</span> : <span>Import a Image</span> }
      <div className='crop-btns' >
        <div className="sliders">
          <div className="slider">
            <h3> Zoom </h3>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              />
          </div>
          { modalCtx.details?.type !== "networkImg" &&
          <>
            <div className="slider">
              <h3> Aspect X </h3>
              <Slider
                value={aspectX}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, aspect) => setAspectX(aspect)}
                />
            </div>
            <div className="slider">
              <h3> Aspect Y </h3>
              <Slider
                value={aspectY}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, aspect) => setAspectY(aspect)}
                />
            </div> 
          </>
          }
        </div>
        <div className="upload-btn">
          <Button onClick={confirmImg} disabled={image ? false : true} >confirm</Button>
          <Button onClick={pushImage} disabled={finalImage ? false : true} >{uploading ?  <CircularProgress style={{color: "#fff"}} /> : "Upload"}</Button>
        </div>
      </div>
    </div>
  )
}

export default AddImage;
