import { TextField } from "@mui/material";
import { useState, useContext, useEffect } from 'react'
import Logo from '../../assets/img/home/logo.png'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { CloseRounded } from '@mui/icons-material'
import { Link, NavLink } from 'react-router-dom'
import AuthContext from '../../store/AuthContext.js';
import AvatarImage from '../../Components/Avatar/AvatarImage';

import JohnMac from "../../assets/img/avatar/JohnMac.jpg";
import JohnYork from "../../assets/img/avatar/JohnYork.jpg";
import StephenD from "../../assets/img/avatar/StephenD.jpg";

import SpiralIcon from '../../assets/img/banner/spiral.png';
import CircleIcon from '../../assets/img/banner/circle1.png';
import PolarIcon from '../../assets/img/banner/polar.png';
import PentagonIcon from '../../assets/img/banner/pentagon.png';
import TriangleIcon from '../../assets/img/banner/triangle1.png';

import FooterImage from '../../assets/img/footer/footer-img.png';


import style from "./Contact.module.scss";

export const Contact = () => {
    const [msgState,setMsgState] = useState(0);

    const authCtx 								    = useContext(AuthContext);
    const [openMenu, setOpenMenu]                   = useState(false)

    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, []);

    return (
        <div className={style.main}>
            <div 
                className={'home-container ' + style.wrapper} 
                style={openMenu ? {overflow: "hidden"} : null} 
            >
                
                <section className="nav-container">
            <nav>
                <div className="logo"><img src={Logo} alt="logo" /></div>

                <div className="links">
                  {
                    authCtx.isLoggedIn ? (
                      <div className="nav">
                        <div className='nav-links'>
                          <NavLink to="/networks"><span>Networks</span></NavLink>
                          <NavLink to="/explore"><span>Explore</span></NavLink>
                          <NavLink to="/messages"><span>Messages</span></NavLink>
                        </div>
                        <div className="profile">
                            <span>{`Hi ${authCtx.userName}`}</span>
                            <AvatarImage />
                        </div>
                      </div>
                    ) : (
                      <div className='link-container'>
                        <NavLink to="/login"><button className='login-button'>Login</button></NavLink>
                        <NavLink to="/register"><button className='register-button'>Sign Up</button></NavLink>
                      </div>
                    )
                  }
                </div>
                <MenuRoundedIcon className='menu-icon' style={openMenu ? {display: "none"} : null} onClick={() => setOpenMenu(true)} />
                <div className={openMenu ? "open mobile-links" : "mobile-links close"}>
                    <CloseRounded onClick={() => setOpenMenu(false)} />
                    {
                    authCtx.isLoggedIn ?

                    <div className="nav">
                        <div className='nav-links'>
                        <NavLink to="/home"><span>Home</span></NavLink>
                        <NavLink to="/networks"><span>Networks</span></NavLink>
                        <NavLink to="/explore"><span>Explore</span></NavLink>
                        <NavLink to="/messages"><span>Messages</span></NavLink>
                        </div>
                        <div className="profile">
                            <span>{`Hi ${authCtx.userName}`}</span>
                            <AvatarImage />
                        </div>
                    </div>
                    :
                    <div className='link-container'>
                        <NavLink to="/login"><button>Login</button></NavLink>
                        <NavLink to="/register"><button>Register for Free</button></NavLink>
                    </div>
                }
                </div>
            </nav>
        </section>

            <main className={"main-content " + style.wrapper__main}>
                <div className={"banner-bg " + style.wrapper__main__bg} >
                    <svg width={"101%"} height={"100%"} viewBox="1 0 500 500" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="250.199" y1="-0.089" x2="250.199" y2="273.474" id="gradient-1" gradientTransform="matrix(-0.000795, -0.999999, 1.833572, -0.001457, -0.237588, 380.700831)">
                                <stop offset="0" style={{ stopColor: 'rgb(28, 157, 164)'}}/>
                                <stop offset="0.245" style={{ stopColor: 'rgb(64, 110, 170)'}}/>
                                <stop offset="0.496" style={{ stopColor: "rgb(145, 76, 132)"}}/>
                                <stop offset="0.768" style={{ stopColor: 'rgb(199, 74, 90)'}}/>
                                <stop offset="1" style={{ stopColor: "rgb(243, 132, 40)"}}/>
                            </linearGradient>
                        </defs>
                        <path
                            style={{
                                paintOrder: 'fill',
                                fill: 'url(#gradient-1)',
                                fillRule: 'nonzero'
                            }}
                            d="M 500 271.166 L 500.399 0 L 0.399 -0.089 L 0 273.474 C 0 273.474 23.563 238.729 40.735 240.726 C 57.907 242.723 62.301 256.302 77.476 259.497 C 92.651 262.692 102.236 222.756 116.613 217.164 C 130.99 211.572 149.761 216.765 166.134 260.295 C 182.507 303.825 192.492 201.588 214.457 189.607 C 236.422 177.626 245.608 280.263 264.777 273.074 C 283.946 265.885 285.944 192.403 300.72 187.212 C 315.496 182.021 334.265 208.378 338.658 212.771 C 343.051 217.164 347.844 224.353 361.023 240.727 C 374.202 257.101 388.978 278.666 414.936 268.681 C 440.894 258.696 431.592 261.124 458.067 246.317 C 484.542 231.51 500 271.166 500 271.166 Z"/>
                    </svg>
                    <img className={"position-absolute"} style={{ left: '-75px', bottom: '55%'}} src={SpiralIcon} alt=""/>
                    <img className={"position-absolute"} style={{ right: '-95px', top: '0'}} src={SpiralIcon} alt=""/>

                    <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '8%', top: '100px'}} src={TriangleIcon} alt=""/>
                    <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '10%', top: '100px'}} src={TriangleIcon} alt=""/>

                    <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '64px', left: '35%', top: '80px'}} src={PolarIcon} alt=""/>
                    <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '64px', right: '20%', top: '280px'}} src={PolarIcon} alt=""/>
                    <img className={"position-absolute"} style={{ transform: 'rotate(-20deg)', width: '64px', left: '25%', top: '35%'}} src={PolarIcon} alt=""/>

                    <img className={"position-absolute"} style={{ width: '60px', left: '42%', top: '470px'}} src={CircleIcon} alt=""/>
                    <img className={"position-absolute"} style={{ width: '60px', right: '9%', top: '490px'}} src={CircleIcon} alt=""/>

                    <img className={"position-absolute"} style={{ transform: 'rotate(-25deg)', width: '60px', left: '12%', top: '460px'}} src={PentagonIcon} alt=""/>
                    <img className={"position-absolute"} style={{ transform: 'rotate(-55deg)', width: '60px', right: '25%', top: '650px'}} src={PentagonIcon} alt=""/>
                </div>
                <div className={style.wrapper__main__content}>
                    <div className={style.title}>Get In Touch</div>
                    <div className={style.form}>
                        <div className={style.form__name}>Contact us</div>
                        <TextField className={style.form__email} id="standard-basic" label="Email" variant="standard" />
                        <TextField
                            className={style.form__message}
                            label="Message"
                            name="comment"
                            inputProps={{ maxLength: 400 }}
                            minRows={4}
                            helperText={msgState + '/' + 400}
                            multiline
                            onChange={(e) => setMsgState(e.target.value.length)}
                        />
                        <Link className={style["submit-button"]} to="/contact">Submit</Link>
                        <div className={style.form__orText}>or</div>
                        <div className={style.form__name}>Contact Support</div>
                        <div className={style.form__helper}>Direct Message any of these profiles within the app for help and support.</div>
                        <div className={style.form__persons}>{[{
                            name: 'Stephen D',
                            image: StephenD
                        }, {
                            name: 'John Mac',
                            image: JohnMac
                        }, {
                            name: 'John York',
                            image: JohnYork
                        }].map((person, index) => (
                            <div className={style.form__person} key={index}>
                                <img className={style.form__person__image} src={person.image} alt="person avatar"/>
                                <div className={style.form__person__name}>{person.name}</div>
                                <Link className={[style["submit-button"], style.form__person__btn].join(" ")} to="/contact">Message</Link>
                            </div>
                        ))}</div>

                    </div>
                </div>
            </main>


        </div>
            <footer className={"footer-section " + style.footer}>
            <div className="custom-container">
                <div className="footer-block">
                    <img className={"footer-image"} src={FooterImage} alt=""/>
                    <div className={"footer-link"}>
                        <Link to={"/privacy-policy"}>Privacy Policy</Link>
                        <Link to={"/terms-of-use"}>Terms of use</Link>
                    </div>
                </div>
                <div className={"footer-copyright"}>Powered by SpokeHub, Inc. 2022 <sup>&copy;</sup></div>
            </div>
        </footer>
        </div>
    )
}
