import React, { createContext, useState } from 'react';

const GlobalContext = createContext({
  leaveNetwork: false,
  details: {},
  setDetails: (setDetails) => {},
  setLeaveNetwork: (setLeaveNetwork) => {},
});

export function GlobalContextProvider(props) {

  const [leaveNetwork, setLeaveNetwork] = useState(false);
  const [details, setDetails] = useState({});


  function setDetailsHandler(data) {
    setDetails(data);
  }

  function setLeaveNetworkHandler(bool) {
    setLeaveNetwork(bool);
  }

  const context = {
    leaveNetwork: leaveNetwork,
    details: details,
    setDetails: setDetailsHandler,
    setLeaveNetwork: setLeaveNetworkHandler,
  };

  return <GlobalContext.Provider value={context}>
    {props.children}
  </GlobalContext.Provider>
}

export default GlobalContext;