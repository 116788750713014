import React, { useContext, useState, useEffect } from 'react'
import UserProfileContext from '../store/UserProfileContext.js';
import AuthContext from '../store/AuthContext.js';
import AvatarChatImage from '../Components/Avatar/AvatarChatImage.jsx';
import NetworkImage from '../Components/Networks/NetworkImage.jsx';

import '../Styles/UserProfile.scss'

const UserProfile = () => {

    const userProfileCtx = useContext(UserProfileContext);
    
    const authCtx = useContext(AuthContext);

    const [userData, setUserData] = useState([]);
    
        const printUser = () => {
            let username = userProfileCtx.userName
            console.log(userProfileCtx.details)
            //userProfileCtx.setDetails(null, null)
            return username
        }

        useEffect(() => {

        fetch(process.env.REACT_APP_API_URI + '/v2/user/' + userProfileCtx.details + "/profile" , {
            method: 'GET',
            mode: 'cors',
            headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authCtx.token
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data.status === "success") {
            console.log(data.data)
            setUserData(data.data)
            console.log(userData)
            } else {
            // setOpenSnackbar(true);
            }
        });
    
        }, [])
    

  return (
        <>
            <div className="user-profile-container">
                <div className="profile-container">
                <div className="user-name">
                    <div className="name">{userData && userData?.name}</div>
                    <div className="username">@{userData && userData?.username}</div>
                </div>
                <div className="user-img">
                    {userData && <AvatarChatImage avatarRef={userData?.avatarRef} />}
                </div>
                </div>
                <div className="networks-container">
                {userData && userData?.networks?.map((data, i) => (
                    <div key={i} className="networks">
                                    <NetworkImage coverRef={ data.coverRef } title={data.title} />
                                    <div className="network-text">
                                        <h2>{data.title}</h2>
                        <h4>{data.headline}</h4>
                                    </div>
                                </div>
                ))}
                {
                    userData?.networks?.length === 0 ? <div className="no-networks">User has no networks yet.</div> : null
                }
                </div>
            </div>
        </>
  )
}

export default UserProfile