import React, { useEffect, useState, useContext } from 'react';
import { getDatabase, ref, set, get, child, onValue, orderByChild, limitToLast, update } from "firebase/database";
import { database, firestore } from "../../../../Components/FirebaseConfig.jsx";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import moment from 'moment';

import CircularProgress from '@mui/material/CircularProgress';

import CommentsDisabledRoundedIcon from '@mui/icons-material/CommentsDisabledRounded';

import clap from '../../../../assets/img/reactions/chat_reaction_clap.svg'
import heart from '../../../../assets/img/reactions/chat_reaction_heart.svg'
import laugh from '../../../../assets/img/reactions/chat_reaction_laugh.svg'
import thumb from '../../../../assets/img/reactions/chat_reaction_thumbs_up.svg'

import AuthContext from '../../../../store/AuthContext.js';
import ModalContext from '../../../../store/ModalContext.js';
import MessageContext from '../../../../store/MessageContext.js';
import UserProfileContext from '../../../../store/UserProfileContext.js';

import AvatarChatImage from '../../../../Components/Avatar/AvatarChatImage.jsx';
import ChatAttachmentImage from './ChatAttachmentImage.jsx'
import ReplyMessage from './ReplyMessage.jsx';
import { CloseRounded, MoreVertRounded, SentimentSatisfiedRounded } from '@mui/icons-material';
import MessageBox from './MessageBox.jsx';

const Chat = ({ selectedNetwork, streamId, liveType, videoTimeData }) => {

  // console.log(streamId)

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const msgCtx = useContext(MessageContext);
  const userProfileCtx = useContext(UserProfileContext);

  const db = database;
  let navigate = useNavigate();
  let location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenReaction, setIsOpenReaction] = useState(false);
  const [chats, setChats] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState();

  const [streamIdHub, setStreamIdHub] = useState();


  useEffect(() => {
    msgCtx.setOrigin(null, "netChat")
  }, [])

  useEffect(() => {

    getMembers()
    // getNetworks()
    messageListener()

  }, [streamId])

  const getMembers = () => {
    // fetching members
    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + streamId + '/members', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // console.log(data?.data)
        setMembers(data?.data)
        setAvatar(data.data)
      } else {
      }
    });
  }

  // const getNetworks = () => {
  //   // fetching members
  //   fetch(process.env.REACT_APP_API_URI + '/v2/hubs', {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authCtx.token
  //     }
  //   }).then(response => {
  //     return response.json();
  //   }).then(data => {
  //     if (data.status === "success") {
  //       console.log(data?.data)
  //     } else {
  //     }
  //   });
  // }

  const messageListener = () => {

    if (streamId !== '' && streamId !== null) {
      const dbRef = ref(db, '/streamChat/' + streamId, orderByChild('timestamp'), limitToLast(50));
      onValue(dbRef, (snapshot) => {
        setChats([]);
        setChats(snapshotToArray(snapshot));
        setIsLoading(false);
      });
      // console.log(chats)
    }
  }


  const snapshotToArray = (snapshot) => {

    const returnArr = [];
    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });
    return returnArr.reverse();

  }

  const messageFunction = (data) => {
    if (data.replyMessage) {
      return <ReplyMessage data={data} />

    } else if (data.previewPath) {
      return <ChatAttachmentImage attachment={data.attachment} previewPath={data.previewPath} />

    } else if (data.attachment) {
      return <ChatAttachmentImage attachment={data.attachment} />

    } else {
      return null;
    }
  }

  const handleReaction = (data) => {
    console.log("reaction open")
    setUserData(data)
    if (!isOpenReaction) {
      setIsOpenReaction(true)
    } else {
      setIsOpenReaction(false)
    }
  }

  const prepareMentionMsg = (msg, taggedHubs) => {

    let finalMsg = ''
    let array = msg?.split(' ');

    const map1 = array.map(x => {
      if (x.includes("@[")) {
        console.log(x)
        let returnArr = ""
        var value = x.substring(
          x.lastIndexOf("[") + 1,
          x.lastIndexOf("]"))
        var key = x.substring(
          x.lastIndexOf("(") + 1,
          x.lastIndexOf(")"));
        returnArr = value
        if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(returnArr)) {
          return returnArr
        } else if (/[@]/g.test(returnArr)) {
          return `@${returnArr}`
        } else {
          return ` @${returnArr}`
        }
      } else if (/[@]/g.test(x)) {
        return ` ${x}`
      } else {
        return x
      }
    });


    // console.log(map1.join(" "))
    //finalMsg = map1.join(" ")
    return map1?.map((word) => {
      if (/(([^\s@]+@[^\s@]+\.[^\s@]+))$/.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[@]/g.test(word)) {
        return <strong className='mention-text' onClick={(e) => passUserName("userProfile", e.target.innerText)} >{word}</strong>
      } else if (/[#]/g.test(word)) {
        return <NavLink to={`/networks/${passHub(word, taggedHubs)}`} className='mention-text'>{word}</NavLink>
      } else if (/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi.test(word)) {
        return <a className='mention-text' target='_blank' rel="noreferrer" href={word} >{word}</a>
      } else {
        return " " + word + " "
      }
    })
  }


  const passHub = (networkName, taggedHubs) => {
    let newNetwork = networkName.substring(1)
    for (let i = 0; i < taggedHubs?.length; i++) {
      if (taggedHubs[i].hubName === newNetwork) {
        return taggedHubs[i].hubId
      }
    }
  }

  const passUserName = (modal, userName) => {
    console.log(userName)
    let newUserName = userName.substring(1)
    for (let i = 0; i < members?.length; i++) {
      console.log(newUserName)
      if (members[i].username === newUserName) {
        // userProfileCtx.setDetails(newUserName, members[i].id) 
        // let path = `/user/${members[i].username}`; 
        // navigate(path);
        modalCtx.openModal();
        modalCtx.setDetails(modal, members[i]?.id);
      }
    }
  }

  const checkForAvatar = (id) => {

    for (let i = 0; i < avatar.length; i++) {
      if (id == avatar[i].id) {
        return avatar[i].avatar
      } else {
      }
    }

  }

  const showModal = (modal, video) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, video);
  }

  const timestampToDate = (t) => {
    var time = new Date(t)
    let relTime = moment(time).fromNow()
    var lastIndex = relTime.lastIndexOf(" ");
    return relTime = relTime.substring(0, lastIndex);
  }

  const addReaction = (reaction) => {

    let hubId = userData?.hubId;
    let timeStamp = userData.timestamp;
    var clapCount = userData?.clapReactionCount ? userData?.clapReactionCount : 0;
    var heartCount = userData?.heartReactionCount ? userData?.heartReactionCount : 0;
    var laughCount = userData?.laughReactionCount ? userData?.laughReactionCount : 0;
    var thumbsUpCount = userData?.thumbsUpReactionCount ? userData?.thumbsUpReactionCount : 0;

    let payload = {}

    switch (reaction) {
      case "clap":
        clapCount++;
        payload["clapReactionCount"] = clapCount;
        break;

      case "heart":
        heartCount++;
        payload["heartReactionCount"] = heartCount;
        break;

      case "laugh":
        laughCount++;
        payload["laughReactionCount"] = laughCount;
        break;

      case "thumbs":
        thumbsUpCount++;
        payload["thumbsUpReactionCount"] = thumbsUpCount;
        break;

      default: return null;
    }

    const messageRef = ref(db, '/hubChat/' + hubId + '/' + timeStamp)
    update(messageRef, payload)
    setIsOpenReaction(false)
  }

  console.log(chats)
  //console.log(msgCtx.origin)

  if (isLoading) {
    return <div className="container loading"><CircularProgress style={{ 'color': '#007C89' }} size={60} /></div>
  }

  // console.log(videoTimeData)
  // console.log(chats)

  return (
    <>
      <div className="chat" style={liveType == 'completed' ? { height: '100%' } : null}>
        <div className="messages">
          {
            chats?.map((data, index) => (
              <>
                <div key={data.timestamp} style={Math.round((data.videoTimestamp % 60000) / 1000) <= Math.round(videoTimeData?.playedSeconds) ? null : { display: 'none' }} className={data.userId === authCtx.userId ? "message message-mine" : "message"}>
                  <span className='message-text'>
                    {messageFunction(data)}
                    {
                      data.message !== ""
                        ? prepareMentionMsg(data.message, data.taggedHubs)
                        : null
                    }
                    <div className='message-emote-time' >
                      {/* <div className="time">{timestampToDate(data.timestamp)}</div> */}
                      {data.clapReactionCount ? <div className='reaction' ><img src={clap} alt="clap" /><span className='reaction-count'>{data.clapReactionCount}</span> </div> : null}
                      {data.thumbsUpReactionCount ? <div className='reaction' ><img src={thumb} alt="thumb" /><span className='reaction-count'>{data.thumbsUpReactionCount}</span> </div> : null}
                      {data.heartReactionCount ? <div className='reaction' ><img src={heart} alt="heart" /><span className='reaction-count'>{data.heartReactionCount}</span> </div> : null}
                      {data.laughReactionCount ? <div className='reaction' ><img src={laugh} alt="laugh" /><span className='reaction-count'>{data.laughReactionCount}</span> </div> : null}
                    </div>
                  </span>
                  {
                    liveType == 'completed'
                      ? null
                      : <div className="message-emote-action">
                        <SentimentSatisfiedRounded onClick={() => handleReaction(data)} />
                        {/* <MoreVertRounded onClick={() => showModal('ShowAction', data)} /> */}
                      </div>
                  }

                  <div className='chat-avatar' style={{ minWidth: "35px" }} onClick={() => showModal("userProfile", data.userId)} >
                    <AvatarChatImage avatarRef={checkForAvatar(data.userId)} title={data.userName} />
                    <div className="name">
                      {data.userName}
                    </div>
                  </div>
                </div>
                {
                  liveType == 'completed'
                    ? null
                    : <div className="add-reaction" style={isOpenReaction ? null : { display: "none" }} >
                      <div className="reactions">
                        <img src={clap} alt="clap" onClick={() => addReaction("clap", data)} />
                        <img src={heart} alt="heart" onClick={() => addReaction("heart", data)} />
                        <img src={laugh} alt="laugh" onClick={() => addReaction("laugh", data)} />
                        <img src={thumb} alt="thumb" onClick={() => addReaction("thumbs", data)} />
                        <CloseRounded className='close-reaction' onClick={handleReaction} />
                      </div>
                    </div>
                }

              </>
            )
            )
          }
        </div>
      </div>
      {
        liveType == 'completed'
          ? null
          : <MessageBox selectedNetwork={selectedNetwork} streamId={streamId} videoTimeData={videoTimeData} />
      }
    </>
  )
}


export default Chat