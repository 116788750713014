import React from 'react'
import tickmark from '../assets/img/create-network/network-creation-successful.png';
import CustomButton from '../common/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';

export default function NetworkCreated() {
  const navigate=useNavigate()

  const {id} = useParams()

  const onClickGoToNetwork=()=>{
    navigate(`/networks/${id}`)
  }



  return (
    <div className='network-created-container'>
      <div className="network-created-box">
          <div className="network-created-tickmark-img">
            <img src={tickmark} alt="" />
          </div>
          <div className="network-created-msg">
            <h1>Network Created!</h1>
            <h5>Your network has been created successfully.</h5>
          </div>
          <div className="go-to-your-network-btn">
              <CustomButton onClick={onClickGoToNetwork}>Go To Your Network</CustomButton>
          </div>
      </div>
    </div>
  )
}
