import React, { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

import CardSection from './CardSection';

import AuthContext from "../../store/AuthContext";

export default function CheckoutFormCard(props) {
  
  const stripe                    = useStripe();
  const elements                  = useElements();

  const [message, setMessage]     = useState(null);
  const [isPaying, setIsPaying]   = useState(false);

  const authCtx 				          = useContext(AuthContext);
  let navigate                    = useNavigate();
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) { return; }
    setIsPaying(true);

    const result = await stripe.confirmCardPayment(props.stripeSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.userName,
          email: props.userEmail
        },
      }      
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log(result);
        
        fetch(process.env.REACT_APP_API_URI + '/v2/stripe/creator/paymentComplete', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: props.userId,
            action: "payment-complete",
            details: {
              subscriptionId: props.subscriptionId,
              transactionId: props.transactionId,
              subscriptionStatus: 'active',
              transactionStatus: 'paid'
            }
          }),
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if(data.status == "success") {
            navigate("/creators/" + props.userId + "/payments/completed", {replace: true});
          } else {
            navigate("/creators/" + props.userId + "/payments/incomplete", {replace: true});
            setIsPaying(false);
            // setOpenSnackbar(true);
          }
          
        }).error(err => console.log(err));
        
      }
    }

    
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "95%"}}>
      <CardSection />
      <button disabled={!stripe}>{ isPaying ? <div style={{ padding: "0" }}><CircularProgress  style={{'color': '#fff'}} size={20} /></div> : <span>Pay Now</span> }</button>
    </form>
  );
}